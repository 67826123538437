import React from 'react';
import moment from "moment";
import _ from "lodash";
import {BASEURL} from "../config/AppUrl";
import ExportFileService from "../service/ExportFileService";
import IconButton from "@mui/material/IconButton";
import {RoleUser} from "../constants/type/RoleUser";
import {Employee} from "../constants/type/Employee";
import Tooltip from '@mui/material/Tooltip';

moment.locale('fr', {
    months : 'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split('_'),
    monthsShort : 'janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.'.split('_'),
    monthsParseExact : true,
    weekdays : 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
    weekdaysShort : 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
    weekdaysMin : 'Di_Lu_Ma_Me_Je_Ve_Sa'.split('_'),
    weekdaysParseExact : true,
    longDateFormat : {
        LT : 'HH:mm',
        LTS : 'HH:mm:ss',
        L : 'DD/MM/YYYY',
        LL : 'D MMMM YYYY',
        LLL : 'D MMMM YYYY HH:mm',
        LLLL : 'dddd D MMMM YYYY HH:mm'
    },
    calendar : {
        sameDay : '[Aujourd’hui à] LT',
        nextDay : '[Demain à] LT',
        nextWeek : 'dddd [à] LT',
        lastDay : '[Hier à] LT',
        lastWeek : 'dddd [dernier à] LT',
        sameElse : 'L'
    },
    relativeTime : {
        future : 'dans %s',
        past : 'il y a %s',
        s : 'quelques secondes',
        m : 'une minute',
        mm : '%d minutes',
        h : 'une heure',
        hh : '%d heures',
        d : 'un jour',
        dd : '%d jours',
        M : 'un mois',
        MM : '%d mois',
        y : 'un an',
        yy : '%d ans'
    },
    dayOfMonthOrdinalParse : /\d{1,2}(er|e)/,
    ordinal : function (number) {
        return number + (number === 1 ? 'er' : 'e');
    },
    meridiemParse : /PD|MD/,
    isPM : function (input) {
        return input.charAt(0) === 'M';
    },
    // In case the meridiem units are not separated around 12, then implement
    // this function (look at locale/id.js for an example).
    // meridiemHour : function (hour, meridiem) {
    //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
    // },
    meridiem : function (hours, minutes, isLower) {
        return hours < 12 ? 'PD' : 'MD';
    },
    week : {
        dow : 1, // Monday is the first day of the week.
        doy : 4  // Used to determine first week of the year.
    }
});

export  const WEBSOCKET_RECONNECTDELAY = 30000;
const MONTHS_BAR_NUMBER = 4;
const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
const SHORT_MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const excelIcon = BASEURL+'/images/xls.png';
export const pdfIcon = BASEURL+'/images/pdf.png';

export const canShowButton = (user: any, menutag:string, userActions:string[]):any =>{
    const droitmenus = _.cloneDeep(user?.userDetails?.droitMenus);
    let menu = null ;
    if(_.isArray(droitmenus)){
        menu = _.find(droitmenus, item => item.common.tag === menutag);
    }
    if(_.isNull(menu?.droits)){
        return false;
    }
    if(_.isUndefined(menu?.droits)){
        return false;
    }
    return !_.isEmpty(_.intersection(_.split(menu.droits, ','), userActions));
}
export const ExcelButton = (props:any) => {
    return (
        <IconButton
            title={props.title}
            sx={props.sx ?? {}}
            disabled={props.disabled ?? false}
            onClick={(a) => handleExportToXLX(props)}>
            <img width="24" height="24" src={excelIcon} alt="Excel icon"/>
        </IconButton>
    );
}

export const ExcelIconButton = (props:any) => {
    return (
        <Tooltip title={props.title}>
            <IconButton
                aria-label="export"
                color="secondary"
                disabled={props.disabled ?? false}
                onClick={(a) => handleExportToXLX(props)}
                sx={props.sx ?? {}}>
                {props.icon}
            </IconButton>
        </Tooltip>

    );
}


export const handleExportToXLX = (props:any) => {
    ExportFileService.exportToCSV(props.excelDataMapping, props.nomFichier);
}

export function isNumericValue(input: any):boolean {
    if(_.isNull(input)){
        return false ;
    }
    if(_.isUndefined(input)){
        return false ;
    }
    if(_.isNumber(input)){
        return true ;
    }
    if(_.isInteger(input)){
        return true ;
    }
    return typeof input === 'string' && !Number.isNaN(input);
}

export function isNotObject(value:any) {
    return !_.isObject(value);
}
export function isNotBlank(value:any) {
    return !isBlank(value);
}


export function isBlank(value:any) {
    return _.isEmpty(value) && !_.isNumber(value) || _.isNaN(value);
}

export function isPositiveValue(input: any):boolean {
    if(_.isNull(input)){
        return false ;
    }
    if(_.isUndefined(input)){
        return false ;
    }
    if(_.isNumber(input) && input > 0){
        return true ;
    }
    if(_.isInteger(input) && input > 0){
        return true ;
    }
    return typeof input === 'string' && !Number.isNaN(input) && _.toNumber(input) > 0 ;
}

export function checkTeacherUser (logic:any, stateUpdate:any) {
    if(logic?.userRef?.current?.role !== RoleUser.TEACHER){
        stateUpdate.isTeacherRef(logic, false);
        return false;
    }
    stateUpdate.isTeacherRef(logic, true);
    const employeeList = _.cloneDeep(logic?.employeesByFonctionRef?.current);
    const sz = employeeList.length;
    let employee:Employee;
    const person = logic.userRef.current?.userDetails?.personDetails?.person ;
    for (let i = 0; i < sz; i++) {
        employee = employeeList[i];
        if(employee.id !== person.id){
            continue ;
        }
        stateUpdate.teacherRef(logic, employee);
        break ;
    }
    const classeId = logic.userRef?.current?.userDetails?.personDetails?.teacherClasses[0]?.classeId ?? 0;
    stateUpdate.classeIdRef(logic, classeId);
    return true ;
}

function hashCode(str:string) { // java String#hashCode
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
}

export const stringToMaterialColor = (str:any) => {
    const colors = [
        '#F44336', '#FF5252', '#C62828',
        '#607D8B', '#B0BEC5', '#455A64',
        '#E91E63', '#FF4081', '#AD1457',
        '#2196F3', '#448AFF', '#1565C0',
        '#9C27B0', '#E040FB', '#6A1B9A',
        '#009688', '#64FFDA', '#00695C',
        '#795548', '#BCAAA4', '#4E342E',
        '#673AB7', '#7C4DFF', '#512DA8',
        '#FF5722', '#FFAB40', '#F57C00',
        '#FFEB3B', '#FFFF00', '#F9A825',
        '#8BC34A', '#B2FF59', '#558B2F'];
    const shades = [300, 400, 500, 600, 700];

    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    const colorIndex = Math.abs(hash) % colors.length;
    const shadeIndex = Math.abs(hash >> 8) % shades.length;

    const color = colors[colorIndex];
 //   const shade = shades[shadeIndex];

    return color;
};


const hexToRgb = (hex:any) => {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m:any, r:any, g:any, b:any) => r + r + g + g + b + b);
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
};

export function stringToColor(str: string) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    const c = (hash & 0x00FFFFFF).toString(16).toUpperCase();
    return '#' + '00000'.substring(0, 6 - c.length) + c;
    /*let hash = 0;
    let i;

    /!* eslint-disable no-bitwise *!/
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /!* eslint-enable no-bitwise *!/

    return color;*/
}

export function stringAvatar(name: string | undefined | null) {
    if(_.isNull(name) || _.isUndefined(name) || _.size(name) < 2){
        return {
            sx: {
                bgcolor: stringToColor("NONE"),
            },
            children: `NONE`,
        };
    }
    const nameList = name.split(' ');
    if(_.isNull(nameList) || _.isUndefined(nameList) || _.size(nameList) < 2){
        return {
            sx: {
                bgcolor: stringToColor("NC"),
            },
            children: `NC`,
        };
    }
    return {
        sx: {
            bgcolor: stringToMaterialColor(name),
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}

const dropZoneFilePreview = (file:any, setFileDataURL:any) => {
    let fileReader:any, isCancel = false;
    if (file) {
        fileReader = new FileReader();
        fileReader.onload = (e:any) => {
            const { result } = e.target;
            if (result && !isCancel) {
                setFileDataURL(result)
            }
        }
        fileReader.readAsDataURL(file);
    }
    return () => {
        isCancel = true;
        if (fileReader && fileReader.readyState === 1) {
            fileReader.abort();
        }
    }
};

const getMonthsListBarByDate = (date:number) => {
    const theMonth = moment(date).format('MMMM');

    let findMonth:any = {};
    for (let i=0; i<MONTHS.length; i++){
        if(MONTHS[i] === theMonth){
            findMonth = {
                month:theMonth,
                index: i,
            }
        }
    }

    let monthsList = [];
    const indexFind = findMonth.index;

    let j= 0;
    for(let i=indexFind; i <= 20; i--){
        if(i >= 0){
            if(j < MONTHS_BAR_NUMBER){
                const data = {
                    month: MONTHS[i],
                    year: parseInt(moment(date).format('YYYY'))
                }
                monthsList.push(data);
                j++;
            }
            else
                break;
        }
        else
            break;
    }

    //monthsList.reverse();
    const countMonths = monthsList.length;
    if(countMonths < MONTHS_BAR_NUMBER){
        const diff = MONTHS_BAR_NUMBER - countMonths;
        const year = parseInt(moment(date).format('YYYY')) - 1;
        let j=1;
        for (let i=(MONTHS.length -1); i<MONTHS.length; i--){
            const data = {
                month: MONTHS[i],
                year: year
            }
            monthsList.push(data);

            if(j === diff){
                break;
            }
            j++;
        }
        monthsList.reverse();
    }

    return monthsList;
}

export const isColumnVisible = (columns:any[], tofind:string) =>  _.findIndex(columns, {'id':tofind}) >= 0 ;

function getShortMonthName(name:string) {
    //const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    //const shortMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    let monthFindIndex:number | null = null;
    for(let i=0; i<MONTHS.length; i++ ){
        if(MONTHS[i] === name){
            monthFindIndex = i;
        }
    }

    if(monthFindIndex !== null){
        return SHORT_MONTHS[monthFindIndex]
    }
    else {
        return null;
    }
}

function getNextMonthsListByDate(date:any){
    //const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    let findMonth:any = {};
    const theMonth = moment(date).format('MMMM');

    for (let i=0; i<MONTHS.length; i++){
        if(MONTHS[i] === theMonth){
            findMonth = {
                month:theMonth,
                index: i,
            }
        }
    }

    let monthsList = [];
    const indexFind = findMonth.index;

    let j= 0;
    for(let i=indexFind; i<MONTHS.length; i++){
        const data = {
            month: MONTHS[i],
            year: parseInt(moment(date).format('YYYY'))
        }
        monthsList.push(data);
        j++;
    }

    return monthsList;
}

function getPrevMonthsListByDate(date:any){
    const theMonth = moment(date).format('MMMM');
    let findMonth:any = {};
    for (let i=0; i<MONTHS.length; i++){
        if(MONTHS[i] === theMonth){
            findMonth = {
                month:theMonth,
                index: i,
            }
        }
    }

    let monthsList:any = [];
    const indexFind = findMonth.index;

    for(let i=indexFind; i<MONTHS.length; i--){
        if(i >= 0){
            const data = {
                month: MONTHS[i],
                year: parseInt(moment(date).format('YYYY'))
            }
            monthsList.push(data);
        }
        else
            break;
    }

    return monthsList;
}

function getFirstLastDateWeekByDate (day:number) {
    const startOfWeek = moment(day).weekday(1);
    const endOfWeek   = moment(day).weekday(7);
    return [startOfWeek, endOfWeek];
}

function getPathNameAndSetTitle (userMenus:any) {
    const path = window.location.pathname;
    let findPath = null;

    if(userMenus.length>0){
        for(let i=0; i<userMenus.length; i++){
            if(userMenus[i].lien !== null && userMenus[i].lien === path){
                findPath = userMenus[i];
            }
            else {
                const children = userMenus[i].children;
                if(children.length>0){
                    const lien = children.find((link:any) => link.lien === path);
                    if(lien !== undefined){
                        findPath = lien;
                    }
                }
            }
        }

        return findPath;
    }
}

export {
    dropZoneFilePreview,
    getMonthsListBarByDate,
    getShortMonthName,
    getNextMonthsListByDate,
    getPrevMonthsListByDate,
    getFirstLastDateWeekByDate,
    getPathNameAndSetTitle
};
