import {darken, Icon, Paper, Typography} from "@mui/material";
import classNames from "classnames";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme:any) => {
    return {
        rootPar:{
            '& $root': {
                width: '100%'
            }
        },
        root: {
            padding: `${theme.spacing(3)}px ${theme.spacing(1)}px`,
            marginBottom: theme.spacing(3),
            boxShadow: theme.shade.light,
            color: theme.palette.text.primary,
            [theme.breakpoints.up('sm')]: {
                padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`
            },
            '&$noMargin': {
                margin: 0
            },
        },
        descBlock: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(1),
            paddingTop: theme.spacing(1),
            marginBottom: theme.spacing(0),
            [theme.breakpoints.down('sm')]: {
                marginBottom: theme.spacing(3),
            },
        },
        titleText: {
            flex: 1,
        },
        title: {
            position: 'relative',
            fontSize: '18px !important',
            fontWeight: 700,
            [theme.breakpoints.down('xs')]: {
                textAlign: 'center',
                fontWeight: 600,
                marginBottom: theme.spacing(1)
            },
        },
        description: {
            maxWidth: 960,
            paddingTop: theme.spacing(0.5),
            [theme.breakpoints.down('xs')]: {
                textAlign: 'center'
            }
        },
        content: {
            marginTop: theme.spacing(2),
            padding: theme.spacing(1),
            borderRadius: theme.rounded.medium,
            backgroundColor: theme.palette.background.default,
            [theme.breakpoints.up('lg')]: {
                padding: theme.spacing(2)
            }
        },
        contentnodesc:{
            marginTop: theme.spacing(2),
            padding: theme.spacing(0.4),
            borderRadius: theme.rounded.medium,
            backgroundColor: theme.palette.background.default,
            [theme.breakpoints.up('lg')]: {
                padding: theme.spacing(2)
            }
        },
        whiteBg: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
        },
        noMargin: {},
        colorDark: {
            backgroundColor: theme.palette.type === 'dark' ? darken(theme.palette.primary.dark, 0.5) : theme.palette.primary.main,
            '& $title': {
                color: theme.palette.grey[100],
            },
            '& $description': {
                color: theme.palette.grey[100],
            }
        },
        colorLight: {
            backgroundColor: theme.palette.type === 'dark' ? darken(theme.palette.primary.dark, 0.5) : theme.palette.primary.light,
            '& $title': {
                color: theme.palette.type === 'dark' ? theme.palette.common.white : theme.palette.text.primary,
            },
            '& $description': {
                color: theme.palette.text.primary,
            }
        },
        overflowX: {
            width: '100% !important',
            overflowX: 'auto',
        },
        iconTitle: {
            borderRadius: '50%',
            background: theme.palette.background.default,
            width: 48,
            height: 48,
            textAlign: 'center',
            lineHeight: '44px',
            verticalAlign: 'middle',
            marginRight: theme.spacing(2),
            [theme.breakpoints.down('xs')]: {
                display: 'none'
            },
            '& span': {
                width: '60%',
                height: '60%',
                verticalAlign: 'middle',
                color: theme.palette.type === 'dark' ? theme.palette.secondary.light : theme.palette.primary.main
            }
        }
    }
})

export default function PapperBlock(props:any) {
    const {
        title,
        desc,
        children,
        whiteBg,
        noMargin,
        colorMode,
        overflowX,
        icon,
        displayNoTitle,
    } = props;
    const classes = useStyles();

    const color = (mode:any) => {
        switch (mode) {
            case 'light':
                return classes.colorLight;
            case 'dark':
                return classes.colorDark;
            default:
                return classes.colorLight;
        }
    };

    return (
        <div className={classes.rootPar}>
            <Paper
                className={classNames(classes.root, noMargin && classes.noMargin, color(colorMode ?? '#fff'))}
                elevation={2}
            >
                <div className={classes.descBlock} style={{ display: displayNoTitle ? 'none' : ''}}>
                    <span className={classes.iconTitle}>
                        <Icon>{icon}</Icon>
                    </span>
                    <div className={classes.titleText}>
                        <Typography variant="h6" component="h2" className={classes.title} >
                            {title}
                        </Typography>
                        <Typography component="p" className={classes.description}>
                            {desc}
                        </Typography>
                    </div>
                </div>
                <section className={classNames(classes.content, whiteBg && classes.whiteBg, overflowX && classes.overflowX)}>
                    {children}
                </section>
            </Paper>
        </div>
    )

}
