import React, {useEffect, useMemo, useRef} from 'react';
import SchoolingSubMenu from "../SchoolingSubMenu";
import Box from "@mui/material/Box";
import {useForm} from "react-hook-form";
import _ from "lodash";
import {yupResolver} from "@hookform/resolvers/yup";
import scolariteStyles from "../jss/scolarite-jss";
import {momentLocalizer} from "react-big-calendar";
import moment from "moment/moment";
import {useDispatch} from "react-redux";
import useFraisOneShotEnfantVariables from './businesslogic/MatchingFraisOneShotEnfantVariables';
import matchingFraisOneShotEnfantBusinessLogic from "./businesslogic/MatchingFraisOneShotEnfantBusinessLogic";
import {withSnackbar} from "../../../components/ui/snackbar/SnackbarHOC";
import {
    Avatar,
    Button,
    Card,
    CardHeader,
    Checkbox,
    CircularProgress,
    Divider,
    FormControl,
    Grid,
    ListItemText,
    MenuItem,
    TextField,
    Typography
} from "@mui/material";
import {FormattedMessage, useIntl} from "react-intl";
import messages from "../../messages/general/messages";
import {
    ScolariteSansEcheanceEnfant,
    scolariteSansEcheanceEnfantDataImpl,
    scolariteSansEcheanceEnfantSchema
} from "../../../constants/type/ScolariteSansEcheanceEnfant";
import {isNotObject, isPositiveValue} from "../../../helpers/helpers";
import matchingFraisOneShotEnfantStateUpdate from './businesslogic/MatchingFraisOneShotEnfantStateUpdate';
import {GroupEnfant} from "../../../constants/type/GroupEnfant";
import {ScolariteGroup} from "../../../constants/type/ScolariteGroup";
import PapperBlock from "../../../components/ui/papperBlock/PapperBlock";
import List from '@mui/material/List';
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";

const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 1;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const useBusinessLogic = (props: any): any => {
    const styleClasses = scolariteStyles();
    const formRef = React.useRef<any>(useForm({
        defaultValues:_.cloneDeep(scolariteSansEcheanceEnfantDataImpl),
        // @ts-ignore
        resolver: yupResolver(scolariteSansEcheanceEnfantSchema),
    }));


    return {
        theme: props.theme,
        styleClasses: styleClasses,
        formRef,
        ...useFraisOneShotEnfantVariables({styleClasses, ...props})
    }
};

function MatchingFraisOneShotEnfant(props:any) {
    const { locale, theme} = props;
    const localizer = momentLocalizer(moment);
    const intl = useIntl() ;
    const logic = useBusinessLogic(props);
    const dispatch = useDispatch();

    const styleClasses = logic.styleClasses;

    const menuLeaveTimerRef = useRef<any>();

    const handleOnScolariteAnneChange = (event: any) => {
        const idSel = parseInt(event.target.value);
        const dataFind = _.find(logic.scolariteAnneesRef.current, {'id': idSel});
        if(isNotObject(dataFind)){
            return ;
        }
        matchingFraisOneShotEnfantStateUpdate.scolariteAnneeSel(logic, dataFind);
        let scolariteGroups : ScolariteGroup[] = [];
        if(isPositiveValue(logic.groupSel?.id)){
            scolariteGroups =  _.filter(logic.scolariteGroupsRef.current, item => {
                return item.scolariteAnneeId === idSel && item.groupesectionId === logic.groupSel?.id ;
            });
        }

        matchingFraisOneShotEnfantStateUpdate.scolariteGroupList(logic, scolariteGroups);
        matchingFraisOneShotEnfantStateUpdate.numeriChange(logic, new Date().getTime());
    };

    const handleOnGroupChange = (event: any) => {
        const idSel = parseInt(event.target.value);
        const dataFind = logic.groupesectionMapRef.current.get(idSel);
        if(isNotObject(dataFind)){
            return ;
        }
        matchingFraisOneShotEnfantStateUpdate.groupSel(logic, dataFind);

        let scolariteGroups : ScolariteGroup[] = [];
        if(isPositiveValue(logic.scolariteAnneeSel?.id)){
            scolariteGroups =  _.filter(logic.scolariteGroupsRef.current, item => {
                return item.scolariteAnneeId === logic.scolariteAnneeSel.id && item.groupesectionId === idSel ;
            });
        }

        matchingFraisOneShotEnfantStateUpdate.scolariteGroupList(logic, scolariteGroups);
        matchingFraisOneShotEnfantStateUpdate.numeriChange(logic, new Date().getTime());
    };

    const handleOnScolariteGroupChange = (event: any) => {
        const idSel = parseInt(event.target.value);
        const dataFind = logic.scolariteGroupMapRef.current.get(idSel);
        if(isNotObject(dataFind)){
            return ;
        }
        matchingFraisOneShotEnfantStateUpdate.scolariteGroupSel(logic, dataFind);
        matchingFraisOneShotEnfantStateUpdate.numeriChange(logic, new Date().getTime());
    };

    const handleToggleCheck = (item:  GroupEnfant) => () => {
        const currentIndex = logic.checkedList.indexOf(item.enfantId);
        const newChecked = _.cloneDeep(logic.checkedList);

        if (currentIndex < 0) {
            newChecked.push(item.enfantId);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        matchingFraisOneShotEnfantStateUpdate.checkedList(logic, newChecked);
    };

    const moveAllToRight = () => {
        matchingFraisOneShotEnfantStateUpdate.checkedList(logic, []) ;
        matchingFraisOneShotEnfantStateUpdate.leftList(logic, []) ;
        matchingFraisOneShotEnfantStateUpdate.rightList(logic, logic.childrenGroupList) ;
    };

    const moveAllToLeft = () => {
        matchingFraisOneShotEnfantStateUpdate.checkedList(logic, []) ;
        matchingFraisOneShotEnfantStateUpdate.rightList(logic, []) ;
        matchingFraisOneShotEnfantStateUpdate.leftList(logic, logic.childrenGroupList) ;
    };


    const moveCheckedToLeft = () => {
        let rightList : GroupEnfant[]   = _.clone(logic.rightList);
        let leftList : GroupEnfant[]    = _.clone(logic.leftList);
        const rightCheked : GroupEnfant[]       = _.filter(rightList, item => _.includes(logic.checkedList, item.enfantId));

        leftList    = _.concat(leftList, rightCheked);
        rightList   = _.filter(logic.rightList, item => !_.includes(logic.checkedList, item.enfantId));

        matchingFraisOneShotEnfantStateUpdate.checkedList(logic, []) ;
        matchingFraisOneShotEnfantStateUpdate.leftList(logic, leftList) ;
        matchingFraisOneShotEnfantStateUpdate.rightList(logic, rightList) ;
    };

    const moveCheckedToRight = () => {
        let rightList : GroupEnfant[]   = _.clone(logic.rightList);
        let leftList : GroupEnfant[]    = _.clone(logic.leftList);
        const leftCheked : GroupEnfant[]       = _.filter(leftList, item => _.includes(logic.checkedList, item.enfantId));

        rightList  = _.concat(rightList, leftCheked);
        leftList   = _.filter(logic.leftList, item => !_.includes(logic.checkedList, item.enfantId));

        matchingFraisOneShotEnfantStateUpdate.checkedList(logic, []) ;
        matchingFraisOneShotEnfantStateUpdate.leftList(logic, leftList) ;
        matchingFraisOneShotEnfantStateUpdate.rightList(logic, rightList) ;
    };

    const numberOfChecked = (items: readonly number[]) => _.size(_.intersection(items, logic.checkedList));
    const isNoneChecked = (position: string) => {
        return position === 'left' ?
            numberOfChecked(_.map(logic.leftList, item => item.enfantId)) <= 0 :
            numberOfChecked(_.map(logic.rightList, item => item.enfantId)) <= 0
            ;
    };

    const handleSubmit = async () => {
        if(_.isEmpty(logic.leftList) && _.isEmpty(logic.rightList)){
            return ;
        }
        matchingFraisOneShotEnfantStateUpdate.processingStateNormBtn(logic, true);

        matchingFraisOneShotEnfantBusinessLogic.saveEdit(logic, messages, logic.leftList, logic.rightList, logic.scolariteGroupSel?.id).then((response:any) => {
            matchingFraisOneShotEnfantStateUpdate.numeriChange(logic, new Date().getTime());
            matchingFraisOneShotEnfantStateUpdate.processingStateNormBtn(logic, false);
        }).catch((e:any) => {
            matchingFraisOneShotEnfantStateUpdate.processingStateNormBtn(logic, false);
        });
    }

    useEffect(() => {
        clearTimeout(menuLeaveTimerRef.current);


        if(!isPositiveValue(logic.scolariteAnneeSel?.id) || !isPositiveValue(logic.groupSel?.id) || !isPositiveValue(logic.scolariteGroupSel?.id)){
            matchingFraisOneShotEnfantStateUpdate.checkedList(logic, []) ;
            matchingFraisOneShotEnfantStateUpdate.leftList(logic, []) ;
            matchingFraisOneShotEnfantStateUpdate.rightList(logic, []) ;
            matchingFraisOneShotEnfantStateUpdate.childrenGroupList(logic, []) ;
            return ;
        }

        menuLeaveTimerRef.current = setTimeout(function() {
            matchingFraisOneShotEnfantStateUpdate.dataLoading(logic, true);
            matchingFraisOneShotEnfantBusinessLogic.loadFraisSansEcheances(logic,  logic.scolariteGroupSel.id).then((fraisValues: ScolariteSansEcheanceEnfant[]) => {
                let children : GroupEnfant []    = _.filter(logic.groupEnfantsRef.current, {'groupesectionId':logic.groupSel.id});
                let leftListChildren       = _.filter(children, item => {
                    return _.findIndex(fraisValues, {'enfantId':item.enfantId}) < 0 ;
                });
                let rightListChildren      = _.filter(children, item => {
                    return _.findIndex(fraisValues, {'enfantId':item.enfantId}) >= 0 ;
                });


                matchingFraisOneShotEnfantStateUpdate.checkedList(logic, []) ;
                matchingFraisOneShotEnfantStateUpdate.leftList(logic, leftListChildren) ;
                matchingFraisOneShotEnfantStateUpdate.rightList(logic, rightListChildren) ;
                matchingFraisOneShotEnfantStateUpdate.childrenGroupList(logic, children) ;
                matchingFraisOneShotEnfantStateUpdate.dataList(logic, fraisValues) ;
                matchingFraisOneShotEnfantStateUpdate.dataLoading(logic, false);
            }).catch((error:any) => {
                matchingFraisOneShotEnfantStateUpdate.dataLoading(logic, false);
            });
        }, 500);
        return () => {
        };
    }, [logic.numeriChange]);

    const customList = (items: readonly GroupEnfant[], title:string) => {
        const thelist : any [] =  _.map(items, item => {
            const labelId = `transfer-list-item-${item.enfantId}-label`;

            return (
                <ListItemButton
                    key={item.enfantId}
                    role="listitem"
                    onClick={handleToggleCheck(item)}
                >
                    <ListItemIcon>
                        <Checkbox
                            checked={_.includes(logic.checkedList, item.enfantId)}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{
                                'aria-labelledby': labelId,
                            }}
                        />
                    </ListItemIcon>
                    <ListItemAvatar>
                        <Avatar
                            alt={item.childForm?.childNomPrenomForm}
                            src={`${item.childForm?.childphotoForm}`}
                            sx={{width:35, height:35}}/>
                    </ListItemAvatar>
                    <ListItemText id={labelId} primary={item.childForm?.childNomPrenomForm} />
                </ListItemButton>

            );
        });

        return (
            <Card>
                <CardHeader
                    sx={{ px: 2, py: 1 }}
                    title={title}
                    subheader={`${numberOfChecked(_.map(items, item => item.enfantId))}/${_.size(items)} selected`}
                />
                <Divider />
                <List
                    sx={{
                        minWidth:450,
                        width: 450,
                        maxWidth: 450,
                        height: 450,
                        bgcolor: 'background.paper',
                        overflow: 'auto',
                        overflowWrap: 'break-word',
                    }}
                    dense
                    component="div"
                    role="list"
                >
                    {thelist}
                </List>
            </Card>

        );
    }
   const titleLeft = logic.activeLang === 'en' ? `The children not involved` : `Les enfants non concernés`;
   const titleRight = logic.activeLang === 'en' ? `The children involved` : `Les enfants concernés`;
   const childrenGroupLeft = useMemo(() => customList(logic.leftList, titleLeft), [logic.leftList, logic.checkedList]);
   const childrenGroupRight = useMemo(() => customList(logic.rightList, titleRight), [logic.rightList, logic.checkedList]);

   const scolariteAnneeItems = useMemo(() => {
       return _.map(logic.scolariteAnneesRef.current, item => (
           <MenuItem key={item.id} value={item.id} sx={{fontWeight: 400}}>
               {item.nom}
           </MenuItem>
       ));
   }, [logic.scolariteAnneesRef.current]);

   const groupSectionItems = useMemo(() => {
      return _.map(logic.groupesectionsRef.current, item => (
           <MenuItem key={item.id} value={item.id} sx={{fontWeight: 400}}>
               {item.nom}
           </MenuItem>
       ));
   }, [logic.groupesectionsRef.current]);


    useEffect(() => {
        moment.locale(logic.activeLang);
        matchingFraisOneShotEnfantBusinessLogic.initPostConstruct(logic);
    }, []);

    return (
        <div className={styleClasses.root}>
            <Box className={styleClasses.buttonContainer}>
                <SchoolingSubMenu classes={styleClasses} selected="matchingfraisoneshotenfant"/>
            </Box>
            <Box className={styleClasses.searchBox}>
                <Grid container spacing={2} sx={{mb:6}}>
                    <Grid item md={3} xs={12} sx={{mt:2}}></Grid>
                    <Grid item md={6} xs={12} sx={{mt:2}}>
                        <Grid container spacing={2}>
                            <Grid item md={12} xs={12}>
                                <FormControl
                                    sx={{
                                        display: { xs: 'block', sm: 'inline-block'},
                                        marginBottom: { xs: 1},
                                        marginRight: { sm: 1 },
                                        mt:2,
                                        width:'100%',
                                    }}
                                >
                                    <TextField
                                        select
                                        label={<FormattedMessage id={messages.scolaritee_annee}/>}
                                        sx={{
                                            width: {
                                                xs: '100%',
                                                sm: '100%',
                                                fontWeight: 'normal',
                                            }
                                        }}
                                        color={"secondary"}
                                        value={logic.scolariteAnneeSel?.id ?? 0}
                                        onChange={handleOnScolariteAnneChange}
                                        inputProps={{
                                            required: "Required field",
                                            min: 1,
                                        }}
                                    >
                                        <MenuItem key="0" value="0" sx={{fontWeight: 400}}>-------</MenuItem>
                                        {scolariteAnneeItems}
                                    </TextField>
                                </FormControl>
                            </Grid>

                            <Grid item md={12} xs={12}>
                                <FormControl
                                    sx={{
                                        display: { xs: 'block', sm: 'inline-block'},
                                        marginBottom: { xs: 1},
                                        marginRight: { sm: 1 },
                                        mt:2,
                                        width:'100%',
                                    }}
                                >
                                    <TextField
                                        select
                                        label={<FormattedMessage id={messages.the_group}/>}
                                        sx={{
                                            width: {
                                                xs: '100%',
                                                sm: '100%',
                                                fontWeight: 'normal',
                                            }
                                        }}
                                        color={"secondary"}
                                        value={logic.groupSel?.id ?? 0}
                                        onChange={handleOnGroupChange}
                                        inputProps={{
                                            required: "Required field",
                                            min: 1,
                                        }}
                                    >
                                        <MenuItem key="0" value="0" sx={{fontWeight: 400}}>-------</MenuItem>
                                        {groupSectionItems}
                                    </TextField>
                                </FormControl>
                            </Grid>

                            <Grid item md={12} xs={12}>
                                <FormControl
                                    sx={{
                                        display: { xs: 'block', sm: 'inline-block'},
                                        marginBottom: { xs: 1},
                                        marginRight: { sm: 1 },
                                        mt:2,
                                        width:'100%',
                                    }}
                                >
                                    <TextField
                                        select
                                        label={<FormattedMessage id={messages.schooling_fees_title}/>}
                                        sx={{
                                            width: {
                                                xs: '100%',
                                                sm: '100%',
                                                fontWeight: 'normal',
                                            }
                                        }}
                                        color={"secondary"}
                                        value={logic.scolariteGroupSel?.id ?? 0}
                                        onChange={handleOnScolariteGroupChange}
                                        inputProps={{
                                            required: "Required field",
                                            min: 1,
                                        }}
                                    >
                                        <MenuItem key="0" value="0" sx={{fontWeight: 400}}>-------</MenuItem>
                                        {logic.scolariteGroupList?.map((opt: any) => (
                                            <MenuItem key={opt.id} value={opt.id} sx={{fontWeight: 400}}>
                                                {opt.nom} ({opt.scolariteTypeForm?.nom})
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </FormControl>
                            </Grid>

                            <Grid item md={12} xs={12}>
                                <Box className={styleClasses.amountDetails}>
                                    {
                                        isPositiveValue(logic.scolariteGroupSel?.id) &&
                                        <Typography
                                            variant={"subtitle2"}
                                            color={"green"}
                                            sx={{ textAlign:'center' }}
                                        >
                                            TOTAL : {new Intl.NumberFormat("fr-FR").format(logic.scolariteGroupSel?.montant ?? 0)} FCFA
                                        </Typography>
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={3} xs={12} sx={{mt:2}}></Grid>
                </Grid>

                <PapperBlock whiteBg noMargin displayNoTitle={true}>
                    {
                        logic.dataLoading &&
                        <Box  sx={{ width: '100%', textAlign: 'center', alignContent:'center', }}>
                            <CircularProgress color="primary" />
                        </Box>
                    }
                    {
                        !logic.dataLoading &&
                        <Box  sx={{ width: '100%', textAlign: 'center', alignContent:'center', height:'auto', pt:'20px', pb:'50px' }}>
                            <Grid
                                container
                                spacing={2}
                                sx={{justifyContent: 'center', alignItems: 'center', }}
                            >
                                <Grid item>{childrenGroupLeft}</Grid>
                                <Grid item>
                                    <Grid container direction="column" sx={{ alignItems: 'center' }}>
                                        <Button
                                            sx={{ my: 0.5 }}
                                            variant="outlined"
                                            size="small"
                                            onClick={moveAllToRight}
                                            disabled={_.isEmpty(logic.leftList)}
                                            aria-label="move all right"
                                        >
                                            ≫
                                        </Button>
                                        <Button
                                            sx={{ my: 0.5 }}
                                            variant="outlined"
                                            size="small"
                                            onClick={moveCheckedToRight}
                                            disabled={isNoneChecked('left')}
                                            aria-label="move selected right"
                                        >
                                            &gt;
                                        </Button>
                                        <Button
                                            sx={{ my: 0.5 }}
                                            variant="outlined"
                                            size="small"
                                            onClick={moveCheckedToLeft}
                                            disabled={isNoneChecked('right')}
                                            aria-label="move selected left"
                                        >
                                            &lt;
                                        </Button>
                                        <Button
                                            sx={{ my: 0.5 }}
                                            variant="outlined"
                                            size="small"
                                            onClick={moveAllToLeft}
                                            disabled={_.isEmpty(logic.rightList)}
                                            aria-label="move all left"
                                        >
                                            ≪
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid item>{childrenGroupRight}</Grid>
                            </Grid>

                            <Grid
                                container
                                spacing={2}
                                sx={{justifyContent: 'right', alignItems: 'right', }}
                            >
                                <Grid item md={12} xs={12}
                                      sx={{pr: 15, mt: 5, mb: 3, textAlign: 'right'}}>
                                    <LoadingButton
                                        loading={logic.processingStateNormBtn}
                                        color="primary"
                                        variant="contained"
                                        loadingPosition="start"
                                        startIcon={<SaveIcon/>}
                                        aria-label="Send notification"
                                        size="large"
                                        onClick={handleSubmit}
                                    >
                                        <FormattedMessage id={messages.dialog_post}/>
                                    </LoadingButton>
                                </Grid>
                            </Grid>

                        </Box>
                    }
                </PapperBlock>
            </Box>
        </div>
    );
}

export default withSnackbar(MatchingFraisOneShotEnfant);
