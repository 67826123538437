import {useMemo, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import _ from "lodash";

import {ClasseRoom} from "../../../../constants/type/ClasseRoom";
import {ScolariteType} from "../../../../constants/type/ScolariteType";
import {ScolariteGroup} from "../../../../constants/type/ScolariteGroup";
import {Child, childDataImpl} from "../../../../constants/type/Child";
import {Fonction} from "../../../../constants/type/Fonction";
import {Employee} from "../../../../constants/type/Employee";
import {ScolariteData, scolariteDataDataImpl} from "../../../../constants/type/ScolariteData";
import {Profils} from "../../../../constants/type/Profils";
import {
    ScolariteVersement,
    scolariteVersementDataImpl,
    versementColumnsVisibility
} from "../../../../constants/type/ScolariteVersement";
import {ScolariteAnnee} from "../../../../constants/type/ScolariteAnnee";
import {GroupEnfant, groupEnfantDataImpl} from "../../../../constants/type/GroupEnfant";
import {Groupesection} from "../../../../constants/type/Groupesection";
import {ScolariteEcheancier, scolariteEcheancierDataImpl} from "../../../../constants/type/ScolariteEcheancier";
import {useMyMaterialReactTable} from "../../../../components/ui/materialReactTable/MyMaterialReactTableEvents";
import {
    getMyReactTableDefaultOptions
} from "../../../../components/ui/materialReactTable/MyDefaultMaterialReactTableOptions";

const useScolaritePaiementVariables = (props: any): any => {
    const {snackbarShowMessage, theme, styleClasses} = props;

    const dispatch = useDispatch();
    const dataCaches = useSelector((state: any) => state.cacheDB.dataCaches);
    const userRef = useRef<any>(useSelector((state:any) => state.user.user));
    const activeLang = props.locale.split('-')[0];

    const mytableState = useMyMaterialReactTable({tableId:'ListPaiement', updateId:3, customOptions:versementColumnsVisibility}) ;
    const logicMRT = useMemo<any>(() => getMyReactTableDefaultOptions<any>(mytableState), []);
    const tableConfigRef = useRef<any>({getState:()=>{}});

    const [paiementListInited, setPaiementListInited] = useState<boolean>(false);
    const [openPDFDialogForm, setOpenPDFDialogForm] = useState<boolean>(false);
    const [openEditDialogForm, setOpenEditDialogForm] = useState<boolean>(false);
    const [openEditDetailsDialogForm, setOpenEditDetailsDialogForm] = useState<boolean>(false);
    const [openDeleteDialogForm, setOpenDeleteDialogForm] = useState<boolean>(false);
    const [processingStateNormBtn, setProcessingStateNormBtn] = useState<boolean>(false);
    const [tableKeyValue, setTableKeyValue] = useState(new Date().getTime());
    const [tableDetailsKeyValue, setTableDetailsKeyValue] = useState(new Date().getTime());
    const [childSelected, setChildSelected] = useState<Child | null | undefined>(_.cloneDeep(childDataImpl));
    const [scolariteTypeIdsSel, setScolariteTypeIdsSel] = useState<number[]>([]);
    const [scolariteGroupIdListSel, setScolariteGroupIdListSel] = useState<number[]>([]);
    const [idScolariteAnneeSel, setIdScolariteAnneeSel] = useState<number[]>([]);
    const [groupEnfantSel, setGroupEnfantSel] = useState<GroupEnfant>(_.cloneDeep(groupEnfantDataImpl));
    const [scolariteGroupList, setScolariteGroupList] = useState<ScolariteGroup[]>([]);


    const jobsRef  = useRef<Map<number,Fonction>>(new Map());
    const employeesRef  = useRef<Employee[]>([]);
    const profilsRef  = useRef<Profils[]>([]);
    const employeesByFonctionRef  = useRef<Employee[]>([]);
    const teachersListRef  = useRef<Employee[]>([]);
    const childrenRef = useRef<Child[]>([]);
    const childrenClasseRef  = useRef<Child[]>([]);
    const childrenMapRef  = useRef<Map<number, Child>>(new Map());
    const classeRoomRef = useRef<ClasseRoom[]>([]);
    const editDisableRef = useRef<boolean>(true);
    const classeRoomMapRef  = useRef<Map<number, ClasseRoom>>(new Map());
    const scolariteTypeMapRef  = useRef<Map<number, ScolariteType>>(new Map());
    const scolariteTypeRef = useRef<ScolariteType[]>([]);
    const scolariteGroupsRef = useRef<ScolariteGroup[]>([]);
    const scolariteGroupsMapRef = useRef<Map<number, ScolariteGroup>>(new Map());
    const scolariteFraisGroupOptionalRef = useRef<ScolariteGroup[]>([]);
    const scolariteAnneesRef = useRef<ScolariteAnnee[]>([]);
    const groupEnfantsRef = useRef<GroupEnfant[]>([]);
    const groupEnfantsMapRef = useRef<Map<number, GroupEnfant>>(new Map());
    const groupesectionsRef = useRef<Groupesection[]>([]);
    const groupesectionMapRef  = useRef<Map<number, Groupesection>>(new Map());

    const [versements, setVersements] = useState<ScolariteVersement[]>([]);
    const [dataList, setDataList] = useState<ScolariteData[]>([]);
    const [dataListSelected, setDataListSelected] = useState<ScolariteData[]>([]);
    const [versementSel, setVersementSel] = useState<ScolariteVersement>(_.cloneDeep(scolariteVersementDataImpl));
    const [dataSel, setDataSel] = useState<ScolariteData>(_.cloneDeep(scolariteDataDataImpl));
    const [dataDetailsSel, setDataDetailsSel] = useState<ScolariteEcheancier>(_.cloneDeep(scolariteEcheancierDataImpl));
    const [tableDataLoading, seTableDataLoading] = useState<boolean>(true);
    const [showDetailsTableContent, setShowDetailsTableContent] = useState<boolean>(true);
    const [contentLoaded, setContentLoaded] = useState<boolean>(false);

    return {
        snackbarShowMessage,theme,styleClasses,userRef,activeLang,openEditDialogForm,setOpenEditDialogForm,
        openDeleteDialogForm, setOpenDeleteDialogForm,processingStateNormBtn,setProcessingStateNormBtn,
        dataList,setDataList,dataListSelected,setDataListSelected,dataSel,setDataSel,scolariteGroupsRef,
        tableKeyValue,setTableKeyValue,tableDataLoading,seTableDataLoading,scolariteTypeRef,classeRoomRef,
        showDetailsTableContent,setShowDetailsTableContent,tableDetailsKeyValue,setTableDetailsKeyValue,classeRoomMapRef,
        dataDetailsSel,setDataDetailsSel,openEditDetailsDialogForm,setOpenEditDetailsDialogForm,scolariteTypeMapRef,
        childrenRef, childrenClasseRef,childSelected,setChildSelected,editDisableRef,contentLoaded,setContentLoaded,
        teachersListRef, employeesByFonctionRef, employeesRef, groupEnfantsRef,scolariteAnneesRef,
        jobsRef,scolariteTypeIdsSel, setScolariteTypeIdsSel,scolariteGroupsMapRef,scolariteFraisGroupOptionalRef,
        profilsRef,openPDFDialogForm,setOpenPDFDialogForm,versementSel,setVersementSel,paiementListInited,setPaiementListInited,
        versements,setVersements,dataCaches,dispatch,childrenMapRef,groupEnfantsMapRef,
        scolariteGroupIdListSel, setScolariteGroupIdListSel, idScolariteAnneeSel, setIdScolariteAnneeSel,groupEnfantSel,
        setGroupEnfantSel, scolariteGroupList, setScolariteGroupList, groupesectionsRef, groupesectionMapRef,
        tableConfigRef,
        ...logicMRT
    }
}
export default useScolaritePaiementVariables;