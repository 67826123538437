import {getRequest} from "../helpers/axios_helpers";
import moment from "moment";
import {getShortMonthName} from "../helpers/helpers";
import _ from "lodash";
import {RdvInfo} from "../constants/type/RdvInfo";

function getBarCharGroupedByMonth(rdvList:any){
    let barListData:any = [];
    const groupedBarData = _.groupBy(rdvList, 'rdvMonth');
    Object.keys(groupedBarData).forEach(function (key){
        const data = {
            barMonth: key,
            barDataList: groupedBarData[key]
        }
        barListData.push(data);
    });
    return barListData;
}

function AppointmentService() {

    return {
        getAllAppointment: async () => {
            const result = await getRequest('/extra/rdv');
            const rdvList = result._embedded !== undefined ? result._embedded?.rdvDTOModelList : [];

            return rdvList.map((rdv:any) => {
                let rdvDateDebut = moment(rdv.dateDebut);
                const rdvDate = rdvDateDebut.set({hours:0,minute:0,second:0,millisecond:0});
                const rdvMonth = rdvDate.format('MMM');

                return {
                    ...rdv,
                    rdvDate: rdvDate.valueOf(),
                    rdvMonth: rdvMonth,
                }
            });
        },
        getAllNextConfirmRdv: async () => {
            const result: RdvInfo[] = await getRequest('/extra/rdv/nextnormalrdvconfirm');
            return  _.sortBy(result ?? [], ['laDate']) ;
        },
        getBarChartData: (rdvList:any, rdvMonths:any) => {
            const barListData = getBarCharGroupedByMonth(rdvList);
            let barData = [];
            for(let i=0; i<rdvMonths.length; i++){
                const shortMonthName = getShortMonthName(rdvMonths[i].month);
                const today = new Date().getTime();
                if(shortMonthName !== null){
                    for(let j=0; j<barListData.length; j++){
                        if(shortMonthName === barListData[j].barMonth){
                            let done = 0;
                            let notDone = 0;
                            for(let x=0; x<barListData[j].barDataList.length; x++){
                                if((
                                    barListData[j].barDataList[x].meetingStatus === 'CONFIRM' && barListData[j].barDataList[x].dateFin <= today
                                ) || barListData[j].barDataList[x].meetingStatus === 'DONE') {
                                    done++;
                                }
                                else
                                    notDone++;
                            }

                            const data = {
                                name: `${shortMonthName} ${rdvMonths[i].year}`,
                                dn: done,
                                ndn: notDone,
                            }
                            barData.push(data);
                        }
                    }
                }
            }

            return barData;
        }
    }
}

export default AppointmentService;
