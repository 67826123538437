import _ from "lodash";
import {request} from "./axios_helpers";
import {RoleUser} from "../constants/type/RoleUser";
import {cacheTableImpl, setTableCaches} from "../features/cache/remoteDBCacheSlice";
import moment from "moment";

const processingPathVar = (logic:any, requestbody: string[]):string => {
    const dataCaches  = _.cloneDeep(logic.dataCaches);
    const cacheTagInfos: string[] = [];
    for(const tagkey of requestbody){
       const lastUpdate:number =  _.find(dataCaches, {tag:tagkey})?.lastUpdate ?? -1;
        cacheTagInfos.push(`${tagkey}=${lastUpdate}`);
    }
    return _.join(cacheTagInfos, ',');
}
const updateCacheDataFromRemoteDB = (logic:any, requestbody: string[], datas:any):any => {
    if(!_.isArray(logic.dataCaches)){
        return datas;
    }
    const currentTime = moment().valueOf();
    const dataCaches = _.cloneDeep(logic.dataCaches);
    for(const tagkey of requestbody){
        const index = _.findIndex(dataCaches, {tag:tagkey});
        const cacheTable = index >= 0  ? dataCaches[index] : _.cloneDeep(cacheTableImpl);
        cacheTable.tag = tagkey;

        if(_.isEmpty(datas[tagkey])){
            datas[tagkey] = {content:[]};
        }
        if(_.isEmpty(datas[tagkey]?.content)){
            datas[tagkey].content = cacheTable.data;
            if(index >= 0){
                dataCaches[index] = cacheTable;
            } else {
                dataCaches.push(cacheTable);
            }
            continue ;
        }

        cacheTable.lastUpdate   = currentTime;
        cacheTable.data         = datas[tagkey]?.content;
        if(index >= 0){
            dataCaches[index] = cacheTable;
        } else {
            dataCaches.push(cacheTable);
        }
    }
    logic.dispatch(setTableCaches(_.cloneDeep(dataCaches)));
    return datas;
}
export const mixLoad = (logic: any, requestbody: string[], fn: any) => {
    return new Promise<boolean>(resolve => {
        const pathvar = processingPathVar(logic, requestbody);
        request("GET", `/corebase/mix/general/${pathvar}`, {}).then((response) => {
            response.data.employees.content = _.filter(response.data.employees?.content ?? [], item => item.id !== 2);
            response.data.fonctions.content = _.filter(response.data.fonctions?.content ?? [], item => item.role !== RoleUser.SUPER_ADMIN);
            response.data   = updateCacheDataFromRemoteDB(logic, requestbody, response.data);
            fn(response.data);
            resolve(true);
        }).catch((error) => {
            console.error(error);
            resolve(false);
        })
    });
}