//export const IMS_WEBSITE = 'https://ims.hostlinguere.net/';
export const IMS_WEBSITE = 'https://erp.ivorymontessorisystem.com/';


// BRANDON
/*
export const BASEURL = 'https://ims.hostlinguere.net';
export const BASEURL_IMG = 'http://hostlinguere.net:81/resources';
export const BASEURL_RESOURCES = 'http://hostlinguere.net:81/resources';
export const SOCKET_URL = 'wss://hostlinguere.net:8081/api/v1/ws';
export const HTTP_MESSAGE_URL = 'https://hostlinguere.net:8081/api/v1';
*/

/*

export const BASEURL = 'http://localhost:3000';
export const BASEURL_IMG = 'http://108.230.241.172:81/resources';
export const BASEURL_RESOURCES = 'http://108.230.241.172:81/resources';
export const SOCKET_URL = 'ws://108.230.241.172:8081/api/v1/ws';
export const HTTP_MESSAGE_URL = 'http://108.230.241.172:8081/api/v1';
*/
/*
export const BASEURL = 'http://localhost:3000';
export const BASEURL_IMG = 'http://108.230.241.172:81/resources';
export const BASEURL_RESOURCES = 'http://108.230.241.172:81/resources';
export const SOCKET_URL = 'ws://108.230.241.172:8081/api/v1/ws';
export const HTTP_MESSAGE_URL = 'http://192.168.11.128:8080/api/v1';*/

// PROD CONFIG33
export const BASEURL = 'https://erp.ivorymontessorisystem.com';
export const BASEURL_IMG = 'https://ivorymontessorisystem.com/resources';
export const BASEURL_RESOURCES = 'https://ivorymontessorisystem.com/resources';
export const SOCKET_URL = 'wss://ivorymontessorisystem.com:8081/api/v1/ws';
export const HTTP_MESSAGE_URL = 'https://ivorymontessorisystem.com:8081/api/v1';

