import React, {useEffect, useState} from 'react';
import Button from "@mui/material/Button";
import classNames from "classnames";
import {Link} from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {FormattedMessage} from "react-intl";
import messages from "../messages/general/messages";
import {Grid} from "@mui/material";
import PapperBlock from "../../components/ui/papperBlock/PapperBlock";
import {makeStyles} from "@mui/styles";
import styles from "../config/config-table-jss";
import StudentForm from "./StudentForm";
import {useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import moment from "moment";
import {SelectChangeEvent} from "@mui/material/Select";
import {dropZoneFilePreview} from "../../helpers/helpers";
import {uploadFileRequest} from "../../helpers/axios_helpers";
import ChildrenService from "../../service/ChildrenService";
import ClassroomService from "../../service/ClassroomService";
import Loading from "../../components/ui/Loading";
import dataPays from "../../data/pays";
import {
    CONSTANT,
    IMAGE_RIGHTS_EN,
    IMAGE_RIGHTS_FR,
    REGISTRATION_FILES_EN,
    REGISTRATION_FILES_FR
} from "../../constants";
import {withSnackbar} from "../../components/ui/snackbar/SnackbarHOC";

const useStyles = makeStyles(styles);
const useStyles2 = makeStyles((theme:any) => {
    return {
        root: {
            padding: theme.spacing(2),
            fontSize: '14px',
            paddingTop: 30,
            '& .css-1ygulig-MuiTypography-root': {
                margin: 0,
                fontSize: '1rem',
                fontWeight:500,
                display: 'block',
                marginTop: 5,
            }
        },
        title: {
            fontWeight:'500 !important'
        },
        list: {
            width: '100%',
            maxWidth: 400,
            marginTop:3,
        },
        save_button: {
            marginTop: 20,
            marginBottom: 10
        },
        subMenuTools: {
            padding: theme.spacing(2),
            paddingBottom:0,
        },
        button: {},
        addFormTitle: {
            textAlign: 'center',
            margin: 0,
            padding: 15,
            borderRadius: 7,
            fontSize: '18px',
            backgroundColor: '#ededed',
            marginBottom: 15,
        },
        stepTitle: {
            paddingLeft: 10,
        },
        separator: {
            marginBottom: 25,
        }
    }
})

function AddNewChild(props:any) {
    const {
        locale, theme, snackbarShowMessage,
    } = props;
    const classes = {...useStyles(), ...useStyles2()};
    const activeLang = locale.split('-')[0];
    const [statusChildForm, setStatusChildForm] = useState(true);
    const [classroomData, setClassroomData] = useState<any[]>([]);
    const [childrenDataList, setChildrenDataList] = useState<any>([]);
    const [file, setFile] = useState(null);
    const [fileDataURL, setFileDataURL] = useState(null);
    const [loading, setLoading] = useState(true);

    //FORM DATA
    const [birthDate, setBirthDate] = useState(moment(new Date()));
    const [classroom, setClassroom] = React.useState<any | ''>('');
    const [selectedOptions, setSelectedOptions] = React.useState<any[]>([]);
    const [countries, setCountries] = useState<any>([]);
    const [gender, setGender] = React.useState('');
    const [profile, setProfile] = React.useState(1);
    const [siblings, setSiblings] = useState<any[]>([]);
    const [displaySiblings, setDisplaySiblings] = useState(false);
    const searchPlaceholder:string | null = activeLang === 'en' ? 'Search by name' : 'Recherche par nom';
    const [optionRegistration, setOptionRegistration] = useState<any>([]);
    const [registrationsFiles, setRegistrationsFiles] = useState<any>([]);
    const [optionImagesRights, setOptionImagesRights] = useState<any>([]);
    const [imageRightsData, setImageRightsData] = useState<any>([]);
    const [authorizationPersonFormValues, setAuthorizationPersonFormValues] = useState<any[]>([{ firstname_authorization: '', lastname_authorization : '', phone_number_authorization : ''}]);
    const [mainParent, setMainParent] = useState('mother');
    const [loadingButton, setLoadingButton] = useState(false);

    const childrenService = ChildrenService();
    const classroomService = ClassroomService();

    const schema = yup.object().shape({
        id: yup.number(),
        firstname: yup.string().min(2).required(),
        lastname: yup.string().min(2).required(),
        classroom: yup.object().required(),
        nationalities: yup.array().of(yup.object().required()).min(1),
        genre: yup.string().required(),
        profile: yup.number(),
        address: yup.string(),
        firstNameMother: yup.string(),
        lastNameMother: yup.string(),
        phoneNumberMother: yup.string(),
        emailMother: yup.string(),
        firstNameFather: yup.string(),
        lastNameFather: yup.string(),
        phoneNumberFather: yup.string(),
        emailFather: yup.string(),
        allergies: yup.string(),
        foodRestriction: yup.string(),
        healthIssue: yup.string(),
        registrationFilesType: yup.array(),
        imageRightsTabId: yup.array(),
        firstnameLastnameContact1: yup.string(),
        //phoneNumberContact1: yup.string().notRequired().matches(phoneRegExp, "Enter a valid phone number"),
        phoneNumberContact1: yup.string().notRequired(),
        firstnameLastnameContact2: yup.string(),
        phoneNumberContact2: yup.string().notRequired(),
    });

    const form = useForm({
        defaultValues:{},
        resolver: yupResolver(schema),
    });

    const {control, register, handleSubmit, formState, reset, setValue, getValues} = form;
    const {errors} = formState;


    const handleDateChange = (date:any) => {
        setBirthDate(date);
    };
    const handleClassroomChange = (event:any) => {
        classroomData.map((selectedClass:any) => {
            if(selectedClass === event.target.value){
                setClassroom(selectedClass);
                setValue('classroom', selectedClass);
            }
        })
    }
    const handleSelectOptionChange = (event: any) => {
        const value:any = event.target.value
        setSelectedOptions(value);
        setValue('nationalities', value);
    };
    const handleGenderChange = (event:any) => {
        setGender(event.target.value);
    }
    const handleProfileChange = (event:any) => {
        setProfile(event.target.value);
    }
    const handleSiblingsChange = (child:any) => {
        if(child !== null) {
            const childFind:any = childrenDataList.find(({ id } : {id:number}) => id === child.id);
            const result = siblings.some(value => value.id === child.id);
            if(!result){
                setSiblings([childFind, ...siblings]);
                setDisplaySiblings(true);
            }
            //setSiblingsParent(child);
        }
    }

    const handleRemoveSibling = (siblingSelected:any) => {
        const result:any = siblings.filter((child:any) => child.id !== siblingSelected.id);
        setSiblings(result)
    }
    const handleChangeMainParent = (event: any) => {
        const value:any = event.target.value;
        setMainParent(value);
    };
    const handleRegistrationChipChange = (event: SelectChangeEvent<typeof optionRegistration>) => {
        const value:any = event.target.value
        setOptionRegistration(value);
    }
    const handleImageRightChipChange = (event: SelectChangeEvent<typeof optionImagesRights>) => {
        const value:any = event.target.value;
        setOptionImagesRights(value);
    }
    const addAuthorizationPersonFormFields = () => {
        setAuthorizationPersonFormValues([
            ...authorizationPersonFormValues,
            { firstname_authorization: '', lastname_authorization : '', phone_number_authorization : ''}
        ]);
    }
    const handleAuthorizationPersonChange = (i:any, e:any) => {
        const newFormValues:any = [...authorizationPersonFormValues];
        newFormValues[i][e.target.name] = e.target.value;
        setAuthorizationPersonFormValues(newFormValues);
    }
    const removeAuthorizationPersonFormFields = (i:any) => {
        let newFormValues = [...authorizationPersonFormValues];
        newFormValues.splice(i, 1);
        setAuthorizationPersonFormValues(newFormValues);
    }
    const handleRegistrationChange = (event: any) => {
        const registrationChecked:any = event.target.value;
    };

    const sendData = (data: any, method: any, url: any, dataToSend: any) => {
        // let childrenItem:any = {}
        let message:any = '';

        uploadFileRequest(method, url, dataToSend).then((response) => {
            reset();
            setStatusChildForm(true);
            setFileDataURL(null);
            setFile(null);
            setSelectedOptions([]);
            setSiblings([]);
            setBirthDate(moment(new Date()));
            setClassroom('');
            setProfile(1);
            setOptionRegistration([]);
            setOptionImagesRights([]);
            setValue('firstname', '');
            setValue('lastname', '');
            setValue('classroom', '');
            setValue('nationalities', []);
            setValue('genre', '');
            setGender('');
            setValue('address', '');
            setValue('profile', 1);

            setValue('lastNameMother', '');
            setValue('firstNameMother', '');
            setValue('phoneNumberFather', '');
            setValue('phoneNumberMother', '');
            setValue('emailMother', '');
            setValue('firstNameFather', '');
            setValue('lastNameFather', '');
            setValue('emailFather', '');
            setValue('allergies', '');
            setValue('foodRestriction', '');
            setValue('healthIssue', '');
            setValue('registrationFilesType', []);
            setValue('imageRightsTabId', []);
            setValue('firstnameLastnameContact1', '');
            setValue('phoneNumberContact1', '');
            setValue('firstnameLastnameContact2', '');
            setValue('phoneNumberContact2', '');
            setAuthorizationPersonFormValues([{ firstname_authorization: '', lastname_authorization : '', phone_number_authorization : ''}])

            setLoadingButton(false);
            message = <FormattedMessage id={messages.sb_add_success} />
            snackbarShowMessage(message);
        }).catch((error) => {
            // Error message
            setLoadingButton(false);
            console.log(error);
            if(error?.response?.data?.codeMessage === 'PERSON_ALREADY_EXIST_EXCEPTION'){
                message = error?.response?.data?.message;
            }
            else {
                message = <FormattedMessage id={messages.sb_action_error} />;
            }
            snackbarShowMessage(message ?? <FormattedMessage id={messages.sb_action_error} />, 'error');
        });
    }

    const onSubmit = (data:any) => {
        setLoadingButton(true);

        if(mainParent === 'mother' && (data.firstNameMother === '' ||  data.lastNameMother === '' || data.phoneNumberMother === '' || data.emailMother === '' ) ){
            snackbarShowMessage(<FormattedMessage id={messages.all_mother_information} />, 'error');
            setLoadingButton(false);
        }
        else if(mainParent === 'father'  && (data.firstNameFather === '' ||  data.lastNameFather === '' || data.phoneNumberFather === '' || data.emailFather === '' )){
            snackbarShowMessage(<FormattedMessage id={messages.all_father_information} />, 'error');
            setLoadingButton(false);
        }
        else {

            //NATIONALITIES
            let nationalityEnfant:any = [];
            if(data.nationalities.length > 0){
                nationalityEnfant = data.nationalities.map((nationality:any) => {
                    return {
                        //id: 0,
                        //personId: 0,
                        paysId: nationality?.id,
                        common: CONSTANT.common,
                    };
                });
            }

            // IMAGE RIGHTS
            let imageRights:any = [];
            if(optionImagesRights.length > 0){
                imageRights = optionImagesRights.map((image:any) => {
                    return {
                        //id: 0,
                        //enfantId: 0,
                        droitImageId: image?.id,
                        common: CONSTANT.common,
                    }
                });
            }

            // CHILD REGISTRATION FILES
            let dossierEnfants:any;
            let contentDossierEnfant:any = [];
            if(optionRegistration.length > 0){
                const documentDossierEnfants = optionRegistration.map((dossier:any) => {
                    return {
                        dossierConstitutifId: dossier?.dossierConstitutifId,
                        fichierNom: '',
                        fichierUri: '',
                        common: CONSTANT.common,
                    }
                });

                contentDossierEnfant = [
                    {
                        typeDossierId: optionRegistration[0]?.typeDossierId,
                        common: CONSTANT.common,
                        documentDossierEnfants: documentDossierEnfants,
                    }
                ];
            }
            dossierEnfants = optionRegistration.length > 0 ? contentDossierEnfant : [];

            // EMERGENCY CONTACT
            let emergencyNom1:string ='';
            let emergencyPhone1:string ='';
            if(data.firstnameLastnameContact1 !=="" && data.phoneNumberContact1 !=""){
                emergencyNom1 = data?.firstnameLastnameContact1?.trim();
                emergencyPhone1 = data?.phoneNumberContact1?.trim();
            }

            let emergencyNom2:string ='';
            let emergencyPhone2:string ='';
            if(data.firstnameLastnameContact2 !=="" && data.phoneNumberContact2 !=""){
                emergencyNom2 = data?.firstnameLastnameContact2?.trim();
                emergencyPhone2 = data?.phoneNumberContact2?.trim();
            }

            // AUTHORIZATION PERSON
            let personneAutoriserEnfants:any = [];
            const dateAuthorization:any = moment(new Date());
            const dateBirthAuthorization:any = moment(new Date());
            if(authorizationPersonFormValues.length > 0){
                for(let i=0; i< authorizationPersonFormValues.length; i++){
                    const authorizationData = authorizationPersonFormValues[i];
                    if(authorizationData.firstname_authorization !=="" && authorizationData.lastname_authorization && authorizationData.phone_number_authorization){
                        const personneAutoriserEnfant:any = {
                            dateDebut: dateAuthorization.valueOf(),
                            dateFin: 0,
                            personneAutoriser: {
                                person: {
                                    dateNaissance: dateBirthAuthorization.valueOf(),
                                    nom: authorizationData?.lastname_authorization?.trim(),
                                    prenom: authorizationData?.firstname_authorization?.trim(),
                                    sexe: 'HOMME',
                                    adresse: '',
                                    photo: '',
                                    email: null,
                                    phone1: authorizationData?.phone_number_authorization?.trim(),
                                    phone2: '',
                                    cniNumero: '',
                                    cniPhoto: '',
                                    note: '',
                                    poste: '',
                                    paysPersons: [],
                                },
                                common: CONSTANT.common,
                            },
                            common: CONSTANT.common,
                        }
                        personneAutoriserEnfants.push(personneAutoriserEnfant);
                    }
                }
            }

            let formData:any = new FormData();
            formData.append("photoEnfant", file);
            formData.append("photoPapa", null);
            formData.append("photoMaman", null);

            const birth_date:any = birthDate;
            const theDayStudent:any = moment(new Date());

            // ADDING SIBLINGS
            //let enfantParents:any = [];
            let firstNameMother = '';
            let lastNameMother = '';
            let emailMother = '';
            let phoneNumberMother = '';
            let sexeMother = '';
            let mainParentMother = true;

            let firstNameFather = '';
            let lastNameFather = '';
            let emailFather = '';
            let phoneNumberFather = '';
            let sexeFather = '';
            let mainParentFather = false;

            let enfantParents:any = [];
            let parentMother;
            let parentFather;
            if(siblings.length > 0){
                const father:any = siblings[0]?.enfantParents[1]?.parent?.person;
                const mother:any = siblings[0]?.enfantParents[0]?.parent?.person;

                mainParentMother = siblings[0]?.enfantParents[0]?.principal;
                parentMother = siblings[0]?.enfantParents[0]?.parent;

                mainParentFather = siblings[0]?.enfantParents[1]?.principal;
                parentFather = siblings[0]?.enfantParents[1]?.parent;


            }
            else {
                firstNameMother = data?.firstNameMother?.trim();
                lastNameMother =  data?.lastNameMother?.trim();
                emailMother = data?.emailMother?.trim();
                phoneNumberMother = data?.phoneNumberMother?.trim();
                sexeMother = 'FEMME';
                mainParentMother = mainParent === 'mother';
                parentMother = {
                    userId: 0,
                    person: {
                        nom: lastNameMother,
                        prenom: firstNameMother,
                        sexe: sexeMother,
                        adresse: '',
                        photo: '',
                        email: emailMother,
                        phone1: phoneNumberMother,
                        phone2: '',
                        cniNumero: '',
                        cniPhoto: '',
                        note: '',
                        poste: '',
                        paysPersons: [],
                    },
                    common: CONSTANT.common,
                    fonctions: [],
                }

                firstNameFather = data?.firstNameFather?.trim();
                lastNameFather = data?.lastNameFather?.trim();
                emailFather = data?.emailFather?.trim();
                phoneNumberFather = data?.phoneNumberFather?.trim();
                sexeFather = 'HOMME';
                mainParentFather = mainParent === 'father';
                parentFather = {
                    userId: 0,
                    person: {
                        nom: lastNameFather,
                        prenom: firstNameFather,
                        sexe: sexeFather,
                        adresse: '',
                        photo: '',
                        email: emailFather,
                        phone1: phoneNumberFather,
                        phone2: '',
                        cniNumero: '',
                        cniPhoto: '',
                        note: '',
                        poste: '',
                        paysPersons: [],
                    },
                    common: CONSTANT.common,
                    fonctions: [],
                }

            }
            enfantParents =  [
                {
                    parent: parentMother,
                    principal: mainParentMother,
                    common: CONSTANT.common,
                },
                {
                    parent: parentFather,
                    principal: mainParentFather,
                    common: CONSTANT.common,
                }
            ];

            const dataToSend = {
                person: {
                    dateNaissance: birth_date._d.valueOf(),
                    nom: data?.lastname?.trim(),
                    prenom: data?.firstname?.trim(),
                    sexe: data?.genre,
                    adresse: data?.address?.trim(),
                    photo: '',
                    email: '',
                    phone1: '',
                    phone2: '',
                    cniNumero: '',
                    cniPhoto: '',
                    note: '',
                    poste: '',
                    paysPersons: nationalityEnfant,
                },
                emergencyNom1: emergencyNom1,
                emergencyPhone1: emergencyPhone1,
                emergencyNom2: emergencyNom2,
                emergencyPhone2: emergencyPhone2,
                situationParent: null,
                foodRestriction: data?.foodRestriction?.trim(),
                healthDetail: data?.healthIssue?.trim(),
                allergy: data?.allergies?.trim(),
                enfantParents: enfantParents,
                droitImages: imageRights,
                eleves: [
                    {
                        classe: classroom,
                        theDate: theDayStudent.valueOf(),
                        common: CONSTANT.common,
                    }
                ],
                dossierEnfants: dossierEnfants,
                personneAutoriserEnfants: personneAutoriserEnfants,
                santes:[],
                enfantProfils:[
                    {
                        profilsId: data.profile,
                        common: CONSTANT.common,
                    }
                ],
                common: CONSTANT.common,
            }

            formData.append('model', new Blob([JSON.stringify(dataToSend)], {
                type: "application/json"
            }));

            sendData(data, 'POST', '/corebase/enfants', formData);

        }
    }

    const handleFileChange = (event:any) => {
        const file = event.target.files[0];
        setFile(file);
    }

    useEffect(() => {
        (async () => {
            try {
                // CHILDREN LIST
                const childListRequest:any = await childrenService.getAllChildrenData();
                let childrenList:any = childListRequest._embedded !== undefined ? childListRequest._embedded?.enfantDTOModelList : [];
                const dataListFormatted = childrenService.reformatChildrenList(childrenList);
                setChildrenDataList([...dataListFormatted]);

                //CLASSROOM LIST
                const classList:any = await classroomService.getAllClassDataAscByName();
                setClassroomData(classList);

                // NATIONALITIES
                /*const nationalityList = dataPays.sort(function (a:any, b:any) {
                    if(activeLang === "en"){
                        if(a.nom_en_gb < b.nom_en_gb) { return -1; }
                        if(a.nom_en_gb > b.nom_en_gb) { return 1; }
                        return 0;
                    }
                    else {
                        if(a.nom_fr_fr < b.nom_fr_fr) { return -1; }
                        if(a.nom_fr_fr > b.nom_fr_fr) { return 1; }
                        return 0;
                    }

                });*/
                const nationalityList = dataPays.sort(
                    (a:any, b:any) => activeLang === "en" ?
                        a.nom_en_gb.localeCompare(b.nom_en_gb, 'en') :
                        a.nom_fr_fr.localeCompare(b.nom_fr_fr, 'fr')
                );
                setCountries(nationalityList);

                // REGISTRATION FILES
                const folderConstitutive:any = await childrenService.getAllRegistrationFile();
                // SUBFOLDER TYPE
                const subFolderList:any = await childrenService.getAllSubRegistrationFile();
                let listSubFilesType = childrenService.getChildRegistrationFile(folderConstitutive, subFolderList);
                listSubFilesType = listSubFilesType.map((fileType:any) => {
                    const fileList = activeLang === 'en' ? REGISTRATION_FILES_EN :REGISTRATION_FILES_FR;
                    const fileFind:any = fileList.find((file:any) => file.id === fileType.id);
                    return {
                        ...fileType,
                        nom: fileFind.value
                    };
                })
                setRegistrationsFiles([...listSubFilesType]);

                // IMAGE RIGHTS
                let imageRightsList:any = await childrenService.getAllImageRights();
                imageRightsList = imageRightsList.map((imageRights:any) => {
                    const imageList = activeLang === 'en' ? IMAGE_RIGHTS_EN :IMAGE_RIGHTS_FR;
                    const imaageFind:any = imageList.find((image:any) => image.id === imageRights.id);

                    return  {
                        ...imageRights,
                        nom: imaageFind.value,
                    };

                });
                setImageRightsData([...imageRightsList]);

                setLoading(false);
            }
            catch (e) {
                setLoading(false);
                console.log(e);
            }
        })()
    }, [activeLang]);

    useEffect(() => {
        dropZoneFilePreview(file, setFileDataURL);
    }, [file]);

    return (
        <PapperBlock whiteBg noMargin displayNoTitle={true}>
            <div className={classes.subMenuTools}>
                <Button
                    className={classNames(classes.button) }
                    variant={"text"}
                    sx={{
                        textTransform: 'none',
                        fontWeight: 400,
                        fontSize: 15,
                        color: "#666666"
                    }}
                    component={Link} to="/app/child/list"
                    startIcon={<KeyboardBackspaceIcon />}
                >
                    <FormattedMessage id={messages.return_children_list} />
                </Button>
            </div>
            <div className={classes.root}>
                <Grid container spacing={2} style={{marginBottom: 30}}>
                    <Grid item md={7} xs={12} style={{background: 'white'}}>
                        <Loading status={loading} />
                        {!loading && (
                            <StudentForm
                                title={<FormattedMessage id={messages.dialog_title_child} />}
                                classes={classes}
                                locale={locale}
                                activeLang={activeLang}
                                fileDataURL={fileDataURL}
                                handleFileChange={handleFileChange}
                                register={register}
                                errors={errors}
                                birthDate={birthDate}
                                handleDateChange={handleDateChange}
                                classroom={classroom}
                                dataClassroom={classroomData}
                                handleClassroomChange={handleClassroomChange}
                                selectedOptions={selectedOptions}
                                handleSelectOptionChange={handleSelectOptionChange}
                                nationalitiesData={countries}
                                gender={gender}
                                handleGenderChange={handleGenderChange}
                                profile={profile}
                                handleProfileChange={handleProfileChange}
                                childrenList={childrenDataList}
                                siblings={siblings}
                                displaySiblings={displaySiblings}
                                handleSiblingsChange={handleSiblingsChange}
                                searchPlaceholder={searchPlaceholder}
                                handleRemoveSibling={handleRemoveSibling}
                                handleRegistrationChange={handleRegistrationChange}
                                handleRegistrationChipChange={handleRegistrationChipChange}
                                registrationsFiles={registrationsFiles}
                                optionRegistration={optionRegistration}
                                setOptionRegistration={setOptionRegistration}
                                optionImagesRights={optionImagesRights}
                                imageRightsData={imageRightsData}
                                handleImageRightChipChange={handleImageRightChipChange}
                                addAuthorizationPersonFormFields={addAuthorizationPersonFormFields}
                                handleAuthorizationPersonChange={handleAuthorizationPersonChange}
                                removeAuthorizationPersonFormFields={removeAuthorizationPersonFormFields}
                                authorizationPersonFormValues={authorizationPersonFormValues}
                                mainParent={mainParent}
                                handleChangeMainParent={handleChangeMainParent}
                                onSubmit={handleSubmit(onSubmit)}
                                loadingButton={loadingButton}
                            />
                        )}
                    </Grid>

                    <Grid item md={5} xs={12} style={{background: 'white'}}>

                    </Grid>
                </Grid>

            </div>
        </PapperBlock>
    );
}

export default withSnackbar(AddNewChild);
