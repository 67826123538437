import _ from "lodash";
import {Common, commonDataImp} from "./Common";
import * as yup from "yup";
import {
    exportExcelScolariteTypePlugin,
    ScolariteType,
    scolariteTypeColumnsPlugin,
    scolariteTypeColumnsVisibilityPlugin,
    scolariteTypeDataImpl
} from "./ScolariteType";
import {Badge} from "@mui/material";
import {FormattedMessage} from "react-intl";
import messages from "../../page/messages/general/messages";
import React from "react";
import {Profils, profilsDataImpl} from "./Profils";
import {isColumnVisible, isPositiveValue} from "../../helpers/helpers";
import moment from "moment/moment";
import {
    columnsGroupPlugin,
    exportExcelGroupPlugin,
    groupColumnsVisibilityPlugin,
    Groupesection,
    groupesectionDataImpl
} from "./Groupesection";
import {
    exportExcelScolariteAnPlugin,
    ScolariteAnnee,
    scolariteAnneeColumnsPlugin,
    scolariteAnneeColumnsVisibilityPlugin,
    scolariteAnneeDataImpl
} from "./ScolariteAnnee";
import {MyBadge} from "../../components/ui/badge/MyBadge";

export const SCOLARITEGROUP_CONSTANT = {
    convertToFrontEnd: (logic:any, item:ScolariteGroup) => {
        // @ts-ignore
        const itemInit = _.cloneDeep(scolariteGroupDataImpl);
        const data : ScolariteGroup = {...itemInit, ...item} ;

       // data.montantForm        = <b>{new Intl.NumberFormat("fr-FR").format(data.montant)}</b> ;

        data.groupesectionForm      = _.find(logic.groupesectionsRef.current, {id:data.groupesectionId}) ?? _.cloneDeep(groupesectionDataImpl);
        data.groupesectionIds       = [];
        data.groupesectionIds.push(data.groupesectionId ?? 0);

        // @ts-ignore
        data.ladateForm             = isPositiveValue(data.ladate) ? moment(data.ladate) : null;
        // @ts-ignore
        data.liredateForm         = _.isObject(data.ladateForm) ?  data.ladateForm.format("L") : '';

        // @ts-ignore
        data.scolariteTypeForm      = _.find(logic.scolariteTypeRef.current, {id:data.scolariteTypeId}) ?? _.cloneDeep(scolariteTypeDataImpl);

        // @ts-ignore
        data.scolariteAnneeForm   = _.find(logic.scolariteAnneesRef.current, {id:data.scolariteAnneeId}) ?? _.cloneDeep(scolariteAnneeDataImpl);

        data.scolariteAnNomForm = data.scolariteAnneeForm?.nom ?? ``;

        if(isPositiveValue(data?.profilsId)){
            // @ts-ignore
            data.profils = _.find(logic.profilsRef?.current, {id: data?.profilsId}) ?? _.cloneDeep(profilsDataImpl);
            data.profilsName = data.profils?.nom;
        }


        return data ;
    },
    convertToBackEnd: (logic:any, item:ScolariteGroup) => item,
};


export const scolariteGroupColumnsVisibility = {
    columnVisibility : {
        ...groupColumnsVisibilityPlugin.columnVisibility,
        ...scolariteAnneeColumnsVisibilityPlugin.columnVisibility,
        ...scolariteTypeColumnsVisibilityPlugin.columnVisibility,
        'scolariteGroupForm_code': false,
        'scolariteGroupForm_obligatoire': true,
        'scolariteGroupForm_echeance': true,
    }
} ;


export const scolariteGroupColumnsVisibilityPlugin = {
    columnVisibility : {
        ...scolariteGroupColumnsVisibility.columnVisibility,
        'scolariteGroupForm_code': true,
        'scolariteGroupForm_nom': false,
        'scolariteGroupForm_obligatoire': false,
        'scolariteGroupForm_liredateForm': false,
        'scolariteGroupForm_echeance': false,
    }
} ;

export const columnsScolariteGroup = (props:any) => {
    const {intl} = props ;
    const groupSectionOptions = {
        nomSize: 80,
        codeSize: 80,
    }
    const scolariteAnOptions = {
        nomSize: 80,
        codeSize: 80,
    }
    const scolariteTypeOptions = {
        nomSize: 80,
        codeSize: 80,
    }
    const callKeyValgroupesection = (row:any) => row?.groupesectionForm ;
    const callKeyValAnnee = (row:any) => row?.scolariteAnneeForm ;
    const callKeyValScolariteType = (row:any) => row?.scolariteTypeForm ;

    return [
        ...scolariteAnneeColumnsPlugin({intl, nomSpace: intl.formatMessage({id:messages.table_h_scolarite}), options:scolariteAnOptions, callKeyValue:callKeyValAnnee}),
        ...columnsGroupPlugin({intl, nomSpace: intl.formatMessage({id:messages.table_h_group}), options:groupSectionOptions, callKeyValue:callKeyValgroupesection}),
        ...scolariteTypeColumnsPlugin({intl, nomSpace: intl.formatMessage({id:messages.schooling_fees_type}), options:scolariteTypeOptions, callKeyValue:callKeyValScolariteType}),
        /*{
            accessorKey: 'scolariteAnNomForm',
            header: `${infoSpace} ${intl.formatMessage({id:messages.table_h_scolarite})}`,
            size: 120,
        },*/
        {
            id: 'scolariteGroupForm_nom',
            accessorKey: 'nom',
            header: intl.formatMessage({id:messages.schooling_fees_title}),
            size: 120,
        },
        {
            id: 'scolariteGroupForm_code',
            accessorKey: 'code',
            header: intl.formatMessage({id:messages.header_code}),
            size: 120,
        },
        {
            id: 'scolariteGroupForm_profilsName',
            accessorKey: 'profilsName',
            header: `${intl.formatMessage({id:messages.table_h_profil})}`,
            size: 80,
        },
        {
            id: 'scolariteGroupForm_liredateForm',
            accessorKey: 'liredateForm',
            header: intl.formatMessage({id:messages.date_limite}),
            muiTableBodyCellProps: {
                align: 'center', //change head cell props
            },
            size:100,
        },
        {
            id: 'scolariteGroupForm_obligatoire',
            accessorKey: 'obligatoire',
            header: intl.formatMessage({id: messages.schooling_fees_obligatory}),
            muiTableBodyCellProps: {
                align: 'center', //change head cell props
            },
            size:80,
            // @ts-ignore
            Cell: ({cell}) => {
                // @ts-ignore
                return cell.getValue<boolean>() ? <MyBadge badgeContent={intl.formatMessage({id:messages.oui})} color="success" /> :
                    <MyBadge badgeContent={intl.formatMessage({id:messages.non})} color="error" />;
            },
        },
        {
            id: 'scolariteGroupForm_echeance',
            accessorKey: 'echeance',
            header: intl.formatMessage({id: messages.schooling_fees_echeance}),
            muiTableBodyCellProps: {
                align: 'center', //change head cell props
            },
            size:80,
            // @ts-ignore
            Cell: ({cell}) => {
                // @ts-ignore
                return  cell.getValue<boolean>() ? <MyBadge badgeContent={intl.formatMessage({id:messages.oui})} color="success" /> :
                    <MyBadge badgeContent={intl.formatMessage({id:messages.non})} color="error" />;
            },
        },
        {
            id: 'scolariteGroupForm_montant',
            accessorKey: 'montant',
            header: intl.formatMessage({id: messages.table_h_montant}),
            size:120,
            filterVariant: 'range',
            filterFn: 'between',
            muiTableBodyCellProps: {
                align: 'right', //change head cell props
            },
            // @ts-ignore
            Cell: ({cell}) => <b>{new Intl.NumberFormat("fr-FR").format(cell.getValue<number>())}</b>,
        },
    ];
} ;

export const columnsScolariteGroupPlugin = (props:any) => {
    const {intl, nomSpace, callKeyValue} = props ;
    const infoSpace = nomSpace ?? ``;
    const groupSectionOptions = {
        nomSize: 80,
        codeSize: 80,
    }
    const scolariteAnOptions = {
        nomSize: 80,
        codeSize: 80,
    }
    const scolariteTypeOptions = {
        nomSize: 80,
        codeSize: 80,
    }
    const callKeyValgroupesection = (row:any) => callKeyValue(row)?.groupesectionForm ;
    const callKeyValAnnee = (row:any) => callKeyValue(row)?.scolariteAnneeForm ;
    const callKeyValScolariteType = (row:any) => callKeyValue(row)?.scolariteTypeForm ;

    return [
        ...scolariteAnneeColumnsPlugin({intl, nomSpace: intl.formatMessage({id:messages.table_h_scolarite}), options:scolariteAnOptions, callKeyValue:callKeyValAnnee}),
        ...columnsGroupPlugin({intl, nomSpace: intl.formatMessage({id:messages.table_h_group}), options:groupSectionOptions, callKeyValue:callKeyValgroupesection}),
        ...scolariteTypeColumnsPlugin({intl, nomSpace: intl.formatMessage({id:messages.schooling_fees_type}), options:scolariteTypeOptions, callKeyValue:callKeyValScolariteType}),
        {
            id: 'scolariteGroupForm_nom',
            accessorFn: (row:any) => callKeyValue(row)?.nom,
            header: `${infoSpace} ${intl.formatMessage({id:messages.schooling_fees_title})}`,
            size: 120,
        },
        {
            id: 'scolariteGroupForm_code',
            accessorFn: (row:any) => callKeyValue(row)?.code,
            header: `${infoSpace} ${intl.formatMessage({id:messages.header_code})}`,
            size: 120,
        },
        {
            id: 'scolariteGroupForm_profilsName',
            accessorFn: (row:any) => callKeyValue(row)?.profilsName,
            header: `${infoSpace} ${intl.formatMessage({id:messages.table_h_profil})}`,
            size: 80,
        },
        {
            id: 'scolariteGroupForm_liredateForm',
            accessorFn: (row:any) => callKeyValue(row)?.liredateForm,
            header: `${infoSpace} ${intl.formatMessage({id:messages.date_limite})}`,
            muiTableBodyCellProps: {
                align: 'center', //change head cell props
            },
            size:100,
        },
        {
            id: 'scolariteGroupForm_obligatoire',
            accessorFn: (row:any) => callKeyValue(row)?.obligatoire,
            header: `${infoSpace} ${intl.formatMessage({id: messages.schooling_fees_obligatory})}`,
            muiTableBodyCellProps: {
                align: 'center', //change head cell props
            },
            // @ts-ignore
            Cell: ({cell}) => {
                // @ts-ignore
                const isObligatoire = cell.getValue<boolean>();
                isObligatoire ? <MyBadge badgeContent={<FormattedMessage id={messages.oui} />} color="success" /> : <Badge badgeContent={<FormattedMessage id={messages.non} />} color="error" />;
            },
        },
        {
            id: 'scolariteGroupForm_echeance',
            accessorFn: (row:any) => callKeyValue(row)?.echeance,
            header: `${infoSpace} ${intl.formatMessage({id: messages.schooling_fees_echeance})}`,
            muiTableBodyCellProps: {
                align: 'center', //change head cell props
            },
            // @ts-ignore
            Cell: ({cell}) => {
                // @ts-ignore
                const isObligatoire = cell.getValue<boolean>();
                isObligatoire ? <MyBadge badgeContent={<FormattedMessage id={messages.oui} />} color="success" /> : <Badge badgeContent={<FormattedMessage id={messages.non} />} color="error" />;
            },
        },
        {
            id: 'scolariteGroupForm_montant',
            accessorFn: (row:any) => callKeyValue(row)?.montant,
            header: `${infoSpace} ${intl.formatMessage({id: messages.table_h_montant})}`,
            size:120,
            filterVariant: 'range',
            filterFn: 'between',
            muiTableBodyCellProps: {
                align: 'right', //change head cell props
            },
            // @ts-ignore
            Cell: ({cell}) => <b>{new Intl.NumberFormat("fr-FR").format(cell.getValue<number>())}</b>,
        },
    ];
} ;



export const exportExcelScolariteGroup =  (
    {rows=[], columns=[],  intl={}} :
        {rows:any[]; columns:any[]; intl:any;}
) => {
    return _.map(rows, (row:any) => {
        let obj:any = {};
        const data = row.original;

        obj = exportExcelScolariteAnPlugin({obj, data: data?.scolariteAnneeForm ?? _.cloneDeep(scolariteAnneeDataImpl), intl, columns, infoSpace: intl.formatMessage({id: messages.school_period_year})
        });

        obj = exportExcelScolariteTypePlugin({obj, data: data?.scolariteTypeForm ?? _.cloneDeep(scolariteTypeDataImpl), intl, columns, infoSpace: intl.formatMessage({id: messages.schooling_fees_type})
        });

        obj = exportExcelGroupPlugin({obj, data: data?.groupesectionForm ?? _.cloneDeep(groupesectionDataImpl), intl, columns, infoSpace: intl.formatMessage({id: messages.table_h_group})
        });


        if(isColumnVisible(columns, 'scolariteGroupForm_nom')){
            obj[intl.formatMessage({id:messages.schooling_fees_title})] = data.nom;
        }
        if(isColumnVisible(columns, 'scolariteGroupForm_code')){
            obj[intl.formatMessage({id:messages.header_code})] = data.code;
        }
        if(isColumnVisible(columns, 'scolariteGroupForm_montant')){
            obj[intl.formatMessage({id:messages.table_h_montant})] = data.montant;
        }
        if(isColumnVisible(columns, 'scolariteGroupForm_profilsName')){
            obj[intl.formatMessage({id:messages.table_h_profil})] = data.profilsName;
        }
        if(isColumnVisible(columns, 'scolariteGroupForm_obligatoire')){
            obj[intl.formatMessage({id:messages.schooling_fees_obligatory})] = data.obligatoire;
        }
        if(isColumnVisible(columns, 'scolariteGroupForm_echeance')){
            obj[intl.formatMessage({id:messages.schooling_fees_echeance})] = data.echeance;
        }
        if(isColumnVisible(columns, 'scolariteGroupForm_liredateForm')){
            obj[intl.formatMessage({id:messages.payment_date_limite})] = data.liredateForm;
        }
        return obj;
    });
};


export const exportExcelScolariteGroupPlugin = ({obj={}, data=_.cloneDeep(scolariteGroupDataImpl), intl={}, infoSpace=``, columns=[]}
                                                    :{obj:any; data:ScolariteGroup; intl:any; infoSpace:string; columns:any[];  }) => {

    obj = exportExcelScolariteAnPlugin({obj, data: data?.scolariteAnneeForm ?? _.cloneDeep(scolariteAnneeDataImpl), intl, columns, infoSpace: intl.formatMessage({id: messages.school_period_year})
    });

    obj = exportExcelScolariteTypePlugin({obj, data: data?.scolariteTypeForm ?? _.cloneDeep(scolariteTypeDataImpl), intl, columns, infoSpace: intl.formatMessage({id: messages.schooling_fees_type})
    });

    obj = exportExcelGroupPlugin({obj, data: data?.groupesectionForm ?? _.cloneDeep(groupesectionDataImpl), intl, columns, infoSpace: intl.formatMessage({id: messages.table_h_group})
    });

    if(isColumnVisible(columns, 'scolariteGroupForm_nom')){
        obj[`${infoSpace} ${intl.formatMessage({id:messages.schooling_fees_title})}`] = data.nom;
    }
    if(isColumnVisible(columns, 'scolariteGroupForm_code')){
        obj[`${infoSpace} ${intl.formatMessage({id:messages.header_code})}`] = data.code;
    }
    if(isColumnVisible(columns, 'scolariteGroupForm_montant')){
        obj[`${infoSpace} ${infoSpace} ${intl.formatMessage({id:messages.table_h_montant})}`] = data.montant;
    }
    if(isColumnVisible(columns, 'scolariteGroupForm_profilsName')){
        obj[`${infoSpace} ${intl.formatMessage({id:messages.table_h_profil})}`] = data.profilsName;
    }
    if(isColumnVisible(columns, 'scolariteGroupForm_obligatoire')){
        obj[`${infoSpace} ${intl.formatMessage({id:messages.schooling_fees_obligatory})}`] = data.obligatoire;
    }
    if(isColumnVisible(columns, 'scolariteGroupForm_echeance')){
        obj[`${infoSpace} ${intl.formatMessage({id:messages.schooling_fees_echeance})}`] = data.echeance;
    }
    if(isColumnVisible(columns, 'scolariteGroupForm_liredateForm')){
        obj[`${infoSpace} ${intl.formatMessage({id:messages.payment_date_limite})}`] = data.liredateForm;
    }
    return obj;
};



export  const scolariteGroupDataImpl: ScolariteGroup = {
    id: null,
    nom: ``,
    scolariteTypeId: 0,
    scolariteTypeTag: null,
    groupesectionIds: [],
    profilsId: 0,
    profils: _.cloneDeep(profilsDataImpl),
    profilsName: ``,
    groupesectionId: 0,
    montant: 0,
    ladate: moment().startOf('day').valueOf(),
    scolariteAnneeId:0,
    obligatoire: false,
    echeance: false,
    extrascolaireCode: null,
    extrascolaireId: null,
    groupesectionForm: null,
    scolariteTypeForm: null,
    ladateForm: moment().startOf('day'),
    liredateForm: '',
    scolariteAnNomForm:'',
    scolariteAnneeForm: _.cloneDeep(scolariteAnneeDataImpl),
    common: _.cloneDeep(commonDataImp),

}

export const scolariteGroupSchema = yup.object().shape({
    id: yup.number().nullable(),
    nom: yup.string().required(),
    code: yup.string().nullable().notRequired(),
    scolariteAnneeId: yup.number().min(1).required(),
    scolariteTypeId: yup.number().nonNullable().min(1).required(),
    scolariteTypeTag: yup.string().notRequired(),
    groupesectionIds: yup.array().of(yup.number()).nonNullable().default([]).min(1).required(),
    profilsId: yup.number().notRequired(),
    ladate: yup.number().notRequired(),
    profilsName: yup.string().notRequired(),
    profils: yup.mixed<Profils>().nonNullable().default(_.cloneDeep(profilsDataImpl)).notRequired(),
    groupesectionId: yup.number().nonNullable().default(0).notRequired(),
    montant: yup.number().default(0).min(1).required(),
    obligatoire: yup.boolean().default(false).notRequired(),
    echeance: yup.boolean().default(false).notRequired(),
    extrascolaireCode: yup.string().notRequired().nullable().notRequired(),
    extrascolaireId: yup.number().notRequired().nullable().notRequired(),
    groupesectionForm: yup.mixed<Groupesection>().nullable().notRequired(),
    ladateForm: yup.mixed<any>().notRequired(),
    liredateForm: yup.string().nonNullable().notRequired(),
    scolariteAnneeForm: yup.mixed<ScolariteAnnee>().nonNullable().default(_.cloneDeep(scolariteAnneeDataImpl)).notRequired(),
    scolariteAnNomForm: yup.string().notRequired(),
    //lireObligatoireForm: yup.mixed<Object>(),
    //lireEcheanceForm: yup.mixed<Object>(),
    scolariteTypeForm: yup.mixed<ScolariteType>().nullable().notRequired(),
    common: yup.mixed<Common>(),

});


export type ScolariteGroup = yup.InferType<typeof scolariteGroupSchema>;

