import React, {useEffect, useState} from 'react'
import {Box, Divider, Grid, Slider, Stack} from "@mui/material";
import {makeStyles} from "@mui/styles";
import classNames from "classnames";
import KpIndicatorWidget from "../components/ui/statistic/KpIndicatorWidget";
import {AccountBalanceWallet, AutoStories, People} from "@mui/icons-material";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import PaymentIcon from "@mui/icons-material/Payment";
import HorizontalSplitIcon from '@mui/icons-material/HorizontalSplit';
import PapperBlock from "../components/ui/papperBlock/PapperBlock";
import TotalAmountWidget from "../components/ui/statistic/TotalAmountWidget";
import PieCustomLabel from "../components/ui/statistic/PieCustomLabel";
import AnalyticSchoolingChartWidget from "../components/ui/statistic/AnalyticSchoolingChartWidget";
import {RdvTimeLine} from "../components/ui/statistic/RdvTimeLine";
import {FormattedMessage, useIntl} from "react-intl";
import messages from "./messages/general/messages";
import SchoolingService from "../service/SchoolingService";
import ChildrenService from "../service/ChildrenService";
import AppointmentService from "../service/AppointmentService";
import moment from "moment";
import ExchangeLibraryService from "../service/ExchangeLibraryService";
import _ from "lodash";
import {ScolariteType} from "../constants/type/ScolariteType";
import {VersementByScolariteType} from "../constants/type/VersementByScolariteType";
import {RevenuPlanningByEcheancier} from "../constants/type/RevenuPlanningByEcheancier";
import {ScolariteEcheancier} from "../constants/type/ScolariteEcheancier";
import {VersementByEcheancier} from "../constants/type/VersementByEcheancier";
import ImsDayService from "../service/ImsDayService";
import MyImsDayChart from "../components/ui/statistic/MyImsDayChart";
import {RdvInfo} from "../constants/type/RdvInfo";

const useStyles = makeStyles((theme:any) => {
    return {
        root: {
            flexGrow: 1,
        },
        spacingContainer: {
            marginBottom: 40
        },
        spacingItem: {
            marginBottom: '20px !important'
        },
        amountIcon: {
            color: theme.palette.common.white,
            opacity: 0.7,
            fontSize: '64px !important',
        },
        schoolingWrap: {
            overflow: 'auto',
        },
        schoolingFluid: {
            width: '100%',
        },
        spacingSchool: {
            paddingLeft: 10,
            paddingRight: 10
        },
        rootCounter: {
            flexGrow: 1,
        },
        rootCounterFull: {
            flexGrow: 1,
        },
        boxIcon: {
            '& $counterIcon': {
                fontSize: 84,
            },
        },
        counterIcon: {
            color: theme.palette.common.black,
            opacity: 0.7,
            fontSize: '84px !important',
        },
    }
});

const marks = [
    {
        value: 0,
        label: '0',
    },
    {
        value: 90,
        label: '90',
    },
];


const DiscreteSliderMarks = (props:any) => {
    const {onChangeCommitted, valeur, intl} = props ;
    let libelle = intl.formatMessage({id:messages.this_day});
    if(valeur > 0){
        libelle = `${valeur} ${intl.formatMessage({id:messages.indicator_imsday_lastdays})}`;
    }
    return (
        <Stack direction="row" spacing={2} sx={{mt:2.5, verticalAlign:"sub"}}>
            <FormattedMessage id={messages.indicator_imsday} />
            <Box sx={{ width: 20 }}></Box>
            <Box sx={{ width: 250 }}>
                <Slider
                    onChangeCommitted={onChangeCommitted}
                    aria-labelledby="track-false-slider"
                    defaultValue={30}
                    shiftStep={1}
                    step={1}
                    min={0}
                    max={90}
                    marks={marks}
                    valueLabelDisplay="auto"
                />
            </Box>
            <span>{libelle}</span>
        </Stack>

    );
}

export default function Dashboard(props:any) {
     const { locale } = props;
    const activeLanguage = locale.split('-');
    const classes = useStyles();
    const [revenuTheoriqueTuitionUntilTodayLabel, setRevenuTheoriqueTuitionUntilTodayLabel] = useState<string>('');
    const [versementTotal, setVersementTotal] = useState<number>(0);
    const [tauxRecouvrementScolarite, setTauxRecouvrementScolarite] = useState<number>(0);
    const [schoolingTurnover, setSchoolingTurnover] = useState(0);
    const [schoolFeesTurnover, setSchoolFeesTurnover] = useState(0);
    const [childrenNumber, setChildrenNumber] = useState(0);
    const [tuitionPie, setTuitionPie] = useState<any[]>([]);
    const [imsDayIndicators, setImsDayIndicators] = useState<any[]>([]);
    const [revenuLineChartPie, setRevenuLineChartPie] = useState<any[]>([]);
    const [rdvData, setRdvData] = useState<RdvInfo[]>([]);
    const [bookLoaningNumber, setBookLoaningNumber] = useState(0);
    const [imsDayLastDaysNumber, setImsDayLastDaysNumber] = useState(30);

    const onHandleSliderChange = (event: Event, newValue: number | number[]) => {
        setImsDayLastDaysNumber(newValue as number);
    };
    //const [countCurve, setCountCurve] = useState(0);

    const intl = useIntl() ;
    const paymentService = SchoolingService();
    const imsDayService = ImsDayService();
    const childrenService = ChildrenService();
    const rdvService = AppointmentService();
    const exchangeLibraryService = ExchangeLibraryService();



    useEffect(() => {
        (async () => {
            const countIndicatorImsDay : any[] = await imsDayService.countIndicator(imsDayLastDaysNumber, intl);
            setImsDayIndicators(countIndicatorImsDay);
        })()
    }, [imsDayLastDaysNumber]);


    useEffect(() => {
        moment.locale(activeLanguage);
    }, [activeLanguage]);

    useEffect(() => {

        (async () => {

            try {
                const resultCount: number = await childrenService.getTotalEleve();
                setChildrenNumber(resultCount);

                const totalBooksLoaning:number = await exchangeLibraryService.totalBooksLoaning();
                setBookLoaningNumber(totalBooksLoaning);

                const revenuTheoriqueTuitionUntilToday:number = await paymentService.getTotalEcheancePlanningUntilToday();

                //setBookLoaningNumber(totalBooksLoaning);

                const scoltypeList: ScolariteType[] = await paymentService.getAllScolariteTypes();
                //setScolariteTypes(scoltypeList);

                const echeanciersList: ScolariteEcheancier[] = await paymentService.getEcheanciers();
                //setEcheanciers(echeanciersList);

                const revenuPlaning: RevenuPlanningByEcheancier[] = await paymentService.getRevenuPlanningByEcheancier();
                // setRevenuPlanningByEcheancier(revenuPlaning);

                const versementByTypeList : VersementByScolariteType[] = await paymentService.getAllVersementByTypes();
                // setVersementsByScolType(versementByTypeList);

                const versementByEcheanList : VersementByEcheancier[] = await paymentService.getVersementsByEcheancier();
               // setVersementByEcheanciers(versementByEcheanList);

                const rdvinfos : RdvInfo[] = await rdvService.getAllNextConfirmRdv();
                setRdvData(rdvinfos);

                // @ts-ignore
                const tuitionsTypesIds : number[] = _.map(
                    _.filter(scoltypeList, item => item?.common?.tag === 'schooling'),
                    itemfilter => itemfilter.id
                );

                const paiementTotal = paymentService.getTurnoverSum(versementByTypeList);
                const versemenTuitionTotal = paymentService.getSchoolingTurnoverSum(versementByTypeList, tuitionsTypesIds);
                const versemenFeesTotal = paiementTotal - versemenTuitionTotal;
                const ecartheoriquereelTuition = revenuTheoriqueTuitionUntilToday - versemenTuitionTotal;

                setRevenuTheoriqueTuitionUntilTodayLabel(`${new Intl.NumberFormat('fr-FR').format(ecartheoriquereelTuition)} FCFA, ${intl.formatMessage({id:messages.ecart_theorique_reel})}`);
                setTauxRecouvrementScolarite(paymentService.getTauxRecouvrement(revenuTheoriqueTuitionUntilToday, versemenTuitionTotal));
                setVersementTotal(paiementTotal);
                setSchoolingTurnover(versemenTuitionTotal);
                setSchoolFeesTurnover(versemenFeesTotal);

                setTuitionPie(paymentService.getTuitionSumByFees(versementByTypeList, scoltypeList));
                setRevenuLineChartPie(paymentService.getSchoolingComparativeList(echeanciersList, revenuPlaning, versementByEcheanList));



                // GET ALL CHILDREN
                //const children = resultCount._embedded.enfantDTOModelList ?? [];
                //setChildrenList(children);


               /* const tauxRecouvrementScolarite = paymentService.getTurnoverSum(turnoverData);
                setTurnover(tauxRecouvrementScolarite);

                const schoolingFees = await paymentService.getAllSchoolingFees();
                if(schoolingFees.length > 0){
                    const scolariteTypeFind = schoolingFees.find((scolariteType:any) => scolariteType.common.tag === "schooling");
                    const schoolingSum = paymentService.getSchoolingTurnoverSum(turnoverData, scolariteTypeFind.id);
                    setSchoolingTurnover(schoolingSum);

                    const schoolFeesSum = paymentService.getFeesTurnoverSum(turnoverData, scolariteTypeFind.id);
                    setSchoolFeesTurnover(schoolFeesSum);

                    //GET SUM OF SCHOOL FEES BY FEES
                    const tuitionFeesSum:any = paymentService.getTuitionFeesSum(
                        turnoverData,
                        tauxRecouvrementScolarite,
                        scolariteTypeFind.id,
                        schoolingSum,
                        schoolingFees,
                        activeLanguage[0]
                    );

                    setTuitionPie(tuitionFeesSum);
                }*/

                //EXCHANGE LIBRARY
                /*const bookListReq = await exchangeLibraryService.getAllBooks();
                setBookNumber(bookListReq.length);



                //COMPARATIVE CURVE TUITION TURNOVER
                const periodList = await periodService.getAllSchoolPeriod();
                let dataPeriodList = periodList.sort(function (a:any, b:any) {
                    return a.dateDebut - b.dateDebut;
                });

                if(dataPeriodList.length > 0){
                    const startSchoolPeriod = dataPeriodList[0];
                    const limit = dataPeriodList.length - 1;
                    const endSchoolPeriod = dataPeriodList[limit];

                    //const listPaymentDeadline:any = []; //await paymentService.getAllDeadline();
                    const listPaymentDeadline =  await paymentService.getAllChildEcheancierDeadline();

                    const paymentListReq = await paymentService.getAllPayment();
                    const schoolingFees = await paymentService.getAllSchoolingFees();
                    if(schoolingFees.length > 0) {
                        const scolariteTypeFind = schoolingFees.find((scolariteType: any) => scolariteType.common.tag === "schooling");
                        const paymentListComp = paymentListReq.filter((payment:any) => payment.scolariteTypeId === scolariteTypeFind.id);

                        const paymentListFormated = paymentService.getSchoolingPaymentDashboard(paymentListComp);
                        const comparativeData:any = paymentService.getSchoolingComparativeList(startSchoolPeriod.dateDebut, endSchoolPeriod.dateFin, listPaymentDeadline, paymentListFormated);
                        setSchoolingComparativeData(comparativeData);
                    }
                }

                const childByGender:any = childrenService.getChildrenNumberByGender(childrenCount, activeLanguage[0]);
                setChildPercentByGender(childByGender);

                const rdvListReq = await rdvService.getAllAppointment();
                const today = moment();
                const rdvListMonths = getMonthsListBarByDate(today.valueOf());
                const barChartData = rdvService.getBarChartData(rdvListReq, rdvListMonths);
                setRdvBarChartData(barChartData);*/

            }
            catch (error){
                console.log(error);
            }
        })()

    }, []);
    //[countCurve, activeLanguage, childrenService, periodService, paymentService, rdvService, exchangeLibraryService]

    return (
        <div>
            <Grid container spacing={3} className={classNames(classes.root, classes.spacingContainer)}>
                <Grid item xs={12}>
                    <div className={classes.rootCounterFull}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <KpIndicatorWidget
                                    color="primary-dark"
                                    number={new Intl.NumberFormat('fr-FR').format(tauxRecouvrementScolarite)}
                                    devise="%"
                                    title={<FormattedMessage id={messages.recouvrement_scolarite} />}
                                    moment={revenuTheoriqueTuitionUntilTodayLabel}
                                >
                                    <AccountBalanceWallet className={classes.counterIcon}  />
                                </KpIndicatorWidget>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <KpIndicatorWidget
                                    color="secondary-main"
                                    number={new Intl.NumberFormat('fr-FR').format(bookLoaningNumber)}
                                    title={<FormattedMessage id={messages.books_loaning} />}
                                    moment={<FormattedMessage id={messages.until_today} />}
                                >
                                    <AutoStories className={classes.counterIcon} />
                                </KpIndicatorWidget>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <KpIndicatorWidget
                                    color="secondary-main"
                                    number={new Intl.NumberFormat('fr-FR').format(childrenNumber)}
                                    moment={<FormattedMessage id={messages.this_year_dashboard} />}
                                    title={<FormattedMessage id={messages.children_dashboard} />}
                                >
                                    <People className={classes.counterIcon} />
                                </KpIndicatorWidget>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>

            {/* 2nd Section */}
            <Grid container spacing={3} className={classes.root}>
                <Grid item xs={12} className={classes.spacingItem}>
                    <Grid item md={12} xs={12}>
                        <AnalyticSchoolingChartWidget
                            data={revenuLineChartPie}
                            legendOne={<FormattedMessage id={messages.turnover_schooling_forecast} />}
                            legendTwo={<FormattedMessage id={messages.turnover_schooling_actual} />}
                            width={"100%"}
                            height={"100%"}
                        />
                    </Grid>
                </Grid>

                <Grid item md={8} xs={12} container spacing={3} >
                    <Grid item md={6} xs={12}>
                        <PapperBlock whiteBg noMargin overflowX title={<FormattedMessage id={messages.title_amount_box} />} icon="payment" desc="">
                            <Grid item md={12} xs={12} sm={12}>
                                <div className={classes.spacingSchool}>
                                    <Grid container spacing={3}>
                                        <Grid item sm={12} xs={12} md={12}>
                                            <TotalAmountWidget
                                                color="secondary-main"
                                                title={<FormattedMessage id={messages.total_amount_schooling_and_fees} />}
                                                amount={new Intl.NumberFormat('fr-FR').format(versementTotal)}
                                                devise="FCFA"
                                            >
                                                <AccountBalanceWalletIcon className={classes.amountIcon} />
                                            </TotalAmountWidget>
                                        </Grid>
                                    </Grid>
                                    <Divider style={{ marginBottom: '20px' }} />

                                    <Grid container spacing={3}>
                                        <Grid item sm={12} xs={12}>
                                            <TotalAmountWidget
                                                color="fourth-main"
                                                title={<FormattedMessage id={messages.total_amount_schooling} />}
                                                amount={new Intl.NumberFormat('fr-FR').format(schoolingTurnover)}
                                                devise="FCFA"
                                            >
                                                <PaymentIcon className={classes.amountIcon} />
                                            </TotalAmountWidget>
                                        </Grid>
                                    </Grid>
                                    <Divider style={{marginBottom: '20px'}} />

                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <TotalAmountWidget
                                                color="thirdly-main"
                                                title={<FormattedMessage id={messages.total_amount_additionalfees} />}
                                                amount={new Intl.NumberFormat('fr-FR').format(schoolFeesTurnover)}
                                                devise="FCFA"
                                            >
                                                <HorizontalSplitIcon className={classes.amountIcon} />
                                            </TotalAmountWidget>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        </PapperBlock>
                    </Grid>

                    <Grid item md={6} xs={12}>
                        <PapperBlock whiteBg noMargin sx={{pb:0, mb:0}} title={<FormattedMessage id={messages.payment_tuition_type} />} icon="group" desc="">
                            <Grid item md={12} xs={12}>
                                <div className={classes.spacingSchool}>
                                    <PieCustomLabel data={tuitionPie} />
                                </div>
                            </Grid>
                        </PapperBlock>
                    </Grid>

                    <Grid item md={12} xs={12}>
                        <PapperBlock whiteBg noMargin overflowX
                                     title={<DiscreteSliderMarks valeur={imsDayLastDaysNumber} intl={intl} onChangeCommitted={onHandleSliderChange} />} icon="accessibility" desc="">
                            <Grid item md={12} xs={12} sm={12}>
                                <MyImsDayChart data={imsDayIndicators}/>
                            </Grid>
                        </PapperBlock>
                      {/*  <PapperBlock whiteBg noMargin overflowX title={<FormattedMessage id={messages.indicator_imsday} />} icon="group" desc="">
                            <Grid item md={12} xs={12} sm={12}>
                                <div className={classes.spacingSchool}>
                                    <FunnelChart data={imsDayIndicators}/>
                                </div>
                            </Grid>
                        </PapperBlock>*/}
                    </Grid>
                </Grid>

                <Grid item md={4} xs={12}>
                    <PapperBlock whiteBg noMargin title={<FormattedMessage id={messages.appointment_dashboard_box} />} icon="timeline" desc="">
                        <Grid item md={12} xs={12}>
                            <div className={classes.spacingSchool}>
                                <RdvTimeLine
                                    data={rdvData}
                                    intl={intl}
                                    width="100%"
                                    height="100%"
                                />
                            </div>
                        </Grid>
                    </PapperBlock>
                </Grid>


            </Grid>

        </div>
    )
}
