import {deleteRequest, getRequest} from "../helpers/axios_helpers";
import {GENDER_EN, GENDER_FR} from "../constants";
import {BASEURL_RESOURCES} from "../config/AppUrl";
import moment from "moment";
import {RoleUser} from "../constants/type/RoleUser";
import React from "react";
import {useSelector} from "react-redux";
import _ from "lodash";

export function decimalAdjust(type:any, value:any, exp:any) {
    type = String(type);
    if (!["round", "floor", "ceil"].includes(type)) {
        throw new TypeError(
            "The type of decimal adjustment must be one of 'round', 'floor', or 'ceil'.",
        );
    }
    exp = Number(exp);
    value = Number(value);
    if (exp % 1 !== 0 || Number.isNaN(value)) {
        return NaN;
    } else if (exp === 0) {
        // @ts-ignore
        return Math[type](value);
    }
    const [magnitude, exponent = 0] = value.toString().split("e");
    // @ts-ignore
    const adjustedValue = Math[type](`${magnitude}e${exponent - exp}`);
    // Shift back
    const [newMagnitude, newExponent = 0] = adjustedValue.toString().split("e");
    return Number(`${newMagnitude}e${+newExponent + exp}`);
}

export function floorNumber(number:any, pad:any) {
    const floor10 = (value:any, exp:any) => decimalAdjust("floor", value, exp);
    return floor10(number, pad);
}

function ChildrenService() {
    const userRef = React.useRef<any>(useSelector((state:any) => state.user.user));
    return {
        getTotalEleve: async () => {
            const total = await getRequest(`/corebase/enfants/totaleleve`);
            return total ?? 0;
        },
        getAllChildrenData: async () => {
            return await getRequest('/corebase/enfants');
        },
        getChildrenNumberByGender: (data:any, lang:string) => {
            let male = 0;
            let female = 0;

            if(data.length > 0){
                for(let i=0; i<data.length; i++){
                    if(data[i].person.sexe === "HOMME"){
                        male++;
                    } else {
                        female++;
                    }
                }
            }

            return [
                {name: lang === 'en' ? GENDER_EN[0].name : GENDER_FR[0].name, value: male},
                {name: lang === 'en' ? GENDER_EN[1].name : GENDER_FR[1].name, value: female},
            ];
        },
        reformatChildrenList: (dataList:any) => {
            let uneListe = _.cloneDeep(dataList);
            if(userRef?.current?.role === RoleUser.TEACHER){
                const classeId = userRef?.current?.userDetails?.personDetails?.teacherClasses[0]?.classeId ?? 0;
                uneListe = _.filter(uneListe, item => item.eleves[0]?.classe?.id === classeId);
            }
            let childrenList = uneListe.sort(function (a:any, b:any) {
                const firstName = a.person.nom.concat(a.person.prenom);
                const secondName = b.person.nom.concat(b.person.prenom);
                if(firstName < secondName) { return -1; }
                if(firstName > secondName) { return 1; }
                return 0;
            });

            return childrenList.map((child:any) => {
                if(child?.eleves !== undefined && child?.eleves !== null){
                    const eleve = child?.eleves[0];
                    child.person.photoDataTable = (child?.person?.photo !== null && child?.person?.photo !== "") ? `${BASEURL_RESOURCES}/${child?.person?.photo}` : '';
                    child.person.birthDay = moment(child?.person?.dateNaissance).format("L");
                    child.person.classeName = eleve?.classe?.nom;
                    return child;
                }
            });
        },
        getAllRegistrationFile: async () => {
            const folderConstitutiveReq:any = await getRequest(`/corebase/typedossier/tag/registration_file`);
            return folderConstitutiveReq?.dossierConstitutifs !== undefined ? folderConstitutiveReq?.dossierConstitutifs : [];
        },
        getAllSubRegistrationFile: async () => {
            const subFolderReq:any = await getRequest(`/corebase/typesousdossier`);
            return subFolderReq._embedded !== undefined ? subFolderReq._embedded?.typeSousDossierDTOModelList : [];
        },
        getChildRegistrationFile: (folderConstitutive:any, subFolderList:any) => {
            let listSubFilesType:any = [];
            for (let i = 0; i < folderConstitutive.length; i++) {
                const element:any = folderConstitutive[i];

                let subFolderFound = subFolderList.find((subFolder:any) => subFolder.id === element.typeSousDossierId);
                if(subFolderFound !== undefined){
                    subFolderFound = {...subFolderFound, typeDossierId:element.typeDossierId, dossierConstitutifId: element.id }
                    listSubFilesType.push(subFolderFound);
                }
            }
            return listSubFilesType.sort(function (a:any, b:any) {
                if(a.nom < b.nom) { return -1; }
                if(a.nom > b.nom) { return 1; }
                return 0;
            });
        },
        getAllImageRights: async () => {
            const fileListRequest:any = await getRequest(`/corebase/imageright`);
            let imageRightsList:any = fileListRequest._embedded !== undefined ? fileListRequest._embedded?.droitImageDTOModelList : [];
            return imageRightsList.sort(function (a:any, b:any) {
                if(a.nom < b.nom) { return -1; }
                if(a.nom > b.nom) { return 1; }
                return 0;
            });
        },
        removeChildFromSiblings: async (childProfileId:any, dataIds:any) => {
           await deleteRequest(`/corebase/enfants/${childProfileId}/parent`, dataIds);
        }
    };
}

export default ChildrenService;
