import {Common, commonDataImp} from "./Common";
import _ from "lodash";
import * as yup from "yup";
import messages from "../../page/messages/general/messages";
import {isColumnVisible} from "../../helpers/helpers";
import {ScolariteAnnee, scolariteAnneeDataImpl} from "./ScolariteAnnee";


export const GROUPESECTION_CONSTANT = {
    convertToFrontEnd: (logic:any, item:Groupesection) => {
        // @ts-ignore
        const itemInit = _.cloneDeep(groupesectionDataImpl);
        return {...itemInit, ...item} ;
    },
    convertToBackEnd: (logic:any, item:Groupesection) => item,
};


export const groupColumnsVisibilityPlugin = {
    columnVisibility : {
        'groupesectionForm_nom': false,
        'groupesectionForm_code': true,
    }
} ;


export const columnsGroup = (props:any) => {
    const {intl} = props ;

    return [
        {
            id: 'groupesectionForm_nom',
            accessorKey: 'nom',
            header: intl.formatMessage({id: messages.table_h_nom}),
        },
        {
            id: 'groupesectionForm_code',
            accessorKey: 'code',
            header: intl.formatMessage({id: messages.header_code}),
        }
    ];
} ;

export const columnsGroupPlugin = (props:any) => {
    const {intl, nomSpace, options, callKeyValue} = props ;
    const infoSpace = nomSpace ?? ``;

    return [
        {
            id: 'groupesectionForm_nom',
            accessorFn: (row:any) => callKeyValue(row)?.nom,
            header: `${infoSpace} ${intl.formatMessage({id: messages.table_h_nom})}`,
            filterVariant: 'multi-select',
            size: options?.nomSize ?? undefined,
        },
        {
            id: 'groupesectionForm_code',
            accessorFn: (row:any) => callKeyValue(row)?.code,
            header: `${infoSpace} ${intl.formatMessage({id: messages.header_code})}`,
            size: options?.codeSize ?? undefined,
        }
    ];
} ;


export const exportExcelGroup = ({columns=[], rows=[], intl={}}:{rows:any[]; intl:any; columns:any[];}) => {
    return _.map(rows, (row:any) => {
        let obj:any = {};
        const data = row.original ;

        if(isColumnVisible(columns, 'nom')){
            obj[intl.formatMessage({id:messages.table_h_nom})] = data.nom;
        }
        if(isColumnVisible(columns, 'code')){
            obj[intl.formatMessage({id:messages.header_code})] = data.code;
        }
        return obj;
    });
}

export const exportExcelGroupPlugin = ({obj={}, columns=[], data=_.cloneDeep(groupesectionDataImpl), infoSpace=``, intl={}}
                                                    :{obj:any; data:Groupesection; intl:any; infoSpace:string; columns:any[];}) => {

    if(isColumnVisible(columns, 'groupesectionForm_nom')){
        obj[`${infoSpace} ${intl.formatMessage({id:messages.table_h_nom})}`] = data.nom;
    }
    if(isColumnVisible(columns, 'groupesectionForm_code')){
        obj[`${infoSpace} ${intl.formatMessage({id:messages.header_code})}`] = data.code;
    }
    return obj;
}



export  const groupesectionDataImpl: Groupesection = {
    id: null,
    nom: '',
    couleur: '',
    code: '',
    common: _.cloneDeep(commonDataImp),
}

export const groupesectionSchema = yup.object().shape({
    id: yup.number().nullable(),
    nom: yup.string().nonNullable(),
    couleur: yup.string().nullable(),
    code: yup.string().nullable(),
    common: yup.mixed<Common>(),
});

export type Groupesection = yup.InferType<typeof groupesectionSchema>;

