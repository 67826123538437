import TimelineItem from '@mui/lab/TimelineItem';
import Timeline from '@mui/lab/Timeline';
import Box from '@mui/material/Box';
import React, {useMemo} from 'react';
import {TimelineConnector, TimelineContent, TimelineDot, TimelineOppositeContent, TimelineSeparator} from "@mui/lab";
import {Avatar, styled, Tooltip, tooltipClasses, TooltipProps, Typography} from '@mui/material';
import _ from "lodash";
import moment from "moment";
import {RdvInfo} from "../../../constants/type/RdvInfo";
import {BASEURL_RESOURCES} from "../../../config/AppUrl";
import {stringAvatar} from "../../../helpers/helpers";
import {FormattedMessage} from "react-intl";
import messages from "../../../page/messages/general/messages";


const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
    [`& ul`]: {
        paddingLeft:12,
        paddingRight:12
    },
    [`& ul li`]: {
        marginTop:6,
        marginBottom:6,
        color: '#616161',
    },
    [`& ul li > span`]: {
        color: '#212121',
    },
}));

export function RdvTimeLine(props:any) {
    const {data, intl} = props;

    const listeDesMessages = useMemo(() => {
        return _.map(data, (rdv:RdvInfo) => {
            const theDate = moment(rdv.laDate);
            const tooltip = (
                <Box>
                    <ul>
                        <li><span><FormattedMessage id={messages.object_message} />:</span>&nbsp;&nbsp;{rdv.objet}</li>
                        <li><span>Details:</span>&nbsp;&nbsp;&nbsp;{rdv.details}</li>
                        <li><span><FormattedMessage id={messages.teacher} />:</span>&nbsp;&nbsp;{rdv.educateur.prenom} {rdv.educateur.nom}</li>
                        <li><span><FormattedMessage id={messages.demandeur} />:</span>&nbsp;&nbsp;{rdv.demandeur}</li>
                    </ul>
                </Box>
            ) ;
            return (
                <TimelineItem>
                    <TimelineOppositeContent
                        sx={{ m: 'auto 0' }}
                        align="right"
                        variant="body2"
                        color="text.secondary"
                    >
                        <p style={{margin:0}}>{_.startCase(_.toLower(theDate.format('LL')))}</p>
                        <p style={{margin:0}}>{theDate.format('LT')}</p>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector sx={{height:30}} />
                        <TimelineDot sx={{padding:0, paddingTop:0.1}}>
                            <HtmlTooltip title={tooltip}>
                                <Avatar {...stringAvatar(rdv.enfant.prenom+' '+rdv.enfant.nom)}
                                        alt={rdv.enfant.prenom+' '+rdv.enfant.nom}
                                        src={`${BASEURL_RESOURCES}/${rdv.enfant.photo}`} />
                            </HtmlTooltip>
                        </TimelineDot>
                        <TimelineConnector sx={{height:30}} />
                    </TimelineSeparator>
                    <TimelineContent  sx={{ m: 'auto 0'}}>
                        <Typography variant="h6"  sx={{fontSize:'1em' }}>{rdv.enfant.prenom}</Typography>
                        <Typography sx={{fontSize:'0.9em', color: '#455A64' }}>{rdv.parent.prenom} {rdv.parent.nom}</Typography>
                    </TimelineContent>
                </TimelineItem>
            )
        });
    }, [data]);



    if(_.isEmpty(data)){
        return <Box sx={{textAlign:'center'}}>NONE APPOINTMENT</Box>
    }

    return (
        <Box sx={{width:'100%', height:1080, overflowY: 'auto'}}>
            <Timeline position="alternate">
                {listeDesMessages}
            </Timeline>

        </Box>

    )
}
