import {getRequest} from "../helpers/axios_helpers";
import {getNextMonthsListByDate, getPrevMonthsListByDate, getShortMonthName, isPositiveValue} from "../helpers/helpers";
import moment from "moment";
import _ from 'lodash';
import {ClasseRoom} from "../constants/type/ClasseRoom";
import {VersementByScolariteType} from "../constants/type/VersementByScolariteType";
import {ScolariteType} from "../constants/type/ScolariteType";
import {RevenuPlanningByEcheancier} from "../constants/type/RevenuPlanningByEcheancier";
import {ScolariteEcheancier} from "../constants/type/ScolariteEcheancier";
import {VersementByEcheancier} from "../constants/type/VersementByEcheancier";

function SchoolingService() {
    return {
        getEcheanciers: async () => {
            const result = await getRequest(`/extra/scolaritecheancier`);
            return result._embedded?.scolariteEcheancierDTOModelList ?? [];
        },
        getRevenuPlanningByEcheancier: async () => {
            const result: any[] = await getRequest(`/extra/scolaritecheancierbyenfant/revenuplanningbyecheancier`);
            return result ?? [];
        },
        getTotalEcheancePlanningUntilToday: async () => {
            const result: number = await getRequest(`/extra/scolaritecheancierbyenfant/totalrevenuplanningecheanceuntiltoday`);
            return result ?? 0;
        },
        getVersementsByEcheancier: async () => {
            const result: any[] = await getRequest(`/extra/scolaritenfant/versementbyecheancier`);
            return result ?? [];
        },
        getVersementsEcheancierEnfant: async () => {
            const result = await getRequest(`/extra/scolaritenfant/versementecheancierenfant`);
            return result._embedded?.scolariteVersementEcheancierEnfantDTOModelList ?? [];
        },
        getAllPayment: async () => {
            const result = await getRequest(`/extra/scolaritenfant`);
            return result._embedded?.scolariteVersementDTOModelList ?? [];
        },
        getAllVersementByTypes: async () => {
            const result: any[] = await getRequest(`/extra/scolaritenfant/versementbyscolaritetype`);
            return  result ?? [];
        },
        getAllScolariteTypes: async () => {
            const result = await getRequest(`/extra/scolaritelistfrais`);
            return result._embedded?.scolariteTypeDTOModelList ?? [];
        },
        getAllScolariteGroups: async () => {
            try {
                const result = await getRequest(`/extra/scolaritedesfrais`);
                return result._embedded?.scolariteGroupDTOModelList ?? [];
            }
            catch (error){
                console.log(error);
            }
        },
        getTurnoverSum: (datas:VersementByScolariteType[]) => _.sumBy(datas, 'montant'),
        getTauxRecouvrement: (totalRevenuPlanningUntilToday:number, versemenTuitionTotal:number) => {
            if(isPositiveValue(totalRevenuPlanningUntilToday)){
                // @ts-ignore
                return parseFloat(Number.parseFloat(((versemenTuitionTotal * 100.0) / totalRevenuPlanningUntilToday)).toFixed(2))  ;
            }
           return 0.0;
        },
        getSchoolingTurnoverSum: (datas: VersementByScolariteType[], tuitionsTypesIds: number[]) => {
            const tuitionDatas = _.filter(datas, item => _.includes(tuitionsTypesIds, item.scolariteTypeId));
            return _.sumBy(tuitionDatas, 'montant');
        },
        getTuitionSumByFees: (
            versementByTypeList: VersementByScolariteType[],
            feesTypeList: ScolariteType[],
        ) => {
            const chartpPieMap:Map<number, any> = new Map();//<scoltypeId, any>
            for(const feesType of feesTypeList){
                const feesPie: any =  {
                    id: feesType.id,
                    label: feesType.code ?? feesType.nom,
                    color: feesType.couleur,
                    value: 0
                };
                // @ts-ignore
                chartpPieMap.set(feesType.id, feesPie);
            }

            let total : number = 0.0 ;
            for(const versementByType of versementByTypeList){
                const chartpPie:any = chartpPieMap.get(versementByType.scolariteTypeId) ;
                if(_.isObject(chartpPie)){
                    // @ts-ignore
                    chartpPie.value += versementByType.montant ;
                    total += versementByType.montant ;
                }
                chartpPieMap.set(chartpPie.id, chartpPie);
            }

            const chartList: any[] = Array.from(chartpPieMap.values()) ;

            if(total > 0){
                const size = chartList.length ;
                for (let i = 0; i < size; i++) {
                    const chartpPie = chartList[i];
                    // @ts-ignore
                    const percent : string = Number.parseFloat( ((chartpPie.value * 100.0) / total) ).toFixed(2) ;
                    chartpPie.label = `${chartpPie.label} (${percent}%)`;
                    chartList[i] = chartpPie ;
                }
            }

            return chartList;
        },
        getSchoolingComparativeList: (echeanciersList: ScolariteEcheancier[],
                                      revenuPlaning: RevenuPlanningByEcheancier[],
                                      versementByEcheanList : VersementByEcheancier[]) => {
            const chiffreAffairePieMap:Map<string, any> = new Map();//<scoltypeId, any>
            const ech: any =  {
                name: 'ECH 0',
                label: 'Echéance 0',
                Theorique: 0,
                Reel: 0
            };
            chiffreAffairePieMap.set(ech.name, ech);
            for(const echeancier of echeanciersList){
                const echeance: any =  {
                    name: echeancier.code,
                    label: echeancier.nom,
                    Theorique: 0,
                    Reel: 0
                };
                // @ts-ignore
                chiffreAffairePieMap.set(echeance.name, echeance);
            }

            for(const revenuTheorique of revenuPlaning){
                const echeancierFound = _.find(echeanciersList, {"id":revenuTheorique.echeancierId});
                if(!_.isObject(echeancierFound)){
                    continue ;
                }
                const revenu = chiffreAffairePieMap.get(echeancierFound.code ?? '') ;
                if(!_.isObject(revenu)){
                    continue ;
                }
                // @ts-ignore
                revenu.Theorique += revenuTheorique.montant ;
                // @ts-ignore
                chiffreAffairePieMap.set(revenu.name, revenu);
            }

            for(const revenuReel of versementByEcheanList){
                const echeancierFound = _.find(echeanciersList, {"id":revenuReel.echeancierId});
                if(!_.isObject(echeancierFound)){
                    continue ;
                }

                const revenu = chiffreAffairePieMap.get(echeancierFound.code ?? '') ;
                if(!_.isObject(revenu)){
                    continue ;
                }

                // @ts-ignore
                revenu.Reel += revenuReel.montant ;
                // @ts-ignore
                chiffreAffairePieMap.set(revenu.name, revenu);
            }
            return _.sortBy(Array.from(chiffreAffairePieMap.values()), ["name"]);
        },
        /*getSchoolingComparativeList: (startPeriod:any, endPeriod:any, deadlineList:any, paymentList:any) => {
            let comparativeMonthsList:any = [];
            const monthsListPartOne:any = getNextMonthsListByDate(startPeriod);

            let monthsListPartTwo:any = getPrevMonthsListByDate(endPeriod);
            monthsListPartTwo = monthsListPartTwo.reverse();
            comparativeMonthsList = monthsListPartOne;

            for(let j=0; j<monthsListPartTwo.length; j++){
                if(!monthsListPartOne.includes(monthsListPartOne[j].month)){
                    comparativeMonthsList.push(monthsListPartTwo[j]);
                }
            }

            let schoolingData = [];
            for(let i=0; i<comparativeMonthsList.length; i++){
                const shortMonthName = getShortMonthName(comparativeMonthsList[i].month);
                if(shortMonthName !== null){
                    let sumDeadline = 0;
                    let sumPayment = 0;
                    for(let x=0; x<deadlineList.length; x++){
                        if(deadlineList[x].deadlineMonth === shortMonthName){
                            sumDeadline += deadlineList[x].montant;
                        }
                    }

                    for(let x=0; x<paymentList.length; x++){
                        if(paymentList[x].monthPayment === shortMonthName){
                            sumPayment += paymentList[x].montant;
                        }
                    }

                    const data = {
                        name: `${shortMonthName} ${comparativeMonthsList[i].year}`,
                        deadline: sumDeadline,
                        payment: sumPayment,
                    }
                    schoolingData.push(data);
                }
            }

            return schoolingData;
        },*/
        getAllDeadline: async () => {
            const result = await getRequest(`/extra/scolaritecheancier`);
            const responseList =  result._embedded !== undefined ? result._embedded?.scolariteProformaDTOModelList : [];

            let deadLineList:any = [];
            if(responseList.length > 0){
                for (let i=0; i<responseList.length; i++){
                    if(responseList[i].scolariteTypeTag === 'schooling'){
                        deadLineList.push(responseList[i]);
                    }
                }
            }

            return deadLineList.map((deadline:any) => {
                let deadlineDate = moment(deadline.ladate);
                const dateDeadline = deadlineDate.set({hours:0,minute:0,second:0,millisecond:0});
                const monthDeadline = deadlineDate.format('MMM');

                return {
                    ...deadline,
                    deadlineDate: dateDeadline.valueOf(),
                    deadlineMonth: monthDeadline,
                }
            });
        },
        getAllChildEcheancierDeadline: async () => {
            const result = await getRequest(`/extra/scolaritecheancierbyenfant`);
            const responseList =  result._embedded !== undefined ? result._embedded?.scolariteProformaEnfantDTOModelList : [];

            let deadLineList:any = [];
            if(responseList.length > 0){
                for (let i=0; i<responseList.length; i++){
                    if(responseList[i].scolariteTypeTag === 'schooling'){
                        deadLineList.push(responseList[i]);
                    }
                }
            }

            return deadLineList.map((deadline:any) => {
                let deadlineDate = moment(deadline.ladate);
                const dateDeadline = deadlineDate.set({hours:0,minute:0,second:0,millisecond:0});
                const monthDeadline = deadlineDate.format('MMM');

                return {
                    ...deadline,
                    deadlineDate: dateDeadline.valueOf(),
                    deadlineMonth: monthDeadline,
                }
            });
        },
        getSchoolingPaymentDashboard: (data:any) => {
            return data.map((payment:any) => {
                let paymentDate = moment(data.ladate);
                const datePayment = paymentDate.set({hours:0,minute:0,second:0,millisecond:0});
                const monthPayment = paymentDate.format('MMM');

                return {
                    ...payment,
                    datePayment: datePayment.valueOf(),
                    monthPayment: monthPayment,
                }
            });
        },
        getTuitionFeesSum: (
            data:any,
            turnover:any,
            scolariteTypeId:number,
            schoolingSum:number,
            schoolingFees:any,
            lang:string
        ) => {
            //console.log(JSON.stringify(schoolingFees));

            // TUITION FEES
            let tuition = schoolingSum > 0 ? (schoolingSum * 100) / turnover  : 0;
            const tuitionCode = schoolingFees.find((tuition:any) => tuition.id === scolariteTypeId);
            let tuitionPie:any = {
                name: tuitionCode !== undefined ? tuitionCode.code : '',
                value: tuition
            };
            let female = 0;

            // OTHER SCHOOL FEES
            const dataFiltered = data.filter((tuition:any) => tuition.scolariteTypeId !== scolariteTypeId)
            const groupedFeesData = _.groupBy(dataFiltered, 'scolariteTypeId');
            let schoolTuitionFees:any = [];

            if(dataFiltered.length > 0) {
                Object.keys(groupedFeesData).forEach(function(key, index) {
                    const tuitionCode = schoolingFees.find((tuition:any) => tuition.id === parseInt(key));
                    const paymentFeesList = groupedFeesData[key];
                    let feesSum = 0;
                    if(paymentFeesList.length > 0){
                        for(let i= 0; i<paymentFeesList.length; i++){
                            feesSum += paymentFeesList[i].montant;
                        }
                    }

                    const feesSumPie:any = {
                        name: tuitionCode !== undefined ? tuitionCode.code : '',
                        value: feesSum > 0 ? (feesSum * 100) / turnover  : 0,
                    };
                    schoolTuitionFees.push(feesSumPie);
                });
            }

            return [
                tuitionPie,
                ...schoolTuitionFees
            ];
        }
    };
}

export default SchoolingService;
