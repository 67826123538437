import React from "react";
import {request} from "../../../../helpers/axios_helpers";
import _ from "lodash";
import {FormattedMessage} from "react-intl";
import {processingChildren} from "../../../../helpers/loader_helpers";
import {ScolariteGroup, SCOLARITEGROUP_CONSTANT} from "../../../../constants/type/ScolariteGroup";
import matchingFraisOneShotEnfantStateUpdate from "./MatchingFraisOneShotEnfantStateUpdate";
import {
    ScolariteSansEcheanceEnfant,
    SCOLARITESANSECHEANCEENFANT_CONSTANT
} from "../../../../constants/type/ScolariteSansEcheanceEnfant";
import {Profils, PROFILS_CONSTANT} from "../../../../constants/type/Profils";
import {ScolariteType, SCOLARITETYPE_CONSTANT} from "../../../../constants/type/ScolariteType";
import {Child} from "../../../../constants/type/Child";
import {getErrorMessage} from "../../../../helpers/errorManager";
import {mixLoad} from "../../../../helpers/loader_cache_helpers";
import {Groupesection, GROUPESECTION_CONSTANT} from "../../../../constants/type/Groupesection";
import {
    ScolariteAnnee,
    SCOLARITEANNEE_CONSTANT,
    scolariteAnneeDataImpl
} from "../../../../constants/type/ScolariteAnnee";
import {GroupEnfant, GROUPENFANT_CONSTANT} from "../../../../constants/type/GroupEnfant";

const matchingFraisOneShotEnfantBusinessLogic : any = {
    initPostConstruct: (logic:any) => {
        const requestbody = ['groups', 'profils', 'scolaritetypes', 'scolariteannees', 'scolaritegroups', 'groupenfants', 'enfants'];
        const convertToFrontEnd = (data:any) => {
            const itemssca : ScolariteAnnee[]   = data.scolariteannees ?? [];
            logic.scolariteAnneesRef.current  = _.sortBy(_.map(itemssca,  item => SCOLARITEANNEE_CONSTANT.convertToFrontEnd(logic, item)), ['dateDebut']);
            matchingFraisOneShotEnfantStateUpdate.scolariteAnneeSel(logic, _.find(itemssca, {migrateNextYear:false}) ?? _.cloneDeep(scolariteAnneeDataImpl));

            const itemscl : Groupesection[]   = data.groups.content ?? [];
            logic.groupesectionsRef.current  = _.sortBy(_.map(itemscl, item => GROUPESECTION_CONSTANT.convertToFrontEnd(logic, item)), ['nom']);
            logic.groupesectionsRef.current.forEach((data:Groupesection) => {
                logic.groupesectionMapRef.current.set(data.id, data);
            });

            const itemsp : Profils[]   = data.profils.content ?? [];
            logic.profilsRef.current  = _.sortBy(_.map(itemsp,  item => PROFILS_CONSTANT.convertToFrontEnd(logic, item)), ['nom']);

            const itemsst : ScolariteType[]   = data.scolaritetypes.content ?? [];
            logic.scolariteTypeRef.current  = _.sortBy(_.map(itemsst,  item => SCOLARITETYPE_CONSTANT.convertToFrontEnd(logic, item)), ['nom']);
            logic.scolariteTypeRef.current.forEach((data:ScolariteType) => {
                logic.scolariteTypeMapRef.current.set(data.id, data);
            });

            let itemssc : ScolariteGroup[]   =_.sortBy(data.scolaritegroups.content ?? [], ['nom']) ;
            const itemsscFilter = _.filter(itemssc, { 'echeance': false});
            logic.scolariteGroupsRef.current = _.map(itemsscFilter,  item => SCOLARITEGROUP_CONSTANT.convertToFrontEnd(logic, item));
            logic.scolariteGroupsRef.current.forEach((data:ScolariteGroup) => {
                logic.scolariteGroupMapRef.current.set(data.id, data);
            });

            const itemchilds : Child[]   = data.enfants.content ?? [];
            logic = processingChildren(logic, itemchilds);

            const itemge : GroupEnfant[]   = data.groupenfants.content ?? [];
            logic.groupEnfantsRef.current  = _.map(itemge,  item => GROUPENFANT_CONSTANT.convertToFrontEnd(logic, item));
        };

        matchingFraisOneShotEnfantStateUpdate.dataLoading(logic, true);
        mixLoad(logic, requestbody, convertToFrontEnd).then(value => {
            matchingFraisOneShotEnfantStateUpdate.tableKeyValue(logic, new Date().getTime());
            matchingFraisOneShotEnfantStateUpdate.dataLoading(logic, false);
        }).catch(e => {
            matchingFraisOneShotEnfantStateUpdate.dataLoading(logic, false);
        });
      
    },
    loadFraisSansEcheances: (logic:any, scolariteGroupId:number) => {
        matchingFraisOneShotEnfantStateUpdate.dataLoading(logic, true);
        return new Promise<ScolariteSansEcheanceEnfant[]>(resolve =>
            request("GET",  `/extra/fraisoneshotenfant/scolaritegroup/${scolariteGroupId}`, {}).then((response) => {
                const items : ScolariteSansEcheanceEnfant[]   = response.data?._embedded?.scolariteSansecheanceEnfantDTOModelList ?? [];
                // @ts-ignore
                const itemsfrontend = _.sortBy(_.map(items,  item => SCOLARITESANSECHEANCEENFANT_CONSTANT.convertToFrontEnd(logic, item)), ['ladate']);
                resolve(itemsfrontend);
            }).catch((error) => {
                logic.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                resolve([]);
                matchingFraisOneShotEnfantStateUpdate.dataLoading(logic, false);
            })
        );
    },
    saveEdit: async (logic:any, messages:any, leftList:GroupEnfant[], rightList: GroupEnfant[],  scolariteGroupId:number):Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            const toSend = {
                groupEnfantFeeFree : leftList,
                groupEnfantFee      : rightList,
                scolariteGroupId    : scolariteGroupId,
            }
            request('PUT', `/extra/fraisoneshotenfant/transfert`, toSend).then((response) => {
                logic.snackbarShowMessage(<FormattedMessage id={messages.sb_success} />);
                resolve(true);
            }).catch((error) => {
                logic.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                resolve(false);
            });
        });
    },
}
export default matchingFraisOneShotEnfantBusinessLogic ;