import {getRequest} from "../helpers/axios_helpers";
import _, {iteratee} from "lodash";
import {stringToColor, stringToMaterialColor} from "../helpers/helpers";
import {IntlShape} from "react-intl";
import messages from "../page/messages/general/messages";

const imsDayColorsMap = new Map([
    ['sieste', '#43A047'],
    ['no_sieste', '#69F0AE'],
    ['incident', '#546E7A'],
    ['NOT_AT_ALL', '#E53935'],
    ['NOT_GOOD', '#29B6F6'],
    ['GOOD', '#FF9E80'],
    ['EASILY', '#FDD835'],
    ['VERY_WELL', '#8E24AA'],
    ['bowel_mvt', '#3949AB'],
]);
function ImsDayService() {
    return {
        countIndicator: async (lastDays:number,  intl: IntlShape) => {
            const result :any[] = await getRequest(`/extra/imsday/countindicator/${lastDays}`);
            let datas:any[] = result ?? [];

            const size = datas.length ;
            for (let i = 0; i < size; i++) {
                const datum = datas[i];
                const keyTraduction = `imsday_indicator_${datum.name}`;
                const idTraduction = messages[keyTraduction];
                if(!_.isUndefined(idTraduction)){
                    datum.name = intl.formatMessage({id:idTraduction});
                }

                datum.fill = imsDayColorsMap.get(datum.id) ?? stringToMaterialColor(datum.id);
                datas[i] = datum ;
            }
            return datas ;
        }
    }
}

export default ImsDayService;