import {Common, commonDataImp} from "./Common";
import _ from "lodash";
import * as yup from "yup";
import {GroupEnfantProfils} from "./GroupEnfantProfils";
import {
    Child,
    childColumnsVisibilityPlugin,
    childDataImpl,
    columnsChildPlugin,
    exportExcelChildPlugin
} from "./Child";
import {
    columnsGroupPlugin,
    exportExcelGroupPlugin, groupColumnsVisibilityPlugin,
    Groupesection,
    groupesectionDataImpl
} from "./Groupesection";
import {
    exportExcelScolariteAnPlugin,
    ScolariteAnnee, scolariteAnneeColumnsPlugin,
    scolariteAnneeColumnsVisibilityPlugin,
    scolariteAnneeDataImpl
} from "./ScolariteAnnee";
import messages from "../../page/messages/general/messages";
import {isColumnVisible} from "../../helpers/helpers";


export const GROUPENFANT_CONSTANT = {
    convertToFrontEnd: (logic:any, item:GroupEnfant) => {
        // @ts-ignore
        const itemInit = _.cloneDeep(groupEnfantDataImpl);
        const data = {...itemInit, ...item} ;
        data.groupesectionForm = _.find(logic.groupesectionsRef.current, {id:data.groupesectionId}) ?? _.cloneDeep(groupesectionDataImpl);

        data.childForm = logic.childrenMapRef.current.get(data.enfantId) ?? _.cloneDeep(childDataImpl);

        data.scolariteAnneeForm = _.find(logic.scolariteAnneesRef.current, {id:data.scolariteAnneeId}) ?? _.cloneDeep(scolariteAnneeDataImpl);
        data.scolariteAnNomForm = data.scolariteAnneeForm?.nom ?? ``;
        data.groupEnfantprofilForm   = _.reduce(data.groupEnfantProfils, function(profilNameReduce, groupEnfantProfil) {
            const profilname = _.find(logic.profilsRef.current, {id:groupEnfantProfil?.profilsId})?.nom ?? ``;
            const previousinfo = _.isEmpty(profilNameReduce) ? `` : profilNameReduce+', ';
            return `${previousinfo}${profilname}`;
        }, ``);


        return data ;
    },
    convertToBackEnd: (logic:any, item:GroupEnfant) => item,
};


export const groupEnfantColumnsVisibility = {
    columnVisibility : {
        ...childColumnsVisibilityPlugin.columnVisibility,
        ...groupColumnsVisibilityPlugin.columnVisibility,
        ...scolariteAnneeColumnsVisibilityPlugin.columnVisibility,
    }
} ;

export const groupEnfantColumnsVisibilityPlugin = {
    columnVisibility : {
        ...groupEnfantColumnsVisibility.columnVisibility
    }
} ;

export const columnsGroupEnfant = (props:any) => {
    const {intl, nomSpace} = props ;
    const infoSpace = nomSpace ?? ``;
    const scolariteAnOptions = {
        nomSize: 100,
        codeSize: 80,
    }
    const groupSectionOptions = {
        nomSize: 100,
        codeSize: 80,
    }

    const callKeyValChild = (row:any) => row?.childForm ;
    const callKeyValgroupesection = (row:any) => row?.groupesectionForm ;
    const callKeyValAnnee = (row:any) => row?.scolariteAnneeForm ;

    return [
        ...columnsChildPlugin({intl, nomSpace: intl.formatMessage({id:messages.table_h_enfant}), callKeyValue:callKeyValChild}),
        ...scolariteAnneeColumnsPlugin({intl, nomSpace: intl.formatMessage({id:messages.table_h_scolarite}), options:scolariteAnOptions, callKeyValue:callKeyValAnnee}),
        ...columnsGroupPlugin({intl, nomSpace: intl.formatMessage({id:messages.table_h_group}), options:groupSectionOptions, callKeyValue:callKeyValgroupesection}),
        {
            id: 'groupEnfantForm_groupEnfantprofilForm',
            accessorKey: 'groupEnfantprofilForm',
            header: `${infoSpace} ${intl.formatMessage({id:messages.table_h_profil})}`,
            size: 150,
        },
    ];
} ;

export const columnsGroupEnfantPlugin = (props:any) => {
    const {intl, nomSpace, callKeyValue, options} = props ;
    const infoSpace = nomSpace ?? ``;

    const scolariteAnOptions = {
        nomSize: 100,
        codeSize: 80,
    }
    const groupSectionOptions = {
        nomSize: 100,
        codeSize: 80,
    }

    const callKeyValChild = (row:any) => callKeyValue(row)?.childForm ;
    const callKeyValgroupesection = (row:any) => callKeyValue(row)?.groupesectionForm ;
    const callKeyValAnnee = (row:any) => callKeyValue(row)?.scolariteAnneeForm ;

    return [
        ...columnsChildPlugin({intl, nomSpace: intl.formatMessage({id:messages.table_h_enfant}), callKeyValue:callKeyValChild}),
        ...scolariteAnneeColumnsPlugin({intl, nomSpace: intl.formatMessage({id:messages.table_h_scolarite}), options:scolariteAnOptions, callKeyValue:callKeyValAnnee}),
        ...columnsGroupPlugin({intl, nomSpace: intl.formatMessage({id:messages.table_h_group}), options:groupSectionOptions, callKeyValue:callKeyValgroupesection}),
        {
            accessorFn: (row:any) => callKeyValue(row)?.scolariteAnNomForm,
            header: `${infoSpace} ${intl.formatMessage({id:messages.table_h_scolarite})}`,
            size: options?.scolariteAnNomFormSize ?? undefined,
        },
        {
            accessorFn: (row:any) => callKeyValue(row)?.groupEnfantprofilForm,
            header: `${infoSpace} ${intl.formatMessage({id:messages.table_h_profil})}`,
            size: options?.groupEnfantprofilFormSize ?? undefined,
            filterVariant: 'multi-select',
        },
    ];
} ;

export const exportExcelGroupEnfant = (
    {rows=[], columns=[], intl={}} :
        {rows:any[]; intl:any; columns: any[]; }
) => {
    return  _.map(rows, (row:any) => {
        let obj:any = {};
        const data = row.original;

        obj = exportExcelChildPlugin({obj, data:data?.childForm ?? _.cloneDeep(childDataImpl), intl, infoSpace:intl.formatMessage({id:messages.table_h_enfant}), columns});
        obj = exportExcelGroupPlugin({obj, data: data?.groupesectionForm ?? _.cloneDeep(groupesectionDataImpl), intl, columns, infoSpace: intl.formatMessage({id: messages.table_h_group})
        });
        obj = exportExcelScolariteAnPlugin({obj, data: data?.scolariteAnneeForm ?? _.cloneDeep(scolariteAnneeDataImpl), intl, columns, infoSpace: intl.formatMessage({id: messages.school_period_year})
        });

        if(isColumnVisible(columns, 'groupEnfantForm_groupEnfantprofilForm')){
            obj[intl.formatMessage({id:messages.table_h_profil})] = data.groupEnfantprofilForm;
        }
        return obj;
    });
}

export const exportExcelGroupEnfantPlugin = ({obj={}, columns=[], data=_.cloneDeep(groupEnfantDataImpl), infoSpace=``, intl={}}
                                                 :{obj:any; data:GroupEnfant; intl:any; infoSpace:string; columns:any[]; }) => {

    obj = exportExcelChildPlugin({obj, data:data?.childForm ?? _.cloneDeep(childDataImpl), intl, infoSpace:intl.formatMessage({id:messages.table_h_enfant}), columns});
    obj = exportExcelGroupPlugin({obj, data: data?.groupesectionForm ?? _.cloneDeep(groupesectionDataImpl), intl, columns, infoSpace: intl.formatMessage({id: messages.table_h_group})
    });
    obj = exportExcelScolariteAnPlugin({obj, data: data?.scolariteAnneeForm ?? _.cloneDeep(scolariteAnneeDataImpl), intl, columns, infoSpace: intl.formatMessage({id: messages.school_period_year})
    });

    if(isColumnVisible(columns, 'groupEnfantForm_groupEnfantprofilForm')){
        obj[`${infoSpace} ${intl.formatMessage({id:messages.table_h_profil})}`] = data.groupEnfantprofilForm;
    }
    return obj;
}

export  const groupEnfantDataImpl: GroupEnfant = {
    id: null,
    enfantId: 0,
    groupesectionId: 0,
    scolariteAnneeId: 0,
    groupEnfantProfils: [],
    common: _.cloneDeep(commonDataImp),
    groupEnfantprofilForm: ``,
    scolariteAnNomForm:``,
    childForm: _.cloneDeep(childDataImpl),
    groupesectionForm:_.cloneDeep(groupesectionDataImpl),
    scolariteAnneeForm:_.cloneDeep(scolariteAnneeDataImpl),
}

export const groupEnfantSchema = yup.object().shape({
    id: yup.number().nullable(),
    enfantId: yup.number().nullable().required(),
    groupesectionId: yup.number().nullable().required(),
    scolariteAnneeId: yup.number().nullable().required(),
    groupesectionForm: yup.mixed<Groupesection>(),
    childForm: yup.mixed<Child>(),
    groupEnfantprofilForm: yup.string(),
    scolariteAnneeForm: yup.mixed<ScolariteAnnee>(),
    scolariteAnNomForm: yup.string(),
    groupEnfantProfils: yup.array().of(yup.mixed<GroupEnfantProfils>()).nonNullable(),
    common: yup.mixed<Common>()
});


export type GroupEnfant = yup.InferType<typeof groupEnfantSchema>;


