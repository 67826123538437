import React, {useEffect, useState} from "react";
import {alpha, AppBar, Box, IconButton, lighten, Toolbar, Tooltip, Typography} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import {makeStyles} from "@mui/styles";
import classNames from "classnames";
import {NavLink} from "react-router-dom";
import {FullscreenExitOutlined, FullscreenOutlined} from "@mui/icons-material";
import UserMenu from "../UserMenu";
import {IMGS} from "../../constants";
import {useSelector} from "react-redux";

const elem:any = document.documentElement;

const useStyles = makeStyles((theme:any) => {
    return {
        appBar: {
            background: 'rgba(0,0,0,0)',
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(['width', 'margin', 'background'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            '& $menuButton': {
                color: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.main,
                zIndex: 10,
            },
            '& $headerTitle': {
                left: theme.spacing(3),
            },
        },
        appBarShift: {
            transition: theme.transitions.create(['width', 'margin', 'background'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            '& $headerAction': {
                marginLeft: theme.spacing(4),
            },
            '&$darker': {
                '& $menuButton': {
                    color: theme.palette.type === 'dark' ? theme.palette.primary.light : theme.palette.primary.main,
                },
            },
            '& $headerTitle': {
                [theme.breakpoints.up('lg')]: {
                    left: theme.spacing(8),
                },
            },
        },
        floatingBar: {
            position: 'fixed',
        },
        darker: {
            backgroundAttachment: 'fixed',
            backgroundColor:
                theme.palette.mode === 'dark' ? lighten(theme.palette.common.black, 0.1) : theme.palette.background.paper,
            boxShadow:
                theme.palette.mode === 'dark'
                    ? '0px 1px 6px 0px rgba(0, 0, 0, 1), 0px 1px 1px 0px rgba(42, 42, 42, 1), 0px 2px 1px -1px rgba(20, 20, 20, 1)'
                    : '0px 1px 6px 0px rgba(142, 142, 142, 0.2), 0px 1px 1px 0px rgba(243, 243, 243, 0.14), 0px 2px 1px -1px rgba(204, 204, 204, 0.12)',
            '& $menuButton': {
                color: theme.palette.common.white,
            },
        },
        fadeOut: {},
        invert: {},
        button: {},
        miniInput:{},
        headerAction: {
            margin: `0 ${theme.spacing(1)}px`,
            transition: 'opacity 0.5s ease',
            '& $button': {
                '& svg': {
                    fill: alpha(theme.palette.text.secondary, 0.48),
                    width: 28,
                    height: 28,
                },
            },
            '&$fadeOut': {
                opacity: 0,
            },
            '&$invert': {
                '& $button': {
                    '& svg': {
                        fill: alpha(theme.palette.text.primary, 0.5),
                    },
                },
            },
        },
        show: {},
        headerTitle: {
            transition: 'all 0.3s ease',
            fontSize: theme.spacing(3),
            position: 'absolute',
            textTransform: 'capitalize',
            fontWeight: 700,
            top: 60,
            color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.primary.main,
            opacity: 0,
            '&$show': {
                top: theme.spacing(1),
                opacity: 0.87,
            },
        },
        brand: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            //padding: 10,
            fontSize: 16,
            margin: 0,
            fontWeight: 500,
            textDecoration: 'none',
            color: theme.palette.text.primary,
            '& img': {
                marginRight: 0,
                marginTop:7,
                // width: 30,
                width: 70,
            },
        },
        avatar: {
            marginLeft: theme.spacing(2)
        },
        brandWrap: {
            display: 'flex',
            paddingLeft: theme.spacing(1.5),
            '& svg': {
                fill:
                    theme.palette.mode === 'dark'
                        ? alpha(theme.palette.primary.light, 0.64)
                        : alpha(theme.palette.primary.dark, 0.64),
            },
        },
        menuButton: {},
        dense: {
            [theme.breakpoints.up('lg')]: {
                paddingLeft: theme.spacing(1),
            },
            '& $brand': {
                [theme.breakpoints.up('lg')]: {
                    marginLeft: theme.spacing(4),
                    marginRight: theme.spacing(1),
                },
            },
        },
        brandBar: {
            transition: theme.transitions.create(['width', 'margin', 'background'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            '&:after': {
                transition: theme.transitions.create(['box-shadow'], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                }),
            },
        },
        headerProperties: {
            overflow: 'hidden',
            position: 'relative',
            display: 'flex',
            flex: 1,
            alignItems: 'left',
            zIndex: 1,
            marginLeft: -1,
        },
        wrapper: {
            fontFamily: theme.typography.fontFamily,
            position: 'relative',
            marginLeft: theme.spacing(1),
            borderRadius: theme.spacing(1),
            display: 'inline-block',
            background: alpha(theme.palette.text.primary, 0.05),
            '& $miniInput': {
                width: 70,
            },
        },
        /*
        searchWrapper: {
            [theme.breakpoints.down('md')]: {
                flex: 1,
                textAlign: 'right',
            },
        },
        search: {
            width: theme.spacing(9),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '& svg': {
                fill: theme.palette.grey[400],
            },
            [theme.breakpoints.down('xs')]: {
                display: 'none',
            },
        },
        separatorV: {
            margin: '0 10px',
        },
        */
    }
})

export default function Header(props:any) {
    const classes = useStyles();
    const { theme, drawerPaper, setDrawerPaper, setMobile, locale, setLocale } = props;
    const margin = true;
    const {pageTitle, menuOpen} = useSelector((state:any) => state.page);
    const [fullScreen, setFullScreen] = useState(false);
    const [turnDarker, setTurnDarker] = useState(false);
    const [showTitle, setShowTitle] = useState(false);
    // Initial header style
    let flagDarker = false;
    let flagTitle = false;

    const openMainMenu = () => {
        setDrawerPaper(!drawerPaper)
    }

    const openDrawerMenu = () => {
        setDrawerPaper(true)
        setMobile(true)
    }

    const handleScroll = () => {
        const doc = document.documentElement;
        const scroll = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
        const newFlagDarker = scroll > 30;
        const newFlagTitle = scroll > 40;
        if (flagDarker !== newFlagDarker) {
            setTurnDarker(newFlagDarker);
            flagDarker = newFlagDarker;
        }
        if (flagTitle !== newFlagTitle) {
            setShowTitle(newFlagTitle);
            flagTitle = newFlagTitle;
        }
    };

    const openFullScreen = () => {
        setFullScreen(true);
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) {
            /* Firefox */
            elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) {
            /* Chrome, Safari & Opera */
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
            /* IE/Edge */
            elem.msRequestFullscreen();
        }
    };

    const closeFullScreen = () => {
        setFullScreen(false);
        if ((document as any).exitFullscreen) {
            (document as any).exitFullscreen();
        } else if ((document as any).mozCancelFullScreen) {
            (document as any).mozCancelFullScreen();
        } else if ((document as any).webkitExitFullscreen) {
            (document as any).webkitExitFullscreen();
        } else if ((document as any).msExitFullscreen) {
            (document as any).msExitFullscreen();
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div>
            <AppBar
                className={classNames(classes.appBar, classes.floatingBar,
                    margin && classes.appBarShift,
                    turnDarker && classes.darker)}
                sx={{ background: '#ffffff', boxShadow: showTitle ? `${theme.shadows[3]}` : 0 }}
            >
                <Toolbar>
                    <div className={classNames(classes.brandWrap, classes.dense)}>
                        <span>
                            <IconButton
                                edge="start"
                                aria-label="menu"
                                sx={{ display: { xs: 'none', sm: 'inline-block' } }}
                                className={classes.menuButton}
                                onClick={() => openMainMenu()}
                            >
                                <MenuIcon />
                            </IconButton>

                            {/* ON MOBILE */}
                            <IconButton
                                edge="start"
                                aria-label="menu"
                                sx={{ ml: 0, mr: 2, display: { xs: 'inline-block', sm: 'none' } }}
                                className={classes.menuButton}
                                onClick={() => openDrawerMenu()}
                            >
                                <MenuIcon />
                            </IconButton>
                        </span>
                        <Box sx={{ display: { xs: 'none', sm: 'inline-block' } }}>
                            <NavLink to="/app/dashboard" className={classNames(classes.brand, classes.brandBar)}>
                                <img src={IMGS.logo} alt='Logo IMS' width="60" />
                            </NavLink>
                        </Box>
                    </div>

                    <div className={classes.headerProperties}>
                        <Box sx={{ display: { xs: 'none', sm: 'inline-block' } }}>
                            <div className={classNames(classes.headerAction, showTitle && classes.fadeOut)}>
                                {fullScreen ? (
                                    <Tooltip title='Exit full screen' placement="bottom">
                                        <IconButton className={classes.button} onClick={closeFullScreen}>
                                            <FullscreenExitOutlined />
                                        </IconButton>
                                    </Tooltip>
                                ) : (
                                    <Tooltip title='Full screen' placement="bottom">
                                        <IconButton className={classes.button} onClick={openFullScreen}>
                                            <FullscreenOutlined />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </div>
                            <Typography
                                component="h2"
                                className={classNames(classes.headerTitle, showTitle && classes.show)}
                                sx={{fontSize:22}}
                            >
                                {pageTitle}
                            </Typography>
                        </Box>
                    </div>
                    <UserMenu
                        locale={locale}
                        setLocale={setLocale}
                    />
                </Toolbar>
            </AppBar>
        </div>
    )
}
