import React, {useContext, useEffect, useMemo} from "react";
import {useForm} from "react-hook-form";
import _ from "lodash";
import {yupResolver} from "@hookform/resolvers/yup";
import {
    LivreCategorie,
    LIVRECATEGORIE_CONSTANT,
    livreCategorieDataImpl,
    livreCategorieSchema
} from "../../../constants/type/LivreCategorie";
import messages from "../../messages/general/messages";
import DialogForm from "../../../components/ui/form/DialogFormKiller";
import Box from "@mui/material/Box";
import {FormattedMessage} from "react-intl";
import InputField from "../../../components/ui/form/InputField";
import categoreLivreStateUpdate from "./businesslogic/CategoreLivreStateUpdate";
import categoreLivreBusinessLogic from "./businesslogic/CategoreLivreBusinessLogic";
import {CategorieLivreContext} from "./CategoreLivrePage";

const CategoreLivreForm = (props:any) => {
    const {classes, locale} = props;
    const logicRacine: any = useContext(CategorieLivreContext);
    const styleClasses = logicRacine.styleClasses ;

    const formRef = React.useRef<any>(useForm({
        defaultValues: _.cloneDeep(livreCategorieDataImpl),
        // @ts-ignore
        resolver: yupResolver(livreCategorieSchema),
    }));
    const {errors} = formRef.current.formState;

    const onSubmit = async (data: FormData) => {
        categoreLivreStateUpdate.savingStateBtn(logicRacine, true);
        // @ts-ignore
        const dataTosend: LivreCategorie = _.cloneDeep(data);

        // @ts-ignore
        const toSend = LIVRECATEGORIE_CONSTANT.convertToBackEnd(dataTosend);
        categoreLivreBusinessLogic.saveData(
            logicRacine,
            toSend).then((response:any) => {
            categoreLivreStateUpdate.openDialog(logicRacine, false);
            categoreLivreStateUpdate.savingStateBtn(logicRacine, false);
        }).catch((e:any) => categoreLivreStateUpdate.savingStateBtn(logicRacine, false));
    };

    useEffect(() => {
        if( logicRacine.openDialog === false){
            const newData = _.cloneDeep(livreCategorieDataImpl);
            formRef.current.reset(newData);
            return ;
        }

        formRef.current.reset(logicRacine.livreCategorieSel);
        return () => {};
    }, [logicRacine.openDialog]);

    return (
        <DialogForm
            theme={logicRacine.theme}
            savingBtn={true}
            savingStateBtn={logicRacine.savingStateBtn}
            title={<FormattedMessage id={messages.selection_edition} />}
            open={logicRacine.openDialog}
            setOpen={logicRacine.setOpenDialog}
            btnCloseLabel={messages.dialog_close}
            onSubmit={formRef.current.handleSubmit(onSubmit)}>
            <Box  sx={{ width: '100%' }}>
                <div className={styleClasses.inputField}>
                    <InputField
                        label={<FormattedMessage id={messages.header_nom} />}
                        size="small"
                        color={"secondary"}
                        width={'100% !important'}
                        inputProps={
                            {
                                ...formRef.current.register("nom", {
                                    required: "Required field"
                                })
                            }
                        }
                    />
                    <div className={styleClasses.errorHelper}>
                        {errors.nom && <span><FormattedMessage id={messages.required_field_child}/></span>}
                    </div>
                </div>
            </Box>
        </DialogForm>
    );
}

export default CategoreLivreForm;