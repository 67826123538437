import React, {useContext} from "react";
import {AppointmentPresetContext} from "../../appointment/Appointment";
import {useForm} from "react-hook-form";
import _ from "lodash";
import {yupResolver} from "@hookform/resolvers/yup";
import {
    ChildStuff,
    CHILDSTUFF_CONSTANT,
    childStuffDataImpl,
    childStuffSchema
} from "../../../constants/type/ChildStuff";
import messages from "../../messages/general/messages";
import DialogForm from "../../../components/ui/form/DialogFormKiller";
import Box from "@mui/material/Box";
import {FormattedMessage} from "react-intl";
import InputField from "../../../components/ui/form/InputField";
import {TextField} from "@mui/material";
import rdvStateUpdate from "../../appointment/businesslogic/RdvStateUpdate";
import childStuffStateUpdate from "./businesslogic/ChildStuffStateUpdate";
import childStuffBusinessLogic from "./businesslogic/ChildStuffBusinessLogic";
import {isPositiveValue} from "../../../helpers/helpers";

const ChildStuffForm = (props:any) => {
    const {classes, locale} = props;
    const logicRacine: any = useContext(AppointmentPresetContext);
    const styleClasses = logicRacine.classes ;

    const formRef = React.useRef<any>(useForm({
        defaultValues: _.cloneDeep(childStuffDataImpl),
        // @ts-ignore
        resolver: yupResolver(childStuffSchema),
    }));
    const {errors} = formRef.current.formState;

    const onSubmit = async (data: FormData) => {
        childStuffStateUpdate.savingStateBtn(logicRacine, true);
        // @ts-ignore
        const dataTosend: ChildStuff = _.cloneDeep(data);

        // @ts-ignore
        const toSend = CHILDSTUFF_CONSTANT.convertToBackEnd(logicRacine, dataTosend);
        childStuffBusinessLogic.saveData(
            logicRacine,
            toSend).then((response:any) => {
            childStuffStateUpdate.open(logicRacine, false);
            childStuffStateUpdate.savingStateBtn(logicRacine, false);
        }).catch((e:any) => childStuffStateUpdate.savingStateBtn(logicRacine, false));
    };

    React.useEffect(() => {
        if( logicRacine.openDialog === false){
            rdvStateUpdate.showContentNorm(logicRacine, false);
            const newData = _.cloneDeep(childStuffDataImpl);
            formRef.current.reset(newData);
            return ;
        }

        formRef.current.reset(logicRacine.childEdit);
        return () => {};
    }, [logicRacine.openDialog]);

    return (
        <DialogForm
            theme={logicRacine.theme}
            width={true}
            savingBtn={true}
            savingStateBtn={logicRacine.savingStateBtn}
            title={logicRacine.childEdit.nom}
            open={logicRacine.openDialog}
            setOpen={logicRacine.setOpenDialog}
            btnCloseLabel={messages.dialog_close}
            onSubmit={formRef.current.handleSubmit(onSubmit)}>
            <Box  sx={{ width: '100%' }}>
                {
                    isPositiveValue(logicRacine.childEdit.id) &&
                    <div className={styleClasses.inputField} style={{textAlign:'center', color:'black'}}>
                        <FormattedMessage id={messages[logicRacine.childEdit.nom]} />
                    </div>
                }
                <div className={styleClasses.inputField}>
                    <InputField
                        label={<FormattedMessage id={messages.header_nom} />}
                        size="small"
                        color={"secondary"}
                        width={'100% !important'}
                        inputProps={
                            {
                                ...formRef.current.register("nom", {
                                    required: "Required field"
                                })
                            }
                        }
                    />
                    <div className={styleClasses.errorHelper}>
                        {errors.nom && <span><FormattedMessage id={messages.required_field_child}/></span>}
                    </div>
                </div>
                <div className={styleClasses.inputField}>
                    <TextField
                        label={<FormattedMessage id={messages.header_quantite} />}
                        type="number"
                        color={"secondary"}
                        inputProps={{
                            min: 0,
                            step: 1,
                            ...formRef.current.register("quantite")
                        }}
                    />
                    <div className={styleClasses.errorHelper}>
                        {errors.quantite && <span><FormattedMessage id={messages.required_field_child}/></span>}
                    </div>
                </div>
            </Box>
        </DialogForm>
    );
}

export default ChildStuffForm;