import React, {useContext, useMemo} from "react";
import {useIntl} from "react-intl";
import {
    MaterialReactTable,
    MRT_ColumnDef,
    type MRT_Localization,
    MRT_Row,
    useMaterialReactTable
} from "material-react-table";
import _ from "lodash";
import {MRT_Localization_EN} from 'material-react-table/locales/en/index.esm.js';
import {MRT_Localization_FR} from 'material-react-table/locales/fr/index.esm.js';
import {ScolariteToutFraisContext} from "./ScolariteFraisGroup";
import scolariteFraisGroupStateUpdate from "./businesslogic/ScolariteFraisGroupStateUpdate";
import {
    columnsScolariteGroup,
    exportExcelScolariteGroup,
    ScolariteGroup,
    scolariteGroupDataImpl
} from "../../../constants/type/ScolariteGroup";
import {
    MyRenderRowActions,
    MyRenderTopToolbarActions
} from "../../../components/ui/materialReactTable/MyDefaultMaterialReactTableOptions";


function ScolariteFraisGroupList() {
    const logic: any = useContext(ScolariteToutFraisContext);
    const styleClasses = logic.styleClasses;
    const intl = useIntl() ;

    const export_xls = logic.activeLang === 'en' ? 'Export to excel' : 'Exporter en excel';
   // const export_pdf = logic.activeLang === 'en' ? 'Export to pdf' : 'Exporter en pdf';

    const addItem = () => {
        scolariteFraisGroupStateUpdate.dataSel(logic, _.cloneDeep(scolariteGroupDataImpl));
        scolariteFraisGroupStateUpdate.openEditDialogForm(logic, true);
    }
    const editItem = (row:MRT_Row<ScolariteGroup>) => {
        const data:ScolariteGroup = logic.dataList[row.index];
        if(_.isNull(data) || _.isUndefined(data)){
            return ;
        }
        scolariteFraisGroupStateUpdate.dataSel(logic, _.cloneDeep(data));
        scolariteFraisGroupStateUpdate.openEditDialogForm(logic, true);
    }
    const handleRowsDelete = (rows:any) => {
        const dataSelected:ScolariteGroup[] = [];
        for(const row of rows){
            const data:ScolariteGroup = logic.dataList[row.index];
            dataSelected.push(data);
        }

        scolariteFraisGroupStateUpdate.dataListSelected(logic, dataSelected);
        scolariteFraisGroupStateUpdate.openDeleteDialogForm(logic, true);
    }

    const columns = useMemo<MRT_ColumnDef<ScolariteGroup>[]>(
        // @ts-ignore
        () => [...columnsScolariteGroup({intl: intl})],
        [logic.activeLang],
    );

    const localization = useMemo<MRT_Localization>(() => logic.activeLang === 'en' ? MRT_Localization_EN : MRT_Localization_FR, [logic.activeLang]) ;
    logic.tableConfigRef.current = useMaterialReactTable({
        ...logic.defaultOptions,
        localization,
        columns,
        data: logic.dataList,
        state:{
            showProgressBars: logic.tableDataLoading,
            showSkeletons: logic.tableDataLoading,
        },
        renderTopToolbarCustomActions: ({ table }: {table:any}) => (
            <MyRenderTopToolbarActions
                table={table}
                addNewDataTable={addItem}
                handleRowsDelete={() => handleRowsDelete(table.getSelectedRowModel().rows)}
                deleteDisabled={_.isEmpty(table.getSelectedRowModel().rows)}
                export_xls_title={export_xls}
                xls_nom_fichier="Tuition_Fees_By_Class"
                excelDataMapping={exportExcelScolariteGroup({
                    rows:table.getPrePaginationRowModel().rows,
                    columns: table?.getVisibleFlatColumns(),
                    intl
                })}
                styleClasses={styleClasses}
            />
        ),
        renderRowActions: ({row, table}: {row:any, table:any}) => (
            <MyRenderRowActions
                editRow={() => editItem(row)}
                styleClasses={styleClasses}
            />
        )
    });

      return (
        <MaterialReactTable
            table={logic.tableConfigRef.current}
            key={logic.tableKeyValue}
        />
    );
}


export default ScolariteFraisGroupList ;