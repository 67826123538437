import _ from "lodash";
import {Common, commonDataImp} from "./Common";
import * as yup from "yup";
import moment from "moment";
import React from "react";
import {
    columnsScolariteGroup, exportExcelScolariteGroupPlugin,
    ScolariteGroup,
    SCOLARITEGROUP_CONSTANT, scolariteGroupColumnsVisibilityPlugin,
    scolariteGroupDataImpl
} from "./ScolariteGroup";
import {isColumnVisible, isPositiveValue} from "../../helpers/helpers";
import messages from "../../page/messages/general/messages";

export const SCOLARITEECHEANCIER_CONSTANT = {
    convertToFrontEnd: (logic:any, item:ScolariteEcheancier) : ScolariteEcheancier => {
        // @ts-ignore
        const itemInit = _.cloneDeep(scolariteEcheancierDataImpl);
        const data : ScolariteEcheancier = {...itemInit, ...item} ;

        data.ladateForm          = moment(data.ladate);
        // @ts-ignore
        data.liredateForm        = data.ladateForm.format("L");
        // @ts-ignore
        data.rappeldateForm      = isPositiveValue(data.rappeldate) ? moment(data.rappeldate) : null;

        // @ts-ignore
        data.scolariteGroup         = SCOLARITEGROUP_CONSTANT.convertToFrontEnd(logic, data.scolariteGroup);
        data.scolariteGroupForm     = data.scolariteGroup;
        // @ts-ignore
        data.obligatoire            = item.scolariteGroup?.obligatoire ;
        return data ;
    },
    convertToBackEnd: (logic:any, item:ScolariteEcheancier) => {
        item.ladate          = item.ladateForm?.valueOf();
        // @ts-ignore
        item.rappeldate      = _.isObject(item.rappeldateForm) ? item.rappeldateForm?.valueOf() : null;

        return item ;
    },
};

export const exportExcelScolariteEcheancier = (
    {rows=[], columns=[], intl={}}:{ rows:any[]; intl:any;  columns: any[];}
)  => {
    return _.map(rows, (row:any) => {
        let obj:any = {};
        const data : ScolariteEcheancier = row.original;

        obj = exportExcelScolariteGroupPlugin({obj, data:data?.scolariteGroupForm ?? _.cloneDeep(scolariteGroupDataImpl), intl, columns, infoSpace:intl.formatMessage({id:messages.fees})});

        if(isColumnVisible(columns, 'nom')){
            obj[`${intl.formatMessage({id:messages.table_h_echeancier_nom})}`] = data.nom;
        }
        if(isColumnVisible(columns, 'code')){
            obj[intl.formatMessage({id:messages.header_code})] = data.code;
        }
        if(isColumnVisible(columns, 'montant')){
            obj[`${intl.formatMessage({id:messages.table_h_montant})}`] = data.montant;
        }
        if(isColumnVisible(columns, 'liredateForm')){
            obj[`${intl.formatMessage({id:messages.table_h_echeancier_date})}`] = data.liredateForm;
        }
        return obj;
    });
}

export const exportExcelScolariteEcheancierPlugin = ({obj={}, data=_.cloneDeep(scolariteEcheancierDataImpl), intl={}, infoSpace=``, columns=[], prefixkey=``}
                                                              :{obj:any; data:ScolariteEcheancier; intl:any; infoSpace:string; columns:any[];  prefixkey:string; })  => {
    const prefix = prefixkey ?? '';

    obj = exportExcelScolariteGroupPlugin({obj, data:data?.scolariteGroupForm ?? _.cloneDeep(scolariteGroupDataImpl), intl, columns, infoSpace:intl.formatMessage({id:messages.fees})});

    if(isColumnVisible(columns, `${prefix}nom`)){
        obj[`${infoSpace} ${intl.formatMessage({id:messages.table_h_nom})}`] = data.nom;
    }
    if(isColumnVisible(columns, `${prefix}code`)){
        obj[`${infoSpace} ${intl.formatMessage({id:messages.header_code})}`] = data.code;
    }
    if(isColumnVisible(columns, `${prefix}montant`)){
        obj[`${infoSpace} ${intl.formatMessage({id:messages.table_h_montant})}`] = data.montant;
    }
    if(isColumnVisible(columns, `${prefix}liredateForm`)){
        obj[`${infoSpace} ${intl.formatMessage({id:messages.table_h_echeancier_date})}`] = data.liredateForm;
    }
    return obj;
}

export const echeancierColumnsVisibility = {
    columnVisibility : {
        ...scolariteGroupColumnsVisibilityPlugin.columnVisibility
    }
} ;

export const echeancierColumnsVisibilityPlugin = {
    columnVisibility : {
        ...echeancierColumnsVisibility.columnVisibility,
    }
} ;

export const columnsEcheanciers = (props:any) => {
    const {intl, nomSpace} = props ;
    const infoSpace = nomSpace ?? ``;
    return [
        ...columnsScolariteGroup({intl: intl, nomSpace: intl.formatMessage({id:messages.class_fees})}
        ),
        {
            id: 'echeancierForm_nom',
            accessorKey: 'nom',
            header: `${infoSpace} ${intl.formatMessage({id:messages.table_h_echeancier_nom})}`,
        },
        {
            id: 'echeancierForm_code',
            accessorKey: 'code',
            header: `${infoSpace} ${intl.formatMessage({id:messages.header_code})}`,
        },
        {
            id: 'echeancierForm_montant',
            accessorKey: 'montant',
            header: `${infoSpace} ${intl.formatMessage({id:messages.table_h_montant})}`,
            muiTableBodyCellProps: {
                align: 'right', //change head cell props
            },
            // @ts-ignore
            Cell: ({cell}) => <b>{new Intl.NumberFormat("fr-FR").format(cell.getValue<number>())}</b>,
        },
        {
            id: 'echeancierForm_liredateForm',
            accessorKey: 'liredateForm',
            header: `${infoSpace} ${intl.formatMessage({id:messages.table_h_echeancier_date})}`,
        },
    ];
} ;


export  const scolariteEcheancierDataImpl: ScolariteEcheancier = {
    id: null,
    ladate: moment().startOf('day').valueOf(),
    nom: ``,
    code: ``,
    scolariteTypeTag: null,
    scolariteGroup: _.cloneDeep(scolariteGroupDataImpl),
    scolariteGroupForm: _.cloneDeep(scolariteGroupDataImpl),
    IdsToDelete: [],
    montant: 0,
    rappeldate: null,
    ladateForm: moment(),
    liredateForm: '',
    rappeldateForm: null,
    obligatoire: false,
    common: _.cloneDeep(commonDataImp),
}

export const scolariteEcheancierSchema = yup.object().shape({
    id: yup.number().nullable().notRequired(),
    nom: yup.string().required(),
    code: yup.string().nullable().notRequired(),
    ladate: yup.number().nullable().notRequired(),
    scolariteTypeTag: yup.string().notRequired(),
    IdsToDelete: yup.array().of(yup.number()).nonNullable().default([]).notRequired(),
    scolariteGroup: yup.mixed<ScolariteGroup>().notRequired(),
    scolariteGroupForm: yup.mixed<ScolariteGroup>().notRequired(),
    montant: yup.number().default(0).min(1).required(),
    rappeldate: yup.number().nullable().notRequired(),
    rappeldateForm: yup.mixed<Object>().nullable().notRequired(),
    ladateForm: yup.mixed<any>().nullable().required(),
    liredateForm: yup.string().nonNullable().notRequired(),
    obligatoire: yup.boolean().default(false).notRequired(),
    common: yup.mixed<Common>(),
});

export type ScolariteEcheancier = yup.InferType<typeof scolariteEcheancierSchema>;

