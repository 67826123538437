import moment from "moment";
import {SOCKET_URL} from "../../../../config/AppUrl";
import _ from "lodash";
import {Client} from "@stomp/stompjs";
import spaceListBusinessLogic from "./SpaceListBusinessLogic";
import {FilDiscussion} from "../../../../constants/type/FilDiscussion";
import {Message} from "../../../../constants/type/Message";
import {UserChatModel} from "../../../../constants/type/UserChatModel";
import {RoleUser} from "../../../../constants/type/RoleUser";
import {DiscussionEtat} from "../../../../constants/type/DiscussionEtat";
import {WEBSOCKET_RECONNECTDELAY} from "../../../../helpers/helpers";

const webSocketBusinessLogic: any = {
    runWebSocket: (logicRef:any) => {
        const userChat      = logicRef.userChatRef.current;
        const user          = logicRef.userRef.current;
        const personDetails = user.userDetails.personDetails ;
        const person        = personDetails.person ;
        const fonctionId    = personDetails.fonctions[0]?.fonctionId ?? 0 ;

        userChat.userId      = user.id;
        userChat.uuid        = user.uuid;
        userChat.role        = user.role;
        userChat.fonction    = fonctionId > 0 ? logicRef.jobsRef.current.get(fonctionId)?.nom ?? `` : `` ;
        userChat.nom         = `${personDetails.person.nom} ${personDetails.person.prenom}`;
        userChat.photo       = person.photo ;
        userChat.connexionDate = moment().valueOf() ;

        const classeId = logicRef.userRef.current.userDetails.personDetails.teacherClasses[0]?.classeId ?? 0;
        const classeRoomSelected =  _.find(logicRef.classeRoomRef.current, {id:classeId}) ;
        if(_.isObject(classeRoomSelected)){
            logicRef.hideClasseRoomSelectRef.current = true ;
            logicRef.setClasseRoomSelected(classeRoomSelected);
        }
        if(userChat.role === RoleUser.TEACHER){
            logicRef.hideClasseRoomSelectRef.current = true ;
        }

        const websocketClientRef = logicRef.websocketClientRef;
        websocketClientRef.current = new Client({
            brokerURL: SOCKET_URL,
            connectHeaders: {id: user.uuid},
            reconnectDelay: WEBSOCKET_RECONNECTDELAY,
            heartbeatIncoming: 4000,
            heartbeatOutgoing: 4000,
            onConnect: () => webSocketBusinessLogic.onConnected(logicRef),
            onDisconnect: (err:any) => webSocketBusinessLogic.onDisconnected(logicRef),
            onStompError: (err:any) => {
                console.log('Broker reported error: ' + err.headers['message']);
                console.log('Additional details: ' + err.body);
                console.log('onStompError error: ' + err);
            },
            onWebSocketError: (err:any) => {
                console.log('onWebSocketError error: ' + err);
            },
        });

        websocketClientRef.current.activate();
    },
    onConnected: (logicRef:any) => {
        console.log("Connected!!");
        webSocketBusinessLogic.listenNewInformations(logicRef);
        webSocketBusinessLogic.notifyImConnected(logicRef);
        spaceListBusinessLogic.getUserSpaces(logicRef).then((currentSpace:FilDiscussion) => {
            if(currentSpace.filEtat === DiscussionEtat.OPEN){
                spaceListBusinessLogic.handleExpanCollapseOpenedSpace(logicRef);
            }else {
                spaceListBusinessLogic.handleExpanCollapseClosedSpace(logicRef);
            }
        });
    },
    onDisconnected: (logicRef:any) => {

        const websocketSubscriptMapRef     = logicRef.websocketSubscriptMapRef;
        if(_.isObject(websocketSubscriptMapRef.current)){
            websocketSubscriptMapRef.current.values().forEach((subscription:any) => subscription.unsubscribe());
            websocketSubscriptMapRef.current = new Map<string,any>();
        }

        /*const websocketMessageSubscriptRef     = logicRef.websocketMessageSubscriptRef;
        if(_.isObject(websocketMessageSubscriptRef.current)){
            // @ts-ignore
            messageSubscriptionRef.current.unsubscribe();
        }*/

        const websocketClientRef = logicRef.websocketClientRef;
        websocketClientRef.current.deactivate();

        logicRef.setIsWebSocketLoaded(false);
    },
    setUsersConnected: (logicRef:any, userschatmodel: UserChatModel[]) => {
        if(_.isUndefined(userschatmodel) || _.isNull(userschatmodel)){
            return ;
        }
        const usermap = new Map<string,UserChatModel>();
        if(_.isObject(userschatmodel) && _.isEmpty(userschatmodel) === false){
            userschatmodel.forEach(item => usermap.set(item.uuid, item));
        }
        logicRef.usersConnectedMapRef.current = usermap ;
        if(_.isEmpty(logicRef.spaceList) === false){
            const spacelist = _.cloneDeep(logicRef.spaceList);
            const orderSpaces = _.reverse(_.sortBy(spacelist, ['theDate']));
            logicRef.setSpaceList(orderSpaces);
        }

    },
    listenNewInformations: (logicRef:any) => {
        const websocketClientRef        = logicRef.websocketClientRef;
        const websocketSubscriptMapRef  = logicRef.websocketSubscriptMapRef;
        const userUUID                  = logicRef.userUUIDRef.current;

        const subUsersConnected = websocketClientRef.current?.subscribe('/chat/topic/usersConnected', function (payload:any) {
            const userchatmodels: UserChatModel[] =  JSON.parse(payload.body);
            logicRef.setWSUsersConnected(userchatmodels);
            logicRef.setSendingMsg(false);
        }, {'content-type': 'application/json'});
        websocketSubscriptMapRef.current.set('usersConnected', subUsersConnected);

        const subNewSpace = websocketClientRef.current?.subscribe(`/chat/utilisateur/${userUUID}/newSpace`, function (payload:any) {
            const newSpaces:FilDiscussion[] = JSON.parse(payload.body);
            logicRef.setWsNewSpace(newSpaces);
            logicRef.setSendingMsg(false);
        }, {'content-type': 'application/json'});
        websocketSubscriptMapRef.current.set('subNewSpace', subNewSpace);

        const subUpSpace = websocketClientRef.current?.subscribe(`/chat/utilisateur/${userUUID}/updatedSpaces`, function (payload:any) {
            const upSpaces:FilDiscussion[] = JSON.parse(payload.body);
            logicRef.setWsUpdatedSpaces(upSpaces);
            logicRef.setSendingMsg(false);
        }, {'content-type': 'application/json'});
        websocketSubscriptMapRef.current.set('subUpSpace', subUpSpace);

        const subMessage = websocketClientRef.current?.subscribe(`/chat/utilisateur/${userUUID}/message`, function (payload:any) {
            const newMessage:Message = JSON.parse(payload.body);
            logicRef.setNewWsMessage(newMessage);
            logicRef.setSendingMsg(false);
        }, {'content-type': 'application/json'});
        websocketSubscriptMapRef.current.set('subMessage', subMessage);
       /* const subUsersSpaces = websocketClientRef.current?.subscribe(`/chat/utilisateur/${userUUIDRef.current}/spaces`, function (payload:any) {
            const spaceList:FilDiscussion[] = JSON.parse(payload.body);
            spaceRoomBusinessLogic.updateConversationList(logicRef, spaceList);
            if(_.isEmpty(spaceList) === false){
                sondageBusinessLogic.handleConversationClick(logicRef, spaceList[0]);
            }
        }, {'content-type': 'application/json'});
        websocketSubscriptRef.current.push(subUsersSpaces);*/
    },
    /*websocketClientRef.current?.publish({destination: '/app/chat/spaces', body: JSON.stringify(userChatRef.current),
        headers: {'content-type': 'application/json'}});*/
    notifyImConnected: (logicRef:any) => {
        const websocketClientRef  = logicRef.websocketClientRef;
        const userChatRef         = logicRef.userChatRef;

        websocketClientRef.current?.publish({destination: '/app/chat/newConnected', body: JSON.stringify(userChatRef.current),
            headers: {'content-type': 'application/json'}});
    },
    sendMessage: (logicRef:any, message:Message) => {
        logicRef.setSendingMsg(true);
        logicRef.websocketClientRef.current?.publish({destination: '/app/chat/sendMessage', body: JSON.stringify(message),
            headers: {'content-type': 'application/json'}});
    },
    /*
    clearWebSocketMessageSubscribe: (logicRef:any) =>{
        if(_.isObject(logicRef.websocketMessageSubscriptRef.current)){
            // @ts-ignore
            logicRef.websocketMessageSubscriptRef.current.unsubscribe();
        }
    },*//*
    subscribeToSpaceMessage: (logicRef:any, userUUID:string, spaceUUID:string) =>{
        webSocketBusinessLogic.clearWebSocketMessageSubscribe(logicRef);
        const websocketClientRef = logicRef.websocketClientRef;
        logicRef.websocketMessageSubscriptRef.current = websocketClientRef.current?.subscribe(`/chat/utilisateur/${userUUID}/space/${spaceUUID}/message`, function (payload:any) {
            spaceRoomBusinessLogic.updateSpaceNewMessage(logicRef, JSON.parse(payload.body));
        }, {'content-type': 'application/json'});
    },*/

};

export default webSocketBusinessLogic;
