import React, {useContext, useMemo} from "react";
import {useIntl} from "react-intl";
import _ from "lodash";
import {MRT_Localization_EN} from 'material-react-table/locales/en/index.esm.js';
import {MRT_Localization_FR} from 'material-react-table/locales/fr/index.esm.js';
import {GroupContext} from "./GroupPage";
import groupStateUpdate from "./businesslogic/GroupStateUpdate";
import {
    columnsGroup,
    exportExcelGroup,
    Groupesection,
    groupesectionDataImpl
} from "../../../constants/type/Groupesection";
import {
    MaterialReactTable,
    MRT_ColumnDef,
    MRT_Localization,
    MRT_Row,
    useMaterialReactTable
} from "material-react-table";
import {
    MyRenderRowActions,
    MyRenderTopToolbarActions
} from "../../../components/ui/materialReactTable/MyDefaultMaterialReactTableOptions";


function GroupList() {
    const logic: any = useContext(GroupContext);
    const styleClasses = logic.styleClasses;
    const intl = useIntl() ;

    const export_xls = logic.activeLang === 'en' ? 'Export to excel' : 'Exporter en excel';
    //const export_pdf = logic.activeLang === 'en' ? 'Export to pdf' : 'Exporter en pdf';


    const addItem = () => {
        groupStateUpdate.dataSel(logic, _.cloneDeep(groupesectionDataImpl));
        groupStateUpdate.openEditDialogForm(logic, true);
    }
    const editItem = (row:MRT_Row<Groupesection>) => {
        const data:Groupesection = logic.dataList[row.index];
        if(_.isNull(data) || _.isUndefined(data)){
            return ;
        }
        groupStateUpdate.dataSel(logic, _.cloneDeep(data));
        groupStateUpdate.openEditDialogForm(logic, true);
    }

    const handleRowsDelete = (rows:any) => {
         const dataSelected:Groupesection[] = [];
        rows.map((row:any) => {
            const data:Groupesection = logic.dataList[row.index];
            dataSelected.push(data);
        });
        groupStateUpdate.dataListSelected(logic, dataSelected);
        groupStateUpdate.openDeleteDialogForm(logic, true);
    }


    const columns = useMemo<MRT_ColumnDef<Groupesection>[]>(
        // @ts-ignore
        () => [...columnsGroup({intl:intl})],
        [logic.activeLang],
    );

    const localization = useMemo<MRT_Localization>(() => logic.activeLang === 'en' ? MRT_Localization_EN : MRT_Localization_FR, [logic.activeLang]) ;
    logic.tableConfigRef.current = useMaterialReactTable({
        ...logic.defaultOptions,
        localization,
        columns,
        data: logic.dataList,
        state:{
            showProgressBars: logic.tableDataLoading,
            showSkeletons: logic.tableDataLoading,
        },
        renderTopToolbarCustomActions: ({ table }: {table:any}) => (
            <MyRenderTopToolbarActions
                table={table}
                addNewDataTable={addItem}
                handleRowsDelete={() => handleRowsDelete(table.getSelectedRowModel().rows)}
                deleteDisabled={_.isEmpty(table.getSelectedRowModel().rows)}
                export_xls_title={export_xls}
                xls_nom_fichier="Group_list"
                excelDataMapping={exportExcelGroup({
                        rows: table.getPrePaginationRowModel().rows,
                        columns: table?.getVisibleFlatColumns(),
                        intl
                    })
                }
                styleClasses={styleClasses}
            />
        ),
        renderRowActions: ({row, table}: {row:any, table:any}) => (
            <MyRenderRowActions
                editRow={() => editItem(row)}
                styleClasses={styleClasses}
            />
        )
    });

    return (
        <MaterialReactTable
            table={logic.tableConfigRef.current}
            key={logic.tableKeyValue}
        />
    );
}
export default GroupList ;