import React, {useContext, useRef} from "react";
import {useForm} from "react-hook-form";
import _ from "lodash";
import {yupResolver} from "@hookform/resolvers/yup";

import {Box} from "@mui/material";
import {FormattedMessage} from "react-intl";
import {BibliothequeContext} from "./Bibliotheque";
import {Livre, livreDataImpl, livreSchema} from "../../../constants/type/Livre";
import livreBusinessLogic from "./businesslogic/LivreBusinessLogic";
import messages from "../../messages/general/messages";
import DialogFormKiller from "../../../components/ui/form/DialogFormKiller";
import livreStateUpdate from "./businesslogic/LivreStateUpdate";

const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 1;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const LivreDeleteForm = (props:any) => {
    const logic: any = useContext(BibliothequeContext);
    const styleClasses = logic.styleClasses ;
    const livresnomsRef = useRef<string>(``);
    const formRef = useRef<any>(useForm({
        defaultValues:_.cloneDeep(logic.livreSel),
        // @ts-ignore
        resolver: yupResolver(livreSchema),
    }));
    const {errors} = formRef.current.formState;

    const onSubmit = async () => {
        livreStateUpdate.processingLivreStateNormBtn(logic, true);

        livreBusinessLogic.deleteLivre(
            logic,
            messages,
            logic.livresSelected).then((response:any) => {
            livreStateUpdate.openLivreDeleteDialogForm(logic, false);
            livreStateUpdate.processingLivreStateNormBtn(logic, false);
        }).catch((e:any) => livreStateUpdate.processingLivreStateNormBtn(logic, false));
    };

    React.useEffect(() => {
        const noms:string[] = []
        logic.livresSelected.forEach((l:Livre) => {
            noms.push(l.nom);
        });
        livresnomsRef.current = _.join(noms, ', ');
        return () => {
            formRef.current.reset(_.cloneDeep(livreDataImpl));
        };
    }, [logic.openLivreDeleteDialogForm]);

    // @ts-ignore
    return (
        <DialogFormKiller
            theme={logic.theme}
            savingBtn={true}
            savingStateBtn={logic.processingLivreStateNormBtn}
            title={<FormattedMessage id={messages.biblio_livre_delete} />}
            open={logic.openLivreDeleteDialogForm}
            setOpen={logic.setOpenLivreDeleteDialogForm}
            btnCloseLabel={messages.dialog_close}
            onSubmit={onSubmit}>
                <Box  sx={{ width: '100%' }}>
                    <div className={styleClasses.inputField} style={{textAlign:'center', color:'black'}}>
                        <FormattedMessage id={messages.biblio_livre_todelete} /> <span style={{fontWeight:'bold'}}>{livresnomsRef.current}</span> ?
                    </div>
                </Box>
        </DialogFormKiller>
    );
}

export default LivreDeleteForm ;