import {Common, commonDataImp} from "./Common";
import _ from "lodash";
import * as yup from "yup";
import {isColumnVisible} from "../../helpers/helpers";
import messages from "../../page/messages/general/messages";
import {ScolariteType, scolariteTypeColumnsVisibilityPlugin, scolariteTypeDataImpl} from "./ScolariteType";


export const SCOLARITEANNEE_CONSTANT = {
    convertToFrontEnd: (logic:any, item:ScolariteAnnee) => {
        // @ts-ignore
        const itemInit = _.cloneDeep(scolariteAnneeDataImpl);
        return {...itemInit, ...item} ;
    },
    convertToBackEnd: (logic:any, item:ScolariteAnnee) => item,
};


export const scolariteAnneeColumnsVisibilityPlugin = {
    columnVisibility : {
        'scolariteAnneeForm_nom': false,
        'scolariteAnneeForm_code': false,
    }
} ;


export const scolariteAnneeColumns = (props:any) => {
    const {intl} = props ;

    return [
        {
            accessorKey: 'nom',
            header: intl.formatMessage({id: messages.table_h_nom}),
        },
        {
            accessorKey: 'code',
            header: intl.formatMessage({id: messages.header_code}),
        }
    ];
} ;


export const scolariteAnneeColumnsPlugin = (props:any) => {
    const {intl, nomSpace, options, callKeyValue} = props ;
    const infoSpace = nomSpace ?? ``;

    return [
        {
            id: 'scolariteAnneeForm_nom',
            accessorFn: (row:any) => callKeyValue(row)?.nom,
            header: `${infoSpace} ${intl.formatMessage({id: messages.table_h_nom})}`,
            filterVariant: 'multi-select',
            size: options?.nomSize ?? undefined,
        },
        {
            id: 'scolariteAnneeForm_code',
            accessorFn: (row:any) => callKeyValue(row)?.code,
            header: `${infoSpace} ${intl.formatMessage({id: messages.header_code})}`,
            size: options?.codeSize ?? undefined,
        }
    ];
} ;


export const exportExcelScolariteAnnee = ({columns=[], rows=[], intl={}}:{rows:any[]; intl:any; columns:any[];}) => {
    return _.map(rows, (row:any) => {
        let obj:any = {};
        const data = row.original ;

        if(isColumnVisible(columns, 'scolariteAnneeForm_nom')){
            obj[intl.formatMessage({id:messages.table_h_nom})] = data.nom;
        }
        if(isColumnVisible(columns, 'scolariteAnneeForm_code')){
            obj[intl.formatMessage({id:messages.header_code})] = data.code;
        }
        return obj;
    });
}

export const exportExcelScolariteAnPlugin = ({obj={}, columns=[], data=_.cloneDeep(scolariteAnneeDataImpl), infoSpace=``, intl={}}
                                                   :{obj:any; data:ScolariteAnnee; intl:any; infoSpace:string; columns:any[]; }) => {

    if(isColumnVisible(columns, 'scolariteAnneeForm_nom')){
        obj[`${infoSpace} ${intl.formatMessage({id:messages.table_h_nom})}`] = data.nom;
    }
    if(isColumnVisible(columns, 'scolariteAnneeForm_code')){
        obj[`${infoSpace} ${intl.formatMessage({id:messages.header_code})}`] = data.code;
    }
    return obj;
}

export  const scolariteAnneeDataImpl: ScolariteAnnee = {
    id: null,
    nom: '',
    dateDebut: 0,
    dateFin: 0,
    code: '',
    migrateNextYear: false,
    common: _.cloneDeep(commonDataImp),
}

export const scolariteAnneeSchema = yup.object().shape({
    id: yup.number().nullable(),
    nom: yup.string().nullable(),
    dateDebut: yup.number().nullable(),
    dateFin: yup.number().nullable(),
    code: yup.string().nullable(),
    migrateNextYear: yup.boolean().nullable(),
    common: yup.mixed<Common>(),
});





export type ScolariteAnnee = yup.InferType<typeof scolariteAnneeSchema>;
