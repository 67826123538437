import React, {useContext} from "react";

import {Box} from "@mui/material";
import {FormattedMessage} from "react-intl";
import {EmprunterContext} from "./Emprunter";
import emprunterBusinessLogic from "./businesslogic/EmprunterBusinessLogic";
import messages from "../../messages/general/messages";
import DialogFormKiller from "../../../components/ui/form/DialogFormKiller";
import emprunterStateUpdate from "./businesslogic/EmprunterStateUpdate";

const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 1;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const EmprunterDeleteForm = (props:any) => {
    const logic: any = useContext(EmprunterContext);
    const styleClasses = logic.styleClasses ;

    const onSubmit = async () => {
        emprunterStateUpdate.processingLivreEmpruntStateNormBtn(logic, true);
        emprunterBusinessLogic.deleteLivre(
            logic,
            messages,
            logic.livresEmpruntSelList).then((response:any) => {
                 emprunterStateUpdate.openLivreEmpruntDeleteDialogForm(logic, false);
                 emprunterStateUpdate.processingLivreEmpruntStateNormBtn(logic, false);
        }).catch((e:any) => emprunterStateUpdate.processingLivreEmpruntStateNormBtn(logic, false));
    };

    React.useEffect(() => {
        return () => {};
    }, [logic.openLivreEmpruntDeleteDialogForm]);

    // @ts-ignore
    return (
        <DialogFormKiller
            theme={logic.theme}
            savingBtn={true}
            savingStateBtn={logic.processingLivreEmpruntStateNormBtn}
            title={<FormattedMessage id={messages.emprunt_delete_title} />}
            open={logic.openLivreEmpruntDeleteDialogForm}
            setOpen={logic.setOpenLivreEmpruntDeleteDialogForm}
            btnCloseLabel={messages.dialog_close}
            onSubmit={onSubmit}>
                <Box  sx={{ width: '100%' }}>
                    <div className={styleClasses.inputField} style={{textAlign:'center', color:'black'}}>
                        <FormattedMessage id={messages.selection_to_delete} />
                    </div>
                </Box>
        </DialogFormKiller>
    );
}

export default EmprunterDeleteForm ;