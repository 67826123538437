import React, {useEffect, useRef, useState} from 'react'
import {makeStyles} from "@mui/styles";
import {alpha, Avatar, Badge, Button, Divider, IconButton, Menu, MenuItem, Typography} from "@mui/material";
import {ExitToApp, HelpOutlineOutlined, NotificationsActiveOutlined} from "@mui/icons-material";
import classNames from "classnames";
import {useLocation, useNavigate} from 'react-router-dom';
import SelectLanguage from "./ui/language/SelectLanguage";
import {BASEURL, BASEURL_RESOURCES, SOCKET_URL} from '../config/AppUrl';
import {useDispatch, useSelector} from "react-redux";
import {logoutUser} from "../features/user/userSlice";
import {removeAuthToken, request} from "../helpers/axios_helpers";
import {EventNotification, EVENTNOTIFICATION_CONSTANT} from "../constants/type/EventNotification";
import ListItem from "@mui/material/ListItem";
import {loadUserUnReadNotifications, updateNotification} from "../helpers/loader_notifications";
import {Client} from "@stomp/stompjs";
import _ from "lodash";
import {FormattedMessage, useIntl} from "react-intl";
import messages from "../page/messages/general/messages";
import menuMessages from "../page/messages/general/menuMessages";
import ListItemText from "@mui/material/ListItemText";
import moment from "moment";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import {stringAvatar, WEBSOCKET_RECONNECTDELAY} from "../helpers/helpers";
import {setMenuOpen, setPageForm, setPageTitle} from "../features/page/pageSlice";
import {clearDataCache} from "../features/cache/remoteDBCacheSlice";

const flag = BASEURL+'/images/flag-lang.png';
const avatar = BASEURL+'/images/avatars/pp_boy4.jpg';

//TODO ISSUE DANS LA PARTIE MESSAGERIE PROBLEME D'INTERACTIVE A LA CREATION DES MENUS
//TODO ISSUE LE DASHBOARD S'AFFICHE PAR DEFAUT PEU IMPORTE LES DROITS
const flagIcon = {
    width: 22,
    height: 22,
    display: 'inline-block',
    position: 'relative',
    marginRight: 5,
    top: 0,
    background: `url(${flag}) no-repeat transparent`,
    backgroundSize: '22px auto',
    '&[class="en"]': {
        backgroundPosition: '0 3px'
    },
    '&[class="fr"]': {
        backgroundPosition: '0 -20px'
    },
};

const useStyles = makeStyles((theme:any) => {
    return {
        dark: {},
        light: {},
        textNotif: {
            '& span': {
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                display: 'block',
            },
        },
        notifIcon: {
            '& svg': {
                width: 28,
                height: 28,
            },
            '&$light': {
                '& svg': {
                    fill: alpha(theme.palette.text.secondary, 0.48),
                },
            },
        },
        helpIcon: {
            '& svg': {
                width: 30,
                height: 30,
            },
            '&$light': {
                //marginTop: 5,
                paddingTop:10,
                '& svg': {
                    fill: alpha(theme.palette.text.secondary, 0.38),
                },
            },
        },
        badge: {
            height: 'auto',
        },
        userInfosBox: {
            marginBottom: 10,
            maxWidth: 300,
            minWidth: 220,
        },
        userAvatarInfoBox: {
            textAlign: 'center',
            marginTop: 5,
            marginBottom: 7,
            lineHeight: '16px',
        },
        userInfoAvatar: {
            display: 'block',
            marginLeft: '40%',
            marginRight: '37%',
            marginBottom: 5,
        },
        userNameInfo: {
            fontWeight: 500,
            fontSize: 13,
        },
        divider: {
            marginTop: theme.spacing(1),
        },
        textMenuItem: {
            fontSize:11,
            fontWeight:500,
        },
        inputLang: {
            maxWidth: 160,
            background: alpha(theme.palette.text.primary, 0.05),
            border: 'none',
            '& i': {
                ...flagIcon
            }
        },
        selectbox: {
            paddingRight: theme.spacing(4)
        },
        langItem: {
            display: 'block',
            '& i': {
                ...flagIcon
            },
        },
        languageName: {
            display: 'block',
            paddingTop:4,
            paddingBottom:0,
            fontWeight:500,
        }

    }
})


function UserMenu(props:any) {
    const { locale, setLocale } = props
    const classes = useStyles();
    const activeLanguageRef = React.useRef<string[]>(props.locale.split('-'));
    const [anchorEl, setAnchorEl] = useState(null);
    const [openMenuNotifs, setOpenMenuNotifs] = useState(null);
    const websocketClientRef = React.useRef<any>(null);

    let location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { user, userDetails, lang } = useSelector((state:any) => state.user);
    const userRef = React.useRef<any>(useSelector((state:any) => state.user.user));
    const [notifications, setNotifications] = useState<EventNotification[]>([]);
    const [menuNotifs, setMenuNotifs] = useState<any[]>([]);
    const [coloringNewNotif, setColoringNewNotif] = useState<boolean>(false)
    const { person, fonctions } = userDetails.personDetails;
    //const person = { photo:'', nom:'Nom', prenom:'Prenom' }
    const intl = useIntl() ;
    const [userFonction, setUserFonction] = useState('');
    const [anchorNotifEl, setAnchorNotifEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const dark = false


    const handleMenu = (menu:any) => (event:any) => {
        setOpenMenuNotifs(openMenuNotifs === menu ? null : menu);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpenMenuNotifs(null);
    };
    const openGuide = () => {}

    const handleProfile = () => {
        dispatch(setMenuOpen('user_profile'));
        dispatch(setPageTitle(intl.formatMessage({id:messages.title_profile})));
        handleClose();
        navigate('/app/profile');
    }

    const handleOpenNotif = (event: React.MouseEvent<HTMLButtonElement>) => {
        setColoringNewNotif(false);
        if(_.isEmpty(notifications)){
            openNotificationsPage();
            return ;
        }
        setAnchorNotifEl(event.currentTarget);
    };
    const handleCloseNotif = () => {
        setAnchorNotifEl(null);
    };
    const onLogout = () => {
        const langBeforeLogOut = lang;
        removeAuthToken();
        window.localStorage.setItem("countLoadingPage", '1');
        window.localStorage.setItem("appSystemLang", langBeforeLogOut);
        dispatch(clearDataCache());
        dispatch(logoutUser());
    }

    const getUserFonction = () => {
        const fonctionId = fonctions[0].fonctionId;
        request('GET',  `/corebase/fonctions/${fonctionId}`, {})
            .then((response) => {
                setUserFonction(response.data.nom);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const updateNotifications = (payloads:any[]) => {
        const itemsOrder: EventNotification[]  = _.reverse(_.sortBy(payloads, ['dateSent']));
        setNotifications(_.cloneDeep(itemsOrder));
    }
    const onConnected = () => {
        const subNotifications = websocketClientRef.current?.subscribe(`/notifications/utilisateur/${userRef.current.uuid}/all`, function (payload:any) {
            const items = JSON.parse(payload.body) ;
            const itemsMaps: EventNotification[]  = _.map(items,  EVENTNOTIFICATION_CONSTANT.convertToFrontEnd);
            updateNotifications(itemsMaps);
            setColoringNewNotif(true);
        }, {'content-type': 'application/json'});
    }
    const onDisconnected = () => {

    }

    const openPageRelatedToNotif = (item:EventNotification) => {
        handleCloseNotif();
        // @ts-ignore
        updateNotification(userRef.current.uuid, item.id);

        const pageForm = {cle:item.uriErpCle, id:item.dataId, metaData:JSON.stringify(item), reload: location.pathname === item.uriErp} ;

        dispatch(setPageForm(pageForm));

        dispatch(setMenuOpen(item.uriErpCle));
        // @ts-ignore
        dispatch(setPageTitle(menuMessages[item.uriErpCle] !== undefined ? intl.formatMessage({id:menuMessages[item.uriErpCle]}) : item.uriErpNom));

        // @ts-ignore
        navigate(item.uriErp);



        /*// @ts-ignore
        updateNotification(userRef.current.uuid, item.id);

        const pageForm = {cle:item.uriErpCle, id:item.dataId, metaData:item};
        dispatch(setPageForm(pageForm));

        dispatch(setMenuOpen(item.uriErpCle));
        // @ts-ignore
        dispatch(setPageTitle(menuMessages[item.uriErpCle] !== undefined ? intl.formatMessage({id:menuMessages[item.uriErpCle]}) : item.uriErpNom));
        // @ts-ignore
        navigate(item.uriErp);*/
    }
    const openNotificationsPage = () => {
        handleCloseNotif();
        dispatch(setMenuOpen('mesnotifs'));
        dispatch(setPageTitle(intl.formatMessage({id:menuMessages.mesnotifs})));
        navigate('/app/notification/mesnotifs');
    }
    useEffect(() => {
        const notifinfos: any[] = [];
        if(!_.isEmpty(notifications)){
            notifinfos.push( <MenuItem key="notif0" onClick={openNotificationsPage} >
                    <ListItem>
                        <ListItemText primary={<FormattedMessage id={messages.mes_notifications} />} />
                    </ListItem>
                </MenuItem>
            );

            const sz = Math.min(10, notifications.length);
            for (let i = 0; i < sz; i++) {
                const notif = notifications[i];
                const ladate = moment(notif.dateSent).format('lll');
                notifinfos.push(<MenuItem
                    key={`notif_${notif.id}`}
                    onClick={() => openPageRelatedToNotif(notif)}
                    sx={{pt:0.3,pb:0.3,pl:1,pr:1,ml:0,mr:0,mt:1,mb:1}}>
                    <ListItemAvatar>
                        <Avatar alt={notif.nameSender}
                                src={notif.photoForm}
                                {...stringAvatar(notif.nameSender ?? ``)}
                        />
                    </ListItemAvatar>
                    <ListItemText
                        primary={ <Typography
                            sx={{ display: 'block', fontSize:'11px' }}
                            variant="h6"
                        >
                            {notif.title}
                        </Typography>}
                        secondary={<>
                            <Typography
                                sx={{ display: 'block', fontSize:'11px'  }}
                                component="span"
                                variant="body1"
                                color="gray"
                            >
                                {notif.body}
                            </Typography>
                            <Typography
                                sx={{ display: 'block',fontSize:'10px'  }}
                                component="span"
                                variant="body2"
                                color="gray"
                            >
                                {notif.nameSender}
                            </Typography>
                            <Typography
                                sx={{ display: 'block', fontSize:'10px'  }}
                                component="span"
                                variant="body2"
                                color="gray"
                            >
                                {ladate}
                            </Typography>
                        </>} />
                </MenuItem>);

            }

        }
        setMenuNotifs(notifinfos)  ;
    }, [notifications]);

    useEffect(() => {
        getUserFonction();
        loadUserUnReadNotifications(userRef.current.uuid).then(value => {
            updateNotifications(value);
            if(!_.isEmpty(value)){
                setColoringNewNotif(true);
            }
        });

        if(_.isEmpty(websocketClientRef.current) && !_.isEmpty(userRef.current.uuid)){
            websocketClientRef.current = new Client({
                brokerURL: SOCKET_URL,
                connectHeaders: {id: userRef.current.uuid},
                reconnectDelay: WEBSOCKET_RECONNECTDELAY,
                heartbeatIncoming: 4000,
                heartbeatOutgoing: 4000,
                onConnect: () => onConnected(),
                onDisconnect: () => onDisconnected()
            });

            websocketClientRef.current.activate();
        }

        return () => {
            websocketClientRef.current?.deactivate();
        }
    }, [user, userDetails]);

    return (
        <div>
            <SelectLanguage classes={classes} locale={locale} setLocale={setLocale} />
            <IconButton
                aria-haspopup="true"
                onClick={handleOpenNotif}
                color={coloringNewNotif ? "error" : "primary"}
                className={classNames(classes.notifIcon, dark ? classes.dark : classes.light)}
                sx={{ml:1}}
            >
                <Badge className={classes.badge} badgeContent={notifications.length} color={coloringNewNotif ? "error" : "primary"}>
                    <NotificationsActiveOutlined />
                </Badge>
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorNotifEl}
                open={Boolean(anchorNotifEl)}
                onClose={handleCloseNotif}
                slotProps={{
                    paper: {
                        sx: {
                            width: 400,
                        },
                    }
                }}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    role: 'listbox',
                }}
            >
                {menuNotifs}

            </Menu>

            <IconButton
                aria-haspopup="true"
                color="inherit"
                title="Aide"
                className={classNames(classes.helpIcon, dark ? classes.dark : classes.light)}
                onClick={openGuide}
                sx={{ml:1, display: { xs: 'none', sm: 'inline-block' } }}>
                <HelpOutlineOutlined />
            </IconButton>

            <Button onClick={handleMenu('user-setting')} sx={{ ml:1 }}>
                <Avatar alt="avatar" src={person.photo !=="" ? `${BASEURL_RESOURCES}/${person.photo}` : avatar} />
            </Button>
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={openMenuNotifs === 'user-setting'}
                onClose={handleClose}
                style={{ paddingBottom: 0, lineHeight:'18px' }}
            >
                <div className={classes.userInfosBox}>
                    <div className={classes.userAvatarInfoBox}>
                        <Avatar alt="avatar" src={person.photo !=="" ? `${BASEURL_RESOURCES}/${person.photo}` : avatar} className={classes.userInfoAvatar} />
                        <div style={{ fontSize: '13px' }}>
                            <span className={classes.userNameInfo}>
                                {person.prenom} <span style={{textTransform:'uppercase'}}>{person.nom}</span>
                            </span> <br />
                            <span>{userFonction} </span>
                        </div>
                    </div>
                    <Divider className={classes.divider} />
                </div>
                <MenuItem onClick={handleProfile} sx={{ fontSize:14, fontWeight:500 }}>
                    <FormattedMessage id={messages.title_profile} />
                </MenuItem>
                {/*<MenuItem onClick={handleClose} component={Link} to={'/'} sx={{ fontSize:14, fontWeight:500 }}>
                    My Task
                </MenuItem>*/}
                <Divider style={{ marginBottom: '5px' }} />
                <MenuItem onClick={() => onLogout() }>
                    <>
                        <ExitToApp color="error" /> &nbsp;
                        <Typography component="h6" color="error" style={{ display: 'inline-block', fontWeight: '500' }}>
                            {<FormattedMessage id={messages.logout_form_app} />}
                        </Typography>
                    </>
                </MenuItem>
            </Menu>
        </div>
    )
}

export default UserMenu ;
