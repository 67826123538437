import React, {useEffect, useMemo, useState} from 'react'
import {makeStyles} from "@mui/styles";
import styles from "./../config-table-jss";
import {MaterialReactTable, MRT_ColumnDef, MRT_Localization, useMaterialReactTable} from 'material-react-table';
import {MRT_Localization_EN} from 'material-react-table/locales/en';
import {MRT_Localization_FR} from 'material-react-table/locales/fr';
import {FormattedMessage, useIntl} from "react-intl";
import messages from "../../messages/general/messages";
import moment, {Moment} from "moment";
import {useForm} from "react-hook-form";
import {request} from "../../../helpers/axios_helpers";
import {CONSTANT} from "../../../constants";
import SchoolPeriodForm from "./../periode/SchoolPeriodForm";
import {withSnackbar} from "../../../components/ui/snackbar/SnackbarHOC";
import {useSelector} from "react-redux";
import {getErrorMessage} from "../../../helpers/errorManager";
import {
    getMyReactTableDefaultOptions,
    MyRenderRowActions,
    MyRenderTopToolbarActions
} from "../../../components/ui/materialReactTable/MyDefaultMaterialReactTableOptions";
import {useMyMaterialReactTable} from "../../../components/ui/materialReactTable/MyMaterialReactTableEvents";
import _ from "lodash";

const useStyles = makeStyles(styles);

type Period = {
    id: number,
    year: string;
    period:string,
    startPeriod: string;
    endPeriod: string;
};

type SchoolPeriodFormValues = {
    id:number,
    year: string,
    period: string,
    startPeriod: Moment,
    endPeriod: Moment,
};

function SchoolPeriod(props:any) {
    const { locale, theme, snackbarShowMessage } = props;
    const classes = useStyles();
    const activeLang = locale.split('-')[0];
    const intl = useIntl() ;
    const [schoolPeriodData, setSchoolPeriodData] = useState<Period[]>([]);
    const [open, setOpen] = React.useState(false);
    const [startDate, setStartDate]:any = useState(moment())
    const [endDate, setEndDate] = useState(startDate);
    const [stateSchoolPeriodForm, setStateSchoolPeriodForm] = useState(true);
    const [tableDataLoading, setTableDataLoading] = useState(true);
    const {schoolYear} = useSelector((state:any) => state.user);
    const [tableKeyValue, setTableKeyValue] = useState(new Date().getTime());

    const mytableState = useMyMaterialReactTable({tableId:'SchoolPeriod',  updateId:1725875936000}) ;
    const logicMRT = useMemo<any>(() => getMyReactTableDefaultOptions<Period>(mytableState), []);

    const handleClickOpen = () => {
        setOpen(true);
    };

   

    const handleStartDateChange = (date:any) => {
        setStartDate(date);
        setEndDate(date);
    };

    const handleEndDateChange = (date:any) => {
        setEndDate(date);
    };

    const addSchoolPeriod = () => {
        setStateSchoolPeriodForm(true);
        setStartDate(moment(new Date()));
        setEndDate(moment(new Date()));
        setValue('year', schoolYear?.nom);
        setValue('period', '');
        handleClickOpen();
    };

    const editSchoolPeriod = (row:any) => {
        setStateSchoolPeriodForm(false);
        const data:any = schoolPeriodData[row.index];
        moment.locale(activeLang);
        setValue('id', data.id );
        setValue('year', data.year );
        setValue('period', data.period);
        const dateDeb = moment(data.startPeriod, activeLang === 'en' ? 'MM-DD-YYYY' : 'DD-MM-YYYY').toDate();
        const dateFin = moment(data.endPeriod, activeLang === 'en' ? 'MM-DD-YYYY' : 'DD-MM-YYYY').toDate();
        setStartDate(moment(dateDeb));
        setEndDate(moment(dateFin));

        handleClickOpen();
    }

    const form = useForm<SchoolPeriodFormValues>({
        defaultValues: {
            id: 0,
            year: '',
            period: '',
            startPeriod: startDate,
            endPeriod: startDate
        }
    });
    const { control, register, handleSubmit, formState, reset, setValue } = form;
    const { errors } = formState;

    const sendData = (data:any, method:any,  url:any, dataToSend:any) => {
        if(startDate <= endDate){
            let message:any = '';
            let schoolPeriod:any = {}
            data.startPeriod = moment(startDate._d);
            data.endPeriod = moment(endDate._d);

            request(method, url, dataToSend).then((response) => {
                moment.locale(activeLang);
                if(stateSchoolPeriodForm) {
                    schoolPeriod.id = response.data.id;
                    schoolPeriod.year = schoolYear?.nom;
                    schoolPeriod.period = data.period;
                    schoolPeriod.startPeriod = moment(data.startPeriod).format("L");
                    schoolPeriod.endPeriod = moment(data.endPeriod).format("L");
                    schoolPeriod.common = response.data.common;
                    setSchoolPeriodData([schoolPeriod, ...schoolPeriodData]);
                    reset();
                    message = <FormattedMessage id={messages.sb_add_success} />
                }
                else {
                    schoolPeriodData.find((periodData:any, index:any) => {
                    const startPeriod = moment(startDate._d);
                    const endPeriod = moment(endDate._d);
                    if(periodData.id === data.id) {
                        schoolPeriodData[index].year = data.year;
                        schoolPeriodData[index].period = data.period;
                        schoolPeriodData[index].startPeriod = moment(startPeriod).format("L");
                        schoolPeriodData[index].endPeriod = moment(endPeriod).format("L");

                        setSchoolPeriodData([...schoolPeriodData]);
                    }
                    });
                    message = <FormattedMessage id={messages.sb_edit_success} />
                }
                setOpen(false);
                snackbarShowMessage(message);
            }).catch((error) => {
                snackbarShowMessage(getErrorMessage(error)?.message, 'error');
            });
        }
    }

    const onSubmit = (data: SchoolPeriodFormValues) => {
        const dataToSend = {
            nom:data.period,
            dateDebut:startDate._d.valueOf(),
            dateFin:endDate._d.valueOf(),
            common:CONSTANT.common
        }
        sendData(data, 'POST', "/corebase/periodes", dataToSend);
    };

    const onSubmitEditForm = (data: SchoolPeriodFormValues) => {
        const periodFind:any = schoolPeriodData.find(periodData => periodData.id === data.id);
        const dataToSend = {
            ...periodFind,
            id: data.id,
            nom: data.period,
            dateDebut:startDate._d.valueOf(),
            dateFin:endDate._d.valueOf(),
            common: periodFind.common
        }
        sendData(data, 'PUT', `/corebase/periodes/${data.id}`, dataToSend);
    }

    const handleRowsDelete = (rows:any) => {
        const dataSelected:any = [];
        const dataPeriodSelectedId:any = [];
        rows.map((row:any) => {
            const data = schoolPeriodData[row.index];
            dataSelected.push(data);
            dataPeriodSelectedId.push(data.id);
        });

        request('DELETE', '/corebase/periodes/bouquet', dataPeriodSelectedId).then((response) => {
            const periodList = schoolPeriodData.filter((period) => {
                if(!dataSelected.includes(period)){
                    return period;
                }
            });
            setSchoolPeriodData([...periodList]);
            setTableKeyValue(new Date().getTime());
            snackbarShowMessage(<FormattedMessage id={messages.sb_delete_success} />);
        }).catch((error) => {
            snackbarShowMessage(getErrorMessage(error)?.message, 'error');
        });

    }


    const columns = useMemo<MRT_ColumnDef<Period>[]>(
        () => [
            {
                accessorKey: 'year',
                header: intl.formatMessage({id: messages.school_period_year}),
                size: 150,
                enableEditing:false,
            },
            {
                accessorKey: 'period',
                header: intl.formatMessage({id: messages.school_period}),
            },
            {
                accessorKey: 'startPeriod',
                header: intl.formatMessage({id: messages.start_period}),
                //size: 50,
            },
            {
                accessorKey: 'endPeriod',
                header:  intl.formatMessage({id: messages.end_period}),
                enableColumnResizing:false,
                //size: 50,
            },
        ],
        [activeLang],
    );

    const localization = useMemo<MRT_Localization>(() => activeLang === 'en' ? MRT_Localization_EN : MRT_Localization_FR, [activeLang]) ;
    const tableConfig = useMaterialReactTable({
        ...logicMRT.defaultOptions,
        localization,
        columns,
        data: schoolPeriodData,
        state:{
            showProgressBars: tableDataLoading,
            showSkeletons: tableDataLoading,
        },
        renderTopToolbarCustomActions: ({ table }: {table:any}) => (
            <MyRenderTopToolbarActions
                table={table}
                addNewDataTable={addSchoolPeriod}
                handleRowsDelete={() => handleRowsDelete(table.getSelectedRowModel().rows)}
                deleteDisabled={_.isEmpty(table.getSelectedRowModel().rows)}
                styleClasses={classes}
            />
        ),
        renderRowActions: ({row, table}: {row:any, table:any}) => (
            <MyRenderRowActions
                editRow={() => editSchoolPeriod(row)}
                styleClasses={classes}
            />
        )
    });


    useEffect(() => {
        try {
            setTableDataLoading(true);
            request("GET", "/corebase/periodes", {}).then((response) => {
                let dataPeriod:any = response.data._embedded !== undefined ?  response.data._embedded.periodeDTOModelList : [];

                dataPeriod = dataPeriod.sort(function (a:any, b:any) {
                    return a.dateDebut - b.dateDebut;
                });

                const dataList = dataPeriod.map((schoolPeriod:any) => {
                    moment.locale(activeLang);
                    let periodData:any = {};
                    periodData.id = schoolPeriod.id;
                    periodData.year = schoolYear?.nom;
                    periodData.period = schoolPeriod.nom;
                    periodData.startPeriod = moment(schoolPeriod.dateDebut).format("L");
                    periodData.endPeriod = moment(schoolPeriod.dateFin).format("L");
                    periodData = {...schoolPeriod, ...periodData, ...{common: schoolPeriod.common }}
                    return periodData;
                })
                setSchoolPeriodData([...dataList]);
                setTableDataLoading(false);
            }).catch((error) => {
                snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                setTableDataLoading(false);
            })   
        }
        catch (error){
            console.log(error);
        }

    }, [activeLang]);
    
    useEffect(() => {
        return () => logicMRT.storeState(tableConfig.getState());
    }, []);
    
    return (
        <div className={classes.root}>
            <MaterialReactTable
                table={tableConfig}
                key={tableKeyValue}
            />

            <SchoolPeriodForm
                theme={theme}
                locale={locale}
                title={
                    stateSchoolPeriodForm ? <FormattedMessage id={messages.dialog_title_period} /> : <FormattedMessage id={messages.dialog_edit_title_period} />
                }
                activeLanguage={activeLang}
                open={open}
                setOpen={setOpen}
                stateSchoolPeriodForm={stateSchoolPeriodForm}
                onSubmit={stateSchoolPeriodForm ? handleSubmit(onSubmit) : handleSubmit(onSubmitEditForm)}
                classes={classes}
                register={register}
                startDate={startDate}
                handleStartDateChange={handleStartDateChange}
                endDate={endDate}
                handleEndDateChange={handleEndDateChange}
                schoolYear={schoolYear}
            />
        </div>
    )
}

export default withSnackbar(SchoolPeriod);
