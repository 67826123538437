import React, {useContext, useRef} from "react";
import _ from "lodash";

import {Box, FormControl, InputLabel, TextField} from "@mui/material";
import {FormattedMessage, useIntl} from "react-intl";
import {EmprunterContext} from "./Emprunter";
import emprunterBusinessLogic from "./businesslogic/EmprunterBusinessLogic";
import messages from "../../messages/general/messages";
import DialogFormKiller from "../../../components/ui/form/DialogFormKiller";
import {LIVREEMPRUNT_CONSTANT, livreEmpruntDataImpl} from "../../../constants/type/LivreEmprunt";
import {NumericFormat} from "react-number-format";
import moment from "moment";
import emprunterStateUpdate from "./businesslogic/EmprunterStateUpdate";

const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 1;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const EmprunterForm = (props:any) => {
    const logic: any = useContext(EmprunterContext);
    const styleClasses = logic.styleClasses ;
    const intl = useIntl() ;
    const titreRef = useRef<any>(intl.formatMessage({id:messages.emprunter_book}));
    const suffixLivreRef = useRef<any>(intl.formatMessage({id:messages.biblio_livre}));


    const onSubmit = async () => {
        // @ts-ignore
        logic.livreEmpruntSel.dateDemande = moment().valueOf();
        logic.livreEmpruntSel.employeeId = logic.userRef.current?.userDetails?.personDetails?.person?.id ;

        const toSend = LIVREEMPRUNT_CONSTANT.convertToBackEnd(logic, logic.livreEmpruntSel);

        emprunterStateUpdate.processingLivreEmpruntStateNormBtn(logic, true);
        emprunterBusinessLogic.saveLivreEmprunt(
            logic,
            messages,
            toSend).then((response:any) => {
            emprunterStateUpdate.openLivreForEmpruntDialogForm(logic, false);
            emprunterStateUpdate.processingLivreEmpruntStateNormBtn(logic, false);
        }).catch((e:any) => emprunterStateUpdate.processingLivreEmpruntStateNormBtn(logic, false));
    };

    const handleQuantiteChange = (values: any) => {
        const data = _.cloneDeep(logic.livreEmpruntSel);
        data.quantite = _.toInteger(values.floatValue) ;
        emprunterStateUpdate.livreEmpruntSel(logic, data);
    };

    React.useEffect(() => {
        if(!logic.openLivreForEmpruntDialogForm){
            const emprunt = _.cloneDeep(livreEmpruntDataImpl);
            emprunterStateUpdate.livreEmpruntSel(logic, emprunt);
            return ;
        }
        return () => {

        };
    }, [logic.openLivreForEmpruntDialogForm]);

    // @ts-ignore
    return (
        <DialogFormKiller
            theme={logic.theme}
            savingBtn={true}
            savingStateBtn={logic.processingLivreEmpruntStateNormBtn}
            title={`${titreRef.current} ${logic.livreSel.nom}`}
            open={logic.openLivreForEmpruntDialogForm}
            setOpen={logic.setOpenLivreForEmpruntDialogForm}
            btnCloseLabel={messages.dialog_close}
            onSubmit={onSubmit}>
                <Box  sx={{ width: '100%' }}>
                    <InputLabel htmlFor="standard-adornment-amount">
                        <FormattedMessage id={messages.emprunt_sentence_sujet} />
                    </InputLabel>
                    <FormControl fullWidth variant="standard">
                        <NumericFormat
                            suffix={`  ${suffixLivreRef.current}(s)`}
                            allowNegative={false}
                            decimalScale={0}
                            min={1}
                            value={logic.livreEmpruntSel.quantite}
                            customInput={TextField}
                            thousandSeparator={' '}
                            onValueChange={(values, sourceInfo) => {
                                handleQuantiteChange(values);
                            }}/>
                    </FormControl>

                </Box>
        </DialogFormKiller>
    );
}

export default EmprunterForm ;