import React, {useContext, useState} from "react";
import {FormattedMessage} from "react-intl";
import messages from "../../messages/general/messages";
import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    FormControl,
    FormControlLabel,
    Grid,
    InputAdornment,
    ListItemText,
    MenuItem,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from "@mui/material";
import _ from "lodash";
import {Livre} from "../../../constants/type/Livre";
import {EmprunterContext} from "./Emprunter";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import {stringAvatar} from "../../../helpers/helpers";
import emprunterStateUpdate from "./businesslogic/EmprunterStateUpdate";
import {SwipeDown} from "@mui/icons-material";
import {FixedSizeList, ListChildComponentProps} from "react-window";
import {livreEmpruntDataImpl} from "../../../constants/type/LivreEmprunt";
import SearchIcon from "@mui/icons-material/Search";
import InputField from "../../../components/ui/form/InputField";


function LivreList() {
    const logic: any = useContext(EmprunterContext);
    const styleClasses = logic.styleClasses;
    const menuLeaveTimerRef = React.useRef<any>();
    const [search, setSearch] = useState<string>('');
    const searchPlaceholder:string | null = logic.activeLang === 'en' ? 'Search' : 'Rechercher';

    const handleSearch = (event:any) => {
        const searchTerm = event.target.value !== undefined ? event.target.value : null;
        setSearch(searchTerm);
        emprunterStateUpdate.filtreRecherche(logic, searchTerm);
    }

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        emprunterStateUpdate.paginer(logic, value);
    };

    const handleEmprunter = (opt:any) => {
        const emprunt = _.cloneDeep(livreEmpruntDataImpl);
        emprunt.livreId = opt.id ;
        emprunt.livre = opt ;
        emprunterStateUpdate.livreSel(logic, opt);
        emprunterStateUpdate.livreEmpruntSel(logic, emprunt);
        emprunterStateUpdate.openLivreForEmpruntDialogForm(logic, true);
    }

    const handleOnCategorieChange = (event: any) =>  emprunterStateUpdate.filtreCategorie(logic, _.toInteger(event.target.value));
    const handleLivreEtatChange = (event: React.ChangeEvent, val: string) => emprunterStateUpdate.filtreEtat(logic, val);

    React.useEffect(() => {
        clearTimeout(menuLeaveTimerRef.current);
        emprunterStateUpdate.showListContent(logic, false);
        menuLeaveTimerRef.current = setTimeout(function() {
            emprunterStateUpdate.livres(logic, logic.livresRef.current, logic.livreFiltre);
            emprunterStateUpdate.showListContent(logic, true);
        }, 1500);
        return () => {
        };
    }, [logic.livreFiltre]);

    function renderRow(props: ListChildComponentProps) {
        const { index, style } = props;
        const opt : Livre = logic.livres[index];
        if(!_.isObject(opt)){
            return <></>;
        }
        return (<ListItem
            style={style}
            key={index}
            component="div"
            disablePadding
            alignItems="flex-start"
            secondaryAction={
                <Button
                    disabled={!opt.available}
                    color="primary"
                    variant="contained"
                    startIcon={<SwipeDown />}
                    onClick={() => handleEmprunter(opt)}
                >
                    <FormattedMessage id={messages.emprunter} />
                </Button>
            }>
            <ListItemAvatar key={`avatar_${opt.id}`} sx={{mr:2}}>
                <Avatar {...stringAvatar(opt.nom)}
                        alt={opt.nom}
                        src={opt.photoForm}
                        variant="square"
                        sx={{ width: 80, height: 100 }}/>
            </ListItemAvatar>
            <ListItemText
                key={`itemtxt_${opt.id}`}
                primary={
                    <Typography
                        sx={{ display: 'block' }}
                        variant="h6"
                    >
                        {opt.nom}
                    </Typography>
                }
                secondary={
                    <>
                        <Typography
                            sx={{ display: 'block' }}
                            component="span"
                            variant="body1"
                            color="text.secondary"
                        >
                            {opt.auteur}
                        </Typography>
                        <Typography
                            sx={{ display: 'block'}}
                            component="span"
                            variant="body2"
                            color="text.secondary"
                        >
                            {opt.langue}
                        </Typography>
                        <Typography
                            sx={{ display: 'block'}}
                            component="span"
                            variant="body2"
                            color="text.secondary"
                        >
                            <FormattedMessage id={messages.table_h_agemin} />: {opt.agemin}+
                        </Typography>
                        <Typography
                            sx={{ display: 'block', mt:1, fontStyle:'italic'}}
                            component="span"
                            variant="caption"
                            color="text.primary"
                        >
                            {opt.description}
                        </Typography>
                    </>
                }
            />
        </ListItem>) ;
    };


    return (
        <div className={styleClasses.root}>
            <div className={styleClasses.tableContainer}>
                <Grid container spacing={2} sx={{mb:4}}>
                    <Grid item md={4} xs={4}>
                        <FormControl className={styleClasses.radioContainer}>
                            <RadioGroup
                                row
                                value={logic.livreFiltre.etat}
                                onChange={handleLivreEtatChange}
                                sx={{width:'100% !important'}}>
                                <FormControlLabel
                                    sx={{ fontWeight: 'normal', mr:2}}
                                    value=""
                                    control={<Radio value="" size="small" color={"secondary"}/>}
                                    label={<FormattedMessage id={messages.all_label} />}
                                />

                                <FormControlLabel
                                    sx={{ fontWeight: 'normal', mr:2}}
                                    value="disponible"
                                    control={<Radio value="disponible" size="small" color={"secondary"}/>}
                                    label={<FormattedMessage id={messages.disponible} />}
                                />

                                <FormControlLabel
                                    sx={{ fontWeight: 'normal'}}
                                    value="indisponible"
                                    control={<Radio value="indisponible" size="small" color={"secondary"}/>}
                                    label={<FormattedMessage id={messages.indisponible} />}
                                />
                            </RadioGroup>
                        </FormControl>
                       {/* <ToggleButtonGroup
                            color="primary"
                            value={logic.livreFiltre.etat}
                            exclusive
                            onChange={handleLivreEtatChange}
                            aria-label="Platform"
                        >
                            <ToggleButton value=""><FormattedMessage id={messages.all_label} /></ToggleButton>
                            <ToggleButton value="disponible"><FormattedMessage id={messages.disponible} /></ToggleButton>
                            <ToggleButton value="indisponible"><FormattedMessage id={messages.indisponible} /></ToggleButton>
                        </ToggleButtonGroup>*/}
                    </Grid>
                    <Grid item md={4} xs={4}>
                        <TextField
                            select
                            label={<FormattedMessage id={messages.biblio_catlivre} />}
                            sx={{ width: { xs: '100% !important'}, }}
                            color={"secondary"}
                            value={logic.livreFiltre.livreCategorieId}
                            onChange={handleOnCategorieChange}
                        >
                            <MenuItem key="0" value="0" sx={{fontWeight:400}}>-------</MenuItem>
                            {logic.livreCategorieRef.current?.map((opt:any) => (
                                <MenuItem key={opt.id} value={opt.id} sx={{fontWeight:400}}>
                                    {opt.nom}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item md={1} xs={1}></Grid>
                    <Grid item md={3} xs={3}>
                        <InputField
                            placeholder={searchPlaceholder}
                            size="small"
                            color={"secondary"}
                            value={search}
                            inputProps={{
                                endAdornment: <InputAdornment position="end" sx={{ p:0, m:0 }}><SearchIcon sx={{fontSize:'1.8rem'}} /></InputAdornment>,
                            }}
                            onChange={handleSearch}
                        />
                    </Grid>
                </Grid>

                {
                    !logic.showListContent &&
                    <Box  sx={{ width: '100%', textAlign: 'center', alignContent:'center', mt:3 }}>
                        <CircularProgress color="primary" />
                    </Box>

                }
                {
                    logic.showListContent &&
                    // @ts-ignore
                    <FixedSizeList
                        height={600}
                        width='100%'
                        itemSize={180}
                        itemCount={_.size(logic.livres)}
                        overscanCount={4}
                    >
                        {renderRow}
                    </FixedSizeList>
                }

                {/*<Pagination count={logic.pageCount} showFirstButton showLastButton  onChange={handlePageChange} />*/}
            </div>
        </div>
    );
}
export default LivreList ;