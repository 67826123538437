import React, {useMemo, useState} from 'react';
import {Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';

import {Checkbox, Grid, Stack} from "@mui/material";
import Brightness1OutlinedIcon from "@mui/icons-material/Brightness1Outlined";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import _ from "lodash";


const getPath = (x:number, y:number, width:number, height:number) => {
    return `M${x},${y + height}C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3}
  ${x + width / 2}, ${y}
  C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${x + width}, ${y + height}
  Z`;
};

const TriangleBar = (props:any) => {
    const { fill, x, y, width, height } = props;

    return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
};


export default function MyImsDayChart (props:any) {
    const {data} = props;
    const [indicatorsUnChecked, setIndicatorsUnChecked] = useState<string[]>([]);
    const dataList: any[] = _.filter(data, item => !_.includes(indicatorsUnChecked, item.id));

    const updateUncheckedIndicators = (isChecked:boolean, id:string) => {
        let unCheckedList = _.cloneDeep(indicatorsUnChecked);
        if(isChecked){
            unCheckedList.push(id);
            setIndicatorsUnChecked(unCheckedList);
            return ;
        }
        _.remove(unCheckedList, val => val === id);
        setIndicatorsUnChecked(unCheckedList);
    }


    const barCells = useMemo(() => {
        return _.map(dataList, (item:any) => <Cell key={`cell-${item.id}`} fill={item.fill}/>);
    }, [dataList]);


    const legends = useMemo(() => {
        return _.map(data, item => {
            const isChecked = !_.includes(indicatorsUnChecked, item.id) ;
            const textDeco = isChecked ? {} : {textDecoration:'line-through', opacity:0.8};
            return  (
                <Grid item xs={2} sm={4} md={4} key={`spoongrid-${item.id}`}>
                       <span key={`spoon-${item.id}`}
                             style={{color: '#8884d8',  verticalAlign: "middle", paddingRight: '10px', cursor:'pointer', ...textDeco}}
                             onClick={event => updateUncheckedIndicators(isChecked, item.id)}>
                                <Checkbox
                                    checked={isChecked}
                                    tabIndex={-1}
                                    key={`spooncheck-${item.id}`}
                                    icon={<Brightness1OutlinedIcon fontSize="small"/>}
                                    checkedIcon={<Brightness1Icon fontSize="small"/>} style={{color: item.fill}}
                                /> {item.name}
                       </span>
                </Grid>

            )
        })
    }, [dataList, data]);

    return (
        <Stack
            direction="column"
            spacing={2}
            sx={{
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <ResponsiveContainer width="98%" height={400}>
                <BarChart
                    width={650}
                    height={400}
                    data={dataList}
                    margin={{
                        top: 20,
                        right: 5,
                        left: 5,
                        bottom: 20,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="name" hide={true}/>
                    <YAxis/>
                    <Tooltip/>
                    <Bar dataKey="valeur" fill="#8884d8" shape={<TriangleBar/>} label={{position: 'top'}}>
                        {barCells}
                    </Bar>
                </BarChart>

            </ResponsiveContainer>
            <div style={{textAlign: "left"}}>
                <Grid container spacing={2}>
                    {legends}
                </Grid>

            </div>
        </Stack>

        /*
            <RadialBarChart
          width={730}
          height={250}
          innerRadius="10%"
          outerRadius="80%"
          data={data}
          startAngle={180}
          endAngle={0}
        >
          <RadialBar minAngle={15} label={{ fill: '#666', position: 'insideStart' }} background clockWise={true} dataKey='uv' />
          <Legend iconSize={10} width={120} height={140} layout='vertical' verticalAlign='middle' align="right" />
          <Tooltip />
        </RadialBarChart>

                <Stack
                    direction="column"
                    spacing={2}
                    sx={{
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >

                        <FunnelChart width={600} height={400} >
                            <Tooltip />
                            <Funnel
                                dataKey="valeur"
                                data={data}
                                isAnimationActive
                            >
                                <LabelList position="right" fill="#000" clockWise={true} offset={15} stroke="none" dataKey="name" />
                            </Funnel>
                       </FunnelChart>
                </Stack>*/
    );

}
