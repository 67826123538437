import {Common, commonDataImp} from "./Common";
import moment from "moment/moment";
import _ from "lodash";
import * as yup from "yup";
import {Child, childColumnsVisibilityPlugin, childDataImpl, columnsChildPlugin, exportExcelChildPlugin} from "./Child";
import {
    columnsScolariteGroupPlugin, exportExcelScolariteGroupPlugin,
    ScolariteGroup, scolariteGroupColumnsVisibilityPlugin,
    scolariteGroupDataImpl
} from "./ScolariteGroup";
import messages from "../../page/messages/general/messages";
import React from "react";
import {isColumnVisible} from "../../helpers/helpers";

export const SCOLARITESANSECHEANCEENFANT_CONSTANT = {
    convertToFrontEnd: (logic: any, item: ScolariteSansEcheanceEnfant) => {
        
        const itemInit = _.cloneDeep(scolariteSansEcheanceEnfantDataImpl);
        item = {...itemInit, ...item};

        item.ladateForm = moment(item.ladate);
        // @ts-ignore
        item.liredateForm = item.ladateForm?.clone().format('L');
        item.childForm = logic.childrenMapRef.current.get(item?.enfantId) ?? _.cloneDeep(childDataImpl);
        item.scolariteGroupForm = logic.scolariteGroupMapRef.current.get(item?.scolariteGroupId) ?? _.cloneDeep(scolariteGroupDataImpl);

        return item;
    },
    convertToBackEnd: (logic: any, item: ScolariteSansEcheanceEnfant) => item
};

export const exportExcelFraisOneShot = ({rows=[], columns=[], intl={}}:{ rows:any[]; intl:any;  columns: any[];}) => {
    return _.map(rows, (row:any) => {
        let obj:any = {};
        const data = row.original;

        obj = exportExcelChildPlugin({obj, data:data?.childForm ?? _.cloneDeep(childDataImpl), intl, infoSpace:intl.formatMessage({id:messages.table_h_enfant}), columns});

        obj = exportExcelScolariteGroupPlugin({obj, data:data?.scolariteGroupForm ?? _.cloneDeep(scolariteGroupDataImpl), intl, columns, infoSpace:intl.formatMessage({id:messages.fees})});

        if(isColumnVisible(columns, 'fraisOneShotForm_liredateForm')){
            obj[`${intl.formatMessage({id:messages.table_h_echeancier_date})}`] = data.liredateForm;
        }
        if(isColumnVisible(columns, 'fraisOneShotForm_montant')){
            obj[`${intl.formatMessage({id:messages.table_h_montant})}`] = data.montant;
        }
        return obj;
    });
}

export const fraisOneShotColumnsVisibility = {
    columnVisibility : {
        ...childColumnsVisibilityPlugin.columnVisibility,
        ...scolariteGroupColumnsVisibilityPlugin.columnVisibility,
        'fraisOneShotForm_liredateForm': false,
        'childForm_childNomPrenomForm': false,
        'scolariteGroupForm_montant': false,
    }
} ;

export const fraisOneShotCustomOptionsPlugin = {
    columnVisibility : {
        ...fraisOneShotColumnsVisibility.columnVisibility,
    }
} ;

export const columnsFraisOneShot = (props:any) => {
    const {intl} = props ;

    const callKeyValChild = (row:any) => row?.childForm ;
    const callKeyValScolariteGroup = (row:any) => row?.scolariteGroupForm ;


    return [
        ...columnsChildPlugin({intl, nomSpace: intl.formatMessage({id:messages.table_h_enfant}), callKeyValue:callKeyValChild}),
        ...columnsScolariteGroupPlugin({intl,  nomSpace: intl.formatMessage({id:messages.fees}), callKeyValue:callKeyValScolariteGroup}),
        {
            id: 'fraisOneShotForm_liredateForm',
            accessorKey: 'liredateForm',
            header: intl.formatMessage({id:messages.date_limite}),
            muiTableBodyCellProps: {
                align: 'center', //change head cell props
            },
            size:100,
        },
        {
            id: 'fraisOneShotForm_montant',
            accessorKey: 'montant',
            header: intl.formatMessage({id:messages.table_h_montant}),
            size:120,
            filterVariant: 'range',
            filterFn: 'between',
            muiTableBodyCellProps: {
                align: 'right', //change head cell props
            },
            // @ts-ignore
            Cell: ({cell}) => <b>{new Intl.NumberFormat("fr-FR").format(cell.getValue<number>())}</b>,
        },
    ];
} ;



export  const scolariteSansEcheanceEnfantDataImpl: ScolariteSansEcheanceEnfant = {
    id: null,
    ladate: moment().endOf('day').valueOf(),
    enfantId: 0,
    parentId:0,
    montant: 0,
    infos: '',
    scolariteGroupId:0,
    ladateForm: moment().endOf('day'),
    liredateForm: '',
    childForm: _.cloneDeep(childDataImpl),
    scolariteGroupForm: _.cloneDeep(scolariteGroupDataImpl),
    common: _.cloneDeep(commonDataImp),

}

export const scolariteSansEcheanceEnfantSchema = yup.object().shape({
    id:  yup.number().nullable(),
    ladate: yup.number().notRequired(),
    enfantId: yup.number().notRequired(),
    parentId: yup.number().notRequired(),
    montant: yup.number().notRequired(),
    infos: yup.string().default(''),
    scolariteGroupId: yup.number().required(),
    ladateForm: yup.object().nullable().required(),
    liredateForm: yup.string().default('').notRequired(),
    childForm: yup.mixed<Child>().notRequired(),
    scolariteGroupForm: yup.mixed<ScolariteGroup>().notRequired(),
    common: yup.mixed<Common>(),
});

export type ScolariteSansEcheanceEnfant = yup.InferType<typeof scolariteSansEcheanceEnfantSchema>;

