import {EnfantParent, ENFANTPARENT_CONSTANT} from "./EnfantParent";
import {Common, commonDataImp} from "./Common";
import _ from "lodash";
import * as yup from "yup";
import {EtatParent} from "./EtatParent";
import {Person, PERSON_CONSTANT, personDataImpl} from "./Person";
import {DroitImage} from "./DroitImage";
import {Eleve} from "./Eleve";
import {DossierEnfant} from "./DossierEnfant";
import {PersonneAutoriserEnfant} from "./PersonneAutoriserEnfant";
import {Sante} from "./Sante";
import {EnfantProfils, ENFANTPROFILS_CONSTANT} from "./EnfantProfils";
import {Profils} from "./Profils";
import messages from "../../page/messages/general/messages";
import React from "react";
import {isColumnVisible, isNotBlank, stringToColor} from "../../helpers/helpers";
import {Avatar, Box} from "@mui/material";
import {Parent, parentDataImpl} from "./Parent";
import {Genre} from "./Genre";


export const CHILD_CONSTANT = {
    convertToFrontEnd: (logicRacine:any, item:Child) => {
        const itemInit = _.cloneDeep(childDataImpl);
        const data: Child = {...itemInit, ...item} ;

        data.person = PERSON_CONSTANT.convertToFrontEnd(logicRacine, data.person ?? _.cloneDeep(personDataImpl)) ;

        // @ts-ignore
        data.enfantParents = _.map(data.enfantParents, opt => ENFANTPARENT_CONSTANT.convertToFrontEnd(logicRacine, opt));
        // @ts-ignore
        data.classeNameForm = data.eleves[0]?.classe?.nom ?? '' ;
        // @ts-ignore
        data.childNomPrenomForm = data.person.nomPrenom;
        // @ts-ignore
        data.childnomForm = data.person.nom;
        // @ts-ignore
        data.childprenomForm = data.person.prenom;
        // @ts-ignore
        data.childdatenaissanceForm = data.person.dateNaissanceForm;
        // @ts-ignore
        data.childsexeForm = data.person.sexe;
        // @ts-ignore
        data.childphotoForm = data.person.photoForm;
        // @ts-ignore
        data.childnoteForm = data.person.note;
        // @ts-ignore
        data.childpaysForm = data.person.paysForm;

        const nomUrgence1 = isNotBlank(data.emergencyNom1) ? `${data.emergencyNom1}, ` : ``;
        const nomUrgence2 = isNotBlank(data.emergencyNom2) ? `${data.emergencyNom2}, ` : ``;

        const phoneUrgence1 = isNotBlank(data.emergencyPhone1) ? `Tel: ${data.emergencyPhone1}` : ``;
        const phoneUrgence2 = isNotBlank(data.emergencyPhone2) ? `Tel: ${data.emergencyPhone2}, ` : ``;

        data.childemergencyNom1Form = `${nomUrgence1} ${phoneUrgence1}`;
        data.childemergencyNom2Form = `${nomUrgence2} ${phoneUrgence2}`;

        const parent1: Parent = data.enfantParents[0]?.parent ?? _.cloneDeep(parentDataImpl);
        const parent2: Parent = data.enfantParents[1]?.parent ?? _.cloneDeep(parentDataImpl);

        let maman : Parent   = parent1 ;
        let papa : Parent    = parent2 ;
        if(parent1.person.sexe === Genre.FEMME){
            maman   = parent1 ;
            papa    = parent2 ;
        }
        data.childMamaNomForm       = maman.person.nomPrenom ?? '' ;
        data.childMamanPhoneForm    = maman.person.phoneForm ?? '';
        data.childPapaNomForm       = papa.person.nomPrenom ?? '' ;
        data.childPapaPhoneForm     = papa.person.phoneForm ?? '';

        // @ts-ignore
        data.enfantProfils  = _.map(data.enfantProfils, enfantprofil => ENFANTPROFILS_CONSTANT.convertToFrontEnd(logicRacine, enfantprofil));

        let childprofil = ``;
        if(_.isEmpty(logicRacine.profilsRef?.current) === false){
            data.profils = [];
            data.enfantProfils.forEach(enfantprofil => {
                // @ts-ignore
                const profiltem: Profils = _.find(logicRacine.profilsRef?.current, {id:enfantprofil?.profilsId});
                if(_.isObject(profiltem)){
                    // @ts-ignore
                    data.profils.push(profiltem);
                    childprofil = `${profiltem.nom}, ${childprofil}`;
                }
            });
        }
        data.childProfilForm = childprofil;
        // @ts-ignore
       return data;
    },
    convertToBackEnd: (item:Child) => item
};



export const childColumnsVisibility = {
    columnVisibility : {
        'childForm_childNomPrenomForm': true,
        'childForm_childemergencyNomForm': false,
        'childForm_foodRestriction': false,
        'childForm_healthDetail': false,
        'childForm_allergy': false,
        'childForm_classeNameForm': false,
        'childForm_childnomForm': false,
        'childForm_childprenomForm': false,
        'childForm_childdatenaissanceForm': false,
        'childForm_childsexeForm': false,
        'childForm_childnoteForm': false,
        //'childemergencyNom1Form': false,
        //'childemergencyNom2Form': false,
        'childForm_childpaysForm': false,
        'childForm_childProfilForm': false,
        'childForm_childMamaNomForm': false,
        'childForm_childMamanPhoneForm': false,
        'childForm_childPapaNomForm': false,
        'childForm_childPapaPhoneForm': false,
    }
} ;

export const childColumnsVisibilityPlugin = {
    columnVisibility : {
        ...childColumnsVisibility.columnVisibility
    }
} ;


export const columnsChild = (props:any) => {
    const {intl} = props ;
    const activeLang = intl.locale;
    return [
        // @ts-ignore
        {
            id: 'childForm_childphotoForm',
            accessorKey: 'childphotoForm',
            header: intl.formatMessage({id: messages.table_h_photo}),
            enableColumnFilter:false,
            enableColumnOrdering:false,
            grow: false,
            size: 90,
            muiTableBodyCellProps: {
                align: 'center',
            },
            // @ts-ignore
            Cell: ({ cell }) => (
                <div style={{textAlign:'center', width:'80'}}>
                    <Avatar
                        // @ts-ignore
                        src={`${cell.getValue<string>()}`}
                        sx={{ textAlign:'center'}} />
                </div>
            ),
        },
        {
            id: 'childForm_childNomPrenomForm',
            accessorKey: 'childNomPrenomForm',
            header: intl.formatMessage({id: messages.table_h_fullname}),
        },
        {
            id: 'childForm_childnomForm',
            accessorKey: 'childnomForm',
            header: intl.formatMessage({id: messages.table_h_child_lastname}),
        },
        {
            id: 'childForm_childprenomForm',
            accessorKey: 'childprenomForm',
            header: intl.formatMessage({id: messages.table_h_child_firstname}),
        },
        {
            id: 'childForm_childdatenaissanceForm',
            accessorKey: 'childdatenaissanceForm',
            header: intl.formatMessage({id: messages.table_h_child_birthday}),
            size: 100,
        },
        { //child.sexeForm = lang === 'en' ? GENDER_EN[0].name : GENDER_FR[0].name;
            id: 'childForm_childsexeForm',
            accessorKey: 'childsexeForm',
            header: intl.formatMessage({id: messages.table_h_child_gender}),
            size: 80,
            grow: false,
            // @ts-ignore
            Cell: ({ cell }) =>  {
                // @ts-ignore
                const value = cell.getValue<string>()
                if(value === 'HOMME'){
                    return activeLang === 'en' ? 'Boy' : 'Garçon' ;
                }
                return activeLang === 'en' ? 'Girl' : 'Fille' ;
            },
        },
        {
            id: 'childForm_classeNameForm',
            accessorKey: 'classeNameForm',
            header: intl.formatMessage({id: messages.classroom_child}),
            size: 120,
        },
        {
            id: 'childForm_childpaysForm',
            accessorKey: 'childpaysForm',
            header: intl.formatMessage({id: messages.country}),
            size: 120,
            muiTableBodyCellProps: {
                align: 'center', //change head cell props
            },
            // @ts-ignore
            Cell: ({ cell }) => {
                // @ts-ignore
                const value = cell.getValue<any>();
                return (
                    <Box component="span" style={{color: stringToColor(value)}}>
                        {value}
                    </Box>
                );
            },
        },
        {
            id:'childForm_childemergencyNomForm',
            accessorFn: (row:any) => `${row.childemergencyNom1Form} | ${row.childemergencyNom2Form}` ,
            header: intl.formatMessage({id: messages.emergency_contact_child}),
        },
        {
            id: 'childForm_foodRestriction',
            accessorKey: 'foodRestriction',
            header: intl.formatMessage({id: messages.food_restriction_child}),
        },
        {
            id: 'childForm_healthDetail',
            accessorKey: 'healthDetail',
            header: intl.formatMessage({id: messages.health_detail_child}),
        },
        {
            id: 'childForm_allergy',
            accessorKey: 'allergy',
            header: intl.formatMessage({id: messages.allergies}),
        },
        {
            id: 'childForm_childProfilForm',
            accessorKey: 'childProfilForm',
            header: intl.formatMessage({id: messages.schooling_profile}),
            size: 120,
        },
        {
            id: 'childForm_childnoteForm',
            accessorKey: 'childnoteForm',
            header: intl.formatMessage({id: messages.note}),
        },

    ];
} ;


export const columnsChildPlugin = (props:any) => {
    const {intl, nomSpace, callKeyValue} = props ;
    const infoSpace = nomSpace ?? ``;
    const activeLang = intl.locale;

    return [
        // @ts-ignore
        {
            id: 'childForm_childnoteForm',
            accessorFn: (row:any) => callKeyValue(row)?.childphotoForm,
            header: `${infoSpace} ${intl.formatMessage({id: messages.table_h_photo})}`,
            enableColumnFilter:false,
            enableColumnOrdering:false,
            grow: false,
            size: 90,
            muiTableBodyCellProps: {
                align: 'center',
            },
            // @ts-ignore
            Cell: ({ cell }) => (
                <div style={{textAlign:'center', width:'80'}}>
                    <Avatar
                        // @ts-ignore
                        src={`${cell.getValue<string>()}`}
                        sx={{ textAlign:'center'}} />
                </div>
            ),
        },
        {
            id: 'childForm_childNomPrenomForm',
            accessorFn: (row:any) => callKeyValue(row)?.childNomPrenomForm,
            header: `${infoSpace} ${intl.formatMessage({id: messages.table_h_fullname})}`,
        },
        {
            id: 'childForm_childnomForm',
            accessorFn: (row:any) => callKeyValue(row)?.childnomForm,
            header: `${infoSpace} ${intl.formatMessage({id: messages.table_h_child_lastname})}`,
        },
        {
            id: 'childForm_childprenomForm',
            accessorFn: (row:any) => callKeyValue(row)?.childprenomForm,
            header: `${infoSpace} ${intl.formatMessage({id: messages.table_h_child_firstname})}`,
        },
        {
            id: 'childForm_childdatenaissanceForm',
            accessorFn: (row:any) => callKeyValue(row)?.childdatenaissanceForm,
            header: `${infoSpace} ${intl.formatMessage({id: messages.table_h_child_birthday})}`,
            size: 100,
        },
        {
            id: 'childForm_childsexeForm',
            accessorFn: (row:any) => callKeyValue(row)?.childsexeForm,
            header: `${infoSpace} ${intl.formatMessage({id: messages.table_h_child_gender})}`,
            size: 80,
            grow: false,
            // @ts-ignore
            Cell: ({ cell }) =>  {
                // @ts-ignore
                const value = cell.getValue<string>()
                if(value === 'HOMME'){
                    return activeLang === 'en' ? 'Boy' : 'Garçon' ;
                }
                return activeLang === 'en' ? 'Girl' : 'Fille' ;
            },
        },
        {
            id: 'childForm_classeNameForm',
            accessorFn: (row:any) => callKeyValue(row)?.classeNameForm,
            header: `${infoSpace} ${intl.formatMessage({id: messages.classroom_child})}`,
            size: 120,
        },
        {
            id: 'childForm_childpaysForm',
            accessorFn: (row:any) => callKeyValue(row)?.childpaysForm,
            header: `${infoSpace} ${intl.formatMessage({id: messages.country})}`,
            size: 120,
            muiTableBodyCellProps: {
                align: 'center', //change head cell props
            },
            // @ts-ignore
            Cell: ({ cell }) => {
                // @ts-ignore
                const value = cell.getValue<any>();
                return (
                    <Box component="span" style={{color: stringToColor(value)}}>
                        {value}
                    </Box>
                );
            },
        },
        {
            id: 'childForm_childemergencyNomForm',
            accessorFn: (row:any) => `${callKeyValue(row)?.childemergencyNom1Form} | ${callKeyValue(row)?.childemergencyNom2Form}` ,
            header: `${infoSpace} ${intl.formatMessage({id: messages.emergency_contact_child})}`,
        },
        {
            id: 'childForm_foodRestriction',
            accessorFn: (row:any) => callKeyValue(row)?.foodRestriction,
            header: `${infoSpace} ${intl.formatMessage({id: messages.food_restriction_child})}`,
        },
        {
            id: 'childForm_healthDetail',
            accessorFn: (row:any) => callKeyValue(row)?.healthDetail,
            header: `${infoSpace} ${intl.formatMessage({id: messages.health_detail_child})}`,
        },
        {
            id: 'childForm_allergy',
            accessorFn: (row:any) => callKeyValue(row)?.allergy,
            header: `${infoSpace} ${intl.formatMessage({id: messages.allergies})}`,
        },
        {
            id: 'childForm_childProfilForm',
            accessorFn: (row:any) => callKeyValue(row)?.childProfilForm,
            header: `${infoSpace} ${intl.formatMessage({id: messages.schooling_profile})}`,
            size: 120,
        },
        {
            id: 'childForm_childnoteForm',
            accessorFn: (row:any) => callKeyValue(row)?.childnoteForm,
            header: `${infoSpace} ${intl.formatMessage({id: messages.note})}`,
        },

    ];
} ;



export const exportExcelChildPlugin = ({obj={}, columns=[], data=_.cloneDeep(childDataImpl), infoSpace=``, intl={}}
                                           :{obj:any; data:Child; intl:any; infoSpace:string; columns:any[];}) => {

    if(isColumnVisible(columns, 'childForm_childNomPrenomForm')){
        obj[`${infoSpace} ${intl.formatMessage({id: messages.table_h_fullname})}`] = data.childNomPrenomForm ?? ``;
    }
    if(isColumnVisible(columns, 'childForm_childnomForm')){
        obj[`${infoSpace} ${intl.formatMessage({id: messages.table_h_child_lastname})}`] = data.childnomForm;
    }
    if(isColumnVisible(columns, 'childForm_childprenomForm')){
        obj[`${infoSpace} ${intl.formatMessage({id: messages.table_h_child_firstname})}`] = data.childprenomForm;
    }
    if(isColumnVisible(columns, 'childForm_childdatenaissanceForm')){
        obj[`${infoSpace} ${intl.formatMessage({id: messages.table_h_child_birthday})}`] = data.childdatenaissanceForm;
    }
    if(isColumnVisible(columns, 'childForm_childsexeForm')){
        obj[`${infoSpace} ${intl.formatMessage({id: messages.table_h_child_gender})}`] = data.childsexeForm;
    }
    if(isColumnVisible(columns, 'childForm_classeNameForm')){
        obj[`${infoSpace} ${intl.formatMessage({id: messages.classroom_child})}`] = data.classeNameForm;
    }
    if(isColumnVisible(columns, 'childForm_childpaysForm')){
        obj[`${infoSpace} ${intl.formatMessage({id: messages.country})}`] = data.childpaysForm;
    }
    if(isColumnVisible(columns, 'childForm_childemergencyNom1Form')){
        obj[`${infoSpace} ${intl.formatMessage({id:messages.emergency_contact_child})} 1`] = data.childemergencyNom1Form;
    }
    if(isColumnVisible(columns, 'childForm_childemergencyNom2Form')){
        obj[`${infoSpace} ${intl.formatMessage({id:messages.emergency_contact_child})} 2`] = data.childemergencyNom2Form;
    }
    if(isColumnVisible(columns, 'childForm_foodRestriction')){
        obj[`${infoSpace} ${intl.formatMessage({id:messages.food_restriction_child})}`] = data.foodRestriction;
    }
    if(isColumnVisible(columns, 'childForm_healthDetail')){
        obj[`${infoSpace} ${intl.formatMessage({id:messages.health_detail_child})}`] = data.healthDetail;
    }
    if(isColumnVisible(columns, 'childForm_allergy')){
        obj[`${infoSpace} ${intl.formatMessage({id:messages.allergies})}`] = data.allergy;
    }
    if(isColumnVisible(columns, 'childForm_childnoteForm')){
        obj[`${infoSpace} ${intl.formatMessage({id:messages.note})}`] = data.childnoteForm;
    }
    if(isColumnVisible(columns, 'childForm_childProfilForm')){
        obj[`${infoSpace} ${intl.formatMessage({id:messages.schooling_profile})}`] = data.childProfilForm;
    }
    if(isColumnVisible(columns, 'childForm_childMamaNomForm')){
        obj[`${infoSpace} ${intl.formatMessage({id:messages.father_child})}`] = data.childMamaNomForm;
    }
    if(isColumnVisible(columns, 'childForm_childMamanPhoneForm')){
        obj[`${infoSpace} ${intl.formatMessage({id:messages.mother_child})}`] =  data.childMamanPhoneForm;
    }
    if(isColumnVisible(columns, 'childForm_childPapaNomForm')){
        obj[`${infoSpace} ${intl.formatMessage({id:messages.father_child})}`] = data.childPapaNomForm;
    }
    if(isColumnVisible(columns, 'childForm_childPapaPhoneForm')){
        obj[`${infoSpace} ${intl.formatMessage({id:messages.mother_child})}`] =  data.childPapaPhoneForm;
    }
    return obj;
}

export  const childDataImpl: Child = {
    id: null,
    emergencyPhone1: '',
    emergencyPhone2: '',
    emergencyNom1: '',
    emergencyNom2: '',
    foodRestriction: '',
    situationParent: EtatParent.ENSEMBLE,
    healthDetail: '',
    allergy: '',
    person: personDataImpl,
    enfantParents: [],
    droitImages: [],
    eleves: [],
    dossierEnfants: [],
    personneAutoriserEnfants: [],
    enfantProfils: [],
    profils: [],
    santes:  [],
    common: _.cloneDeep(commonDataImp),
    classeNameForm: ``,
    childNomPrenomForm: ``,
    childnomForm: ``,
    childprenomForm: ``,
    childdatenaissanceForm: ``,
    childsexeForm: ``,
    childphotoForm: ``,
    childnoteForm: ``,
    childemergencyNom1Form: ``,
    childemergencyNom2Form: ``,
    childpaysForm: ``,
    childProfilForm: ``,
    childPapaNomForm: ``,
    childPapaPhoneForm: ``,
    childMamaNomForm: ``,
    childMamanPhoneForm: ``,
}


export const childSchema = yup.object().shape({
    id: yup.number().nullable(),
    emergencyPhone1: yup.string().nullable(),
    emergencyPhone2: yup.string().nullable(),
    emergencyNom1: yup.string().nullable(),
    emergencyNom2: yup.string().nullable(),
    situationParent: yup.mixed<EtatParent>().oneOf(Object.values(EtatParent)).nullable(),
    foodRestriction: yup.string().nullable(),
    healthDetail: yup.string().nullable(),
    allergy: yup.string().nullable(),
    person: yup.mixed<Person>().nonNullable(),
    enfantParents: yup.array().of(yup.mixed<EnfantParent>()).nonNullable().default([]),
    droitImages: yup.array().of(yup.mixed<DroitImage>()).nonNullable().default([]),
    eleves: yup.array().of(yup.mixed<Eleve>()).nonNullable().default([]),
    dossierEnfants: yup.array().of(yup.mixed<DossierEnfant>()).nonNullable().default([]),
    personneAutoriserEnfants: yup.array().of(yup.mixed<PersonneAutoriserEnfant>()).nonNullable().default([]),
    enfantProfils: yup.array().of(yup.mixed<EnfantProfils>()).nonNullable().default([]),
    profils: yup.array().of(yup.mixed<Profils>()).nonNullable().default([]),
    santes:  yup.array().of(yup.mixed<Sante>()).nonNullable().default([]),
    photoForm: yup.string(),
    common: yup.mixed<Common>(),
    classeNameForm: yup.string().default(''),
    childNomPrenomForm: yup.string().default(''),
    childnomForm: yup.string().default(''),
    childprenomForm: yup.string().default(''),
    childdatenaissanceForm: yup.string().default(''),
    childsexeForm: yup.string().default(''),
    childphotoForm: yup.string().default(''),
    childnoteForm: yup.string().default(''),
    childemergencyNom1Form: yup.string().default(''),
    childemergencyNom2Form: yup.string().default(''),
    childpaysForm: yup.string().default(''),
    childProfilForm: yup.string().default(''),
    childPapaNomForm: yup.string().default(''),
    childPapaPhoneForm: yup.string().default(''),
    childMamaNomForm: yup.string().default(''),
    childMamanPhoneForm: yup.string().default(''),
});

export type Child = yup.InferType<typeof childSchema>;
