import React, {useCallback, useEffect, useMemo} from 'react'
import PapperBlock from "../../components/ui/papperBlock/PapperBlock";
import {Calendar, momentLocalizer, SlotInfo} from "react-big-calendar";
import {Box, Grid} from "@mui/material";
import 'moment/locale/en-gb';
import 'moment/locale/fr'
import moment from 'moment-timezone';
import appointmentStyles from "./jss/appointment-jss";
import AppointmenPresetToolBar from './AppointmenPresetToolBar';
import {withSnackbar} from "../../components/ui/snackbar/SnackbarHOC";
import PopperBlock from "./PopperBlock";
import PresetForm from "./PresetForm";
import NormalForm from "./NormalForm";
import PresetDetails from "./PresetDetails";
import RdvFilterForm from "./RdvFilterForm";
import useRdvVariables from "./businesslogic/RdvVariables";
import rdvStateUpdate from "./businesslogic/RdvStateUpdate";
import rdvBusinessLogic from "./businesslogic/RdvBusinessLogic";
import rdvToolBarBusinessLogic from "./businesslogic/RdvToolBarBusinessLogic";

require('globalize/lib/cultures/globalize.culture.en-GB');
require('globalize/lib/cultures/globalize.culture.fr');

//const cultures = ['en', 'en-GB', 'es', 'fr', 'ar-AE']
const langOption = {
    en: null,
    'en-GB': null,
    fr: {
        week: 'Semaine',
        work_week: 'Semaine de travail',
        day: 'Jour',
        month: 'Mois',
        previous: 'Antérieur',
        next: 'Prochain',
        today: `Aujourd'hui`,
        agenda: 'Vue d\'ensemble',
        showMore: (total:any) => `+${total} plus`,
    },
}


//TODO: POUR LA LISTE DES RDV - ECRIRE LE NOM DE L'ENFANT ET L'OBJET DU RDV
// TODO: LES RDV DEPUIS LE MOBILE ONT 03 DATES POSSIBLES
//TODO: CHANGER LA COULEUR ROSE POUR UNE AUTRE COULEUR (ROUGE, JAUNE ET BLEUE)
const useBusinessLogic = (props: any): any => {
    const styleClasses = appointmentStyles();
    //moment.tz.setDefault("Africa/Abidjan");
    return {
        theme: props.theme,
        styleClasses: styleClasses,
        ...useRdvVariables({styleClasses, ...props})
    }
};

export const AppointmentPresetContext = React.createContext({});

function Appointment(props:any){
    //const { locale, theme} = props;
    const localizer = momentLocalizer(moment);
    const logicRacine = useBusinessLogic(props);
    //const dispatch = useDispatch();
    const scrolleTimeRef  = React.useRef<Date>(new Date());




    const menuRdvList = useMemo(() => {
        return rdvToolBarBusinessLogic.rdvMenuList(logicRacine);
    }, [logicRacine.rdvList, logicRacine.listItemUpdate]);

    const { currentDate, messages } = useMemo(
        () => ({
            currentDate: new Date(),
            // @ts-ignore
            messages: langOption[logicRacine.activeLanguageRef.current[0]],
        }),
        [logicRacine.activeLanguageRef.current[0]]
    );



/*
    const selectCreneauEventProcessing = useCallback((eventCreneau: CreneauRdv): boolean => {
        return rdvBusinessLogic.selectCreneauEventProcessing(logicRacine, eventCreneau);
    }, [logicRacine.creneauRdvListInCalendar]);*/

    const handleOnNavigate = useCallback((date: Date) => {
        rdvBusinessLogic.handleOnNavigate(logicRacine, date);
            return () => {};
        }, [logicRacine.creneauRdvListInCalendar]
    );
    const handleSelectSlot = useCallback((slotInfo: SlotInfo) => {
        rdvBusinessLogic.handleSelectSlot(logicRacine, slotInfo);
            return () => {};
        }, [logicRacine.creneauRdvListInCalendar]
    );

    useMemo(() => {
        rdvToolBarBusinessLogic.updateCrenoListFromRdvToolBarSelected(logicRacine, logicRacine.rdvToolbarSelect);
        rdvStateUpdate.minuteStep(logicRacine, logicRacine.rdvToolbarSelect.dureeMeetingForm);
        rdvStateUpdate.calendarDate(logicRacine, logicRacine.rdvToolbarSelect.start);
        rdvStateUpdate.minDate(logicRacine, logicRacine.rdvToolbarSelect.start);
        // @ts-ignore
        rdvStateUpdate.maxDate(logicRacine, logicRacine.rdvToolbarSelect.end);

    }, [logicRacine.rdvToolbarSelect]);



    const {components} = useMemo(() => ({
            components: {
                event: ({ event }:{event:any}) => {
                    return <AppointmentPresetContext.Provider value={logicRacine}>
                        <PopperBlock eventCreneau={event} />
                    </AppointmentPresetContext.Provider>
                }
            }
        }),[logicRacine.creneauRdvListInCalendar]
    );
    const {calendarStyle} = useMemo(() => ({
            calendarStyle:  (date: Date) => {
                const thisDay = moment(date);
                let style={};
                if(thisDay.isBetween(logicRacine.rdvToolbarSelect.startForm, logicRacine.rdvToolbarSelect.endForm)){
                    style = {
                        style: {
                            backgroundColor:'#F9FBE7'
                            //'#f7f7fa'
                        }
                    };
                }
                return  style ;
            }
        }),[logicRacine.rdvToolbarSelect]
    );


    useEffect(() => {
        if(logicRacine.pageForm.reload){
            rdvBusinessLogic.initPostConstruct(logicRacine);
        }
    }, [logicRacine.pageForm]);

    useEffect(() => {
        //moment.tz.setDefault("Africa/Abidjan");
        moment.locale(logicRacine.activeLanguageRef.current[0]);
        const scrollMoment = moment(scrolleTimeRef.current);
        scrollMoment.hours(8);
        scrollMoment.minutes(0);
        scrollMoment.seconds(0);
        scrolleTimeRef.current = scrollMoment.toDate();
        rdvBusinessLogic.initPostConstruct(logicRacine);
    }, []);



    // @ts-ignore
    return (
        <PapperBlock whiteBg noMargin displayNoTitle={true}>
            <div className={logicRacine.styleClasses.root}>
                <div className={logicRacine.styleClasses.appointmenToolbar}>
                    <Box>
                        <AppointmentPresetContext.Provider value={logicRacine}>
                            <AppointmenPresetToolBar />
                        </AppointmentPresetContext.Provider>
                    </Box>
                </div>

                <AppointmentPresetContext.Provider value={logicRacine}>
                    <PresetDetails />
                </AppointmentPresetContext.Provider>

               {/* {
                    !logicRacine.showContent &&
                    <Box  sx={{ width: '100%', textAlign: 'center', alignContent:'center', }}>
                        <CircularProgress color="primary" />
                    </Box>

                }
                {
                    logicRacine.showContent &&
                    <Box style={{width:'100%'}}>
                        <Grid container spacing={2}>
                            <Grid item md={logicRacine.openRdvList? 8 : 12} xs={logicRacine.openRdvList? 8 : 12}>
                                <Calendar
                                    localizer={localizer}
                                    style={{ height: 600 }}
                                    ref={logicRacine.calendarRef}
                                    selectable
                                    step={logicRacine.minuteStep}
                                    timeslots={1}
                                    culture={logicRacine.activeLanguageRef.current[0]}
                                    date={logicRacine.calendarDate}
                                    events={logicRacine.creneauRdvListInCalendar}
                                    //onView={logic.handleCalendarView}
                                    messages={messages}
                                    onSelectSlot={handleSelectSlot}
                                    onNavigate={handleOnNavigate}
                                    components={components}
                                    dayPropGetter={calendarStyle}
                                    popup
                                    scrollToTime={scrolleTimeRef.current}
                                />
                            </Grid>
                            {
                                logicRacine.openRdvList &&
                                <Grid item md={4} xs={4}>
                                    {menuRdvList}
                                </Grid>
                            }

                        </Grid>
                    </Box>

                }*/}

                <Box style={{width:'100%'}}>
                    <Grid container spacing={2}>
                        <Grid item md={logicRacine.openRdvList? 8 : 12} xs={logicRacine.openRdvList? 8 : 12}>
                            {
                                // @ts-ignore
                                <Calendar
                                    localizer={localizer}
                                    style={{ height: 600 }}
                                    ref={logicRacine.calendarRef}
                                    selectable
                                    step={logicRacine.minuteStep}
                                    timeslots={1}
                                    culture={logicRacine.activeLanguageRef.current[0]}
                                    date={logicRacine.calendarDate}
                                    events={logicRacine.creneauRdvListInCalendar}
                                    //onView={logic.handleCalendarView}
                                    messages={messages}
                                    onSelectSlot={handleSelectSlot}
                                    onNavigate={handleOnNavigate}
                                    components={components}
                                    dayPropGetter={calendarStyle}
                                    popup
                                    scrollToTime={scrolleTimeRef.current}
                                />
                            }
                        </Grid>
                        {
                            logicRacine.openRdvList &&
                            <Grid item md={4} xs={4}>
                                {menuRdvList}
                            </Grid>
                        }

                    </Grid>
                </Box>
            </div>

            <AppointmentPresetContext.Provider value={logicRacine}>
                <Box>
                    <PresetForm />
                </Box>
            </AppointmentPresetContext.Provider>
            <AppointmentPresetContext.Provider value={logicRacine}>
                <Box>
                    <NormalForm />
                </Box>
            </AppointmentPresetContext.Provider>
            <AppointmentPresetContext.Provider value={logicRacine}>
                <Box>
                    <RdvFilterForm />
                </Box>
            </AppointmentPresetContext.Provider>
        </PapperBlock>
    )
}





/*export const synchroniser = (logicRacine:any, method:string, rdvSaved:Rdv) => {
    let rdvs = _.cloneDeep(logicRacine.rdvsRef.current);

    updateRdvList(logicRacine, rdvs, rdvSaved);
    synchroniserSelected(logicRacine, method, rdvSaved);
}*/
export default withSnackbar(Appointment);
