import {Checkbox, Grid} from "@mui/material";
import PapperBlock from "../papperBlock/PapperBlock";
import {Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {makeStyles} from "@mui/styles";
import React, {useState} from "react";
import Brightness1OutlinedIcon from "@mui/icons-material/Brightness1Outlined";
import Brightness1Icon from '@mui/icons-material/Brightness1';
import {FormattedMessage} from "react-intl";
import messages from "../../../page/messages/general/messages";
import _ from "lodash";


const useStyles = makeStyles((theme:any) => {
    return {
        chartWrap: {
            width: '100%',
            overflow: 'auto',
            margin: theme.spacing(2),
        },
        chartFluid: {
            width: '100%',
            //minWidth: 400,
            height: 300,
            marginLeft: theme.spacing(1) * -3,
        },
        tooltipLabel: {
            fontSize: 'normal'
        }
    }
})

const formatMoney = (tickFormat:number) =>  `${new Intl.NumberFormat('fr-FR').format(tickFormat)}` ;
const reel = 'Reel';
const theorique = 'Theorique';

export default function AnalyticSchoolingChartWidget(props:any){
    const {data, legendOne, legendTwo} = props;
    const classes = useStyles();
    const [indicatorsUnChecked, setIndicatorsUnChecked] = useState<string[]>([]);
    const isTheoriqueChecked = !_.includes(indicatorsUnChecked, theorique) ;
    const isRelChecked = !_.includes(indicatorsUnChecked, reel) ;
    const textDecoTheorique =  isTheoriqueChecked ? {} : {textDecoration:'line-through', opacity:0.8};
    const textDecoReel = isRelChecked  ? {} : {textDecoration:'line-through', opacity:0.8};
    //const dataList: any[] = _.filter(data, item => !_.includes(indicatorsUnChecked, item.id));


    const updateUncheckedIndicators = (id:string) => {
        const isChecked = !_.includes(indicatorsUnChecked, id) ;
        let unCheckedList = _.cloneDeep(indicatorsUnChecked);
        if(isChecked){
            unCheckedList.push(id);
            setIndicatorsUnChecked(unCheckedList);
            return ;
        }
        _.remove(unCheckedList, val => val === id);
        setIndicatorsUnChecked(unCheckedList);
    }

    return (
        <PapperBlock whiteBg noMargin title={<FormattedMessage id={messages.turnover_schooling_compare} />} icon="timeline" desc="">
            <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                    <div className={classes.chartWrap}>
                        <div className={classes.chartFluid}>
                            <ResponsiveContainer width="100%" height="100%">
                                <AreaChart width={730} height={250} data={data}
                                           margin={{ top: 10, right: 50, left: 50, bottom: 10 }}>
                                    <defs>
                                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                                            <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                                        </linearGradient>
                                        <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                                            <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
                                        </linearGradient>
                                    </defs>
                                    <XAxis dataKey="name"  />
                                    <YAxis tickFormatter={formatMoney} />
                                    <CartesianGrid strokeDasharray="3 3" />
                                    {
                                        isTheoriqueChecked &&
                                        <Area type="monotone" dataKey="Theorique"
                                              stroke="#8884d8" fillOpacity={1}
                                              fill="url(#colorUv)" format="number" unit=" FCFA" />
                                    }
                                    {
                                       isRelChecked &&
                                        <Area type="monotone" dataKey="Reel"
                                              stroke="#82ca9d" fillOpacity={1}
                                              fill="url(#colorPv)" format="number" unit=" FCFA"  />
                                    }

                                    <Tooltip formatter={formatMoney} />
                                </AreaChart>
                            </ResponsiveContainer>

                        </div>
                        <div style={{textAlign:"center"}}>
                            <span
                                onClick={event => updateUncheckedIndicators(theorique)}
                                style={{color:'#8884d8', verticalAlign: "middle", cursor:'pointer', ...textDecoTheorique }}>
                                <Checkbox
                                          checked
                                          tabIndex={-1}
                                          icon={<Brightness1OutlinedIcon fontSize="small" />}
                                          checkedIcon={<Brightness1Icon fontSize="small" />} style={{color:'#8884d8'}}
                                /> {legendOne}
                            </span>
                            <span
                                onClick={event => updateUncheckedIndicators(reel)}
                                style={{color:'#82ca9d', verticalAlign: "middle", cursor:'pointer', ...textDecoReel}}>
                                <Checkbox
                                          checked
                                          tabIndex={-1}
                                          icon={<Brightness1OutlinedIcon fontSize="small" />}
                                          checkedIcon={<Brightness1Icon fontSize="small" />} style={{color:'#82ca9d'}}
                                />
                                {legendTwo}
                            </span>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </PapperBlock>
    )
}
