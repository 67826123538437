import {TConnectionFormValues} from "../constants/type/connection/connectionType";
import {getAuthToken, postRequest, putRequest, removeAuthToken} from "../helpers/axios_helpers";
import jwt_decode from "jwt-decode";
import {useNavigate} from "react-router-dom";
import {logoutUser} from "../features/user/userSlice";
import {useDispatch} from "react-redux";
import {clearDataCache} from "../features/cache/remoteDBCacheSlice";
import _ from "lodash";
import {getDataTableStates, initDataTableState} from "../features/datatable/dataTableSlice";

function AuthenticationService() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const onLogout = () => {
        removeAuthToken();
        window.localStorage.setItem("countLoadingPage", '1');
        dispatch(clearDataCache());
        dispatch(logoutUser());
    }

    return {
        authentificateUser: async (data: TConnectionFormValues) => {
            return await postRequest("/public/auth/login/employee", {
                username: _.trim(data.username),
                password: _.trim(data.password)
            });
        },
        checkUserToken: ():boolean => {
            const dataTableStates = getDataTableStates() ?? [];
            dispatch(initDataTableState(dataTableStates));

            const token = getAuthToken();
            if(token) {
                let decodedToken:any = jwt_decode(token);
                let currentDate = new Date();
                // JWT exp is in seconds
                if (decodedToken.exp * 1000 < currentDate.getTime()) {
                    onLogout();
                    return false ;
                }/* else {
                    //navigate('/app/dashboard');
                   // const userRef = React.useRef<any>(useSelector((state:any) => state.user.user));
                    //navigate(userRef.current.role === RoleUser.ADMIN || userRef.current.role === RoleUser.SUPER_ADMIN ? '/app/dashboard' : '/app/child/child-day');
                    //if(url !== null) navigate(url);
                }*/
                return true ;
            }
            onLogout();
            return false ;
        },
        refreshOnLogout: (count:any) => {
            if(count == 1){
                window.localStorage.setItem('countLoadingPage', String(parseInt(count+1)));
                window.location.reload();
            }
            else {
                window.localStorage.removeItem('countLoadingPage');
            }
        },
        updateUserPassword: async (data:any) => {
            return await putRequest("/public/auth/employee/motdepasse", {
                username: data.username,
                password: data.password,
                newPassword: data.newPassword
            });
        }
        /*async authentificateUser(data: TConnectionFormValues): Promise<any> {
            return await postRequest("/public/auth/login/employee", {
                 username: data.username,
                 password: data.password
             });
        },*/
    };
}

export default AuthenticationService;
