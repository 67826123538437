import {getRequest} from "../helpers/axios_helpers";

function ExchangeLibraryService() {
    return {
        getAllBooks: async () => {
            const result = await getRequest(`/extra/bibliotheque`);
            return result._embedded !== undefined ? result._embedded?.livreDTOModelList : [];
        },
        totalBooks: async () => {
            const result = await getRequest(`/extra/bibliotheque/total`);
            return result.data?.content ?? 0;
        },
        totalBooksLoaning: async () => {
            const total:number = await getRequest(`/extra/livremprunt/compterempruntencours`);
            return total;
        }
    }
}

export default ExchangeLibraryService;