import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    menus: [],
    droitMenus:[]
}

const menuSlice = createSlice({
    name: 'menu',
    initialState,
    reducers:{
        getMenus:(state, action) => {
            state.menus = action.payload.user.userDetails.menus;
            state.droitMenus = action.payload.user.userDetails.droitMenus;
        }
    }
})

export const { getMenus } = menuSlice.actions;

export default menuSlice.reducer;
