import {LOCALES} from '../locales';

export default {
    [LOCALES.FRENCH]: {
        'boilerplate.components.Sidebar.dashboard': 'Dashboard',
        'boilerplate.components.Sidebar.config': 'Configuration',
        'boilerplate.components.Sidebar.config.config_submenu': 'Période',
        'boilerplate.components.Sidebar.config.school_period': 'Période scolaire',
        'boilerplate.components.Sidebar.config.school_period.toolbar_top.add_action': 'Ajouter',
        'boilerplate.components.Sidebar.config.school_period.toolbar_top.delete_action': 'Supprimer',

        'boilerplate.components.Sidebar.config.school_day': 'Jour de cours',
        'boilerplate.components.Sidebar.config.personal_type': 'Fonction',
        'boilerplate.components.Sidebar.config.categorielivre': 'Catégorie de livre',
        'boilerplate.components.Sidebar.config.school_document_type': 'Type de document scolaire',
        'boilerplate.components.Sidebar.config.inscription_file_type': 'Type de document d\'inscription',
        'boilerplate.components.Sidebar.config.products': 'Produits',
        'boilerplate.components.Sidebar.config.image_rights': 'Droit à l\'image',
        'boilerplate.components.Sidebar.config.dish_menu': 'Plat',
        'boilerplate.components.Sidebar.config.schooling_payment': 'Scolarité',
        'boilerplate.components.Sidebar.config.classroom': 'Classe',
        'boilerplate.components.Sidebar.config.appointment_type': 'Type de rdv',
        'boilerplate.components.Sidebar.config.timetable_class': 'Horaire/Classe',
        'boilerplate.components.Sidebar.config.schoolfees': 'Type de frais',
        'boilerplate.components.Sidebar.appointment': 'Rendez-vous',
        'boilerplate.components.Sidebar.appointment.appointment_calendar': 'Calendrier des RDV',
        'boilerplate.components.Sidebar.appointment.appointment_list': 'Liste des RDV',
        'boilerplate.components.Sidebar.mesnotifs': 'Mes notifications',
        'boilerplate.components.Sidebar.user_profile': 'Mon profil',
        'boilerplate.components.Sidebar.message_center': 'Message',
        'boilerplate.components.Sidebar.alertmessage': 'Notification',
        'boilerplate.components.Sidebar.school_document': 'Document scolaire',
        'boilerplate.components.Sidebar.folder_type': 'Dossier',
        'boilerplate.components.Sidebar.personal': 'Equipe',
        'boilerplate.components.Sidebar.children': 'Enfant',
        'boilerplate.components.Sidebar.children_list': 'Liste enfant',
        'boilerplate.components.Sidebar.ims_day': 'Ma journée à IMS',
        'boilerplate.components.Sidebar.security_parent': 'Securité',
        'boilerplate.components.Sidebar.week_ims': 'Semaine de la Cantine',
        'boilerplate.components.Sidebar.schooling': 'Scolarité',
        'boilerplate.components.Sidebar.menu_of_year': 'Menu de l\'année',
        'boilerplate.components.Sidebar.image': 'Image',
        'boilerplate.components.Sidebar.my_books': 'Ma Sélection',
        'boilerplate.components.Sidebar.mini_club': 'Mini club',
        'boilerplate.components.Sidebar.exchange_library': 'Bibliothèque',
        'boilerplate.components.Sidebar.marketing': 'Marketing',
        'boilerplate.components.Sidebar.books_mangement': 'Bibliothèque',
        'boilerplate.components.Sidebar.books': 'Livres',
        'boilerplate.components.Sidebar.books_loan': 'Livres empruntés',
        'boilerplate.components.Sidebar.fraisoneshotenfant': 'Frais payable une seule fois par enfant ',
        'boilerplate.components.Sidebar.photo': 'Photos',
        'boilerplate.components.Sidebar.sondage': 'Sondage',

        "boilerplate.components.Widgets.KpIndicatorWidget.dashboard.schooling_dashboard": "Chiffre d'affaire de tous les frais",
        "boilerplate.components.Widgets.KpIndicatorWidget.dashboard.recouvrement_scolarite": "Taux de recouvrement sur la scolarité",
        "boilerplate.components.Widgets.KpIndicatorWidget.dashboard.ecart_theorique_reel": "à recouvrir",
        "boilerplate.components.Widgets.KpIndicatorWidget.dashboard.until_today": "A ce jour",
        "boilerplate.components.Widgets.KpIndicatorWidget.dashboard.incident": "Incident(s)",
        "boilerplate.components.Widgets.KpIndicatorWidget.dashboard.this_week_dashboard": "Cette semaine",
        "boilerplate.components.Widgets.KpIndicatorWidget.dashboard.allergies": "Allergie(s)",
        "boilerplate.components.Widgets.KpIndicatorWidget.dashboard.children_dashboard": "Enfant(s)",
        "boilerplate.components.Widgets.KpIndicatorWidget.dashboard.this_year_dashboard": "Cette année",
        "boilerplate.components.Widgets.KpIndicatorWidget.dashboard.this_day": "Aujourd'hui",
        "boilerplate.components.Widgets.SchoolingIndicator.dashboard.turnover_schooling_compare": "Courbe comparative scolarité C.A. réel / C.A. prévisionnel",
        "boilerplate.components.Widgets.SchoolingIndicator.dashboard.turnover_schooling_forecast": "Chiffre d'affaire prévisionnel",
        "boilerplate.components.Widgets.SchoolingIndicator.dashboard.turnover_schooling_actual": "Chiffre d'affaire réel",
        "boilerplate.components.Widgets.SchoolingIndicator.dashboard.title_amount_box": "Total des paiments scolarité / Frais annexe",
        "boilerplate.components.Widgets.SchoolingIndicator.dashboard.indicator_imsday": "Indicateurs IMS Day",
        "boilerplate.components.Widgets.SchoolingIndicator.dashboard.indicator_imsday_lastdays": "derniers jours",
        "boilerplate.components.Widgets.SchoolingIndicator.dashboard.imsday_indicator_no_sieste": "Nombre de \"Pas de sieste\"",
        "boilerplate.components.Widgets.SchoolingIndicator.dashboard.imsday_indicator_sieste": "Nombre de \"Sieste effectué\"",
        "boilerplate.components.Widgets.SchoolingIndicator.dashboard.imsday_indicator_VERY_WELL": "Nombre de \"J'ai très bien mangé\"",
        "boilerplate.components.Widgets.SchoolingIndicator.dashboard.imsday_indicator_GOOD": "Nombre de \"J'ai bien mangé\"",
        "boilerplate.components.Widgets.SchoolingIndicator.dashboard.imsday_indicator_NOT_AT_ALL": "Nombre de \"J'ai pas mangé\"",
        "boilerplate.components.Widgets.SchoolingIndicator.dashboard.imsday_indicator_NOT_GOOD": "Nombre de \"J'ai peu mangé\"",
        "boilerplate.components.Widgets.SchoolingIndicator.dashboard.imsday_indicator_EASILY": "Nombre de \"J'ai mangé avec facilité\"",
        "boilerplate.components.Widgets.SchoolingIndicator.dashboard.imsday_indicator_bowel_mvt": "Nombre de \"Trouble gastrique\"",
        "boilerplate.components.Widgets.SchoolingIndicator.dashboard.imsday_indicator_incident": "Nombre de \"Incident\"",
        "boilerplate.components.Widgets.SchoolingIndicator.dashboard.total_amount_schooling_and_fees": "Total chiffre d'affaire",
        "boilerplate.components.Widgets.SchoolingIndicator.dashboard.total_amount_schooling": "Total paiment de scolarité",
        "boilerplate.components.Widgets.SchoolingIndicator.dashboard.total_amount_additionalfees": "Total paiment de frais annexe",
        "boilerplate.components.Widgets.SchoolingIndicator.dashboard.student_number_box": "Effectif des enfants par genre",
        "boilerplate.components.Widgets.SchoolingIndicator.dashboard.payment_tuition_type": "Paiement par type de frais",
        "boilerplate.components.Widgets.SchoolingIndicator.dashboard.note_average_box": "Top 5 des moyennes",
        "boilerplate.components.Widgets.SchoolingIndicator.dashboard.appointment_dashboard_box": "Les prochains Rendez-vous",
        "boilerplate.components.Widgets.KpIndicatorWidget.dashboard.exchange_book_list": "Livre(s)",
        "boilerplate.components.Widgets.KpIndicatorWidget.dashboard.books_loaning": "Livre(s) en cours d'emprunt",

        'boilerplate.components.Widgets.page.appointment_calendar.rdv_type': "Type de rendez-vous",
        'boilerplate.components.Widgets.page.appointment_calendar.rdv_read_employee_feedback': "Rendez-vous demandé",
        'boilerplate.components.Widgets.page.appointment_calendar.rdv_read_report_feedback': "Rendez-vous reporté",
        'boilerplate.components.Widgets.page.appointment_calendar.rdv_read_cancel_feedback': "Rendez-vous annulé",
        'boilerplate.components.Widgets.page.appointment_calendar.rdv_read_parent_feedback': "En attente du feedback-parent",
        'boilerplate.components.Widgets.page.appointment_calendar.rdv_read_parent_noresponse_yet': "Le parent n'a pas encore repondu",
        'boilerplate.components.Widgets.page.appointment_calendar.rdv_read_parent_wait_response': "Le parent attend votre réponse",
        'boilerplate.components.Widgets.page.appointment_calendar.rdv_read_employee_which_outcome': "Votre réponse ?",
        'boilerplate.components.Widgets.page.appointment_calendar.rdv_read_title_employee_report': "Vous avez proposé une date",
        'boilerplate.components.Widgets.page.appointment_calendar.rdv_read_title_parent_report': "Le parent a proposé une date",
        'boilerplate.components.Widgets.page.appointment_calendar.rdv_read_title_someone_report': "a proposé une date",
        'boilerplate.components.Widgets.page.appointment_calendar.rdv_read_title_confirm': "Rendez-vous convenu",
        'boilerplate.components.Widgets.page.appointment_calendar.rdv_read_title_employee_cancel': "Vous avez annulé le rendez-vous",
        'boilerplate.components.Widgets.page.appointment_calendar.rdv_read_title_someone_cancel': "a annulé le rendez-vous",
        'boilerplate.components.Widgets.page.appointment_calendar.rdv_read_title_parent_cancel': "Le parent a annulé le rendez-vous",
        'boilerplate.components.Widgets.page.appointment_calendar.rdv_read_title_confirm_rapport': "Rendez-vous convenu",
        'boilerplate.components.Widgets.page.appointment_calendar.rdv_read_title_notheld_rapport': "Rendez-vous non convenu",
        'boilerplate.components.Widgets.page.appointment_calendar.class_effectif': 'Effectif classe',
        'boilerplate.components.Widgets.page.appointment_calendar.rdv_status_libelle': 'Statut',
        'boilerplate.components.Widgets.page.appointment_calendar.rdv_requested_libelle': 'Demandé par',
        'boilerplate.components.Widgets.page.appointment_calendar.comment': 'Commentaire',
        'boilerplate.components.Widgets.page.appointment_calendar.add_normal': 'Normal',
        'boilerplate.components.Widgets.page.appointment_calendar.add_preset': 'Prédéfini',
        'boilerplate.components.Widgets.page.appointment_calendar.type_normal': 'Normal',
        'boilerplate.components.Widgets.page.appointment_calendar.type_preset': 'Prédéfini',
        'boilerplate.components.Widgets.page.appointment_calendar.all_profiles': 'Tous les profils',
        'boilerplate.components.Widgets.page.appointment_calendar.all_classes': 'Toutes les classes',
        'boilerplate.components.Widgets.page.appointment_calendar.user_appointment_list': 'Liste des rendez-vous de :',
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.normal_title": "Rendez-vous normal",
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.choose_interlocutor": "Avec un interlocuteur",
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.choose_child": "Pour un enfant",
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.preset_title": "Rendez-vous prédéfini",
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.rdv_filter_title": "Filtrer les rendez-vous",
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.actions.dialog_post": "Valider",
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.actions.dialog_cancel": "Annuler",
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.actions.dialog_close": "Fermer",
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.radio.appointment_radio": "Rendez-vous",
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.radio.preset_appointment": "Rendz-vous prédéfini",
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.input.title_appointment": "Titre",
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.input.title_document_optional": "Titre document (facultatif)",
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.input.appointment_status": "Statut",
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.input.appointment_deleted": "Effacé",
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.input.appointment_not_held": "Non tenu",
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.input.appointment_cancel": "Annulé",
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.input.appointment_confirm": "Confirmé",
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.input.appointment_past": "Passé",
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.input.appointment_current": "En cours",
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.input.appointment_upcoming": "À venir",
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.input.appointment_creno": "Creneau horaire",
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.input.appointment_done": "Effectué",
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.input.appointment_not_respected": "Non respecté",
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.input.appointment_report": "Reporté",
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.input.appointment_reopen": "Reouvert",
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.input.appointment_report_confirm": "Report confirmé",
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.input.appointment_partial_confirm": "Confirmé en partie",
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.input.appointment_wait": "En attente",
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.input.description_appointment": "Description",
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.input.class_preset": "Classe",
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.input.duration_preset": "Durée (min)",
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.input.deadline_rdv": "Deadline réponse (heure)",
        "boilerplate.components.Widgets.page.appointment_calendar.dialog.form.input.deadline_rdv_date": "Deadline réponse",
        "boilerplate.components.Widgets.page.appointment_list.datagrid.export_xls": "Exporter en xls",
        "boilerplate.components.Widgets.page.message_center.compose_message": "Écrire un message",
        "boilerplate.components.Widgets.page.message_center.compose_ticket": "Ouvrir un nouveau ticket",
        "boilerplate.components.Widgets.page.message_center.conversation_list": "Liste des conversations",
        "boilerplate.components.Widgets.page.message_center.type_message": "Ecrire un message",
        "boilerplate.components.Widgets.page.message_center.chat_list_empty_message": "Vous n'avez pas encore de discussion",
        "boilerplate.components.Widgets.page.message_center.please_write_message": "Veuillez écrire votre message",
        "boilerplate.components.Widgets.page.message_center.please_write_subject_message": "Veuillez écrire l'objet de votre message",
        "boilerplate.components.Widgets.page.message_center.please_select_parent": "Veuillez choisir des parents à qui adresser le message",
        "boilerplate.components.Widgets.page.message_center.opened_space": "Tickets ouverts",
        "boilerplate.components.Widgets.page.message_center.closed_space": "Tickets fermés",
        "boilerplate.components.Widgets.page.message_center.online_space": "En ligne",
        "boilerplate.components.Widgets.page.message_center.offline_space": "Hors ligne",
        "boilerplate.components.Widgets.page.message_center.object_message": "Objet",
        "boilerplate.components.Widgets.page.message_center.close_space": "Fermer le ticket",
        "boilerplate.components.Widgets.page.message_center.delete_space": "Supprimer le ticket",

        'boilerplate.components.Widgets.page.config.school_period.toolbar_top.add_action': 'Ajouter',
        'boilerplate.components.Widgets.page.config.school_period.toolbar_top.generer_action': 'Générer',
        'boilerplate.components.Widgets.page.config.school_period.toolbar_top.delete_action': 'Supprimer',
        'boilerplate.components.Widgets.page.config.school_period.toolbar_top.printer_action': 'Imprimer',
        'boilerplate.components.Widgets.page.config.school_period.table.columns.school_period_year': 'Année',
        'boilerplate.components.Widgets.page.config.school_period.table.columns.school_period': 'Période scolaire',
        'boilerplate.components.Widgets.page.config.school_period.table.columns.start_period': 'Début période',
        'boilerplate.components.Widgets.page.config.school_period.table.columns.end_period': 'Fin période',

        'boilerplate.components.Widgets.page.config.school_period.dialog.form.dialog_title_period': 'Ajouter une période scolaire',
        'boilerplate.components.Widgets.page.config.school_period.dialog.form.dialog_edit_title_period': 'Modifier une période scolaire',
        'boilerplate.components.Widgets.page.config.school_period.dialog.form.year_period': 'Année',
        'boilerplate.components.Widgets.page.config.school_period.dialog.form.title_school_period': 'Période',
        "boilerplate.components.Widgets.page.config.period.school_day_title": "Liste des jours de cours",
        "boilerplate.components.Widgets.page.config.period.day_off": "Jour de repos",
        "boilerplate.components.Widgets.page.config.period.day_open": "Jour de cours",
        'boilerplate.components.Widgets.page.config.canteen_week.dialog.form.dialog_title_canteen_week': 'Ajouter une semaine',
        'boilerplate.components.Widgets.page.config.canteen_week.dialog.form.dialog_edit_title_canteen_week': 'Editer une semaine',
        'boilerplate.components.Widgets.page.config.canteen_week.dialog.form.title_canteen_week': 'Semaine',
        'boilerplate.components.Widgets.page.config.school_period.dialog.form.dialog_title_personal_type': 'Ajouter une fonction',
        'boilerplate.components.Widgets.page.config.school_period.dialog.form.dialog_edit_title_job': 'Editer la fonction',
        'boilerplate.components.Widgets.page.config.school_period.dialog.form.dialog_job_role': 'Rôle',
        'boilerplate.components.Widgets.page.config.school_period.dialog.form.title_personal_type': 'Fonction',
        'boilerplate.components.Widgets.page.config.school_document.dialog.form.dialog_title_school_document_type': 'Ajouter un type document scolaire',
        'boilerplate.components.Widgets.page.config.school_document.dialog.form.dialog_edit_title_school_document_type': 'Editer un type document scolaire',
        'boilerplate.components.Widgets.page.config.school_document.dialog.form.title_school_document_type': 'Type de document scolaire',
        'boilerplate.components.Widgets.page.config.school_document.dialog.form.title_document_type': 'Type de document',
        'boilerplate.components.Widgets.page.config.inscription_file.dialog.form.dialog_title_inscription_file_type': 'Ajouter un type de document d\'inscription',
        'boilerplate.components.Widgets.page.config.inscription_file.dialog.form.dialog_edit_title_inscription_file_type': 'Editer un type de document d\'inscription',
        'boilerplate.components.Widgets.page.config.inscription_file.dialog.form.title_inscription_file_type': 'Type de document d\'inscription',
        'boilerplate.components.Widgets.page.config.inscription_file.dialog.form.inscription_file_type': 'Type fichier',
        'boilerplate.components.Widgets.page.config.folder.sub_folder_type': 'Type de sous dossier',
        'boilerplate.components.Widgets.page.config.folder.dialog.form.dialog_title_sub_folder_type': 'Ajouter  un type de sous dossier ',
        'boilerplate.components.Widgets.page.config.folder.dialog.form.dialog_edit_title_sub_folder_type': 'Editer un type de sous dossier ',
        'boilerplate.components.Widgets.page.config.folder.folder_type': 'Type dossier',
        'boilerplate.components.Widgets.page.config.folder.dialog.form.dialog_title_folder_type': 'Ajouter un type de dossier',
        'boilerplate.components.Widgets.page.config.folder.dialog.form.dialog_edit_title_folder_type': 'Editer un type de dossier',
        'boilerplate.components.Widgets.page.config.folder.dialog.form.dialog_folder_type_file': 'Fichiers constituant le type de dossier',
        'boilerplate.components.Widgets.page.config.image_rights.table_columns.image_rights': 'Droit à l\'image',
        'boilerplate.components.Widgets.page.config.image_rights.dialog.form.dialog_title_image_right': 'Ajouter un droit à l\'image',
        'boilerplate.components.Widgets.page.config.image_rights.dialog.form.dialog_edit_title_image_right': 'Editer un droit  à l\'image',


        'boilerplate.components.Widgets.page.config.dish.dialog.form.dialog_title_dish_config': 'Ajouter un plat',
        'boilerplate.components.Widgets.page.config.dish.dialog.form.dialog_title_edit_dish': 'Modifier un plat',
        'boilerplate.components.Widgets.page.config.dish.dialog.form.dish_type_config': 'Type plat',
        'boilerplate.components.Widgets.page.config.dish.dialog.form.title_dish_config': 'Nom',
        'boilerplate.components.Widgets.page.config.appointment_type.table_columns.appointment_type': 'Type de rdv',
        'boilerplate.components.Widgets.page.config.appointment_type.dialog.form.title_appoint_type_config': 'Ajouter un type de rdv',
        'boilerplate.components.Widgets.page.config.appointment_type.dialog.form.edit_title_appoint_type_config': 'Modifier un type de rdv',
        'boilerplate.components.Widgets.page.config.appointment_type.dialog.form.input_appoint_type': 'Type de rdv',

        'boilerplate.components.Widgets.page.config.schooling.proforma_du': 'Proforma du',
        'boilerplate.components.Widgets.page.config.schooling.generer_facture': 'GÉNÉRER DES FACTURES',
        'boilerplate.components.Widgets.page.config.schooling.notifier_parent': 'NOTIFIER LES PARENTS',
        'boilerplate.components.Widgets.page.config.schooling.schooling_echeance_label': 'Libellé de l\'échéance',
        'boilerplate.components.Widgets.page.config.schooling.schooling_add_payment': 'Ajouter un paiement',
        'boilerplate.components.Widgets.page.config.schooling.schooling_payment_list': 'Liste des paiements',
        'boilerplate.components.Widgets.page.config.schooling.schooling_proforma_invoice': 'Facture proforma',
        'boilerplate.components.Widgets.page.config.schooling.schooling_fees_label': 'Libellé du frais',
        'boilerplate.components.Widgets.page.config.schooling.schooling_payment_period': 'Période de paiement de scolarité',
        'boilerplate.components.Widgets.page.config.schooling.fees': 'Frais',
        'boilerplate.components.Widgets.page.config.schooling.schooling_fees': 'Frais annexe de scolarité',
        'boilerplate.components.Widgets.page.config.schooling.class_fees': 'Frais par groupe',
        'boilerplate.components.Widgets.page.config.schooling.schooling_fees_title': 'Frais de scolarité',
        'boilerplate.components.Widgets.page.config.schooling.schooling_fees_type': 'Type de frais',
        'boilerplate.components.Widgets.page.config.schooling.schooling_versement_etat': 'ETAT DES PAIEMENTS',
        'boilerplate.components.Widgets.page.config.schooling.schooling_total_payment': 'TOTAL PAIEMENT',
        'boilerplate.components.Widgets.page.config.schooling.schooling_restant_apayer': 'RESTE A PAYER',
        'boilerplate.components.Widgets.page.config.schooling.schooling_total_montant': 'MONTANT',
        'boilerplate.components.Widgets.page.config.schooling.schooling_total_payment_done': 'PAIEMENT EFFECTUE',
        'boilerplate.components.Widgets.page.config.schooling.schooling_total_new_payment': 'PAIEMENT ACTUEL',
        'boilerplate.components.Widgets.page.config.schooling.schooling_total_restant': 'RESTE A PAYER',
        'boilerplate.components.Widgets.page.config.schooling.schooling_save_payment': 'Enregistrer le paiement',
        'boilerplate.components.Widgets.page.config.schooling.schooling_fees_title_custom': 'Frais annexe particulier',
        'boilerplate.components.Widgets.page.config.schooling.schooling_other_fees_list': 'Liste des frais',
        'boilerplate.components.Widgets.page.config.schooling.schooling': 'Scolarité',
        'boilerplate.components.Widgets.page.config.schooling.dialog.form.dialog_title_schooling': 'Ajouter une scolarité',
        'boilerplate.components.Widgets.page.config.schooling.dialog.form.dialog_edit_title_schooling': 'Modifier une scolarité',
        'boilerplate.components.Widgets.page.config.schooling.dialog.form.input.schooling_profile': 'Profil',
        'boilerplate.components.Widgets.page.config.schooling.dialog.form.input.schooling_amount': 'Montant',
        'boilerplate.components.Widgets.page.config.schooling.table_grid.schooling_period_payment': 'Date limite',
        'boilerplate.components.Widgets.page.config.schooling.table_grid.payment_date_limite': 'Date limite de paiement',
        'boilerplate.components.Widgets.page.config.schooling.table_grid.date_limite': 'Délai paiement',
        'boilerplate.components.Widgets.page.config.schooling.table_grid.schooling_rappel_payment': 'Libellé',
        'boilerplate.components.Widgets.page.config.schooling.dialog.form.dialog_title_schooling_payment_period': 'Ajouter une période paiement',
        'boilerplate.components.Widgets.page.config.schooling.dialog.form.dialog_edit_title_schooling_payment_period': 'Modifier  une période paiement',
        'boilerplate.components.Widgets.page.config.schooling.schooling_fees.table_grid.schooling_fees_obligatory': 'Obligatoire',
        'boilerplate.components.Widgets.page.config.schooling.schooling_fees.table_grid.schooling_fees_echeance': 'Echéance',
        'boilerplate.components.Widgets.page.config.schooling.schooling_fees.dialog.form.dialog_title_additional_fees': 'Ajouter un frais annexe',
        'boilerplate.components.Widgets.page.config.schooling.schooling_fees.dialog.form.dialog_edit_title_additional_fees': 'Modifier un frais annexe',
        'boilerplate.components.Widgets.page.config.schooling.schooling_fees.dialog.form.input.additional_fees_title': 'Libellé du frais annexe',

        'boilerplate.components.Widgets.page.profile.title_password_profile': 'Mettre à jour le mot de passe',
        'boilerplate.components.Widgets.page.profile.username_profile': 'Nom d\'utilisateur',
        'boilerplate.components.Widgets.page.profile.old_password_profile': 'Ancien mot de passe',
        'boilerplate.components.Widgets.page.profile.new_password_profile': 'Nouveau mot de passe',
        'boilerplate.components.Widgets.page.profile.confirm_new_password_profile': 'Confirmer le nouveau mot de passe',
        'boilerplate.components.Widgets.page.profile.password_not_match_profile': 'Les mots de passes ne correspondent pas',
        'boilerplate.components.Widgets.page.profile.title_profile': 'Profil',

        'boilerplate.components.Widgets.page.personal.dialog.form.dialog_title_personal': 'Ajouter un membre de l\'équipe',
        'boilerplate.components.Widgets.page.personal.dialog.form.dialog_title_edit_personal': 'Modifier une information',
        'boilerplate.components.Widgets.page.personal.dialog.form.firstname_personal': 'Prénom(s)',
        'boilerplate.components.Widgets.page.personal.dialog.form.lastname_personal': 'Nom',
        'boilerplate.components.Widgets.page.personal.dialog.form.birth_date_personal': 'Date de naissance',
        'boilerplate.components.Widgets.page.personal.dialog.form.hire_date_personal': 'Date d\'embauche',
        'boilerplate.components.Widgets.page.personal.dialog.form.email_personal': 'Email',
        'boilerplate.components.Widgets.page.personal.dialog.form.phone_number_personal': 'Numéro de téléphone',
        'boilerplate.components.Widgets.page.personal.dialog.form.type_personal': 'Type employé',
        'boilerplate.components.Widgets.page.personal.dialog.form.genre_personal': 'Genre',
        'boilerplate.components.Widgets.page.personal.dialog.form.more_info_personal': 'Informations complementaires',
        'boilerplate.components.Widgets.page.personal.dialog.form.job_name_personal': 'Fonction',
        'boilerplate.components.Widgets.page.personal.dialog.form.post_personal': 'Poste occupé',
        'boilerplate.components.Widgets.page.personal.table.header.table_h_person_avatar': 'Avatar',
        'boilerplate.components.Widgets.page.personal.table.header.table_h_person_email': 'Email',
        'boilerplate.components.Widgets.page.personal.table.header.table_h_person_phone': 'Numéro de téléphone',
        'boilerplate.components.Widgets.page.personal.table.header.table_h_person_job': 'Fonction',
        'boilerplate.components.Widgets.page.personal.table.header.table_h_account_status': 'Statut du compte',

        'boilerplate.components.Widgets.page.children.dialog.form.dialog_title_child': 'Ajouter un enfant',
        'boilerplate.components.Widgets.page.children.dialog.form.dialog_title_edit_child': 'Modifier un enfant',
        'boilerplate.components.Widgets.page.children.dialog.form.identity_child': 'Identité',
        'boilerplate.components.Widgets.page.children.dialog.form.parent_child': 'Parent(s)',
        'boilerplate.components.Widgets.page.children.dialog.form.other_details_child': 'Autres informations',
        'boilerplate.components.Widgets.page.children.dialog.form.emergency_child': 'Urgence',
        'boilerplate.components.Widgets.page.children.dialog.form.emergency_contact_child': 'Contact(s) d\'urgence',
        'boilerplate.components.Widgets.page.children.dialog.form.firstname_child': 'Prénom(s)',
        'boilerplate.components.Widgets.page.children.dialog.form.lastname_child': 'Nom',
        'boilerplate.components.Widgets.page.children.dialog.form.birth_date_child': 'Date de naissance',
        'boilerplate.components.Widgets.page.children.dialog.form.classroom_child': 'Classe',
        'boilerplate.components.Widgets.page.children.dialog.form.scolaritee_annee': 'Scolarité',
        'boilerplate.components.Widgets.page.children.dialog.form.nationality_child': 'Nationalité',
        'boilerplate.components.Widgets.page.children.dialog.form.country': 'Pays',
        'boilerplate.components.Widgets.page.children.dialog.form.genre_child': 'Sexe',
        'boilerplate.components.Widgets.page.children.dialog.form.address_child': 'Addresse',
        'boilerplate.components.Widgets.page.children.dialog.form.phone_number_child': 'Numéro',
        'boilerplate.components.Widgets.page.children.dialog.form.email_child': 'Email',
        'boilerplate.components.Widgets.page.children.dialog.form.father_child': 'Père',
        'boilerplate.components.Widgets.page.children.dialog.form.father_phone_child': 'Père numéro',
        'boilerplate.components.Widgets.page.children.dialog.form.mother_child': 'Mère',
        'boilerplate.components.Widgets.page.children.dialog.form.mother_phone_child': 'Mère numéro',
        'boilerplate.components.Widgets.page.children.dialog.form.health_detail_child': 'Détails sur la santé de l\'enfant',
        'boilerplate.components.Widgets.page.children.dialog.form.allergies_child': 'Allergies',
        'boilerplate.components.Widgets.page.children.dialog.form.food_restriction_child': 'Restriction alimentaire',
        'boilerplate.components.Widgets.page.children.dialog.form.health_issue_child': 'Problème de santé',
        'boilerplate.components.Widgets.page.children.dialog.form.siblings_child': 'Fratrie',
        'boilerplate.components.Widgets.page.children.dialog.form.empty_siblings': 'Pas de fratrie',
        'boilerplate.components.Widgets.page.children.dialog.form.registration_file_child': 'Dossiers d\'inscription',
        'boilerplate.components.Widgets.page.children.dialog.form.select_file_child': 'Sélectionnez un fichier',
        'boilerplate.components.Widgets.page.children.dialog.form.image_rights_child': 'Droit à l\'image',
        'boilerplate.components.Widgets.page.children.form.return_children_list': 'Liste des enfants',
        'boilerplate.components.Widgets.page.children.form.main_parent': 'Interlocuteur principal',
        'boilerplate.components.Widgets.page.children.form.all_mother_information': 'Toutes les informations de la mère sont obligatoires',
        'boilerplate.components.Widgets.page.children.form.all_father_information': 'Toutes les informations du père sont obligatoires',
        'boilerplate.components.Widgets.page.children.form.incorrect_email': 'Format d\'email incorrect',
        'boilerplate.components.Widgets.page.children.form.warning_child_registration': 'Évitez de renseigner plusieurs fois le même email',

        'boilerplate.components.Widgets.page.menu_year.tab_panel.empty_tab': 'Aucun menu défini',
        'boilerplate.components.Widgets.page.menu_year.dialog.form.add_to_menu_year': 'Ajouter au menu',
        'boilerplate.components.Widgets.page.menu_year.dialog.form.add_new_menu_year': 'Créer un menu',
        'boilerplate.components.Widgets.page.menu_year.dialog.form.edit_menu_year': 'Modifier un menu',
        'boilerplate.components.Widgets.page.menu_year.dialog.form.add_dish_year': 'Ajouter un plat',
        'boilerplate.components.Widgets.page.menu_year.dialog.form.pause_menu_year': 'Pause',
        'boilerplate.components.Widgets.page.menu_year.dialog.form.play_menu_year': 'Demarrer',
        'boilerplate.components.Widgets.page.menu_year.dialog.form.total_menu_year': 'Total',
        'boilerplate.components.Widgets.page.menu_year.dialog.form.starter_menu_year': 'Entrée',
        'boilerplate.components.Widgets.page.menu_year.dialog.form.dish_menu_year': 'Plat',
        'boilerplate.components.Widgets.page.menu_year.dialog.form.dessert_menu_year': 'Dessert',
        'boilerplate.components.Widgets.page.menu_year.dialog.form.generate_menu_other_week': 'Générer des semaines et des menus',
        'boilerplate.components.Widgets.page.classroom.dialog.form.dialog_title_classroom': 'Ajouter une classe',
        'boilerplate.components.Widgets.page.classroom.dialog.form.dialog_edit_title_classroom': 'Editer une classe',
        'boilerplate.components.Widgets.page.classroom.dialog.form.dialog_input_classroom': 'Classe',
        'boilerplate.components.Widgets.page.classroom.dialog.form.dialog_table_header_classroom': 'Classe',
        'boilerplate.components.Widgets.page.children.dialog.form.emergency_contact_one_child': 'Nom & prénom(s)',
        'boilerplate.components.Widgets.page.children.dialog.form.emergency_contact_one_phone_child': 'Numéro',
        'boilerplate.components.Widgets.page.children.dialog.form.emergency_contact_two_child': 'Nom & prénom(s)',
        'boilerplate.components.Widgets.page.children.dialog.form.emergency_contact_two_phone_child': 'Numéro',
        'boilerplate.components.Widgets.page.children.dialog.form.authorization_person_child': 'Personne(s) autorisée(s)',
        'boilerplate.components.Widgets.page.children.dialog.form.add_person_authorization_child': 'Ajouter une personne',
        'boilerplate.components.Widgets.page.children.dialog.form.required_field_child': 'Champ requis',
        'boilerplate.components.Widgets.page.children.table.header.table_h_ims_day_naptime': 'Période repos',
        'boilerplate.components.Widgets.page.children.table.header.table_h_ims_day_eat': 'Ai-je déjeuné ?',
        'boilerplate.components.Widgets.page.children.table.header.table_h_ims_day_date': 'Date',
        'boilerplate.components.Widgets.page.children.table.header.table_h_ims_day_obs_general': 'Observation',
        'boilerplate.components.Widgets.page.children.table.header.table_h_child_avatar': 'Photo',
        'boilerplate.components.Widgets.page.children.table.header.table_h_child_firstname': 'Prénom(s)',
        'boilerplate.components.Widgets.page.children.table.header.table_h_child_name': 'Nom',
        'boilerplate.components.Widgets.page.children.table.header.table_h_child_lastname': 'Nom',
        'boilerplate.components.Widgets.page.children.table.header.table_h_fullname': 'Nom Prénom',
        'boilerplate.components.Widgets.page.children.table.header.table_h_child_birthday': 'Date de naissance',
        'boilerplate.components.Widgets.page.children.table.header.table_h_child_gender': 'Genre',
        'boilerplate.components.Widgets.page.children.table.header.table_h_child_classroom': 'Classe',
        'boilerplate.components.Widgets.page.children.dialog.form.profile_child': 'Profil',

        'boilerplate.components.Widgets.page.mini_club.form.dialog_tab_hd_club_illustration': 'Illustration',
        'boilerplate.components.Widgets.page.mini_club.form.dialog_tab_hd_club_title': 'Titre',
        'boilerplate.components.Widgets.page.mini_club.form.dialog_tab_hd_club_time': 'Heure',
        'boilerplate.components.Widgets.page.mini_club.form.dialog_tab_hd_club_start_time': 'Heure debut',
        'boilerplate.components.Widgets.page.mini_club.form.dialog_tab_hd_club_end_time': 'Heure fin',
        'boilerplate.components.Widgets.page.mini_club.form.dialog_tab_hd_club_description': 'Description',
        'boilerplate.components.Widgets.page.mini_club.form.dialog_tab_hd_club_number': 'Inscrit(s)',
        'boilerplate.components.Widgets.page.mini_club.form.dialog_tab_hd_club_price': 'Prix (CFA)',
        'boilerplate.components.Widgets.page.mini_club.form.dialog_modal_club_title': 'Ajouter un mini club',
        'boilerplate.components.Widgets.page.mini_club.form.dialog_modal_club_limit': 'Limite',
        'boilerplate.components.Widgets.page.mini_club.form.return_mini_club_list': 'Liste des mini clubs',
        'boilerplate.components.Widgets.page.mini_club.form.save_edit_mini_club_list': 'Sauvegarder',
        'boilerplate.components.Widgets.page.mini_club.form.edit_mini_club_list': 'Editer mini club',
        'boilerplate.components.Widgets.page.mini_club.form.miniclub': 'Mini club',
        'boilerplate.components.Widgets.page.mini_club.form.registered_mini_club_list': 'Liste des inscrits',
        'boilerplate.components.Widgets.page.mini_club.form.empty_registered_mini_club': 'Aucune inscription pour l\'instant',

        'boilerplate.components.Widgets.page.marketing.table.marketing_product_photo': 'Photo',
        'boilerplate.components.Widgets.page.marketing.table.marketing_product_nom': 'Nom',
        'boilerplate.components.Widgets.page.marketing.table.marketing_product_pix': 'Prix',
        'boilerplate.components.Widgets.page.marketing.table.marketing_product_quantity': 'Quantité',
        'boilerplate.components.Widgets.page.marketing.table.marketing_product_delivery': 'Produit livré',
        'boilerplate.components.Widgets.page.marketing.table.marketing_product_delete': 'Supprimer la commande',
        'boilerplate.components.Widgets.page.marketing.table.marketing_product_command': 'Commande',
        'boilerplate.components.Widgets.page.marketing.form.dialog_modal_title': 'Ajout de produit',
        'boilerplate.components.Widgets.page.marketing.form.dialog_modal_edit_title': 'Edition de produit',
        'boilerplate.components.Widgets.page.marketing.form.return_product_list': 'Liste des produits',
        'boilerplate.components.Widgets.page.marketing.form.orders_product_list': 'Liste des commandes',
        'boilerplate.components.Widgets.page.marketing.form.empty_order_product': 'Aucune commande pour l\'instant',
        'boilerplate.components.Widgets.page.marketing.form.order_identity_product': 'Parent de ',
        'boilerplate.components.Widgets.page.marketing.form.date_order_product': 'Date',


        'boilerplate.components.Widgets.page.bibliotheque.table.header.table_h_available': 'Disponible',
        'boilerplate.components.Widgets.page.bibliotheque.table.header.table_h_parent': 'Parent',
        'boilerplate.components.Widgets.page.bibliotheque.table.header.table_h_enfant': 'Enfant',
        'boilerplate.components.Widgets.page.bibliotheque.table.header.table_h_nom': 'Titre',
        'boilerplate.components.Widgets.page.bibliotheque.table.header.table_h_livre': 'Livre',
        'boilerplate.components.Widgets.page.bibliotheque.table.header.table_h_emprunt': 'Emprunt',
        'boilerplate.components.Widgets.page.bibliotheque.table.header.table_h_horsdelai': 'Hors délai',
        'boilerplate.components.Widgets.page.bibliotheque.table.header.table_h_emprunt_retour': 'Retourné le',
        'boilerplate.components.Widgets.page.bibliotheque.table.header.table_h_emprunt_datedemande': 'Demandé le',
        'boilerplate.components.Widgets.page.bibliotheque.table.header.table_h_emprunt_date': 'Emprunté le',
        'boilerplate.components.Widgets.page.bibliotheque.table.header.table_h_emprunt_deadline': 'Deadline',
        'boilerplate.components.Widgets.page.bibliotheque.table.header.table_h_catlivre': 'Catégorie livre',
        'boilerplate.components.Widgets.page.bibliotheque.table.header.table_h_auteur': 'Auteur',
        'boilerplate.components.Widgets.page.bibliotheque.table.header.table_h_agemin': 'Age min',
        'boilerplate.components.Widgets.page.bibliotheque.table.header.table_h_agemax': 'Age max (an)',
        'boilerplate.components.Widgets.page.bibliotheque.table.header.table_h_langue': 'Langue',
        'boilerplate.components.Widgets.page.bibliotheque.table.header.table_h_empruntmaxjour': 'Jour max d\'emprunt',
        'boilerplate.components.Widgets.page.bibliotheque.table.header.table_h_prix': 'Prix (Fcfa)',
        'boilerplate.components.Widgets.page.bibliotheque.table.header.table_h_quantite': 'Quantite',
        'boilerplate.components.Widgets.page.bibliotheque.table.header.table_h_photo': 'Photo',

        "boilerplate.components.Widgets.system.ui.historical_loaned": "Historique des livres empruntés",
        'boilerplate.components.Widgets.system.ui.personal_member': 'Membre de l\'équipe',
        'boilerplate.components.Widgets.system.ui.return_list': 'Afficher la liste',
        'boilerplate.components.Widgets.system.ui.table_h_agent_caisse': 'Agent caisse',
        'boilerplate.components.Widgets.system.ui.table_h_montant': 'Montant',
        'boilerplate.components.Widgets.system.ui.loading_list_for_notifications': 'Charger la liste',
        'boilerplate.components.Widgets.system.ui.fees_type': 'Type de frais',
        'boilerplate.components.Widgets.system.ui.table_h_echeancier_date': 'Date',
        'boilerplate.components.Widgets.system.ui.table_h_echeancier_nom': 'Echéancier',
        'boilerplate.components.Widgets.system.ui.table_h_echeancier_type': 'Type d\'échéancier',
        'boilerplate.components.Widgets.system.ui.table_h_profil': 'Profil',
        'boilerplate.components.Widgets.system.ui.table_h_group': 'Groupe',
        'boilerplate.components.Widgets.system.ui.table_h_scolarite': 'Scolarité',
        "boilerplate.components.Widgets.system.ui.books_loan_requested": "Demande d'emprunt de livre",
        "boilerplate.components.Widgets.system.ui.biblio_quantitedispo": "livre(s) disponible(s)",
        "boilerplate.components.Widgets.system.ui.en_stock": "en stock",
        "boilerplate.components.Widgets.system.ui.bibliotheque": "Bibliothèque",
        "boilerplate.components.Widgets.system.ui.donateur": "Donateurs",
        "boilerplate.components.Widgets.system.ui.mes_livres_empruntes": "Les livres empruntés",
        "boilerplate.components.Widgets.system.ui.disponible": "Disponible",
        "boilerplate.components.Widgets.system.ui.indisponible": "Indisponible",
        "boilerplate.components.Widgets.system.ui.description": "Description",
        "boilerplate.components.Widgets.system.ui.emprunter": "Emprunter",
        "boilerplate.components.Widgets.system.ui.biblio_edit_book": "Edition de livre",
        "boilerplate.components.Widgets.system.ui.biblio_catlivre": "Catégorie de livre",
        "boilerplate.components.Widgets.system.ui.book_loan_valid": "Livre donné le",
        "boilerplate.components.Widgets.system.ui.book_loan_retour_valid": "Livre retourné",
        "boilerplate.components.Widgets.system.ui.biblio_livre": "Livre",
        "boilerplate.components.Widgets.system.ui.biblio_livre_delete": "Suppresion des livres",
        "boilerplate.components.Widgets.system.ui.emprunt_delete_title": "Suppresion d'un emprunt",
        "boilerplate.components.Widgets.system.ui.emprunteur": "Demandeur",
        "boilerplate.components.Widgets.system.ui.selection_delete": "Suppresion",
        "boilerplate.components.Widgets.system.ui.selection_to_delete": "Souhaitez-vous supprimer votre sélection ?",
        "boilerplate.components.Widgets.system.ui.selection_edition": "Edition de la sélection",
        "boilerplate.components.Widgets.system.ui.add_item": "Effectuer un ajout",
        "boilerplate.components.Widgets.system.ui.add_children": "Ajout d'enfants",
        "boilerplate.components.Widgets.system.ui.generate_financial_schedule": "Générer des échéanciers",
        "boilerplate.components.Widgets.system.ui.emprunter_book": "Emprunt du livre",
        "boilerplate.components.Widgets.system.ui.emprunt_sentence_sujet": "Nombre d'exemplaire que je souhaite emprunter",
        "boilerplate.components.Widgets.system.ui.scolarite_typepaiement": "Les frais",
        "boilerplate.components.Widgets.system.ui.scolarite_echeancier": "Les échéanciers",
        "boilerplate.components.Widgets.system.ui.scolarite_echeancier_enfant": "Les échéanciers par enfant",
        "boilerplate.components.Widgets.system.ui.groups": "Les groupes",
        "boilerplate.components.Widgets.system.ui.the_group": "Le groupe",
        "boilerplate.components.Widgets.system.ui.load_children": "Charger les enfants",
        "boilerplate.components.Widgets.system.ui.groupsenfants": "Les enfants par groupe",
        "boilerplate.components.Widgets.system.ui.fraisoneshot_enfant": "Frais unique par enfant",
        "boilerplate.components.Widgets.system.ui.matchingfraisoneshot_enfant": "Frais unique groupé",
        "boilerplate.components.Widgets.system.ui.biblio_livre_todelete": "Livre(s) à supprimer",
        "boilerplate.components.Widgets.system.ui.biblio_edit_folder": "Édition du livre",
        "boilerplate.components.Widgets.system.ui.doc_file": "Fichier(s)",
        "boilerplate.components.Widgets.system.ui.doc_folder_todelete": "Supprimer le dossier",
        "boilerplate.components.Widgets.system.ui.doc_file_todelete": "Supprimer le fichier",
        "boilerplate.components.Widgets.system.ui.doc_upload_file": "Uploader un fichier",
        "boilerplate.components.Widgets.system.ui.doc_folder_delete": "Suppression d'un dossier",
        "boilerplate.components.Widgets.system.ui.gestion_compte": "Gestion des comptes",
        "boilerplate.components.Widgets.system.ui.doc_file_delete": "Suppression d'un fichier",
        "boilerplate.components.Widgets.system.ui.doc_folder": "Dossier",
        "boilerplate.components.Widgets.system.ui.doc_edit_folder": "Édition du dossier",
        "boilerplate.components.Widgets.system.ui.doc_add_folder": "Ajouter un dossier",
        "boilerplate.components.Widgets.system.ui.doc_del_folder": "Supprimer le dossier",
        "boilerplate.components.Widgets.system.ui.doc_update_folder": "Mettre à jour le dossier",
        "boilerplate.components.Widgets.system.ui.doc_del_file": "Supprimer le fichier",
        "boilerplate.components.Widgets.system.ui.doc_add_file": "Ajouter un fichier",
        "boilerplate.components.Widgets.system.ui.ims_day_bowel": "Toilettes",
        "boilerplate.components.Widgets.system.ui.ims_day_bowel_many": "Combien de fois par jour ?",
        "boilerplate.components.Widgets.system.ui.ims_day_week_ims": "Semaine cantine",
        "boilerplate.components.Widgets.system.ui.ims_day_sieste_true": "Oui, j'ai fait la sieste",
        "boilerplate.components.Widgets.system.ui.ims_day_sieste_false": "Non, je n'ai pas fait la sieste",
        'boilerplate.components.Widgets.system.ui.return_to_list': 'Afficher la liste',
        'boilerplate.components.Widgets.system.ui.period': 'Période',
        'boilerplate.components.Widgets.system.ui.rdv_list': 'Liste des RDV',
        'boilerplate.components.Widgets.system.ui.oui': 'Oui',
        'boilerplate.components.Widgets.system.ui.non': 'Non',

        "boilerplate.components.Widgets.system.ui.tooltip_generate_account": "Les comptes seront actifs. De nouveaux noms utilisateurs seront générés. De nouveaux mots de passe seront générés. Un message contenant de nouveaux accès sera envoyé par courriel.",
        "boilerplate.components.Widgets.system.ui.tooltip_activate_account": "Les comptes seront actifs. De nouveaux mots de passe seront générés. Un message contenant les accès sera envoyé par courriel.",
        "boilerplate.components.Widgets.system.ui.tooltip_suspended_account": "Les comptes seront suspendus. Les utilisateurs ne pourront pas se connecter. De nouvelles informations liées aux utilisateurs suspendus seront sauvegardées et visibles une fois les comptes réactivés. Seuls les comptes suspendus pourront-être activés",
        "boilerplate.components.Widgets.system.ui.tooltip_deleted_account": "Les comptes seront supprimés. Les utilisateurs ne pourront pas se connecter.",
        "boilerplate.components.Widgets.system.ui.teacher": "Educateur(rice)",
        "boilerplate.components.Widgets.system.ui.all_label": "Tous",
        "boilerplate.components.Widgets.system.ui.demandeur": "Demandeur",
        "boilerplate.components.Widgets.system.ui.all_label_rdv": "Tous les rendez-vous",
        "boilerplate.components.Widgets.system.ui.mes_notifications": "Toutes mes notifications",
        "boilerplate.components.Widgets.system.ui.paiement_mode_numero_placeholder": "Numéro du bordereau, chèque ou virement",
        "boilerplate.components.Widgets.system.ui.paiement_mode_numero": "Code du paiement",
        "boilerplate.components.Widgets.system.ui.paiement_mode_date": "Date d'opération du mode de paiement",
        "boilerplate.components.Widgets.system.ui.paiement_mode": "Mode de paiement",
        "boilerplate.components.Widgets.system.ui.paiement_cheque": "Chèque",
        "boilerplate.components.Widgets.system.ui.paiement_espece": "Espèce",
        "boilerplate.components.Widgets.system.ui.paiement_virement": "Virement",
        "boilerplate.components.Widgets.system.ui.paiement_by": "Payé par",
        "boilerplate.components.Widgets.system.ui.paiement_date": "Date de paiement",
        "boilerplate.components.Widgets.system.ui.survey_list": "Les sondages",
        "boilerplate.components.Widgets.system.ui.add_survey_list": "Ajouter un sondage",
        "boilerplate.components.Widgets.system.ui.open_survey_list": "Sondage ouverts",
        "boilerplate.components.Widgets.system.ui.close_survey_list": "Sondage fermés",
        "boilerplate.components.Widgets.system.ui.gestion_compte_parent": "Gestion des comptes parents",
        "boilerplate.components.Widgets.system.ui.gestion_compte_equipe": "Gestion des comptes de l'équipe",
        "boilerplate.components.Widgets.system.ui.accountetat_ok": "Actif",
        "boilerplate.components.Widgets.system.ui.accountetat_locked": "Fermer",
        "boilerplate.components.Widgets.system.ui.accountetat_pending_validation": "Inactif",
        "boilerplate.components.Widgets.system.ui.accountetat_suspended": "Suspendu",
        "boilerplate.components.Widgets.system.ui.generer_compte_question": "Souhaitez-vous générer des comptes pour cette sélection ?",
        "boilerplate.components.Widgets.system.ui.suspendre_compte_question": "Souhaitez-vous suspendre les accès  pour cette sélection ?",
        "boilerplate.components.Widgets.system.ui.activer_compte_question": "Souhaitez-vous activer les accès  pour cette sélection ?",
        "boilerplate.components.Widgets.system.ui.supprimer_compte_question": "Souhaitez-vous supprimer définitivement le compte de cet utilisateur ?",
        "boilerplate.components.Widgets.system.ui.generer_compte": "Generer des comptes",
        "boilerplate.components.Widgets.system.ui.fermer_compte": "Suspendre des comptes",
        "boilerplate.components.Widgets.system.ui.activer_compte": "Réactiver des comptes",
        "boilerplate.components.Widgets.system.ui.supprimer_compte": "Supprimer des comptes",
        "boilerplate.components.Widgets.system.ui.libelle_libelle": "Libellé",
        "boilerplate.components.Widgets.system.ui.account_ok": "Actif",
        "boilerplate.components.Widgets.system.ui.account_locked": "Clos",
        "boilerplate.components.Widgets.system.ui.account_pending_validation": "Inactif",
        "boilerplate.components.Widgets.system.ui.account_suspended": "Suspendu",
        "boilerplate.components.Widgets.system.ui.libelle_title": "Titre",
        "boilerplate.components.Widgets.system.ui.close_this": "Clore",
        "boilerplate.components.Widgets.system.ui.close_sondage": "Clore le sondage",
        "boilerplate.components.Widgets.system.ui.notif_create": " a enregistré ",
        "boilerplate.components.Widgets.system.ui.notif_update": " a mis à jour ",
        "boilerplate.components.Widgets.system.ui.notif_delete": " a supprimé ",
        "boilerplate.components.Widgets.system.ui.notif_update_action": "Mise à jour",
        "boilerplate.components.Widgets.system.ui.notif_read": " a lu ",
        "boilerplate.components.Widgets.system.ui.header_nom": "Nom",
        "boilerplate.components.Widgets.system.ui.header_code": "Code",
        "boilerplate.components.Widgets.system.ui.jour": "Jour",
        "boilerplate.components.Widgets.system.ui.header_quantite": "Quantité",
        "boilerplate.components.Widgets.system.ui.send_to": "Envoyer à",
        "boilerplate.components.Widgets.system.ui.ims_nap_time": "Heure de sieste",
        "boilerplate.components.Widgets.system.ui.sieste": "Sieste",
        "boilerplate.components.Widgets.system.ui.ims_menu_cantine_obs": "Observation déjeuner",
        "boilerplate.components.Widgets.system.ui.ims_menu_day": "Menu du jour",
        "boilerplate.components.Widgets.system.ui.note": "Note",
        "boilerplate.components.Widgets.system.ui.ims_menu_obs_very_well": "Très bien",
        "boilerplate.components.Widgets.system.ui.ims_menu_obs_easily": "Avec facilité",
        "boilerplate.components.Widgets.system.ui.ims_menu_obs_good": "Bien",
        "boilerplate.components.Widgets.system.ui.ims_menu_obs_not_good": "Peu",
        "boilerplate.components.Widgets.system.ui.ims_menu_obs_not_at_all": "Pas du tout",
        "boilerplate.components.Widgets.system.ui.ims_day_how_ate": "Comment ai-je mangé ?",
        "boilerplate.components.Widgets.system.ui.ims_plat_entree": "Entrée",
        "boilerplate.components.Widgets.system.ui.ims_plat_plat": "Plat",
        "boilerplate.components.Widgets.system.ui.ims_plat_dessert": "Dessert",
        'boilerplate.components.Widgets.system.ui.rdv_read_void': " ",
        "boilerplate.components.Widgets.system.ui.child": "Enfant",
        "boilerplate.components.Widgets.system.ui.times_number": "Fois",
        "boilerplate.components.Widgets.system.ui.obs_parent": "Commentaire parent",
        "boilerplate.components.Widgets.system.ui.child_stuff": "Ce dont j’ai besoin pour demain",
        "boilerplate.components.Widgets.system.ui.obs_gen": "Observation générale",
        "boilerplate.components.Widgets.system.ui.day": "Jour",
        "boilerplate.components.Widgets.system.ui.ims_day_title": "Ma journée à IMS du",
        "boilerplate.components.Widgets.system.ui.hour": "Heure",
        "boilerplate.components.Widgets.system.ui.none": "Aucun(e)",
        "boilerplate.components.Widgets.system.ui.start_day": "Date de début",
        "boilerplate.components.Widgets.system.ui.end_day": "Date de fin",
        "boilerplate.components.Widgets.system.ui.start_hour": "Heure de début",
        "boilerplate.components.Widgets.system.ui.end_hour": "Heure de fin",
        "boilerplate.components.Widgets.system.ui.save_form_crud_btn": "Sauvegarder",
        "boilerplate.components.Widgets.system.ui.edit": "Editer",
        "boilerplate.components.Widgets.system.ui.delete_in_table": "Supprimer",
        "boilerplate.components.Widgets.system.ui.back_action_stepper": "Précédent",
        "boilerplate.components.Widgets.system.ui.next_action_stepper": "Suivant",
        'boilerplate.components.Widgets.system.ui.import_action': 'Importer',
        'boilerplate.components.Widgets.system.ui.la_valeur': 'La valeur',
        'boilerplate.components.Widgets.system.ui.must_be_uniq': 'doit être unique',
        'boilerplate.components.Widgets.system.ui.snackbar.message.sb_add_success': 'Succès de l\'enregistrement',
        'boilerplate.components.Widgets.system.ui.snackbar.message.sb_edit_success': 'Succès de la modification',
        'boilerplate.components.Widgets.system.ui.snackbar.message.sb_delete_success': 'Succès de la suppression',
        'boilerplate.components.Widgets.system.ui.snackbar.message.sb_success': 'Enregistrement effectué',
        'boilerplate.components.Widgets.system.ui.snackbar.message.sb_error': 'Problème de connection BD',
        'boilerplate.components.Widgets.system.ui.snackbar.message.sb_action_error': 'Erreur lors de l\'enregistrement',
        'boilerplate.components.Widgets.system.ui.snackbar.message.sb_selection_empty': 'Veuillez effectuer une sélection',
        'boilerplate.components.Widgets.system.ui.snackbar.message.sb_selection_only_suspended_account': 'Seuls les comptes suspendus peuvent être activés. Veuillez-vous assurer de ne sélectionner que des comptes suspendus',
        'boilerplate.components.Widgets.system.ui.snackbar.message.sb_employee_exist': 'Un membre de l\'equipe avec le même courriel a été enregistré dans la plateforme',
        'boilerplate.components.Widgets.system.ui.snackbar.message.sb_only_suspended_to_active': 'Seuls les comptes suspendus peuvent être activés',
        'boilerplate.components.Widgets.system.ui.snackbar.message.sb_filesize_limit': 'La taille du fichier est trop grande.',
        'boilerplate.components.Widgets.system.ui.snackbar.message.sb_not_enough_book_in_store': 'Il n\'ya pas de livre disponible en stock',
        'boilerplate.components.Widgets.system.ui.snackbar.message.sb_parent_already_exist': 'Le parent est déjà enregistré dans l\'application',
        'boilerplate.components.Widgets.system.ui.snackbar.message.sb_error_400': 'Echec de la requete avec le status 400',
        'boilerplate.components.Widgets.system.ui.enum.role.enum_admin': 'Administration',
        'boilerplate.components.Widgets.system.ui.enum.role.enum_teacher': 'Équipe pédagogique',
        'boilerplate.components.Widgets.system.ui.enum.role.enum_support': 'Équipe support',


        "boilerplate.components.Widgets.page.user.login.form.title_form_login": "Se connecter",
        "boilerplate.components.Widgets.page.user.login.form.input.mail_number_form_login": "Nom d'utilisateur",
        "boilerplate.components.Widgets.page.user.login.form.input.password_form_login": "Mot de passe",
        "boilerplate.components.Widgets.page.user.login.form.input.remember_form_login": "Se souvenir de moi",
        "boilerplate.components.Widgets.page.user.login.form.input.forgot_password_form_login": "Mot de passe oublié ?",
        "boilerplate.components.Widgets.page.user.login.form.button.connection_form_login": "Se connecter",
        "boilerplate.components.Widgets.page.user.login.form.button.logout_form_app": "Se deconnecter",
        "boilerplate.components.Widgets.page.user.forgot_password.form.title_form_reset": "Mot de passe oublié",
        "boilerplate.components.Widgets.page.user.forgot_password.form.input.email_form_reset": "Email",
        "boilerplate.components.Widgets.page.user.forgot_password.form.button.reset_password_form_reset": "Demande de réinitialisation",


        'boilerplate.components.Errors.BAD_REQUEST': 'Votre demande ne peut-être traitée par le serveur.',
        'boilerplate.components.Errors.CONSTRAINT_VIOLATION': "The new data is not conform to the good.",
        'boilerplate.components.Errors.DATA_EXIST': 'Cette donnée existe déjà.',
        'boilerplate.components.Errors.DEFAULT_ERROR': "Votre réquête ne peut être traitée.",
        'boilerplate.components.Errors.DTO_SENT_FAILED_VALIDATION': "S'il vous plaît veuillez vous assurer de renseigner toutes les informations nécessaires avant toute demande.",
        'boilerplate.components.Errors.ERR_CONFLICT': 'Votre requête est en conflit avec une donnée qui existe déjà.',
        "boilerplate.components.Errors.NOT_FOUND": "Aucune correspondance n'a été retrouvée.",
        "boilerplate.components.Errors.PERSON_SAME_EMAIL_ALREADY_EXISTED": "Une personne avec un courriel identitque est déjà enregistré dans la Base de Données.",
        "boilerplate.components.Errors.RDV_NOT_FOUND": "Le rendez-vous n'est plus disponible.",
        "boilerplate.components.Errors.SERVICE_AVAILABLE_ONLY_FOR_PRESET_APPOINTMENT": "Cette fonctionalité n'est disponible que pour les rendez-vous prédéfinis.",
        "boilerplate.components.Errors.UNIQUE_CONSTRAINT": "Une ou plusieurs données existent déjà dans l'application. Vous devez les modifier pour qu'elles soient uniques.",

    }
}