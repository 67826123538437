import _ from "lodash";
import {Common, commonDataImp} from "./Common";
import * as yup from "yup";
import moment from "moment";
import React from "react";
import {isColumnVisible, isPositiveValue} from "../../helpers/helpers";
import {
    columnsScolariteGroupPlugin,
    exportExcelScolariteGroupPlugin,
    ScolariteGroup,
    scolariteGroupColumnsVisibilityPlugin,
    scolariteGroupDataImpl
} from "./ScolariteGroup";
import messages from "../../page/messages/general/messages";
import {Child, childColumnsVisibilityPlugin, childDataImpl, columnsChildPlugin, exportExcelChildPlugin} from "./Child";

export const SCOLARITEECHEANCIERENFANT_CONSTANT = {
    convertToFrontEnd: (logic:any, item:ScolariteEcheancierEnfant) => {
        // @ts-ignore
        const itemInit = _.cloneDeep(scolariteEcheancierEnfantDataImpl);
        const data: ScolariteEcheancierEnfant = {...itemInit, ...item} ;

        data.childForm           = logic.childrenMapRef?.current.get(item.enfantId) ?? _.cloneDeep(childDataImpl);
        data.ladateForm          = isPositiveValue(data.ladate) ? moment(data.ladate) : undefined;
        // @ts-ignore
        data.liredateForm        = _.isObject(data.ladateForm) ? data.ladateForm.format("L") : ``;
        // @ts-ignore
        data.rappeldateForm      = isPositiveValue(data.rappeldate) ? moment(data.rappeldate) : null;

        data.scolariteGroupForm   = logic.scolariteGroupsMapRef?.current.get(data.scolariteGroupId)  ?? _.cloneDeep(scolariteGroupDataImpl);

        return data ;
    },
    convertToBackEnd: (logic:any, item:ScolariteEcheancierEnfant) => {
        item.ladate          = item.ladateForm?.valueOf();
        // @ts-ignore
        item.rappeldate      = _.isObject(item.rappeldateForm) ? item.rappeldateForm?.valueOf() : null;

        return item ;
    },
};



export const exportExcelEcheancierEnfant = (
    {rows=[], columns=[], intl={}}:{ rows:any[]; intl:any;  columns: any[];}
) => {
    return _.map(rows, (row:any) => {
        let obj:any = {};
        const data = row.original;
        obj = exportExcelChildPlugin({obj, data:data?.childForm ?? _.cloneDeep(childDataImpl), intl, infoSpace:intl.formatMessage({id:messages.table_h_enfant}), columns});

        obj = exportExcelScolariteGroupPlugin({obj, data:data?.scolariteGroupForm ?? _.cloneDeep(scolariteGroupDataImpl), intl, columns, infoSpace:intl.formatMessage({id:messages.fees})});

        if(isColumnVisible(columns, 'echeancierEnfantForm_nom')){
            obj[`${intl.formatMessage({id:messages.table_h_echeancier_nom})}`] = data.nom;
        }
        if(isColumnVisible(columns, 'echeancierEnfantForm_code')){
            obj[`${intl.formatMessage({id:messages.header_code})}`] = data.code;
        }
        if(isColumnVisible(columns, 'echeancierEnfantForm_liredateForm')){
            obj[`${intl.formatMessage({id:messages.date_limite})}`] = data.liredateForm;
        }
        if(isColumnVisible(columns, 'echeancierEnfantForm_montant')){
            obj[`${intl.formatMessage({id:messages.table_h_montant})}`] = data.montant;
        }
        return obj;
    });
}



export const echeancierEnfantColumnsVisibility = {
    columnVisibility : {
        ...childColumnsVisibilityPlugin.columnVisibility,
        ...scolariteGroupColumnsVisibilityPlugin.columnVisibility,
        'childForm_childNomPrenomForm': false,
        'echeancierEnfantForm_nom':false,
        'scolariteGroupForm_code': false,
        'groupesectionForm_code': false,
    }
} ;


export const columnsEcheancierEnfant = (props:any) => {
    const {intl} = props ;

    const callKeyValChild = (row:any) => row?.childForm ;
    const callKeyValScolariteGroup = (row:any) => row?.scolariteGroupForm ;

    return [
        ...columnsChildPlugin({intl, nomSpace: intl.formatMessage({id:messages.table_h_enfant}), callKeyValue:callKeyValChild}),
        ...columnsScolariteGroupPlugin({intl,  nomSpace: intl.formatMessage({id:messages.fees}), callKeyValue:callKeyValScolariteGroup}),
        {
            id: 'echeancierEnfantForm_nom',
            accessorKey: 'nom',
            header: intl.formatMessage({id:messages.table_h_echeancier_nom}),
        },
        {
            id: 'echeancierEnfantForm_code',
            accessorKey: 'code',
            header: intl.formatMessage({id:messages.header_code}),
        },
        {
            id: 'echeancierEnfantForm_liredateForm',
            accessorKey: 'liredateForm',
            header: intl.formatMessage({id:messages.date_limite}),
            muiTableBodyCellProps: {
                align: 'center', //change head cell props
            },
            size:100,
        },
        {
            id: 'echeancierEnfantForm_montant',
            accessorKey: 'montant',
            header: intl.formatMessage({id:messages.table_h_montant}),
            size:120,
            filterVariant: 'range',
            filterFn: 'between',
            muiTableBodyCellProps: {
                align: 'right', //change head cell props
            },
            // @ts-ignore
            Cell: ({cell}) => <b>{new Intl.NumberFormat("fr-FR").format(cell.getValue<number>())}</b>,
        },
    ];
} ;


export  const scolariteEcheancierEnfantDataImpl: ScolariteEcheancierEnfant = {
    id: null,
    ladate: 0,
    nom: ``,
    code: ``,
    nomFraisGroup: ``,
    enfantId: 0,
    scolariteGroupId: 0,
    scolariteTypeId: 0,
    scolariteEcheancierId:0,
    groupesectionIds: [],
    IdsToDelete: [],
    groupesectionId: 0,
    montant: 0,
    rappeldate: null,
    scolariteGroupForm: null,
    ladateForm: null,
    childForm: _.cloneDeep(childDataImpl),
    liredateForm: '',
    scolariteTypeNomForm: '',
    rappeldateForm: null,
    scolariteTypeTag: '',
    infos: '',
    nestedForm: {},
    common: _.cloneDeep(commonDataImp),
}

export const scolariteEcheancierEnfantSchema = yup.object().shape({
    id: yup.number().nullable(),
    nom: yup.string(),
    code: yup.string().nullable(),
    ladate: yup.number().nullable(),
    enfantId: yup.number().nonNullable().notRequired(),
    scolariteGroupId: yup.number().nonNullable().required(),
    scolariteTypeId: yup.number().nonNullable().required(),
    scolariteEcheancierId: yup.number().nonNullable().notRequired().default(0),
    groupesectionIds: yup.array().of(yup.number()).nonNullable().default([]).notRequired(),
    IdsToDelete: yup.array().of(yup.number()).nonNullable().default([]).notRequired(),
    groupesectionId: yup.number().nonNullable().default(0).notRequired(),
    nomFraisGroup: yup.string().nonNullable().notRequired(),
    montant: yup.number().default(0).min(0).notRequired(),
    rappeldate: yup.number().nullable().notRequired(),
    childForm: yup.mixed<Child>().nullable().notRequired(),
    rappeldateForm: yup.mixed<Object>().nullable().notRequired(),
    ladateForm: yup.mixed<any>().notRequired(),
    liredateForm: yup.string().nonNullable().notRequired(),
    scolariteGroupForm: yup.mixed<ScolariteGroup>().nullable().notRequired(),
    scolariteTypeTag: yup.string().notRequired(),
    infos: yup.string().notRequired(),
    scolariteTypeNomForm: yup.string().notRequired(),
    nestedForm: yup.mixed<Object>().nullable().notRequired(),
    common: yup.mixed<Common>(),
});


export type ScolariteEcheancierEnfant = yup.InferType<typeof scolariteEcheancierEnfantSchema> ;
