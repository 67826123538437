import _ from "lodash";
import {Common, commonDataImp} from "./Common";
import * as yup from "yup";
import moment from "moment";
import React from "react";
import {isColumnVisible, isPositiveValue} from "../../helpers/helpers";
import messages from "../../page/messages/general/messages";
import {ScolariteEcheancier} from "./ScolariteEcheancier";
import {MyBadge} from "../../components/ui/badge/MyBadge";
import {echeancierGroupingCustomOptions} from "./EcheancierGrouping";

export const ECHEANCIERGROUPINGDETAILS_CONSTANT = {
    convertScolariteEcheancierToFrontEnd: (logic:any, item:ScolariteEcheancier) => {
        // @ts-ignore
        const itemInit = _.cloneDeep(echeancierGroupingDetailsDataImpl);
        const data = {...itemInit, ...item} ;

        data.ladateForm          = moment(item.ladate);
        // @ts-ignore
        data.liredateForm        = item.ladateForm.format("L");
        // @ts-ignore
        data.rappeldateForm      = isPositiveValue(item.rappeldate) ? moment(item.rappeldate) : null;

        data.scolariteGroupId           = item.scolariteGroup?.id ?? 0;
        data.scolariteAnneeId           = item.scolariteGroup?.scolariteAnneeId ?? 0;
        data.scolariteTypeId            = item.scolariteGroup?.scolariteTypeForm?.id ?? 0;
        data.groupeSectionId            = item.scolariteGroup?.groupesectionForm?.id ?? 0;
        data.scolariteTypeNomForm       = item.scolariteGroup?.scolariteTypeForm?.nom ;
        data.groupNomForm               = item.scolariteGroup?.groupesectionForm?.nom ;
        // @ts-ignore
        data.obligatoire                = item.scolariteGroup?.obligatoire ;

        return data ;
    },
    convertToBackEnd: (logic:any, item:EcheancierGroupingDetails) => {
        item.ladate          = item.ladateForm?.valueOf();
        // @ts-ignore
        item.rappeldate      = _.isObject(item.rappeldateForm) ? item.rappeldateForm?.valueOf() : null;

        return item ;
    },
};
export  const echeancierGroupingDetailsDataImpl: EcheancierGroupingDetails = {
    id: null,
    ladate: moment().startOf('day').valueOf(),
    nom: ``,
    code: ``,
    scolariteTypeTag: null,
    scolariteGroupId: 0,
    groupeSectionId: 0,
    scolariteAnneeId:0,
    scolariteTypeId:0,
    groupesectionIds:[],
    IdsToDelete: [],
    sumEcheancierDetail:0,
    montant: 0,
    obligatoire: false,
    echeance: false,
    rappeldate: null,
    ladateForm: moment(),
    scolariteTypeNomForm: '',
    groupNomForm: '',
    liredateForm: '',
    rappeldateForm: null,
    typeModeForm: 'EcheancierGroupingDetails',
    common: _.cloneDeep(commonDataImp),
}

export const echeancierGroupingDetailsSchema = yup.object().shape({
    id: yup.number().nullable().notRequired(),
    nom: yup.string().required(),
    code: yup.string().nullable().notRequired(),
    ladate: yup.number().nullable().default(moment().startOf('day').valueOf()).notRequired(),
    scolariteTypeTag: yup.string().notRequired(),
    IdsToDelete: yup.array().of(yup.number()).nonNullable().default([]).notRequired(),
    groupesectionIds: yup.array().of(yup.number()).nonNullable().default([]).notRequired(),
    scolariteGroupId: yup.number(),
    groupeSectionId: yup.number(),
    scolariteAnneeId: yup.number(),
    scolariteTypeId: yup.number(),
    sumEcheancierDetail: yup.number().default(0).notRequired(),
    montant: yup.number().default(0).min(1).required(),
    obligatoire: yup.boolean().default(false).notRequired(),
    echeance: yup.boolean().default(false).notRequired(),
    rappeldate: yup.number().nullable().notRequired(),
    rappeldateForm: yup.mixed<Object>().nullable().notRequired(),
    ladateForm: yup.mixed<any>().nullable().required(),
    liredateForm: yup.string().nonNullable().notRequired(),
    groupNomForm: yup.string().notRequired(),
    scolariteTypeNomForm: yup.string().notRequired(),
    typeModeForm: yup.string().notRequired().default('EcheancierGroupingDetails'),
    common: yup.mixed<Common>(),
});
export type EcheancierGroupingDetails = yup.InferType<typeof echeancierGroupingDetailsSchema>;


export const excelEcheancierGroupingDetailsDataMapping = (
    {rows=[], columns=[], intl={}, table=[], prefix=``}:{ rows:any[]; intl:any;  table: any; columns: any[]; prefix:string;}
) => {
    const echeanciers = _.map(rows, (row:any) => {
        let obj:any = {};
        const data = row.original;
        if(isColumnVisible(columns, `${prefix}scolariteTypeNomForm`)){
            obj[`${intl.formatMessage({id:messages.schooling_fees_type})}`] = data.scolariteTypeNomForm;
        }
        if(isColumnVisible(columns, `${prefix}groupNomForm`)){
            obj[`${intl.formatMessage({id:messages.table_h_group})}`] = data.groupNomForm;
        }
        if(isColumnVisible(columns, `${prefix}nom`)){
            obj[`${intl.formatMessage({id:messages.table_h_echeancier_nom})}`] = data.nom;
        }
        if(isColumnVisible(columns, `${prefix}code`)){
            obj[intl.formatMessage({id:messages.header_code})] = data.code;
        }
        if(isColumnVisible(columns, `${prefix}montant`)){
            obj[`${intl.formatMessage({id:messages.table_h_montant})}`] = data.montant;
        }
        if(isColumnVisible(columns, `${prefix}liredateForm`)){
            obj[`${intl.formatMessage({id:messages.table_h_echeancier_date})}`] = data.liredateForm;
        }
        if(isColumnVisible(columns, `${prefix}obligatoire`)){
            obj[`${intl.formatMessage({id:messages.schooling_fees_obligatory})}`] = data.obligatoire;
        }
        return obj;
    });
    return echeanciers;
}
export const echeancierGroupingDetailsCustomOptions = {
    columnVisibility : {
        ...echeancierGroupingCustomOptions.columnVisibility,
    }
} ;
export const columnsEcheanciersGroupingDetails = (props:any) => {
    const {intl, nomSpace} = props ;
    const infoSpace = nomSpace ?? ``;
    return [
        // @ts-ignore
        {
            accessorKey: 'scolariteTypeNomForm',
            header: `${infoSpace} ${intl.formatMessage({id:messages.schooling_fees_type})}`,
        },
        {
            accessorKey: 'groupNomForm',
            header: `${infoSpace} ${intl.formatMessage({id:messages.table_h_group})}`,
        },
        {
            accessorKey: 'nom',
            header: `${infoSpace} ${intl.formatMessage({id:messages.table_h_echeancier_nom})}`,
        },
        {
            accessorKey: 'code',
            header: `${infoSpace} ${intl.formatMessage({id:messages.header_code})}`,
        },
        {
            accessorKey: 'montant',
            header: `${infoSpace} ${intl.formatMessage({id:messages.table_h_montant})}`,
            muiTableBodyCellProps: {
                align: 'right', //change head cell props
            },
            // @ts-ignore
            Cell: ({cell}) => <b>{new Intl.NumberFormat("fr-FR").format(cell.getValue<number>())}</b>,
        },
        {
            accessorKey: 'liredateForm',
            header: `${infoSpace} ${intl.formatMessage({id:messages.table_h_echeancier_date})}`,
        },
        {
            accessorKey: 'obligatoire',
            header: `${infoSpace} ${intl.formatMessage({id:messages.schooling_fees_obligatory})}`,
            muiTableBodyCellProps: {
                align: 'center', //change head cell props
            },
            // @ts-ignore
            Cell: ({cell}) => {
                // @ts-ignore
                return cell.getValue<boolean>() ? <MyBadge badgeContent={intl.formatMessage({id:messages.disponible})} color="success" /> :
                    <MyBadge badgeContent={intl.formatMessage({id:messages.indisponible})} color="error" />;
            },
        },
    ];
} ;
