import React, {Fragment, useContext} from 'react';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowBack from '@mui/icons-material/ArrowBack';
import classNames from "classnames";
import {Box} from "@mui/material";
import {MessageCenterContext} from "./MessageCenter";
import messageCenterBusinessLogic from "./businessLogic/MessageCenterBusinessLogic";
import spaceRoomBusinessLogic from './businessLogic/SpaceRoomBusinessLogic';
import messages from "../general/messages";
import {FormattedMessage} from "react-intl";
import sondageStateUpdate from "../../sondage/businessLogic/SondageStateUpdate";


const optionsOpt = [
    'Delete Conversation',
    'Option 1',
    'Option 2',
    'Option 3',
];
const ITEM_HEIGHT = 48;

function ChatHeader(props:any) {
    const logicRef: any = useContext(MessageCenterContext);

    const onMenuClose = () => sondageStateUpdate.updateHeaderAnchor(logicRef, null);
    const onSpaceDelete = () => {
        spaceRoomBusinessLogic.deleteSpace(logicRef, logicRef.space);
        onMenuClose();
    }
    const onSpaceClose = () => {
        spaceRoomBusinessLogic.closeSpace(logicRef, logicRef.space);
        onMenuClose();
    }

    return (
        <AppBar
            position="absolute"
            className={classNames(logicRef.chatHeaderStyles.appBar, logicRef.chatHeaderStyles.fixHeight, logicRef.chatHeaderStyles.appBarShift)}
        >
            <div className={logicRef.chatHeaderStyles.cover}>
                <Box sx={{ display: { xs:'inline-block', sm:'none'} }}>
                    {(logicRef.showMobileDetail || logicRef.newMessage) && (
                        <IconButton
                            aria-label="open drawer"
                            onClick={() => messageCenterBusinessLogic.hideDetail(logicRef)}
                            className={logicRef.chatHeaderStyles.navIconHide}
                            size="large">
                            <ArrowBack />
                        </IconButton>
                    )}
                </Box>

                <Fragment>
                    <Avatar
                        sx={{marginRight:2}}
                        alt={logicRef.space.parentNom}
                        src={`${logicRef.space.avatarForm}`}
                        className={logicRef.chatStyles.avatar} />
                    <Typography variant="h6" component="h2" color="inherit" noWrap>
                        <span className={logicRef.chatHeaderStyles.coverText}>
                            {logicRef.space.objet}
                        </span>
                        <Typography variant="caption" display="block" className={logicRef.chatHeaderStyles.status} color="inherit" noWrap>
                            <span className={logicRef.chatHeaderStyles.online} />
                            {logicRef.space.enfantNom}
                        </Typography>
                        <Typography variant="caption" display="block" className={logicRef.chatHeaderStyles.status} color="inherit" noWrap>
                            <span className={logicRef.chatHeaderStyles.online} />
                            {logicRef.space.parentNom}
                        </Typography>
                    </Typography>
                </Fragment>

                {
                    !logicRef.newMessage && (
                        <>
                            <IconButton
                                aria-label="More"
                                aria-haspopup="true"
                                className={logicRef.chatHeaderStyles.buttonMore}
                                aria-expanded={Boolean(logicRef.anchorElHeader) ? 'true' : undefined}
                                onClick={(evt:React.MouseEvent<HTMLButtonElement>) => sondageStateUpdate.updateHeaderAnchor(logicRef, evt)}
                                size="large"
                                sx={{ marginRight: 2}}
                            >
                                <MoreVertIcon color="inherit" />
                            </IconButton>

                            <Menu
                                id="long-menu"
                                anchorEl={logicRef.anchorElHeader}
                                open={Boolean(logicRef.anchorElHeader)}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                                onClose={onMenuClose}
                                /*PaperProps={{
                                    style: {
                                        maxHeight: ITEM_HEIGHT * 4.5,
                                        width: 200,
                                    },2255956482
                                }}
                                IDUSER 395
                                IDPERSO 431
                                2255956482
                                */
                            >
                                <MenuItem onClick={onSpaceClose}>
                                    <FormattedMessage id={messages.close_space} />
                                </MenuItem>
                                <MenuItem onClick={onSpaceDelete}>
                                    <FormattedMessage id={messages.delete_space} />
                                </MenuItem>
                            </Menu>
                        </>
                    )
                }
            </div>
        </AppBar>
    );
}

export default ChatHeader;
