import React from "react";
import _ from "lodash";
import {FormattedMessage} from "react-intl";
import {processingChildren} from "../../../../helpers/loader_helpers";
import {ScolariteType, SCOLARITETYPE_CONSTANT} from "../../../../constants/type/ScolariteType";
import {request} from "../../../../helpers/axios_helpers";
import {
    ScolariteProformaEnfant,
    SCOLARITEPROFROMAENFANT_CONSTANT
} from "../../../../constants/type/ScolariteProformaEnfant";
import {
    ScolariteProforma,
    SCOLARITEPROFORMA_CONSTANT,
    ScolariteProformaGroupEnfant,
    scolariteProformaGroupEnfantDataImpl
} from "../../../../constants/type/ScolariteProforma";
import {Child} from "../../../../constants/type/Child";
import proformaStateUpdate from "./ProformaStateUpdate";
import moment from "moment";
import {Profils, PROFILS_CONSTANT} from "../../../../constants/type/Profils";
import {getErrorMessage} from "../../../../helpers/errorManager";
import {mixLoad} from "../../../../helpers/loader_cache_helpers";
import {ScolariteAnnee, SCOLARITEANNEE_CONSTANT} from "../../../../constants/type/ScolariteAnnee";
import {Groupesection, GROUPESECTION_CONSTANT} from "../../../../constants/type/Groupesection";
import {GroupEnfant, GROUPENFANT_CONSTANT} from "../../../../constants/type/GroupEnfant";

const profromaBusinessLogic : any = {
    initPostConstruct: (logic:any) => {
        const filterApi = {
            filters:[
                {
                    key: "common.etat",
                    operator: "EQUAL",
                    field_type: "STRING",
                    value: "OK"
                }
            ]
        };

        proformaStateUpdate.contentLoaded(logic, false) ;
        const requestbody = ['groups', 'profils', 'scolaritetypes', 'scolaritegroups', 'scolariteannees', 'groupenfants', 'enfants', 'proformalist'];
        const convertToFrontEnd = (data:any) => {
            const itemssca : ScolariteAnnee[]   = data.scolariteannees ?? [];
            logic.scolariteAnneesRef.current  = _.sortBy(_.map(itemssca,  item => SCOLARITEANNEE_CONSTANT.convertToFrontEnd(logic, item)), ['dateDebut']);
            proformaStateUpdate.idScolariteAnneeSel(logic, _.find(itemssca, {migrateNextYear:false})?.id ?? 0);

            const itemscl : Groupesection[]   = data.groups.content ?? [];
            logic.groupesectionsRef.current  = _.sortBy(_.map(itemscl, item => GROUPESECTION_CONSTANT.convertToFrontEnd(logic, item)), ['nom']);
            logic.groupesectionsRef.current.forEach((data:Groupesection) => {
                logic.groupesectionMapRef.current.set(data.id, data);
            });

            const itemsp : Profils[]   = data.profils.content ?? [];
            logic.profilsRef.current  = _.sortBy(_.map(itemsp,  item => PROFILS_CONSTANT.convertToFrontEnd(logic, item)), ['nom']);
            logic.profilsRef.current.forEach((data:Profils) => {
                logic.profilsMapRef.current.set(data.id, data);
            });

            const itemsst : ScolariteType[]   = data.scolaritetypes.content ?? [];
            logic.scolariteTypeRef.current  = _.sortBy(_.map(itemsst,  item => SCOLARITETYPE_CONSTANT.convertToFrontEnd(logic, item)), ['nom']);
            logic.scolariteTypeRef.current.forEach((data:ScolariteType) => {
                logic.scolariteTypeMapRef.current.set(data.id, data);
            });

            const itemchilds : Child[]   = data.enfants.content ?? [];
            logic = processingChildren(logic, itemchilds);


            const itemge : GroupEnfant[]   = data.groupenfants.content ?? [];
            logic.groupEnfantsRef.current  = _.map(itemge,  item => GROUPENFANT_CONSTANT.convertToFrontEnd(logic, item));

            const proformalist : ScolariteProforma[]   = data.proformalist ?? [];
            const dataproformalist : ScolariteProforma[]   =  _.sortBy(_.map(proformalist, item => SCOLARITEPROFORMA_CONSTANT.convertToFrontEnd(logic, item)), ['ladate']);
            proformaStateUpdate.proformaDataList(logic, dataproformalist) ;
        };

        mixLoad(logic, requestbody, convertToFrontEnd).then(value => {
            proformaStateUpdate.contentLoaded(logic, true);
            proformaStateUpdate.tableDataLoading(logic, false);
        }).catch(e => {
            proformaStateUpdate.contentLoaded(logic, true);
            proformaStateUpdate.tableDataLoading(logic, false);
        })
    },
    notifier: async (logic:any,  messages:any, scolariteproformaId:number):Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {

            request('POST', `/extra/scolariteproforma/notifier/${scolariteproformaId}`, {}).then((response) => {
                logic.snackbarShowMessage(<FormattedMessage id={messages.sb_success} />);
                resolve(true);
            }).catch((error) => {
                logic.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                resolve(false);
            });
        });
    },
    notifierSelection: async (logic:any,  messages:any, scolariteproformaId:number, ids:number[]):Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {

            request('POST', `/extra/scolariteproforma/notifier/${scolariteproformaId}/destinataires`, ids).then((response) => {
                logic.snackbarShowMessage(<FormattedMessage id={messages.sb_success} />);
                resolve(true);
            }).catch((error) => {
                logic.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                resolve(false);
            });
        });
    },
    saveEdit: async (logic:any, messages:any, toSend:any, id:number|null):Promise<ScolariteProforma> => {
        return new Promise<ScolariteProforma>((resolve, reject) => {
            const method = _.isNumber(id) ? 'PUT' : 'POST';
            let params = '';
            if(method === 'PUT'){
                params = `/${toSend.id}`;
            }
            request('POST', `/extra/scolariteproforma/generer${params}`, toSend).then((response) => {

                 const dataSaved : ScolariteProforma   =  SCOLARITEPROFORMA_CONSTANT.convertToFrontEnd(logic, response.data);
                 const olDatas = _.cloneDeep(logic.proformaDataList);
                 const index = _.findIndex(olDatas, {id:dataSaved.id});
                  if(index >= 0){
                      olDatas[index] = dataSaved;
                   } else {
                      olDatas.unshift(dataSaved);
                   }
                  const datas =  _.reverse(_.sortBy(olDatas, ['ladate']));
                 proformaStateUpdate.proformaDataList(logic, datas) ;
                resolve(dataSaved);
            }).catch((error:any) => {
                logic.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                proformaStateUpdate.processingSubmitStateNormBtn(logic, false);
            })
        });
    },
    loadProformaList: (logic:any) => {
        return new Promise<boolean>(resolve =>
            request("GET",  `/extra/scolariteproforma/proformalist`, {}).then((response) => {
                const items : ScolariteProforma[]   = response.data ?? [];
                // @ts-ignore
                const itemsfrontend = _.reverse(_.sortBy(_.map(items,  item => SCOLARITEPROFORMA_CONSTANT.convertToFrontEnd(logic, item)), ['ladate']));
                proformaStateUpdate.proformaDataList(logic, itemsfrontend) ;
                resolve(true);
            }).catch((error) => {
                logic.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                resolve(false);
            })
        );
    },
    loadEnfantProformas: (logic:any, scolariteproformaId:number) => {
        return new Promise<boolean>(resolve =>
            request("GET",  `/extra/scolariteproforma/list/${scolariteproformaId}`, {}).then((response) => {
                const items : ScolariteProformaEnfant[]   = response.data._embedded?.scolariteProformaEnfantDTOModelList ?? [];
                // @ts-ignore
                const itemsfrontend = _.map(items,  item => SCOLARITEPROFROMAENFANT_CONSTANT.convertToFrontEnd(logic, item));
                proformaStateUpdate.dataList(logic, itemsfrontend) ;
                proformaStateUpdate.tableKeyValue(logic, moment().valueOf());
                resolve(true);
            }).catch((error) => {
                logic.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                resolve(false);
            })
        );
    },
    loadChildren: async (logic:any, groupesectionsIds:number[], profilsIds:number[]) => {
        return new Promise<ScolariteProformaGroupEnfant[]>(resolve => {
            const scolariteProformaGroupEnfants:ScolariteProformaGroupEnfant[] = [];
            const groupEnfants : GroupEnfant[] = _.filter(logic.groupEnfantsRef.current, item => {
                const isGroupIncluded = _.includes(groupesectionsIds, item.groupesectionId) ;

                const profilsIdOfGroupChild: number[]    = _.map(item.groupEnfantProfils, itemgpe => itemgpe?.profilsId);
                const isProfilIncluded = _.isEmpty(profilsIds) || !_.isEmpty(_.intersection(profilsIds, profilsIdOfGroupChild)) ;

                return isGroupIncluded && isProfilIncluded;
            }) ;
            let index : number = 0 ;
            for(const groupEnfant of groupEnfants){
                const enfant: Child = logic.childrenMapRef.current.get(groupEnfant.enfantId);
                if(_.isObject(enfant) === false){
                    continue ;
                }
                const groupsection: Groupesection = logic.groupesectionMapRef.current.get(groupEnfant.groupesectionId);

                if(_.isObject(groupsection) === false){
                    continue ;
                }

                enfant.classeNameForm = groupsection.nom ?? ``;
                const scolariteProformaGroupEnfant = _.cloneDeep(scolariteProformaGroupEnfantDataImpl);
                scolariteProformaGroupEnfant.id = --index ;
                scolariteProformaGroupEnfant.childForm = enfant ;
                scolariteProformaGroupEnfant.groupEnfantForm = groupEnfant ;
                scolariteProformaGroupEnfants.push(scolariteProformaGroupEnfant);
            }
            resolve(scolariteProformaGroupEnfants);
        });
        /*return new Promise<Child[]>(resolve =>
            request("GET",  `/corebase/enfants/groupesections/${groupeIds}`, groupeIds).then((response) => {
                const data = _.cloneDeep(logic.dataSel);
                const scolariteProformaGroupEnfants:ScolariteProformaGroupEnfant[] = [];
                for(const item of response.data){
                    const enfant = CHILD_CONSTANT.convertToFrontEnd(logic, item);

                    const groupEnfant = _.find(logic.groupEnfantsRef.current, {enfantId:item.id}) ?? _.cloneDeep(groupEnfantDataImpl);
                    const groupsection = _.find(logic.groupesectionsRef.current, {id:groupEnfant.groupesectionId}) ?? _.cloneDeep(groupesectionDataImpl);

                    enfant.classeNameForm = groupsection.nom ;

                    const scolariteProformaGroupEnfant = _.cloneDeep(scolariteProformaGroupEnfantDataImpl);
                    scolariteProformaGroupEnfant.child = enfant ;
                    scolariteProformaGroupEnfant.groupEnfant = groupEnfant ;
                    scolariteProformaGroupEnfants.push(scolariteProformaGroupEnfant);
                }
                data.scolariteProformaGroupEnfants =  scolariteProformaGroupEnfants;
                // @ts-ignore
                proformaStateUpdate.dataSel(logic, data) ;
                resolve(data.enfants);
            }).catch((error) => {
                logic.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                resolve([]);
            })
        );*/
    },
    delete: async (logic:any, messages:any, ids:number[]):Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            request('DELETE', `/extra/scolariteproforma/bouquet`, ids).then((response) => {
                // @ts-ignore
                profromaBusinessLogic.loadEnfantProformas(logic, logic.dataSel.id);
                logic.snackbarShowMessage(<FormattedMessage id={messages.sb_delete_success} />);
                resolve(true);
            }).catch((error) => {
                logic.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                resolve(false);
            });
        });
    },
    deleteDatas: async (logic:any, messages:any, toSends:ScolariteProformaEnfant[]):Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            const ids:number[] = []
            toSends.forEach((l:ScolariteProformaEnfant) => {
                if(_.isNumber(l.id)){ ids.push(l.id);}
            });
            profromaBusinessLogic.delete(logic, messages, ids);
        });
    }
}
export default profromaBusinessLogic ;