import React from 'react';
import {PieChart} from '@mui/x-charts/PieChart';
import {Stack} from '@mui/material';

const valueFormatter = (item: { value: number }) => `${new Intl.NumberFormat('fr-FR').format(item.value)} FCFA`;

export default function PieCustomLabel (props:any) {
    const {data} = props;

    return (
        <Stack
            direction="column"
            spacing={2}
            sx={{
                justifyContent: "center",
                alignItems: "center",
            }}
        >

            <PieChart
                    series={[
                        {
                            data,
                            valueFormatter,
                            innerRadius: 30,
                            outerRadius: 120,
                            paddingAngle: 4,
                            cornerRadius: 5,
                            startAngle: -45,
                            endAngle: 314,
                            cx: 190,
                            cy: 160,
                            highlightScope: { fade: 'global', highlight: 'item' },
                            faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                        },
                    ]}
                    width={420}
                    height={390}
                    slotProps={{
                        legend: {
                            direction: 'row',
                            position: { vertical: 'bottom', horizontal: 'middle' },
                            padding: 0,
                        },
                    }}
                />
        </Stack>
    );


}
