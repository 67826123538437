import React from "react";
import messages from "../page/messages/error/messages";
import {FormattedMessage} from "react-intl";
import _ from "lodash";
import {ErrorMessage} from "../constants/type/ErrorMessage";
import {errorResponseDataDataImpl} from "../constants/type/ErrorResponseData";

const ERRORS_MESSAGE:any[] = [
    <FormattedMessage id={messages.sb_default_error} />,
    <FormattedMessage id={messages.sb_data_exist} />,
    <FormattedMessage id={messages.sb_error_400} />,
];
const ERRORS_MAP = new Map<string,any>([

    ['ACTIVATE_ACCOUNT_EXCEPTION', {
        default_message: <FormattedMessage id={messages.sb_data_exist} />,
            message: {
                ONLY_SUSPENDED_ACCOUNT_COULD_BE_ACTIVATE: <FormattedMessage id={messages.sb_only_suspended_to_active} />,
            },
            status: {
                409: <FormattedMessage id={messages.sb_data_exist} />
            },
            response: {},
        }
    ],
    ['ERR_BAD_REQUEST', {
        default_message: <FormattedMessage id={messages.sb_err_bad_request} />,
            message: {},
            status: {409: <FormattedMessage id={messages.sb_data_exist} />},
            response: {},
        }
    ],
    ['CONSTRAINT_VIOLATION', {
        default_message: <FormattedMessage id={messages.sb_err_conflict} />,
            message: {
                ims_day_menu_jour_id_enfant_id_key: <FormattedMessage id={messages.sb_data_exist} />,
            },
            status: {
                409: <FormattedMessage id={messages.sb_err_conflict} />
            },
            response: {},
        }
    ],
    ['CONSTRAINT_VIOLATION_EXCEPTION', {
            default_message: <FormattedMessage id={messages.sb_err_bad_request} />,
            message: {
                PARENT_ALREADY_EXIST_EXCEPTION: <FormattedMessage id={messages.sb_parent_already_exist} />
            },
            status: {409: <FormattedMessage id={messages.sb_data_exist} />},
            response: {},
        }
    ],
    ['NOT_FOUND', {
        default_message: <FormattedMessage id={messages.sb_data_exist} />,
            message: {
                EMPLOYEE_WITH_SAME_EMAIL_EXIST: <FormattedMessage id={messages.sb_employee_exist} />,
            },
            status: {
                409: <FormattedMessage id={messages.sb_data_exist} />
            },
            response: {},
        }
    ],
    ['PERSON_ALREADY_EXIST_EXCEPTION', {
        default_message: <FormattedMessage id={messages.sb_data_exist} />,
            message: {
                EMPLOYEE_WITH_SAME_EMAIL_EXIST: <FormattedMessage id={messages.sb_employee_exist} />,
            },
            status: {
                409: <FormattedMessage id={messages.sb_data_exist} />
            },
            response: {},
        }
    ],
    ['SIZE_EXCEPTION', {
        default_message: <FormattedMessage id={messages.sb_filesize_limit} />,
            message: {
                NOT_ENOUGH_BOOK_IN_STORE: <FormattedMessage id={messages.sb_not_enough_book_in_store} />
            },
            status: {
                424: <FormattedMessage id={messages.sb_filesize_limit} />
            },
            response: {},
        }
    ],
]);
export const getErrorMessage = (errorData:any):ErrorMessage => {
   /* console.log(errorData);
    let erreur = ERRORS_MAP.get(errorData.response?.data?.codeMessage ?? errorData.code );
    console.log(messages[errorData.response?.data?.codeMessage] ?? errorData?.response?.data?.message);
    console.log("erreur >> ", erreur);
    if(_.isObject(erreur)){

        const status: number = errorData?.response?.status ?? 400;
        const message: string = errorData?.response?.data?.message ;
        const dataError : ErrorResponseData = errorData?.response?.data ?? _.cloneDeep(errorResponseDataDataImpl);
        console.log("dataError >> ", dataError);
        // @ts-ignore
        console.log("erreur.message[message] ??  erreur.status[status] ?? message ?? erreur.default_message >> ", erreur.message[message] ??  erreur.status[status] ?? message ?? erreur.default_message);
        return {
            status: status,
            // @ts-ignore
            message : erreur.message[message] ??  erreur.status[status] ??  erreur.default_message,
            technical_message: errorData.message,
            statusText: errorData.statusText,
            response: dataError,
        }
    }*/
    console.log(errorData);
    console.log(messages[errorData?.codeMessage] ?? errorData?.message);
    let message     =  errorData?.message ;


    if(_.includes(message, "_")){
        const idMessage = messages[errorData?.message] ??
            messages[errorData?.codeMessage] ??
            messages[errorData?.code] ??
            messages.DEFAULT_ERROR;

        if(_.isString(idMessage)){
            message = <FormattedMessage id={idMessage} />
        }
    }

    if(_.includes(message, "JWT")){
        document.location.href="/";
        message="Nous vous préconisons de vous deconnecter et de vous reconnecter";
    }

    return {
        status: errorData?.status ?? 400,
        message : message,
        technical_message: errorData.message,
        statusText:'',
        response: _.cloneDeep(errorResponseDataDataImpl),
    }
}
