import React, {useEffect, useMemo, useState} from 'react';
import Box from "@mui/material/Box";
import SchoolingSubMenu from "../SchoolingSubMenu";
import PapperBlock from "../../../components/ui/papperBlock/PapperBlock";
import {
    Autocomplete,
    Avatar,
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    MenuItem,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from "@mui/material";
import classNames from "classnames";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {MobileDateTimePicker} from "@mui/x-date-pickers";
import moment from "moment";
import SaveIcon from "@mui/icons-material/Save";
import scolariteStyles from "../jss/scolarite-jss";
import {useForm} from "react-hook-form";
import _ from "lodash";
import {yupResolver} from "@hookform/resolvers/yup";
import {
    ScolariteData,
    SCOLARITEDATA_CONSTANT,
    scolariteDataDataImpl,
    scolariteDataSchema
} from "../../../constants/type/ScolariteData";
import useScolaritePaiementVariables from "./businesslogic/ScolaritePaiementVariables";
import {momentLocalizer} from "react-big-calendar";
import {Child, childDataImpl} from "../../../constants/type/Child";
import {FormattedMessage, useIntl} from "react-intl";
import messages from "../../messages/general/messages";
import scolaritePaiementStateUpdate from "./businesslogic/ScolaritePaiementStateUpdate";
import InputField from "../../../components/ui/form/InputField";
import {NumericFormat} from "react-number-format";
import {ScolariteEtat} from "../../../constants/type/ScolariteEtat";
import LoadingButton from "@mui/lab/LoadingButton";
import scolariteFraisGroupStateUpdate from "../montantdesfrais/businesslogic/ScolariteFraisGroupStateUpdate";
import scolaritePaiementBusinessLogic from "./businesslogic/ScolaritePaiementBusinessLogic";
import {withSnackbar} from "../../../components/ui/snackbar/SnackbarHOC";
import {isNotBlank, isPositiveValue} from "../../../helpers/helpers";
import {GroupEnfant} from "../../../constants/type/GroupEnfant";
import {scolariteAnneeDataImpl} from "../../../constants/type/ScolariteAnnee";
import {ScolariteVersementEcheancierEnfant} from "../../../constants/type/ScolariteVersementEcheancierEnfant";
import {getErrorMessage} from "../../../helpers/errorManager";

const useBusinessLogic = (props: any): any => {
    const styleClasses = scolariteStyles();



    return {
        theme: props.theme,
        styleClasses: styleClasses,
        ...useScolaritePaiementVariables({styleClasses, ...props})
    }
};

const sumMontantVerser= (logic:any, datas:ScolariteEtat[]) =>  {
    const initialValue = 0;
    let sumWithInitial:number = datas.reduce(
        (accumulator:number, currentValue:ScolariteEtat) => accumulator + (currentValue?.nouveauVersement?.montant ?? 0),
        initialValue,
    );
    return sumWithInitial;
}

function SchoolingPayment(props:any) {
    const { locale, theme} = props;
    const localizer = momentLocalizer(moment);
    const logic = useBusinessLogic(props);
    const styleClasses = logic.styleClasses;
    const intl = useIntl() ;
    const menuLeaveTimerRef = React.useRef<any>();
    const searchPlaceholderRef =  React.useRef<string>(logic.activeLang === 'en' ? 'Find a child' : 'Rechercher un enfant');
    const [numeriChange, setNumeriChange] = useState<number>(moment().valueOf());
    const [montantChange, setMontantChange] = useState<number>(moment().valueOf());
    const [montantSolde, setMontantSolde] = useState<number>(0);
    const [montantSoldeForm, setMontantSoldeForm] = useState<any>();


    const formRef = React.useRef<any>(useForm({
        defaultValues: _.cloneDeep(logic.dataSel),
        // @ts-ignore
        resolver: yupResolver(scolariteDataSchema),
    }));
    const {errors} = formRef.current.formState;

    const handleScolariteType = (id:number, event: any) => {
        const data = _.cloneDeep(logic.dataSel);
        if(data.scolariteTypeIds.includes(id)){
            _.remove(data.scolariteTypeIds, function(n) {
                return n === id;
            });
        } else {
            data.scolariteTypeIds.push(id);
        }

        scolaritePaiementStateUpdate.dataSel(logic, data);
        setNumeriChange(moment().valueOf());
    };

    const setChild = (item:Child | null) => {
        if(_.isObject(item) === false){
            item = _.cloneDeep(childDataImpl);
        }
        scolaritePaiementStateUpdate.childSelected(logic, item);
    }
    const handleOnScolariteAnneChange = (event: any) => {
        scolaritePaiementStateUpdate.idScolariteAnneeSel(logic, parseInt(event.target.value));
    };
    const handleChildChange = (event: React.SyntheticEvent, value: Child) => setChild(value);

    const updateLaDate = (data:ScolariteData, ladate: moment.Moment):ScolariteData => {
        data.versementReference.ladateForm = ladate;
        data.versementReference.ladate = data.versementReference.ladateForm.valueOf();
        data.versementReference.liredateForm =  data.versementReference.ladateForm?.clone().format('L');

        return data ;
    }
    const handleLaDateChange = (value: moment.Moment,     context: any) => {
        const newday = moment(value.valueOf()).startOf('day');
        let data = _.cloneDeep(logic.dataSel);
        data = updateLaDate(data, newday);

        scolaritePaiementStateUpdate.dataSel(logic, data);
        formRef.current.setValue('versementReference', data.versementReference);
        setNumeriChange(moment().valueOf());
    };

    const onPaiementModeChange = (event: React.ChangeEvent, modePaiement: string) => {
        const oldModePaiement = logic.dataSel.versementReference.scolaritePaiementmodeId;
        
        const data = _.cloneDeep(logic.dataSel);
        data.versementReference.scolaritePaiementmodeId = parseInt(modePaiement);
        if(oldModePaiement === 1 && data.versementReference.scolaritePaiementmodeId !== 1){
            data.versementReference.dateModePaiementForm = null ;
        }
        if(oldModePaiement !== 1 && data.versementReference.scolaritePaiementmodeId === 1){
            data.versementReference.dateModePaiementForm = null ;
        }
        data.versementReference.modePaiementTag = 'espece';
        if(data.versementReference.scolaritePaiementmodeId === 2 ){
            data.versementReference.modePaiementTag = 'cheque';
        }if(data.versementReference.scolaritePaiementmodeId === 3 ){
            data.versementReference.modePaiementTag = 'virement';
        }

        scolaritePaiementStateUpdate.dataSel(logic, data);
        formRef.current.setValue('versementReference', data.versementReference);
    }
    const handlePaiementModeDateChange = (value: moment.Moment,     context: any) => {
        const data = _.cloneDeep(logic.dataSel);
        
        data.versementReference.dateModePaiementForm = value.clone().startOf('day');

        scolaritePaiementStateUpdate.dataSel(logic, data);
        formRef.current.setValue('versementReference', data.versementReference);
    }
    
    const onHandlecodeModePaiement = (event:any) => {
        const data = _.cloneDeep(logic.dataSel);
        data.versementReference.codeModePaiement = event.target.value;
        scolaritePaiementStateUpdate.dataSel(logic, data);
        formRef.current.setValue('versementReference', data.versementReference);
    }
    const onCommentChange = (event: any) => {
        const data = _.cloneDeep(logic.dataSel);
        data.versementReference.commentaire = event.target.value;
        scolaritePaiementStateUpdate.dataSel(logic, data);
        formRef.current.setValue('versementReference', data.versementReference);
    };
    const onHandlePaymentBy = (event:any) => {
        const data = _.cloneDeep(logic.dataSel);
        data.versementReference.payerpar = event.target.value;
        scolaritePaiementStateUpdate.dataSel(logic, data);
        formRef.current.setValue('versementReference', data.versementReference);
    }

    const handleMontantChange = (scolariteEtat:ScolariteEtat, montant: number | undefined) => {
        if(_.isNumber(montant) === false){
            return;
        }
        const data  = _.cloneDeep(logic.dataSel);
        const index = _.findIndex(data.scolariteEtats, {id:scolariteEtat.id} );

        if(index < 0){
            return ;
        }
        // @ts-ignore
        scolariteEtat.nouveauVersement.montant = montant;
        data.scolariteEtats[index] = scolariteEtat;

        scolaritePaiementStateUpdate.dataSel(logic, data);
        formRef.current.setValue('scolariteEtats', data.scolariteEtats);
        setMontantChange(moment().valueOf());
    }

    const handleMontantEcheancierChange = (scolariteEtat:ScolariteEtat, versementEcheancierEnfant: ScolariteVersementEcheancierEnfant, montant: number | undefined, disabled:boolean) => {
        if(_.isNumber(montant) === false){
            return;
        }
        const data  = _.cloneDeep(logic.dataSel);
        const indexScolariteEtat = _.findIndex(data.scolariteEtats, {id:scolariteEtat.id} );

        if(indexScolariteEtat < 0){
            return ;
        }
        const nouveauVersement = scolariteEtat.nouveauVersement ;
        if(_.isObject(nouveauVersement) === false){
            return;
        }
        // @ts-ignore
        const indexVersementEcheancier = _.findIndex(nouveauVersement.scolariteVersementEcheanciers, {'id':versementEcheancierEnfant.id} );
        if(indexVersementEcheancier < 0){
            return ;
        }
        versementEcheancierEnfant.disabled  = disabled;
        // @ts-ignore
        versementEcheancierEnfant.montant   = montant;
        // @ts-ignore
        nouveauVersement.scolariteVersementEcheanciers[indexVersementEcheancier] = versementEcheancierEnfant;
        // @ts-ignore
        scolariteEtat.nouveauVersement = nouveauVersement;
        // @ts-ignore
        scolariteEtat.nouveauVersement.montant = _.sumBy(nouveauVersement.scolariteVersementEcheanciers, item => {
            if(_.isObject(item) === false || item?.disabled){
                return 0 ;
            }
            // @ts-ignore
            return item.montant ;
        });
        data.scolariteEtats[indexScolariteEtat] = scolariteEtat;

        scolaritePaiementStateUpdate.dataSel(logic, data);
        formRef.current.setValue('scolariteEtats', data.scolariteEtats);
        setMontantChange(moment().valueOf());
    }

    const submitForm = async () => {
        scolaritePaiementStateUpdate.processingStateNormBtn(logic, true);
        // @ts-ignore
        const dataToSend: ScolariteData = _.cloneDeep(logic.dataSel);
        const person = logic.userRef.current.userDetails.personDetails.person ;
        dataToSend.versementReference.agentCaisse = `${person.nom} ${person.prenom}`;
        dataToSend.versementReference.dateModePaiement = _.isObject(dataToSend.versementReference.dateModePaiementForm) ? dataToSend.versementReference.dateModePaiementForm?.clone().valueOf() : null;

        const scolariteEtatList : any [] = []
        for(const scolariteEtat of dataToSend.scolariteEtats){
            const nouveauversement = scolariteEtat?.nouveauVersement ;
            if(_.isObject(nouveauversement) === false){
                continue ;
            }
            // @ts-ignore
            const versementEcheanciers = nouveauversement.scolariteVersementEcheanciers ;
            if(_.isEmpty(versementEcheanciers)){
                scolariteEtatList.push(scolariteEtat);
                continue ;
            }
            const versementEcheanciersToSend : any[] = [];
            // @ts-ignore
            for(const versementEcheancierItem of versementEcheanciers){
                // @ts-ignore
                if(versementEcheancierItem.disabled ||  isPositiveValue(versementEcheancierItem.montant) === false){
                    continue ;
                }
                versementEcheanciersToSend.push(versementEcheancierItem);
            }
            // @ts-ignore
            nouveauversement.scolariteVersementEcheanciers = versementEcheanciersToSend;
            // @ts-ignore
            scolariteEtat.nouveauVersement = nouveauversement;
            scolariteEtatList.push(scolariteEtat);
        }
        dataToSend.scolariteEtats = scolariteEtatList;
        // @ts-ignore
        const toSend = SCOLARITEDATA_CONSTANT.convertToBackEnd(logic, dataToSend);


        scolaritePaiementBusinessLogic.saveEdit(
            logic,
            messages,
            toSend).then((response:any) => {
                const data = _.cloneDeep(scolariteDataDataImpl);
                scolaritePaiementStateUpdate.dataSel(logic, data);
                scolaritePaiementStateUpdate.childSelected(logic, _.cloneDeep(childDataImpl));
                formRef.current.reset(data);
            scolariteFraisGroupStateUpdate.processingStateNormBtn(logic, false);
        }).catch((e:any) => scolariteFraisGroupStateUpdate.processingStateNormBtn(logic, false));
    };
    const handleSubmit =   async () => {
        const lang = logic.activeLang ;
        if(logic.dataSel.versementReference.groupEnfantId < 1){
            logic.snackbarShowMessage(lang === "en" ? "Please select a child" : "Veuillez choisir un enfant", 'error');
            return ;
        }

        if(_.isEmpty(logic.dataSel.scolariteEtats)){
            logic.snackbarShowMessage(lang === "en" ? "Please select a fee" : "Veuillez choisir des frais", 'error');
            return ;
        }

        if(_.isEmpty(logic.dataSel.versementReference.codeModePaiement) || _.size(logic.dataSel.versementReference.codeModePaiement)<2){
            if(logic.dataSel.versementReference.scolaritePaiementmodeId === 2){
                logic.snackbarShowMessage(lang === "en" ? "Please enter the cheque number" : "Veuillez renseigner le numéro du chèque", 'error');
                return;
            }
            if(logic.dataSel.versementReference.scolaritePaiementmodeId === 3){
                logic.snackbarShowMessage(lang === "en" ? "Please enter the transfer number" : "Veuillez renseigner le numéro du virement", 'error');
                return;
            }
        }

        if(!isPositiveValue(logic.dataSel.versementReference.dateModePaiementForm?.clone()?.valueOf())){
            if(logic.dataSel.versementReference.scolaritePaiementmodeId !== 1){
                logic.snackbarShowMessage(lang === "en" ? "Please enter the date of operation" : "Veuillez renseigner la date de l'opération", 'error');
                return;
            }
        }

        let errorDetecter = false ;
        logic.dataSel.scolariteEtats.forEach((scolariteEtat:ScolariteEtat) => {
            if(isPositiveValue(scolariteEtat?.nouveauVersement?.montant) || _.isEmpty(scolariteEtat?.nouveauVersement?.scolariteVersementEcheanciers)){
                return ;
            }
            logic.snackbarShowMessage(`Veuillez renseigner le montant du frais ${scolariteEtat?.nouveauVersement?.scolariteGroupForm?.scolariteTypeForm?.nom}`, 'error');
            errorDetecter=true ;
        })

        if(errorDetecter){
            return ;
        }
        submitForm();
    };


    const filtrerScolariteGroupOfAnnee = (idScolariteAnneeSel:number, idChild:number, profilsIdList: number[]) => {
        const groupEnfant : GroupEnfant =  _.find(logic.groupEnfantsRef.current, {'enfantId':idChild, 'scolariteAnneeId':idScolariteAnneeSel});
        if(!_.isObject(groupEnfant)){
            return ;
        }
        const groupesectionId = groupEnfant.groupesectionId ;
        const scolariteGroups =  _.filter(logic.scolariteGroupsRef.current, item => {
            const isMatchProfil = !isPositiveValue(item.profilsId) || _.includes(profilsIdList, item.profilsId);
            return isMatchProfil && item.scolariteAnneeId === idScolariteAnneeSel && item.groupesectionId === groupesectionId ;
        });

        const scolariteAnnee = _.find(logic.scolariteAnneesRef.current, {id:idScolariteAnneeSel}) ?? _.cloneDeep(scolariteAnneeDataImpl);
        let data = _.cloneDeep(logic.dataSel);
        data.enfantId = groupEnfant.enfantId ;
        data.groupesectionId = groupEnfant.groupesectionId ;
        data.scolariteAnneeId = groupEnfant.scolariteAnneeId ;
        data.codeScolariteAnnee = scolariteAnnee.code ;
        data.versementReference.groupEnfantId = groupEnfant?.id ?? 0;

        scolaritePaiementStateUpdate.dataSel(logic, data);
        scolaritePaiementStateUpdate.groupEnfantSel(logic, groupEnfant);
        scolaritePaiementStateUpdate.scolariteGroupList(logic, scolariteGroups);
        formRef.current.setValue('versementReference', data.versementReference);

        setNumeriChange(moment().valueOf());
    }

    const scolariteEtats = useMemo(
        // @ts-ignore
        () => {
            const listAPayer: any[] = [];

            for(const opt of logic.dataSel.scolariteEtats){
                const scolariteVersementEcheanciers = opt.nouveauVersement?.scolariteVersementEcheanciers ;

                if(_.isEmpty(scolariteVersementEcheanciers)) {
                    const ligne = (
                        <Grid container key={opt.nouveauVersement?.scolariteGroupForm?.scolariteTypeForm?.id} spacing={2} sx={{ marginBottom:2,}}>
                            <Grid item md={4} xs={12}>
                                <Box className={styleClasses.itemPaymentTypeState}>
                                    <Box sx={{ minWidth:{ xs:150, sm:200, textTransform: 'uppercase' } }}>
                                        {isNotBlank(opt.nouveauVersement.code) ?  opt.nouveauVersement.code : opt.nouveauVersement.nomFraisGroup} :
                                    </Box>

                                    <div className={styleClasses.schoolingInputField}>
                                        <NumericFormat
                                            value={opt.nouveauVersement.montant}
                                            customInput={TextField}
                                            thousandSeparator={' '}
                                            onValueChange={(values, sourceInfo) => {
                                                handleMontantChange(opt, values.floatValue);
                                            }}/>
                                    </div>
                                </Box>
                            </Grid>


                            <Grid item md={3} xs={12}>
                                <Box className={styleClasses.itemPaymentTypeState}>
                                    <Box sx={{marginTop:1, color:'green' }}><FormattedMessage id={messages.schooling_total_payment} /> : {opt.montantPayerForm} FCFA</Box>
                                </Box>
                            </Grid>

                            <Grid item md={3} xs={12}>
                                <Box className={styleClasses.itemPaymentTypeState}>
                                    <Box sx={{marginTop:1, color:'red' }}><FormattedMessage id={messages.schooling_restant_apayer} /> : {opt.montantSoldeForm} FCFA</Box>
                                </Box>
                            </Grid>

                            <Grid item md={2} xs={12}>
                                <Box className={styleClasses.itemPaymentTypeState}>
                                    <Box sx={{marginTop:1}}><FormattedMessage id={messages.schooling_total_montant} /> : {opt.montantFactureForm} FCFA</Box>
                                </Box>

                            </Grid>
                        </Grid>
                    );
                    listAPayer.push(ligne);
                    continue ;
                }

                for(const versementEcheancier of scolariteVersementEcheanciers){
                    const opacity = versementEcheancier.disabled ? 0.6 : 1.0;
                    //const textDecoration = versementEcheancier.disabled ? 'line-through' : 'None';
                    const ligne = (
                        <Grid container key={versementEcheancier.id} spacing={2} sx={{ marginBottom:2,}}>
                            <Grid item md={4} xs={12}>
                                <Box className={styleClasses.itemPaymentTypeState}>
                                    <Box sx={{ minWidth:{ xs:150, sm:200}, textTransform: 'uppercase', opacity}}>
                                        <Checkbox
                                            checked={!versementEcheancier.disabled}
                                            onChange={(event:any) => handleMontantEcheancierChange(opt, versementEcheancier, versementEcheancier.montant, !event.target.checked)}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        /> {isNotBlank(versementEcheancier.code) ?  versementEcheancier.code : versementEcheancier.nom} :
                                    </Box>

                                    <div className={styleClasses.schoolingInputField}>
                                        <NumericFormat
                                            disabled={versementEcheancier.disabled}
                                            value={versementEcheancier.montant}
                                            customInput={TextField}
                                            thousandSeparator={' '}
                                            onValueChange={(values, sourceInfo) => {
                                                handleMontantEcheancierChange(opt, versementEcheancier, values.floatValue, versementEcheancier.disabled);
                                            }}/>
                                    </div>
                                </Box>
                            </Grid>


                            <Grid item md={3} xs={12}>
                                <Box className={styleClasses.itemPaymentTypeState}>
                                    <Box sx={{marginTop:1, color:'green', opacity}}><FormattedMessage id={messages.schooling_total_payment} /> : {versementEcheancier.montantPayerForm} FCFA</Box>
                                </Box>
                            </Grid>

                            <Grid item md={3} xs={12}>
                                <Box className={styleClasses.itemPaymentTypeState}>
                                    <Box sx={{marginTop:1, color:'red',  opacity}}><FormattedMessage id={messages.schooling_restant_apayer} /> : {versementEcheancier.montantSoldeForm} FCFA</Box>
                                </Box>
                            </Grid>

                            <Grid item md={2} xs={12}>
                                <Box className={styleClasses.itemPaymentTypeState}>
                                    <Box sx={{marginTop:1,  opacity}}><FormattedMessage id={messages.schooling_total_montant} /> : {versementEcheancier.montantFactureForm} FCFA</Box>
                                </Box>

                            </Grid>
                        </Grid>
                    );
                    listAPayer.push(ligne);
                }
            }

            return listAPayer ;
        },
        [logic.dataSel.scolariteEtats],
    );


    const scolariteTypeMenuItems = useMemo<any>(() => {
        return _.map(logic.scolariteTypeRef?.current, item => (
            <Grid item md={12} xs={12} sx={{p:0, m:0, mb:1}} key={item.id}>
                <FormControlLabel
                    sx={{p:0, m:0}}
                    control={<Checkbox
                        checked={logic.dataSel.scolariteTypeIds.includes(item.id)}
                        color={"secondary"}
                        sx={{p:0, m:0, mr:1}}
                        onChange={(event:any) => handleScolariteType(item.id, event)}
                    />}
                    label={<Typography variant={"subtitle2"} sx={{ fontWeight:'normal'}}>{item.nom}</Typography>}
                />
            </Grid>
        ));
    }, [logic.scolariteTypeRef?.current, logic.dataSel.scolariteTypeIds]) ;

    const profilsMenuItems = useMemo<any>(() => {
        return _.map(logic.profilsRef?.current, item => (
            <FormControlLabel
                key={item.id}
                sx={{p:0, m:0, mr:1}}
                control={<Checkbox
                    checked={_.findIndex(logic.childSelected.enfantProfils, {'profilsId':item.id}) >= 0}
                    color={"secondary"}
                    disabled
                    sx={{p:0, m:0}}
                />}
                label={<Typography variant={"subtitle2"} sx={{ fontWeight:'normal'}}>{item.nom}</Typography>}
            />
        ));
    }, [logic.profilsRef?.current, logic.childSelected.enfantProfils]) ;

    const scolariteAnneeMenuItems = useMemo<any>(() => {
        return _.map(logic.scolariteAnneesRef?.current, item => (
            <MenuItem key={item.id} value={item.id} sx={{fontWeight: 400}}>
                {item.nom}
            </MenuItem>
        ));
    }, [logic.scolariteAnneesRef?.current]) ;

    useEffect(() => {
        if(isPositiveValue(logic.idScolariteAnneeSel) && isPositiveValue(logic.childSelected?.id)){
            const profilsIds: number[] = _.map(logic.childSelected?.enfantProfils, item => item.profilsId);
            filtrerScolariteGroupOfAnnee(logic.idScolariteAnneeSel, logic.childSelected.id, profilsIds);
        }
    }, [logic.childSelected, logic.idScolariteAnneeSel]);

    useEffect(() => {
        clearTimeout(menuLeaveTimerRef.current);
        if(isPositiveValue(logic.dataSel.versementReference.groupEnfantId) === false){
            return ;
        }
        if(_.isEmpty(logic.dataSel.scolariteTypeIds)){
            return ;
        }
        const data : ScolariteData   =  _.cloneDeep(logic.dataSel);
        data.scolariteEtats = [];
        scolaritePaiementStateUpdate.dataSel(logic, data) ;
        //console.log(logic.dataSel);
        menuLeaveTimerRef.current = setTimeout(function() {
            scolaritePaiementStateUpdate.showDetailsTableContent(logic, false);
            scolaritePaiementBusinessLogic.loadScolariteForPaiement(logic, _.cloneDeep(logic.dataSel)).then((val:any) => {
                scolaritePaiementStateUpdate.showDetailsTableContent(logic, true);
            }).catch((error:any) => {
                logic.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                scolaritePaiementStateUpdate.showDetailsTableContent(logic, true);
            });
        }, 1500);
        return () => {
        };
    }, [numeriChange]);

    useEffect(() => {
        if(logic.showDetailsTableContent === false){
            return ;
        }
        formRef.current.reset(logic.dataSel);
    }, [logic.showDetailsTableContent]);

    useEffect(() => {
        const data = _.cloneDeep(logic.dataSel);
        data.versementReference.montant         = sumMontantVerser(logic, data.scolariteEtats);
        data.versementReference.montantForm     = new Intl.NumberFormat("fr-FR").format(data.versementReference.montant ?? 0) ;
        const montantSolde             = data.montantSolde - data.versementReference.montant ;
        const montantSoldeForm           = new Intl.NumberFormat("fr-FR").format(montantSolde ?? 0) ;
        setMontantSolde(montantSolde);
        setMontantSoldeForm(montantSoldeForm);

        scolaritePaiementStateUpdate.dataSel(logic, data);
        formRef.current.setValue('versementReference', data.versementReference);
    }, [montantChange, logic.showDetailsTableContent]);

    useEffect(() => {
        moment.locale(logic.activeLang);
        scolaritePaiementBusinessLogic.initPostConstruct(logic);
    }, []);


    return (
        <div className={styleClasses.root}>
            <Box className={styleClasses.buttonContainer}>
                <SchoolingSubMenu classes={styleClasses} selected="addpayment"/>
            </Box>

            <PapperBlock whiteBg noMargin displayNoTitle={true}>
                {
                    logic.contentLoaded === false &&
                    <Box  sx={{ width: '100%', height:'200px', textAlign: 'center', alignContent:'center', verticalAlign:'middle' }}>
                        <CircularProgress color="primary" />
                    </Box>
                }

                {
                    logic.contentLoaded &&
                    <form>
                        <div className={styleClasses.content}>
                            <Box className={styleClasses.schoolingContainer}>
                                <Grid container spacing={2}>
                                    <Grid item md={7} xs={12}>
                                        <Grid item md={12} xs={12}>
                                            <FormControl
                                                sx={{
                                                    display: { xs: 'block', sm: 'inline-block'},
                                                    marginBottom: { xs: 2},
                                                    marginRight: { sm: 2 },
                                                    mt:2,
                                                    width:'100%',
                                                }}
                                            >
                                                <TextField
                                                    select
                                                    label={<FormattedMessage id={messages.scolaritee_annee}/>}
                                                    sx={{
                                                        width: {
                                                            xs: '100%',
                                                            sm: '100%',
                                                            fontWeight: 'normal',
                                                        }
                                                    }}
                                                    color={"secondary"}
                                                    value={logic.idScolariteAnneeSel}
                                                    onChange={handleOnScolariteAnneChange}
                                                    inputProps={{
                                                        required: "Required field",
                                                        min: 1,
                                                    }}
                                                >
                                                    <MenuItem key="0" value="0" sx={{fontWeight: 400}}>-------</MenuItem>
                                                    {scolariteAnneeMenuItems}
                                                </TextField>
                                            </FormControl>

                                        </Grid>
                                        <Grid item md={12} xs={12}>
                                            <FormControl
                                                sx={{
                                                    display: { xs: 'block', sm: 'inline-block'},
                                                    marginBottom: { xs: 2},
                                                    marginRight: { sm: 2 },
                                                    width:'100%',
                                                }}
                                                className={styleClasses.autoCompleteInput}
                                            >
                                                <Autocomplete
                                                    sx={{
                                                        width: {
                                                            xs: '100%',
                                                            sm: '100%',
                                                            fontWeight: 'normal',
                                                        }
                                                    }}
                                                    options={logic.childrenClasseRef.current}
                                                    value={logic.childSelected}
                                                    autoHighlight
                                                    getOptionLabel={(opt:any) => opt.childNomPrenomForm}
                                                    isOptionEqualToValue={(opt:any, value:any) => opt.id === value.id}
                                                    // @ts-ignore
                                                    groupBy={(opt:any) => opt.eleves?.[0]?.classe.nom ?? ''}
                                                    loading={false}
                                                    onChange={handleChildChange}
                                                    renderOption={(props, opt:any) => (
                                                        <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}, fontWeight: 400}} {...props}>
                                                            <Avatar alt="avatar"  src={opt.childphotoForm} sx={{mr: 2}}/>
                                                            {opt.childNomPrenomForm}
                                                        </Box>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label={<FormattedMessage id={messages.choose_child} />}
                                                            required
                                                            color="secondary"
                                                            variant="outlined"
                                                            sx={{marginTop: 0.4, width: '100% !important'}}
                                                            size="small"
                                                            placeholder={searchPlaceholderRef.current}
                                                            inputProps={{
                                                                ...params.inputProps
                                                            }}
                                                        />
                                                    )}
                                                />
                                                <div className={styleClasses.errorHelper}>
                                                    {errors?.versementReference?.groupEnfantId && <span><FormattedMessage id={messages.required_field_child}/></span>}
                                                </div>
                                            </FormControl>
                                        </Grid>

                                        <Grid container spacing={2}>
                                            <Grid item md={2} xs={12}>
                                                <Box className={classNames(styleClasses.userAvatarBox)}  sx={{backgroundColor:'#F1EFF0'}}>
                                                    <Avatar className={styleClasses.customAvatar}
                                                            src={logic.childSelected.childphotoForm}
                                                            sx={{width:80, height:80}}
                                                            alt={logic.childSelected.childNomPrenomForm}
                                                    />
                                                </Box>
                                            </Grid>

                                            <Grid item md={5} xs={12}>
                                                <div className={classNames(styleClasses.inputField, styleClasses.inputFieldMarginBottom)}>
                                                    <TextField
                                                        size="small"
                                                        color={"secondary"}
                                                        sx={{ width:'100% !important' }}
                                                        placeholder={"Prenom"}
                                                        value={logic.childSelected.childnomForm}
                                                        disabled
                                                        //error={!!errors.period?.message}
                                                        //helperText={errors.username?.message}
                                                    />
                                                </div>

                                                <div className={classNames(styleClasses.inputField, styleClasses.inputFieldMarginBottom)}>
                                                    <TextField
                                                        size="small"
                                                        color={"secondary"}
                                                        sx={{ width:'100% !important' }}
                                                        placeholder={"Nom"}
                                                        value={logic.childSelected.childprenomForm}
                                                        disabled
                                                        //error={!!errors.period?.message}
                                                        //helperText={errors.username?.message}
                                                    />
                                                </div>

                                            </Grid>

                                            <Grid item md={5} xs={12}>
                                                <div className={classNames(styleClasses.inputField, styleClasses.inputFieldMarginBottom)}>
                                                    <TextField
                                                        size="small"
                                                        color={"secondary"}
                                                        sx={{ width:'100% !important' }}
                                                        placeholder={intl.formatMessage({id:messages.the_group})}
                                                        value={logic.groupEnfantSel?.groupesectionForm?.nom}
                                                        disabled
                                                        //error={!!errors.period?.message}
                                                        //helperText={errors.username?.message}
                                                    />
                                                </div>

                                                <div className={classNames(styleClasses.inputField, styleClasses.inputFieldMarginBottom)}>
                                                    <TextField
                                                        size="small"
                                                        color={"secondary"}
                                                        sx={{ width:'100% !important' }}
                                                        placeholder={"Date de naissance"}
                                                        value={logic.childSelected.person.dateNaissanceForm}
                                                        disabled
                                                        //error={!!errors.period?.message}
                                                        //helperText={errors.username?.message}
                                                    />
                                                </div>
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={2}>
                                            <Grid item md={2} xs={12}> </Grid>
                                            <Grid item md={5} xs={12}>
                                                <Typography variant={"subtitle2"}><FormattedMessage id={messages.fees_type} /> *</Typography>
                                                <Box sx={{ overflow:'auto', padding:1, marginTop:1, height:110, backgroundColor:"#F1EFF0"}}>
                                                    {scolariteTypeMenuItems}
                                                </Box>
                                            </Grid>

                                            <Grid item md={5} xs={12}>
                                                <div style={{ marginBottom:15}}>
                                                    <Typography variant={"subtitle2"}><FormattedMessage id={messages.paiement_date} /> *</Typography>
                                                    <Box sx={{marginTop:1,}}>
                                                        <div className={styleClasses.inputField}>
                                                            <LocalizationProvider
                                                                dateAdapter={AdapterMoment} adapterLocale={logic.activeLang}>
                                                                <MobileDateTimePicker
                                                                    label={<FormattedMessage id={messages.day} />}
                                                                    value={logic.dataSel.versementReference.ladateForm}
                                                                    maxDate={moment().endOf('day')}
                                                                    //@ts-ignore
                                                                    onChange={handleLaDateChange}
                                                                    views={['year', 'month', 'day']}
                                                                    slotProps={{ textField: { color: 'secondary' } }}
                                                                    sx={{
                                                                        width: {
                                                                            xs: '100%',
                                                                            sm: '100%',
                                                                            fontWeight: 'normal',
                                                                        }
                                                                    }}
                                                                />
                                                            </LocalizationProvider>

                                                        </div>
                                                    </Box>
                                                </div>

                                                <div>
                                                    <Typography variant={"subtitle2"}><FormattedMessage id={messages.paiement_by} /></Typography>
                                                    <Box sx={{marginTop:1,}}>
                                                        <div className={styleClasses.inputField}>
                                                            <InputField
                                                                label={<FormattedMessage id={messages.header_nom} />}
                                                                size="small"
                                                                color={"secondary"}
                                                                sx={{ width:'100% !important' }}
                                                                placeholder={"First name and Last name "}
                                                                onChange={onHandlePaymentBy}
                                                                value={logic.dataSel.versementReference.payerpar}
                                                            />
                                                        </div>
                                                    </Box>
                                                </div>
                                            </Grid>

                                        </Grid>
                                    </Grid>


                                    <Grid item md={5} xs={12} sx={{mt:9}} >
                                        <Grid container>
                                            <Grid item md={12} xs={12}>
                                               <Grid container>
                                                <Grid item md={12} xs={12} sx={{marginTop:1, marginBottom: { xs:1, sm:1 } }}>
                                                    <Typography variant={"subtitle2"}>
                                                        <FormattedMessage id={messages.profile_child} />
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={12} xs={12} sx={{marginTop:1, marginBottom: { xs:1, sm:1 } }}>
                                                    <FormGroup row={true}>
                                                        {profilsMenuItems}
                                                    </FormGroup>
                                                </Grid>
                                            </Grid>

                                                <Grid item md={12} xs={12} sx={{ marginTop:1, }}>
                                                    <FormControl className={styleClasses.radioContainer}>
                                                        <RadioGroup
                                                            row
                                                            value={logic.dataSel.versementReference.scolaritePaiementmodeId}
                                                            onChange={onPaiementModeChange}
                                                            sx={{width:'100% !important'}}>
                                                            <FormControlLabel
                                                                sx={{ fontWeight: 'normal', width: 150, }}
                                                                value="1"
                                                                control={<Radio value="1" size="small" color={"secondary"}/>}
                                                                label={<FormattedMessage id={messages.paiement_espece} />}
                                                            />

                                                            <FormControlLabel
                                                                sx={{ fontWeight: 'normal', width: 150, }}
                                                                value="2"
                                                                control={<Radio value="2" size="small" color={"secondary"}/>}
                                                                label={<FormattedMessage id={messages.paiement_cheque} />}
                                                            />

                                                            <FormControlLabel
                                                                sx={{ fontWeight: 'normal', width: 150, }}
                                                                value="3"
                                                                control={<Radio value="3" size="small" color={"secondary"}/>}
                                                                label={<FormattedMessage id={messages.paiement_virement} />}
                                                            />
                                                        </RadioGroup>
                                                    </FormControl>

                                                    <div className={classNames(styleClasses.inputField)}>
                                                        <InputField
                                                            label={<FormattedMessage id={messages.paiement_mode_numero}/>}
                                                            size="small"
                                                            color={"secondary"}
                                                            sx={{ width: { xs: '100% !important', sm:'85% !important' } }}
                                                            placeholder={logic.activeLang === 'en' ? `Cheque number, bank transfer...` : `Numéro du bordereau, chèque ou virement`}
                                                            value={logic.dataSel.versementReference.codeModePaiement}
                                                            disabled={logic.dataSel.versementReference.scolaritePaiementmodeId === 1}
                                                            onChange={onHandlecodeModePaiement}
                                                        />
                                                    </div>


                                                </Grid>
                                                {
                                                    logic.dataSel.versementReference.scolaritePaiementmodeId !== 1 &&
                                                    <Grid item md={12} xs={12} sx={{ marginTop:2, }}>
                                                        <div className={styleClasses.inputField} style={{width:'100%', marginTop:10}}>
                                                            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={logic.activeLang}>
                                                                <MobileDateTimePicker
                                                                    label={<FormattedMessage id={messages.paiement_mode_date} />}
                                                                    value={logic.dataSel.versementReference.dateModePaiementForm}
                                                                    //@ts-ignore
                                                                    onChange={handlePaiementModeDateChange}
                                                                    views={['year', 'month', 'day']}
                                                                    slotProps={{ textField: { color: 'secondary' } }}
                                                                    maxDate={logic.dataSel.versementReference.ladateForm}
                                                                    sx={{
                                                                        width: {
                                                                            xs: '100%',
                                                                            sm: '100%',
                                                                            fontWeight: 'normal',
                                                                        }
                                                                    }}
                                                                />
                                                            </LocalizationProvider>
                                                        </div>
                                                    </Grid>
                                                }


                                            </Grid>

                                            {/*<Grid item md={4} xs={12}> </Grid>*/}

                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Grid container spacing={2} sx={{marginTop:2}}>
                                <Grid item md={1} xs={12} sx={{ display:{xs:'none', sm:'inline-block'}}}> </Grid>
                                <Grid item md={11} xs={12}>
                                    <Box sx={{
                                        marginLeft:{ xs: 0, sm: 3},
                                        padding:1,
                                        backgroundColor: '#E86FA5',
                                        //backgroundColor: '#2A88C9',
                                        color:'#FFF',
                                        marginBottom:3,
                                        fontWeight:500
                                    }}>
                                        <FormattedMessage id={messages.schooling_versement_etat} />
                                    </Box>

                                    {
                                        !logic.showDetailsTableContent &&
                                        <Box  sx={{ width: '100%', textAlign: 'center', alignContent:'center', }}>
                                            <CircularProgress color="primary" />
                                        </Box>
                                    }
                                    {
                                        logic.showDetailsTableContent &&
                                        <Box sx={{ marginLeft:{ xs: 0, sm: 3}, marginBottom:5 }}>
                                            {scolariteEtats}
                                        </Box>
                                    }


                                    <Grid container spacing={2}>
                                        <Grid item md={12} xs={12} sx={{backgroundColor: '#F1EFF0', ml:5, mb:4 }}>
                                            <Grid container>
                                                <Grid item md={4} xs={12} sx={{paddingBottom:1, paddingLeft:{xs:0, sm:2,} }}>
                                                    <Typography variant={'subtitle2'}><FormattedMessage id={messages.schooling_total_new_payment} /> : {logic.dataSel.versementReference.montantForm} FCFA</Typography>
                                                </Grid>
                                                <Grid item md={4} xs={12} sx={{paddingBottom:1, paddingLeft:{xs:0, sm:2,} }}>
                                                    <Typography variant={'subtitle2'}><FormattedMessage id={messages.schooling_total_payment_done} /> : {logic.dataSel.montantPayerForm} FCFA</Typography>
                                                </Grid>
                                                <Grid item md={4} xs={12} sx={{paddingBottom:1}}>
                                                    <Typography variant={'subtitle2'}><FormattedMessage id={messages.schooling_total_restant} /> : {montantSoldeForm} FCFA</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>


                                        <Grid item md={4} xs={12} > </Grid>
                                        <Grid item md={8} xs={12}>
                                            <div className={styleClasses.inputField}>
                                                <InputField
                                                    label={<FormattedMessage id={messages.comment} />}
                                                    size="small"
                                                    color={"secondary"}
                                                    InputProps={{
                                                        rows: 3,
                                                        multiline: true,
                                                        inputComponent: 'textarea',
                                                    }}
                                                    value={logic.dataSel.versementReference.commentaire}
                                                    onChange={onCommentChange}
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>

                                </Grid>

                                <Grid item md={12} xs={12} sx={{mt:3, mb:3, textAlign:'right'}}>
                                    <LoadingButton
                                        loading={logic.processingStateNormBtn}
                                        color="primary"
                                        variant="contained"
                                        loadingPosition="start"
                                        startIcon={<SaveIcon />}
                                        aria-label="Save payment"
                                        size="large"
                                        onClick={handleSubmit}
                                    >
                                        <FormattedMessage id={messages.schooling_save_payment} />
                                    </LoadingButton>
                                </Grid>
                            </Grid>

                        </div>
                    </form>
                }

            </PapperBlock>
        </div>
    );
}

export default withSnackbar(SchoolingPayment);
