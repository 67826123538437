import {Box, Button, Grid, Icon, IconButton, Tooltip} from "@mui/material";
import {FormattedMessage, useIntl} from "react-intl";
import messages from "../messages/general/messages";
import React, {useContext} from "react";
import {RDV_CONSTANT, rdvNormalDataImpl} from "../../constants/type/Rdv";
import _ from "lodash";
import {AppointmentPresetContext} from "./Appointment";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from "@mui/icons-material/Refresh";
import rdvStateUpdate from "./businesslogic/RdvStateUpdate";
import rdvBusinessLogic from "./businesslogic/RdvBusinessLogic";
import rdvNormalBusinessLogic from "./businesslogic/RdvNormalBusinessLogic";
import rdvPresetBusinessLogic from "./businesslogic/RdvPresetBusinessLogic";
import rdvToolBarBusinessLogic from "./businesslogic/RdvToolBarBusinessLogic";
import MenuIcon from "@mui/icons-material/Menu";
import {lireStatutRdv} from "../../constants/type/EventNotification";
import {ExcelIconButton} from "../../helpers/helpers";
import IosShareIcon from '@mui/icons-material/IosShare';
import {RoleUser} from "../../constants/type/RoleUser";


function AppointmenPresetToolBar(props:any){
    const logicRacine: any = useContext(AppointmentPresetContext);
    const intl = useIntl() ;
    const export_xls = logicRacine.activeLanguageRef.current[0] === 'en' ? 'Export to excel' : 'Exporter en excel';
    const excelDataMapping = (rows:any) => {
        return _.map(rows, (row:any) => {
            let obj:any = {};
            obj[intl.formatMessage({id:messages.appointment_type})] = intl.formatMessage({id:messages[`type_${_.toLower(row.rdvTypeForm)}`]});

            obj[intl.formatMessage({id:messages.title_appointment})] = row.title;

            obj[intl.formatMessage({id:messages.rdv_status_libelle})] = intl.formatMessage({id:messages[lireStatutRdv(row.meetingStatus)]});

            obj[intl.formatMessage({id:messages.day})] = row.startForm?.format('L') ?? ``;

            obj[intl.formatMessage({id:messages.hour})] = `${row.startForm?.format('LT')} - ${row.endForm?.format('LT')}`  ;

            obj[intl.formatMessage({id:messages.child})] = row.childNomPrenomForm ?? ``;

            obj['Parent'] = row.parentNameForm ?? ``;

            obj[intl.formatMessage({id:messages.phone_number_child})] = row.parentPhoneForm ?? ``;

            obj[intl.formatMessage({id:messages.classroom_child})] = row.classeNameForm ?? ``;

            obj[intl.formatMessage({id:messages.title_personal_type})] = row.employeeFonctionForm ?? ``;

            obj[intl.formatMessage({id:messages.personal_member})] = row.employeeNameForm ?? ``;
            return obj;
        });
    }

    const handleSaveRdvEdition = React.useCallback(async () => {
        rdvStateUpdate.savingStateBtn(logicRacine, true);
        const  data  = _.cloneDeep(logicRacine.rdvEdit);
        data.creneauRdvs = _.cloneDeep(logicRacine.creneauRdvListInCalendar);
        // @ts-ignore
        const toSend = RDV_CONSTANT.convertToBackEnd(data);

        rdvBusinessLogic.saveRdv(logicRacine, messages, toSend).then((response:any) => {
            rdvStateUpdate.savingStateBtn(logicRacine, false);
            rdvStateUpdate.openPresetDialogForm(logicRacine, false);
        }).catch((e:any) => rdvStateUpdate.savingStateBtn(logicRacine, false));
    }, [logicRacine.creneauRdvListInCalendar]);



    const handleRdvChange = (event: any) => {
        const rdvId = parseInt(event.target.value);
        let rdv =  _.find(logicRacine.rdvList, {id:rdvId}) ;
        if(_.isNull(rdv) || _.isUndefined(rdv)){
            rdv = _.cloneDeep(rdvNormalDataImpl);
        }
        rdvStateUpdate.rdvToolbarSelect(logicRacine, rdv);
        rdvStateUpdate.rdvEdit(logicRacine, rdv);
    }
    const handleClickAddNormalRdv = () => rdvNormalBusinessLogic.handleClickAddNormalRdv(logicRacine);
    const handleClickAddPreset = () => rdvPresetBusinessLogic.handleClickAddPreset(logicRacine);

    const handleClickMenuRdvList = () => rdvStateUpdate.openRdvList(logicRacine);
    const handleFilterRdv = () => rdvToolBarBusinessLogic.handleFilterRdv(logicRacine);
    const handleClickEditRdv = () => {
        const rdv = _.cloneDeep(logicRacine.rdvToolbarSelect);
        rdvStateUpdate.rdvEdit(logicRacine, rdv);
        rdvBusinessLogic.handleClickEditThisRdv(logicRacine, rdv);
    };
    const handleMenuRdvList = () => {
        const rdv = _.cloneDeep(logicRacine.rdvToolbarSelect);
        rdvStateUpdate.rdvEdit(logicRacine, rdv);
        rdvBusinessLogic.handleClickEditThisRdv(logicRacine, rdv);
    };

    return(
        <div style={{display:"flex"}}>
            <Box sx={{ width:'65%', textAlign:'left'}}>
                <Button variant={"contained"}
                        component="label"
                        size="small"
                        color={"primary"}
                        sx={{textTransform: 'none', marginTop:0.5, marginRight: { sm: 2 } }}
                        onClick={handleClickAddNormalRdv}
                >
                    <Icon className={logicRacine.styleClasses.actionIcon}>add</Icon>
                    <FormattedMessage id={messages.add_normal} />
                </Button>

                {
                    logicRacine?.userRef?.current?.role !== RoleUser.TEACHER &&
                    <Button variant={"contained"}
                            component="label"
                            size="small"
                            sx={{
                                backgroundColor: '#577a6a',
                                textTransform: 'none',
                                marginTop: 0.5,
                                marginRight: {sm: 2}
                            }}
                            onClick={handleClickAddPreset}>
                        <Icon className={logicRacine.styleClasses.actionIcon}>add</Icon>
                        <FormattedMessage id={messages.add_preset}/>
                    </Button>
                }


            </Box>
            <Box style={{width:'35%', textAlign:'right'}}>
                <Grid container spacing={2}>
                    <Grid item md={12} xs={12}>
                        <div className={logicRacine.styleClasses.inputField} style={{display:"flex", alignItems:'right', justifyContent:'right' }}>
                            {/*<TextField
                                select
                                label={<FormattedMessage id={messages.rdv_list} />}
                                sx={{ width: { xs: '100% !important'}, float:'right', marginRight:2 }}
                                color={"secondary"}
                                value={logicRacine.rdvToolbarSelect.id ?? 0}
                                onChange={handleRdvChange}
                            >
                                <MenuItem key="0" value="0" sx={{fontWeight:400}}>All</MenuItem>
                                <ListSubheader><FormattedMessage id={messages.normal_title} /></ListSubheader>
                                {
                                    logicRacine.rdvList.filter((data:Rdv) => data.meetingType === RdvType.NORMAL).map( (data:Rdv) => (
                                        // @ts-ignore
                                        <MenuItem key={data.id} value={data.id} sx={{fontWeight:400}}>
                                            {data.objet}
                                        </MenuItem>
                                    ))
                                }
                                <ListSubheader><FormattedMessage id={messages.preset_title} /></ListSubheader>
                                {
                                    logicRacine.rdvList.filter((data:Rdv) => data.meetingType === RdvType.PRESET).map( (data:Rdv) => (
                                        // @ts-ignore
                                        <MenuItem key={data.id} value={data.id} sx={{fontWeight:400}}>
                                            {data.objet}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>*/}
                            {
                                logicRacine.rdvToolbarSelect && !_.isNull(logicRacine.rdvToolbarSelect.id) &&
                                <>
                                    <IconButton
                                        aria-label="edit"
                                        color="secondary"
                                        onClick={handleClickEditRdv}
                                        sx={{float:'right', marginRight:2}}>
                                        <EditIcon />
                                    </IconButton>
                                    {
                                        rdvToolBarBusinessLogic.showToolbarSavePreset(logicRacine, logicRacine.rdvToolbarSelect) &&
                                        <IconButton
                                            aria-label="save"
                                            color="secondary"
                                            onClick={handleSaveRdvEdition}
                                            sx={{float:'right', marginRight:2}}>
                                            {
                                                logicRacine.savingStateBtn &&
                                                <RefreshIcon />
                                            }
                                            {
                                                !logicRacine.savingStateBtn &&
                                                <SaveIcon />
                                            }
                                        </IconButton>
                                    }

                                </>
                            }

                            <Tooltip title="Search">
                                <IconButton
                                    aria-label="search"
                                    color="secondary"
                                    onClick={handleFilterRdv}
                                    sx={{float:'right', marginRight:2}}>
                                    <SearchIcon />
                                </IconButton>
                            </Tooltip>

                            <IconButton
                                aria-label="edit"
                                color="secondary"
                                onClick={handleClickMenuRdvList}
                                sx={{float:'right', marginRight:2}}>
                                <MenuIcon />
                            </IconButton>
                            <ExcelIconButton
                                title={export_xls}
                                nomFichier="Appointment_list"
                                disabled={_.isEmpty(logicRacine.creneauRdvListInCalendar)}
                                excelDataMapping={excelDataMapping(logicRacine.creneauRdvListInCalendar)}
                                sx={{float:'right'}}
                                icon={ <IosShareIcon />}
                            />
                        </div>
                    </Grid>
                </Grid>


                {/*<FormControl
                    sx={{
                        display: { xs: 'block', sm: 'inline-block' },
                        marginBottom: { xs: 2},
                        marginRight: { sm: 2 },
                    }}
                    size="small"
                    color={"secondary"}
                >
                    <Select
                        value={logic.logicRacine.rdvToolbarSelect.id ?? 0}
                        onChange={logic.handleRdvChange}
                        displayEmpty
                        sx={{ textAlign:"left", width: {xs: '100%', sm: 200 }, fontWeight:'normal' }}>

                        <MenuItem value={0} sx={{fontWeight:400}}>All</MenuItem>
                        <ListSubheader><FormattedMessage id={messages.normal_title} /></ListSubheader>
                        {
                            logic.logicRacine.rdvsRef.current?.filter((data:Rdv) => data.meetingType === RdvType.NORMAL).map( (data:Rdv) => (
                                <MenuItem key={data.id} value={data.id ?? 0} sx={{fontWeight:400}}>
                                    {data.objet}
                                </MenuItem>
                            ))
                        }
                        <ListSubheader><FormattedMessage id={messages.preset_title} /></ListSubheader>
                        {
                            logic.logicRacine.rdvsRef.current?.filter((data:Rdv) => data.meetingType === RdvType.PRESET).map( (data:Rdv) => (
                                <MenuItem key={data.id} value={data.id ?? 0} sx={{fontWeight:400}}>
                                    {data.objet}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>*/}


            </Box>
        </div>
    )
}

export default AppointmenPresetToolBar ;
