import React, {useContext, useEffect, useMemo, useState} from "react";
import _ from "lodash";

import {
    Avatar,
    Box,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Grid,
    List,
    ListItemText,
    ListSubheader,
    MenuItem,
    TextField
} from "@mui/material";
import {FormattedMessage} from "react-intl";
import DialogFormKiller from "../../../components/ui/form/DialogFormKiller";
import messages from "../../messages/general/messages";
import {GroupEnfantContext} from "./GroupEnfantPage";
import groupEnfantStateUpdate from "./businesslogic/GroupEnfantStateUpdate";
import {GROUPENFANT_CONSTANT, groupEnfantDataImpl} from "../../../constants/type/GroupEnfant";
import {Child} from "../../../constants/type/Child";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import {EnfantProfils} from "../../../constants/type/EnfantProfils";
import {GROUPENFANTPROFILS_CONSTANT, groupEnfantProfilsDataImpl} from "../../../constants/type/GroupEnfantProfils";


const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 1;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const GroupEnfantAddForm = (props:any) => {
    const logic: any = useContext(GroupEnfantContext);
    const styleClasses = logic.styleClasses ;
   // const enfantIdsOfClasseRef  =useRef<number[]>([]);
    const [checkedAll, setCheckedAll] = useState(false);
    const [idClassRoomSel, setIdClassRoomSel] = useState<number>(0);
    const [checked, setChecked] = useState<number[]>([]);


    const handleCheckedAll = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckedAll(event.target.checked);
        let newChecked:number[] = [];
        if(event.target.checked){
            newChecked = _.map(logic.enfantsOfClasse, item => item.id);
        }
        setChecked(newChecked);
    };
    const handleOnClassRoomChange = (event: any) => {
        setIdClassRoomSel(parseInt(event.target.value));
    };
    const handleToggle = (id: number) => () => {
        const newChecked = _.cloneDeep(checked);
        const currentIndex = _.indexOf(checked, id);

        if (currentIndex < 0) {
            newChecked.push(id);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };

    const onSubmit = async () => {
        groupEnfantStateUpdate.processingStateNormBtn(logic, true);
        const groupEnfants = _.map(checked, enfantId => {
            const groupEnfant = _.cloneDeep(groupEnfantDataImpl);
            groupEnfant.id = enfantId * -1;
            groupEnfant.enfantId = enfantId;
            groupEnfant.groupesectionId = logic.idGroupSel;
            groupEnfant.scolariteAnneeId = logic.idScolariteAnneeSel;

            const enfantProfils = _.find(logic.enfantsOfClasse, {id:enfantId})?.enfantProfils ?? [] ;
            groupEnfant.groupEnfantProfils = _.map(enfantProfils, (item:EnfantProfils) => {
                const groupEnfantProfil = _.cloneDeep(groupEnfantProfilsDataImpl);
                groupEnfantProfil.profilsId = item.profilsId ;
                return GROUPENFANTPROFILS_CONSTANT.convertToFrontEnd(logic, groupEnfantProfil)  ;
            });

            return  GROUPENFANT_CONSTANT.convertToFrontEnd(logic, groupEnfant);
        });
        groupEnfantStateUpdate.addDataList(logic, groupEnfants);
        groupEnfantStateUpdate.processingStateNormBtn(logic, false);
        groupEnfantStateUpdate.openAddDialogForm(logic, false);
    };

    const classroomMenuItems = useMemo<any>(() => {
        return _.map(logic.classeRoomRef?.current, item => (
            <MenuItem key={item.id} value={item.id} sx={{fontWeight: 400}}>
                {item.nom}
            </MenuItem>
        ));
    }, [logic.classeRoomRef?.current]) ;

    const eleveListItems = useMemo<any>(() => {
        if(_.isEmpty(logic.enfantsOfClasse)){
            return <></>;
        }
        return _.map(logic.enfantsOfClasse, item => {
            const labelId = `checkbox-list-secondary-label-${item.id}`;
            return (
                <ListItem
                    key={item.id}
                    secondaryAction={
                        <Checkbox
                            edge="end"
                            onChange={handleToggle(item.id ?? 0)}
                            checked={_.includes(checked, item.id)}
                            inputProps={{ 'aria-labelledby': labelId }}
                        />
                    }
                    disablePadding
                >
                    <ListItemButton onClick={handleToggle(item.id ?? 0)}>
                        <ListItemAvatar>
                            <Avatar
                                alt={item.childNomPrenomForm}
                                src={item.childphotoForm}
                            />
                        </ListItemAvatar>
                        <ListItemText id={labelId}
                                      primary={item.childNomPrenomForm} />
                    </ListItemButton>
                </ListItem>
            );
        });
    }, [logic.enfantsOfClasse, checked]) ;

   useEffect(() => {
        if(logic.openAddDialogForm === false){
            setIdClassRoomSel(0);
            setChecked([]);
            return ;
        }

        return () => {};
    }, [logic.openAddDialogForm]);


    useEffect(() => {
        const enfantsOfClasse = _.filter(logic.childrenRef.current, (item:Child) => item.eleves[0]?.classe?.id === idClassRoomSel && !_.includes(logic.idEnfantsOfGroup, item.id));
        groupEnfantStateUpdate.enfantsOfClasse(logic, enfantsOfClasse);
        return () => {};
    }, [idClassRoomSel]);

    // @ts-ignore
    return (
        <DialogFormKiller
            theme={logic.theme}
            savingBtn={true}
            savingStateBtn={logic.processingStateNormBtn}
            title={<FormattedMessage id={messages.add_children}/>}
            open={logic.openAddDialogForm}
            setOpen={logic.setOpenAddDialogForm}
            btnCloseLabel={messages.dialog_close}
            onSubmit={onSubmit}>
            <Box sx={{width: '100%'}}>
                <Grid container spacing={2} sx={{marginBottom:2}}>
                    <Grid item md={12} xs={12}>
                        <TextField
                            select
                            label={<FormattedMessage id={messages.classroom_child}/>}
                            sx={{width: {xs: '100% !important'},}}
                            color={"secondary"}
                            value={idClassRoomSel}
                            onChange={handleOnClassRoomChange}
                        >
                            <MenuItem key="0" value="0" sx={{fontWeight: 400}}>-------</MenuItem>
                            {classroomMenuItems}
                        </TextField>
                    </Grid>
                </Grid>

                {
                    idClassRoomSel > 0 &&
                    <List
                        dense
                        sx={{ width: '100%',  bgcolor: 'background.paper' }}
                        subheader={<ListSubheader>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox checked={checkedAll} onChange={handleCheckedAll} inputProps={{ 'aria-label': 'controlled' }} />}
                                    label={<FormattedMessage id={messages.return_children_list}/>} />
                            </FormGroup>
                        </ListSubheader>}>
                        {eleveListItems}
                    </List>
                }
            </Box>
        </DialogFormKiller>
    );
}

export default GroupEnfantAddForm ;