import classNames from "classnames";
import ChatHeader from "./ChatHeader";
import ChatFooter from "./ChatFooter";
import spaceRoomBusinessLogic from "./businessLogic/SpaceRoomBusinessLogic";
import React, {useContext, useMemo} from "react";
import {MessageCenterContext} from "./MessageCenter";
import {Box, CircularProgress} from "@mui/material";

const  Space = (props:any) => {
    const logicRef: any = useContext(MessageCenterContext);

    const listeDesMessages = useMemo(() => spaceRoomBusinessLogic.listerLesMessages(logicRef), [logicRef.messages]);

/*
    if(logicRef.showChatRoom === false){
        return <Box  sx={{ width: '100%', textAlign: 'center', alignContent:'center', }}>
            <CircularProgress color="primary" />
        </Box> ;
    }*/

    return (
        <div className={classNames(logicRef.chatStyles.root, logicRef.chatStyles.content, logicRef.showMobileDetail ? logicRef.chatStyles.detailPopup : '')}>

            <ChatHeader />

            <div className={logicRef.chatStyles.boxChatList} id="roomContainer">
                <div style={{height: 540,}} >
                    <ul className={logicRef.chatStyles.chatList}>
                        {
                            logicRef.loadingMsg &&
                            <li>
                                <Box  sx={{ width: '100%', textAlign: 'center', alignContent:'center', }}>
                                    <CircularProgress color="primary" />
                                </Box>
                            </li>
                        }
                        {
                            !logicRef.loadingMsg &&
                            listeDesMessages
                        }
                    </ul>
                </div>
            </div>
            <div>
                <ChatFooter />
            </div>
            <div style={{display:'none'}} className={logicRef.chatStyles.uploadFile}>
                Upload file
            </div>
        </div>
    );
}

export default Space ;